import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

export interface BackAction {
    fontIcon?: string;
    svgIcon?: string;
    className: string;
    callback: () => void;
}

@Injectable({providedIn: 'root'})
export class BboStoreHeaderService {

    defaultBackAction: BackAction = {
        svgIcon: "bbo-logo",
        className: "bbo-logo",
        callback: () => this.backToHomePage()
    }

    backAction$: BehaviorSubject<BackAction> = new BehaviorSubject(this.defaultBackAction);

    constructor(public router: Router) {}

    setHeaderBackAction(backAction?: BackAction): void {
        if (backAction) {
            this.backAction$.next(backAction);
        } else {
            this.backAction$.next(this.defaultBackAction);
        }
    }

    backToHomePage(): void {
        this.router.navigateByUrl("/home");
        this.backAction$.next(this.defaultBackAction);
    }
}
