import { BboPlusGetResponse } from "src/app/bbo-plus-home/infrastructure/bbo-plus.interface";
import {createGenericObjectActions, EntityAction} from "../../../@core";
import { createAction } from "@ngrx/store";

const subscribe = createAction('[General Store] Current bbo plus subscribe');
const unSubscribe = createAction('[General Store] Current bbo plus unsubscribe');

export const bboPlusActions = ({
    ...createGenericObjectActions<BboPlusGetResponse, BboPlusGetResponse, Partial<BboPlusGetResponse>>('Bbo Plus'),
    subscribe,
    unSubscribe,
})
export type BboPlusActions = EntityAction<BboPlusGetResponse, BboPlusGetResponse, Partial<BboPlusGetResponse>>;