import { Observable, catchError, tap, throwError } from "rxjs";
import {ApplicationError, Response} from 'src/base/response';
import { IUseCase } from "src/base/usecase";
import { BBO_PAYMENT_REPOSITORY, IPaymentRepository } from "../infrastructure/payment.interface";
import { Inject, Injectable } from "@angular/core";
import { UserService } from "src/app/shared/services/user.service";
import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { GeneralStoreFacade } from "src/app/bbo-store/store";

export enum DeleteCreditCardUseCaseError {
    LOCAL_UID_INVALID,
    BAD_REQUEST,
    GENERIC_ERROR
}

@Injectable({
    providedIn: "root"
})
export class DeleteCreditCardUseCase implements IUseCase<void, Observable<void>> {
    constructor(
        @Inject(BBO_PAYMENT_REPOSITORY) private repository: IPaymentRepository,
        private userService: UserService,
        private generalStore: GeneralStoreFacade
    ) {}

    execute(): Observable<void> {
        const localUid = this.userService.getUId();

        if (!localUid) {
            return throwError(() => new Response(
                false,
                null,
                new ApplicationError(DeleteCreditCardUseCaseError.LOCAL_UID_INVALID, "Trying to delete credit card for local user but uid is falsy")
            ));
        }
        return this.repository.deleteCreditCard(localUid ?? '').pipe(
            catchError((error) => {
                let applicationError: ApplicationError | null = null;
                if (error instanceof HttpErrorResponse && (error.status === HttpStatusCode.BadRequest || error.status === HttpStatusCode.UnprocessableEntity)) {
                    applicationError = new ApplicationError(
                        DeleteCreditCardUseCaseError.BAD_REQUEST,
                        `${error.error.title}: ${error.error.detail}`,
                        error
                    )
                } else {
                    applicationError = new ApplicationError(
                        DeleteCreditCardUseCaseError.GENERIC_ERROR,
                        "An error occured while trying to delete the credit card for local user",
                        error
                    );
                }
                return throwError(() => new Response(false, null, applicationError));
            })
        );
    }
}
