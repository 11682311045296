import { Injectable } from "@angular/core";
import { jsPDF } from "jspdf";
import { DatePipe } from "@angular/common";
import { PaymentHistoryItem } from "../domain/get-payment-history.usecase";
import { LanguageService } from "src/app/shared/translations/language.service";

const addText = (doc: jsPDF, title: string, value: string, topPosition: number) => {
    doc.text(title, 20, topPosition);
    doc.text(value, 60, topPosition);
}

const setTitleStyle = (doc: jsPDF): void => {
    doc.setFontSize(27);
}

const setDefaultStyle = (doc: jsPDF): void => {
    doc.setFontSize(13);
}
@Injectable({
    providedIn: "root"
})
export class DownloadReceiptService {

    constructor(
        private datePipe: DatePipe,
        private languageService: LanguageService
    ) {}

    convertReceiptToPdf(paymentHistoryItem: PaymentHistoryItem): void {
        const doc = new jsPDF();
        let lineHeight = 0;
        const receiptDate = this.datePipe.transform(paymentHistoryItem.dateTime, 'dd MMM. YYYY')
        const documentTitle = this.datePipe.transform(paymentHistoryItem.dateTime, 'yyyy-MM-dd');
        setTitleStyle(doc);
        doc.text("Order", 10, lineHeight+=15);
        setDefaultStyle(doc);
        addText(doc, "Amount :", `${paymentHistoryItem.amount.toString()} BB$`, lineHeight+=15);
        setTitleStyle(doc);
        doc.text("Payment details", 10, lineHeight+=15);
        setDefaultStyle(doc);
        const paymentMethod = this.languageService.getXlation(paymentHistoryItem.displayedMethod);
        addText(doc, "Payment method :", paymentMethod, lineHeight+=15);
        addText(doc, "Date :", receiptDate ?? "", lineHeight+=10);
        const status = this.languageService.getXlation(paymentHistoryItem.displayedStatus);
        addText(doc, "Status :", status, lineHeight+=10);
        doc.save(`receipt-${documentTitle}.pdf`);
    }
}