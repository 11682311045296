import {Component} from '@angular/core';

@Component({
  selector: 'bbo-background',
  templateUrl: './page-background.svg',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent {

}
