import {createAction as c, props as p} from '@ngrx/store';
import {
    actionType,
    LOAD,
    LOAD_FAIL,
    LOAD_SUCCESS,
    RELOAD,
    RESET,
    SUBMIT,
    SUBMIT_FAILURE,
    SUBMIT_SUCCESS,
    UPDATE,
    UPDATE_FAIL,
    UPDATE_SUCCESS
} from '../action-type';
import {ActionsBase, IActionWithPropsCreator, IRawActionCreator, LoadFailureProps, LoadPropsBase} from '../base';
import {ApiFailure} from "../base/api-error-base";
import {HttpErrorResponse} from "@angular/common/http";

export interface UpdateEntityPayload<
    P extends object = never,
    Q extends object = never,
> {
    readonly patch: P;
    readonly query?: Q;
}

export interface UpdateEntityProps<P extends object, Q extends object = never>
    extends LoadPropsBase {
    readonly payload: UpdateEntityPayload<P, Q>;
}

export interface LoadEntityProps<Q extends object = never>
    extends LoadPropsBase {
    readonly payload?: Q;
}

export interface LoadEntitySuccessProps<
    D extends object,
    Q extends object = never
> {
    readonly payload: LoadEntitySuccessPayload<D, Q>;
}

export interface LoadEntitySuccessPayload<
    D extends object,
    Q extends object = never,
> {
    readonly data: D;
    readonly query?: Q;
}

export interface SubmitSuccessProps<R extends object> {
    readonly result: R;
}

export interface UpdateEntitySuccessPayload<
    D extends object,
    P extends object = never,
    Q extends object = never,
> {
    readonly patch: P;
    readonly query?: Q;
    readonly updated?: D;
}

export interface UpdateEntitySuccessProps<
    D extends object,
    P extends object = never,
    Q extends object = never,
> {
    readonly payload: UpdateEntitySuccessPayload<P, D, Q>;
}

/**
 * @description
 *  Type params:
 *  - D for Data - represents the basic DTO entity type
 *  - Q for Query - represents params required to get item, e.g. `{ id: string }`
 *  - P for Patch - represents the type of input used to update entity
 */
export interface EntityActions<
    D extends object,
    R extends object,
    P extends object = never,
    Q extends object = never,
    E = Error | HttpErrorResponse
> {
    readonly reset: IRawActionCreator;
    readonly reload: IActionWithPropsCreator<LoadEntityProps<Q>>;
    readonly load: IActionWithPropsCreator<LoadEntityProps<Q>>;
    readonly loadSuccess: IActionWithPropsCreator<LoadEntitySuccessProps<D, Q>>;
    readonly loadFail: IActionWithPropsCreator<LoadFailureProps<E>>;
    readonly update: IActionWithPropsCreator<
        UpdateEntitySuccessProps<P, D>
    >;
    readonly updateSuccess: IActionWithPropsCreator<
        UpdateEntitySuccessProps<P, D>
    >;
    readonly updateFail: IActionWithPropsCreator<LoadFailureProps<E>>;
    readonly submit: IRawActionCreator;
    readonly submitSuccess: IActionWithPropsCreator<
        SubmitSuccessProps<R>
    >;
    readonly submitFailure: IActionWithPropsCreator<LoadFailureProps<E>>;
}

/**
 * @description
 *  Type params:
 *  - D for Data - represents the basic DTO entity type
 *  - P for Patch - represents the type of input used to update entity,
 *  - Q for Query - represents params required to get item, e.g. `{ id: string }`
 *  - E for Error
 *  - R for result
 */
export const createGenericObjectActions = <
    D extends object,
    R extends object,
    P extends object = never,
    Q extends object = never,
    E = Error | HttpErrorResponse
>(
    scope: string
): EntityActions<D, R, P, Q, E> & ActionsBase => {
    const type = actionType(scope);

    return {
        scope,
        type,
        reset: c(type(RESET)),
        reload: c(type(RELOAD), p<LoadEntityProps<Q>>()),
        load: c(type(LOAD), p<LoadEntityProps<Q>>()),
        loadSuccess: c(type(LOAD_SUCCESS), p<LoadEntitySuccessProps<D, Q>>()),
        loadFail: c(type(LOAD_FAIL), p<LoadFailureProps<E>>()),
        update: c(
            type(UPDATE),
            p<UpdateEntitySuccessProps<P, D>>()
        ),
        updateSuccess: c(
            type(UPDATE_SUCCESS),
            p<UpdateEntitySuccessProps<P, D>>()
        ),
        updateFail: c(type(UPDATE_FAIL), p<LoadFailureProps<E>>()),
        submit: c(type(SUBMIT)),
        submitSuccess: c(type(SUBMIT_SUCCESS), p<SubmitSuccessProps<R>>()),
        submitFailure: c(type(SUBMIT_FAILURE), p<LoadFailureProps<E>>())
    };
};

/**
 * @description
 *  Type params:
 *  - D for Data - represents the basic DTO entity type
 *  - R for Result
 *  - P for Patch - represents the type of input used to update entity
 *  - E for Error
 */
export type EntityAction<
    D extends object,
    R extends object,
    P extends object = never,
    Q extends object = never,
    E = Error | HttpErrorResponse
> =
    | IRawActionCreator
    | IActionWithPropsCreator<LoadPropsBase>
    | IActionWithPropsCreator<LoadEntityProps<Q>>
    | IActionWithPropsCreator<LoadEntitySuccessProps<D, Q>>
    | IActionWithPropsCreator<UpdateEntitySuccessProps<P, D>>
    | IActionWithPropsCreator<SubmitSuccessProps<R>>
    | IActionWithPropsCreator<LoadFailureProps<E>>
