import {ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {Option} from "../../../@core";
import {CreditCard, PaymentMethod} from "../../../payment";
import {XlationCodes} from '../../translations/xlation.codes';
import {CreditCardService} from "../../../payment/service/credit-card.service";
import {
    BillingAddressGetResponse,
    BillingAddressRequest
} from "../../../billing-address/domain/get-billing-address.usecase";
import {
    CreditCardFormDialogOutput
} from "../../../payment/ui/credit-card-form-dialog/credit-card-form-dialog.component";

@Component({
    selector: 'bbo-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent {
    xlationCodes = XlationCodes;
    @Input() creditCard: Option<CreditCard>;
    @Input() billingAddress: BillingAddressRequest | null | undefined;
    @Input() paymentMethod: Option<PaymentMethod>;
    @Input() price: Option<number>;
    @Input() paypalOrderId?: string | null;
    @Input() enablePayWithOtherCard?: boolean | null;
    @Input() enablePaymentMethodEdit = false;
    @Input() enableChangePaymentMethodButton = true;
    @Input() enableSaveCardOnAdd = false;
    @Input() titleKey = "";

    @Output() userWantsToChangePaymentMethod: EventEmitter<void> = new EventEmitter();
    @Output() userWantsToPay: EventEmitter<boolean> = new EventEmitter();
    @Output() paypalOrderApproved: EventEmitter<void> = new EventEmitter<void>();
    @Output() addCardAsPayment: EventEmitter<CreditCardFormDialogOutput> = new EventEmitter<CreditCardFormDialogOutput>();
    @Output() saveCard: EventEmitter<CreditCard> = new EventEmitter<CreditCard>();
    @Output() deleteCard: EventEmitter<void> = new EventEmitter<void>();
    @Output() updateCard: EventEmitter<{
        creditCard: CreditCard,
        billingAddress: BillingAddressRequest
    }> = new EventEmitter<{ creditCard: CreditCard; billingAddress: BillingAddressRequest }>();
    // @Output() cardUpdate: EventEmitter<CreditCardFormDialogOutput> = new EventEmitter<CreditCardFormDialogOutput>();

    isPaypalApproved = false;
    isOrderSubmitted = false;

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly creditCardService: CreditCardService
    ) {
    }

    ngOnInit(): void {
        this.isPaypalApproved = false;
    }

    proceedToPayment(state: boolean): void {
        this.isOrderSubmitted = true;
        this.userWantsToPay.emit(state);
        // disable submit button since we dont have state management for now...
        setTimeout(() => {
            this.isOrderSubmitted = false;
        }, 3500)
    }

    changePaymentMethod(): void {
        this.userWantsToChangePaymentMethod.emit();
    }

    approvePaypalOrder() {
        this.paypalOrderApproved.emit();
        this.isPaypalApproved = true;
        this.cdr.markForCheck();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const changedPayPalOrderId = changes['paypalOrderId']
        if (changedPayPalOrderId) {
            this.isPaypalApproved = false;
            this.paypalOrderId = changedPayPalOrderId.currentValue;
        }
    }

    // eslint-disable-next-line class-methods-use-this
    openLink(url: string): void {
        window.open(url, "_blank");
    }

    openOneTimeCardModal() {
        this.creditCardService.openModalUseDifferentCard()
    }

    openAddCardModal() {
        this.creditCardService.openFillFormModal({
            showSaveCard: this.enableSaveCardOnAdd,
            submitButtonLabel: this.xlationCodes.continue
        }).pipe()
            .subscribe((v) => this.addCardAsPayment.emit(v));
    }

    openEditCardModal() {
        this.creditCardService.openFillFormModal({
                creditCard: this.creditCard,
                billingAddress: this.billingAddress as BillingAddressGetResponse
            }
        ).pipe().subscribe((v) => {
            if (v.deleteCard) {
                this.deleteCard.emit();
            } else {
                this.updateCard.emit({
                    creditCard: v.creditCard as CreditCard,
                    billingAddress: v.billingAddress as BillingAddressRequest
                })
            }
        });
    }
}
