import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './ui/home/home.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from 'src/app/shared/shared.module';
import { AuthenticateModule } from 'src/app/authenticate/authenticate.module';
import { BalanceModule } from 'src/app/balance/balance.module';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { CurrentAutoRefillCardComponent } from './ui/autorefill-current-plan-card/current-auto-refill-card.component';
import { BBO_PRODUCT_REPOSITORY } from 'src/app/product/infrastructure/product.interface';
import { ProductRepository } from 'src/app/product/infrastructure/product.repository';
import { GetCurrentAutoRefillPlanUseCase } from 'src/app/product/domain/get-current-auto-refill-plan.usecase';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { OneTimePurchaseListComponent } from './ui/one-time-purchase-list/one-time-purchase-list.component';
import { GetOneTimePurchaseOffersUseCase } from 'src/app/product/domain/get-one-time-purchase-offers.usecase';
import { MatStepperModule } from '@angular/material/stepper';
import { OneTimePurchaseComponent } from 'src/app/product/ui/one-time-purchase/one-time-purchase.component';
import { PaymentSelectionComponent } from './ui/payment-selection/payment-selection.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BillingAddressModule } from '../billing-address/billing-address.module';
import { OneTimePurchaseCompleteComponent } from './ui/one-time-purchase-complete/one-time-purchase-complete.component';
import { PurchaseFailureComponent } from './ui/purchase-failure/purchase-failure.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {AutoRefillOfferComponent} from './ui/auto-refill-offer/auto-refill-offer.component';
import {AutoRefillOfferListComponent} from './ui/auto-refill-offer-list/auto-refill-offer-list.component';
import {AutoRefillSubscriptionComponent} from "../auto-refill-subsription/auto-refill-subscription.component";
import {
  AutoRefillSubscriptionOverviewComponent
} from "./ui/auto-refill-subscription-overview/auto-refill-subscription-overview.component";
import {
  AutoRefillSubscriptionCheckoutComponent
} from "./ui/auto-refill-subscription-checkout/auto-refill-subscription-checkout.component";
import {
  OneTimePurchaseOrderDetailsComponent
} from "./ui/one-time-purchase-order-details/one-time-purchase-order-details.component";
import {
  AutoRefillSubscriptionPurchaseCompleteComponent
} from "./ui/auto-refill-subscription-purchase-complete/auto-refill-subscription-purchase-complete.component";
import { SubscribeToAutoRefillPlanUseCase } from './domain';
import {PaypalModule} from "../paypal/paypal.module";
import {ProductStoreModule} from "./store/product-store.module";
import { OneTimePurchaseCheckoutComponent } from './ui/one-time-purchase-checkout/one-time-purchase-checkout.component';
import { TranslateModule } from '../shared/translations/translate.module';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { SavingPurchaseProcessModalComponent } from './ui/saving-purchase-process-modal/saving-purchase-process-modal.component';
import { ProductOneTimePurchaseComponent } from './ui/home/product-one-time-purchase/product-one-time-purchase.component';
import { ProductBboPlusComponent } from './ui/home/product-bbo-plus/product-bbo-plus.component';
import { ProductAutorefillPlanComponent } from './ui/home/product-autorefill-plan/product-autorefill-plan.component';


@NgModule({
  declarations: [
    HomeComponent,
    CurrentAutoRefillCardComponent,
    OneTimePurchaseComponent,
    OneTimePurchaseListComponent,
    PaymentSelectionComponent,
    OneTimePurchaseCompleteComponent,
    PurchaseFailureComponent,
    AutoRefillSubscriptionComponent,
    AutoRefillOfferComponent,
    AutoRefillOfferListComponent,
    AutoRefillSubscriptionOverviewComponent,
    AutoRefillSubscriptionCheckoutComponent,
    OneTimePurchaseOrderDetailsComponent,
    AutoRefillSubscriptionPurchaseCompleteComponent,
    OneTimePurchaseOrderDetailsComponent,
    AutoRefillSubscriptionCheckoutComponent,
    AutoRefillSubscriptionOverviewComponent,
    AutoRefillSubscriptionComponent,
    AutoRefillOfferComponent,
    AutoRefillOfferListComponent,
    AutoRefillSubscriptionOverviewComponent,
    PurchaseFailureComponent,
    OneTimePurchaseCheckoutComponent,
    SavingPurchaseProcessModalComponent,
    ProductOneTimePurchaseComponent,
    ProductBboPlusComponent,
    ProductAutorefillPlanComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AuthenticateModule,
    BalanceModule,
    MatIconModule,
    MatDividerModule,
    HttpClientModule,
    RouterModule,
    MatStepperModule,
    BillingAddressModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatAutocompleteModule,
    PaypalModule,
    ProductStoreModule,
    TranslateModule,
    MatProgressSpinnerModule
  ],
  exports: [
    PaymentSelectionComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProductModule {

    static forRoot(): ModuleWithProviders<ProductModule> {
        return {
            ngModule: ProductModule,
            providers: [
                { provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } },
                { provide: BBO_PRODUCT_REPOSITORY, useClass: ProductRepository },
                GetCurrentAutoRefillPlanUseCase,
                GetOneTimePurchaseOffersUseCase,
                SubscribeToAutoRefillPlanUseCase,
            ]
        };
    }

}
