import { Component } from '@angular/core';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';

@Component({
    selector: 'bbo-plus-unsubscribe',
    styleUrls: ['bbo-plus-unsubscribe.component.scss'],
    templateUrl: 'bbo-plus-unsubscribe.component.html'
})

export class BboPlusUnsubscribeComponent {
    xlationCodes = XlationCodes;
}