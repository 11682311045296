import {Injectable} from "@angular/core";

@Injectable()
export class AccessTokenService {

    private token: string | null = null;
    private refreshToken: string | null = null;

    /**
     * Pass empty string to clear the token
     */
    setToken(value: string | null): void {
        this.token = value;
    }

    /**
     * Pass empty string to clear the refresh token
     */
    setRefreshToken(value: string | null): void {
        this.refreshToken = value;
    }

    getToken(): string | null {
        return this.token;
    }

    getRefreshToken(): string | null {
        return this.refreshToken;
    }
}
