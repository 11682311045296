/* eslint-disable no-magic-numbers */
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {concat, Observable, throwError} from 'rxjs';
import {catchError, concatMap, first, ignoreElements, map} from 'rxjs/operators';
import {LogoutUseCase} from 'src/app/authenticate/domain/logout.usecase';
import {AuthenticateService} from 'src/app/authenticate/service/authenticate.service';

/**
 * Add the API access token to each request. If a 401 occurs then try to refresh the access token.
 */
@Injectable()
export class AuthenticateInterceptor implements HttpInterceptor {
    constructor(
        private authenticateService: AuthenticateService,
        private logOutUseCase: LogoutUseCase
    ) {}

    static addToken(req: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
    }

    static skipRequest(request: HttpRequest<unknown>): boolean {
        return request.url.includes("/authenticate") || request.url.includes("/assets/") || request.url.includes("/v2/languages");
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (AuthenticateInterceptor.skipRequest(request)) {
            return next.handle(request);
        }

        return this.authenticateService.token$.pipe(
            first(),
            map(token => AuthenticateInterceptor.addToken(request, token)),
            concatMap(authReq => next.handle(authReq)),
            // Catch the 401 and handle it by refreshing the token and restarting the chain by using the source observable
            // (where a new subscription to this.authService.token$ will get the latest token).
            catchError((err, restart) => {
                // If the request is unauthorized, try refreshing the token before restarting.
                if (err.status === HttpStatusCode.Unauthorized) {
                    return concat(
                        // ignoreElements because we just use the refreshToken$ as a trigger to restart the, we need to return a HttpEvent not a string
                        this.authenticateService.refreshToken$.pipe(ignoreElements()),
                        restart
                    );
                }

                return throwError(() => err);
            }),
            catchError((err) => {
                if (err.status === HttpStatusCode.Unauthorized) {
                    this.logOutUseCase.execute({invalidateRefreshToken: false});
                }
                return throwError(() => err);
            })
        );
    }
}
