<div class="details">
    <ng-container *ngIf="!loading">
        <div style="display: flex;align-items: center; gap:12px">
            <mat-icon *ngIf="prefixIcon" [fontIcon]="prefixIcon"></mat-icon>
            <div class="label">
                <ng-container *ngIf="label">
                    {{label}}
                </ng-container>
                <ng-container *ngIf="!label">
                    <ng-content></ng-content>
                </ng-container>
            </div>
        </div>
        <ng-content select="[radio-button]"></ng-content>
    </ng-container>
    <ng-container *ngIf="loading">
        <div class="loader-container">
            <mat-progress-spinner [diameter]="30" mode="indeterminate"></mat-progress-spinner>
        </div>
    </ng-container>
</div>
<ng-content></ng-content>
