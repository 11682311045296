<ng-container *ngIf="(backAction$ | async) as backAction">
    <mat-icon class="back-action" [ngClass]="backAction.className" [svgIcon]="backAction.svgIcon ?? ''" [fontIcon]="backAction.fontIcon ?? ''" (click)="backAction.callback()"></mat-icon>
</ng-container>
<nav class="nav-bar-container nav-bar-hidden">
    <a *ngFor="let navBarItem of navBarItemsList"
        [ngClass]="'bbo-nav-item '+navBarItem.cssClasses"
        [routerLink]="navBarItem.route"
        routerLinkActive="active"
        [routerLinkActiveOptions]= "{exact: true}">
        {{navBarItem.xlationCode | translate}}
    </a>
</nav>
<span class="spacer"></span>
<div class="profil-wrapper">
    <div class="mobile-hidden">{{userName}}</div>
    <bbo-balance [balanceAmount]="balanceAmount$ | async"></bbo-balance>
</div>
<button mat-icon-button class="menu-icon" (click)="toggleMenuAside()">
    <mat-icon fontIcon="menu"></mat-icon>
</button>
<mat-drawer-container>
    <mat-drawer #drawer class="side-nav-right" mode="over" position="end" [opened]="menuOpened">
        <bbo-side-nav [userName]="userName" [balanceAmount]="balanceAmount$ | async" (closeAside)="toggleMenuAside()"></bbo-side-nav>
    </mat-drawer>
</mat-drawer-container>
