<div class="history-list-container" [hidden]="(shouldHideHistoryList$ | async) && selectedHistoryItem">
    <mat-card class="history-card">
        <mat-card-content class="bbo-medium-card">
            <div class="card-header">
                <span class="bbo-headline-3 bbo-headline-2-md-up">{{xlationCodes.paymentHistory | translate}}</span>
            </div>
            <div class="card-content">
                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef>{{xlationCodes.type | translate}}</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon [svgIcon]="(element.type === 'refill') ? 'refill' : 'oneoff-purchase'"></mat-icon>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef>{{xlationCodes.date | translate}}</th>
                        <td mat-cell *matCellDef="let element"> {{element.dateTime | date:'dd/MM/YYYY hh:mm a'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="method">
                        <th mat-header-cell *matHeaderCellDef [hidden]="lessDetailsInHistoryList$ | async">{{xlationCodes.method | translate}}</th>
                        <td mat-cell *matCellDef="let element" [hidden]="lessDetailsInHistoryList$ | async"> {{element.displayedMethod | translate}}</td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef [hidden]="lessDetailsInHistoryList$ | async">{{xlationCodes.status | translate}}</th>
                        <td mat-cell *matCellDef="let element" [hidden]="lessDetailsInHistoryList$ | async" [ngClass]="{'failed': element.status === 'failed'}"> {{element.displayedStatus | translate}} </td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>{{xlationCodes.amount | translate}}</th>
                        <td mat-cell *matCellDef="let element"> ${{element.amount}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr class="bbo-body-2" [ngClass]="{'selected': paymentHistoryItem.id === selectedHistoryItem?.id}" (click)="onHistoryItemClick(paymentHistoryItem)" mat-row *matRowDef="let paymentHistoryItem; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator
                    #paginator
                    [hidePageSize]="true"
                    [pageSize]="8">
                </mat-paginator>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<div class="blank-for-history-details mobile-hidden" *ngIf="!selectedHistoryItem"></div>
<mat-card class="history-details-card" *ngIf="selectedHistoryItem">
    <bbo-history-details [paymentHistoryItem]="selectedHistoryItem"></bbo-history-details>
</mat-card>