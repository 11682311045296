import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';

import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '../shared/translations/translate.module';

import { BboPlusHomeComponent } from './bbo-plus-home.component';
import { BboPlusSubscribeComponent } from './bbo-plus-subscribe/bbo-plus-subscribe.component';
import { BBO_PLUS_REPOSITORY } from './infrastructure/bbo-plus.interface';
import { BboPlusRepository } from './infrastructure/bbo-plus.repository';
import { GetBboPlusUseCase } from './domain/get-bbo-plus.usecase';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { BboPlusComponent } from './bbo-plus/bbo-plus.component';
import { BboPlusUnsubscribeComponent } from './bbo-plus-unsubscribe/bbo-plus-unsubscribe.component';
import { MatDialogModule } from '@angular/material/dialog';
import { UnsubscribeBboPlusUseCase } from './domain/unsubscribe-bbo-plus.usecase';
import { JoinBboPlusComponent } from './bbo-plus/join-bbo-plus/join-bbo-plus.component';
import { BboPlusDetailsComponent } from './bbo-plus/bbo-plus-details/bbo-plus-details.component';
import { BboPlusTileComponent } from './bbo-plus-tile/bbo-plus-tile.component';
import { MatIconModule } from '@angular/material/icon';
import { SubscribeBboPlusUseCase } from './domain/subscribe-bbo-plus.usecase';
import { BboPlusOrderDetailsComponent } from './bbo-plus-subscribe/bbo-plus-checkout/bbo-plus-order-details/bbo-plus-order-details.component';
import { BboPlusSubscriptionCompletedComponent } from './bbo-plus-subscribe/bbo-plus-subscription-completed/bbo-plus-subscription-completed.component';
import { BboPlusCheckoutComponent } from './bbo-plus-subscribe/bbo-plus-checkout/bbo-plus-checkout.component';
import { BboPlusSubscriptionFailedComponent } from './bbo-plus-subscribe/bbo-plus-subscription-failed/bbo-plus-subscription-failed.component';
import { GetBboPlusPriceUseCase } from './domain/get-bbo-plus-price.usecase';

@NgModule({
    imports: [
        RouterModule,
        TranslateModule,
        SharedModule,
        MatCardModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        CommonModule,
        SharedModule
    ],
    declarations: [
        BboPlusHomeComponent,
        BboPlusComponent,
        BboPlusSubscribeComponent,
        BboPlusUnsubscribeComponent,
        JoinBboPlusComponent,
        BboPlusDetailsComponent,
        BboPlusTileComponent,
        BboPlusOrderDetailsComponent,
        BboPlusSubscriptionCompletedComponent,
        BboPlusCheckoutComponent,
        BboPlusSubscriptionFailedComponent
    ],
    providers: [
        DatePipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BboPlusModule {
    static forRoot(): ModuleWithProviders<BboPlusModule> {
        return {
            ngModule: BboPlusModule,
            providers: [
                GetBboPlusUseCase,
                GetBboPlusPriceUseCase,
                UnsubscribeBboPlusUseCase,
                SubscribeBboPlusUseCase,
                { provide: BBO_PLUS_REPOSITORY, useClass: BboPlusRepository },
            ],
        };
    }
}
