import {Inject, Injectable} from "@angular/core";
import {IUseCase} from "src/base/usecase";
import {catchError, map, Observable, throwError} from "rxjs";
import {ApplicationError, IResponse, Response} from "src/base/response";
import {HttpErrorResponse, HttpStatusCode} from "@angular/common/http";
import {UserService} from "src/app/shared/services/user.service";
import { GeneralStoreFacade } from "src/app/bbo-store/store";
import { BBO_PLUS_REPOSITORY, IBboPlusRepository } from "../infrastructure/bbo-plus.interface";

export enum UnsubscribeBboPlusUseCaseError {
    LOCAL_UID_INVALID,
    GENERIC_ERROR,
    NOT_FOUND
}

export interface BboPlusUnsubscribeResponse {
    owner: {
        id: number
    },
    active: boolean,
    autoRenew: boolean,
    billingType: string,
    expiresAt: Date
}

@Injectable()
export class UnsubscribeBboPlusUseCase implements IUseCase<void, Observable<IResponse<BboPlusUnsubscribeResponse>>> {
    constructor(
        private userService: UserService,
        @Inject(BBO_PLUS_REPOSITORY) private repository: IBboPlusRepository,
        private generalStore: GeneralStoreFacade
    ) {}

    execute(): Observable<IResponse<BboPlusUnsubscribeResponse>> {
        const localUid = this.userService.getUId();

        if (!localUid) {
            return throwError(() => new Response(
                false,
                null,
                new ApplicationError(UnsubscribeBboPlusUseCaseError.LOCAL_UID_INVALID, "Trying to unsubscribe bbo plus for local user but uid is falsy")
            ));
        }

        return this.repository.unsubscribeBboPlus(localUid).pipe(
            catchError((error) => {
                let applicationError: ApplicationError | null = null;
                if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.NotFound) {
                    applicationError = new ApplicationError(
                        UnsubscribeBboPlusUseCaseError.NOT_FOUND,
                        'Fail your user is not registered as a bbo plus member' + localUid,
                    )
                } else {
                    applicationError = new ApplicationError(
                        UnsubscribeBboPlusUseCaseError.GENERIC_ERROR,
                        "Fail trying to unsubscribe bbo plus for local user"
                    );
                }
                return throwError(() => new Response(
                    false,
                    null,
                    applicationError
                ))
            }),
            map((bboPlus: BboPlusUnsubscribeResponse) => {
                this.generalStore.updateGeneralStore({ bboPlus });
                return new Response(
                    true,
                    bboPlus,
                    null
                );
            }),
        );
    }

}
