export interface CountryDefinition {
    id: string;
    label: string;
    states?: {aspect: 'autocomplete', values: string[]} | {aspect: 'select', values: {id: string, label: string}[]};
}

const STATES_OF_USA: {aspect: 'select', values: {id: string, label: string}[]} = {
    aspect: 'select',
    values: [
        {id: "AL", label: "Alabama"},
        {id: "AK", label: "Alaska"},
        {id: "AZ", label: "Arizona"},
        {id: "AR", label: "Arkansas"},
        {id: "CA", label: "California"},
        {id: "CO", label: "Colorado"},
        {id: "CT", label: "Connecticut"},
        {id: "DE", label: "Delaware"},
        {id: "DC", label: "District of Columbia"},
        {id: "FL", label: "Florida"},
        {id: "GA", label: "Georgia"},
        {id: "HI", label: "Hawaii"},
        {id: "ID", label: "Idaho"},
        {id: "IL", label: "Illinois"},
        {id: "IN", label: "Indiana"},
        {id: "IA", label: "Iowa"},
        {id: "KS", label: "Kansas"},
        {id: "KY", label: "Kentuckuy"},
        {id: "LA", label: "Louisiana"},
        {id: "ME", label: "Maine"},
        {id: "MD", label: "Maryland"},
        {id: "MA", label: "Massachusetts"},
        {id: "MI", label: "Michigan"},
        {id: "MN", label: "Minnesota"},
        {id: "MS", label: "Mississippi"},
        {id: "MO", label: "Missouri"},
        {id: "MT", label: "Montana"},
        {id: "NE", label: "Nebraska"},
        {id: "NV", label: "Nevada"},
        {id: "NH", label: "New Hampshire"},
        {id: "NJ", label: "New Jersey"},
        {id: "NM", label: "New Mexico"},
        {id: "NY", label: "New York"},
        {id: "NC", label: "North Carolina"},
        {id: "ND", label: "North Dakota"},
        {id: "OH", label: "Ohio"},
        {id: "OK", label: "Oklahoma"},
        {id: "OR", label: "Oregon"},
        {id: "PA", label: "Pennsylvania"},
        {id: "RI", label: "Rhode Island"},
        {id: "SC", label: "South Carolina"},
        {id: "SD", label: "South Dakota"},
        {id: "TN", label: "Tennessee"},
        {id: "TX", label: "Texas"},
        {id: "UT", label: "Utah"},
        {id: "VT", label: "Vermont"},
        {id: "VA", label: "Virginia"},
        {id: "WA", label: "Washington State"},
        {id: "WV", label: "West Virginia"},
        {id: "WI", label: "Wisconsin"},
        {id: "WY", label: "Wyoming"},
        {id: "AS", label: "American Samoa"},
        {id: "AE", label: "Armed Forces"},
        {id: "AA", label: "Armed Forces America"},
        {id: "AP", label: "Armed Forces Pacific"},
        {id: "GU", label: "Guam"},
        {id: "MH", label: "Marshall Islands"},
        {id: "FM", label: "Micronesia"},
        {id: "MP", label: "Northern Mariana Islands"},
        {id: "PW", label: "Palau"},
        {id: "PR", label: "Puerto Rico"},
        {id: "VI", label: "US Virgin Islands"},
    ]
};

const STATES_OF_CANADA: {aspect: 'select', values: {id: string, label: string}[]} = {
    aspect: 'select',
    values: [
        {id: "AB", label: "Alberta"},
        {id: "BC", label: "British Columbia"},
        {id: "MB", label: "Manitoba"},
        {id: "NB", label: "New Brunswick"},
        {id: "NL", label: "Newfoundland"},
        {id: "NS", label: "Nova Scotia"},
        {id: "NU", label: "Nunavut"},
        {id: "NT", label: "Northwest Territories"},
        {id: "ON", label: "Ontario"},
        {id: "PE", label: "Prince Edward Island"},
        {id: "QC", label: "Quebec"},
        {id: "SK", label: "Saskatchewan"},
        {id: "YT", label: "Yukon"}
    ]
}

const STATES_OF_ITALIA: {aspect: 'autocomplete', values: string[]} = {
    aspect: 'autocomplete',
    values: [
        "Agrigento",
        "Alessandria",
        "Ancona",
        "Aosta",
        "L'Aquila",
        "Arezzo",
        "Ascoli Piceno",
        "Asti",
        "Avellino",
        "Bari",
        "Belluno",
        "Benevento",
        "Bergamo",
        "Biella",
        "Bologna",
        "Bolzano",
        "Brescia",
        "Brindisi",
        "Cagliari",
        "Caltanissetta",
        "Campobasso",
        "Carbonia-Iglesias",
        "Caserta",
        "Catania",
        "Catanzaro",
        "Chieti",
        "Como",
        "Cosenza",
        "Cremona",
        "Crotone",
        "Cuneo",
        "Enna",
        "Ferrara",
        "Firenze",
        "Foggia",
        "Forli",
        "Frosinone",
        "Genova",
        "Gorizia",
        "Grosseto",
        "Imperia",
        "Isernia",
        "Latina",
        "Lecce",
        "Lecco",
        "Livorno",
        "Lodi",
        "Lucca",
        "Macerata",
        "Mantova",
        "Massa Carrara",
        "Matera",
        "Medio Campidano",
        "Messina",
        "Milano",
        "Modena",
        "Napoli",
        "Novara",
        "Nuoro",
        "Ogliastra",
        "Olbia-Tempio",
        "Oristano",
        "Padova",
        "Palermo",
        "Parma",
        "Pavia",
        "Perugia",
        "Pesaro",
        "Pescara",
        "Piacenza",
        "Pisa",
        "Pistoia",
        "Pordenone",
        "Potenza",
        "Prato",
        "Ragusa",
        "Ravenna",
        "Reggio Calabria",
        "Reggio Emilia",
        "Rieti",
        "Rimini",
        "Roma",
        "Rovigo",
        "Salerno",
        "Sassari",
        "Savona",
        "Siena",
        "Siracusa",
        "Sondrio",
        "La Spezia",
        "Taranto",
        "Teramo",
        "Terni",
        "Torino",
        "Trapani",
        "Trento",
        "Treviso",
        "Trieste",
        "Udine",
        "Varese",
        "Venezia",
        "Verbania-Cusio-Ossola",
        "Vercelli",
        "Verona",
        "Vibo Valentia",
        "Vicenza",
        "Viterbo"
    ]
}

export const COUNTRIES: CountryDefinition[] = [
    {id: "AL", label: "Albania",},
    {id: "DZ", label: "Algeria",},
    {id: "AD", label: "Andorra",},
    {id: "AO", label: "Angola",},
    {id: "AI", label: "Anguilla",},
    {id: "AG", label: "Antigua and Barbuda",},
    {id: "AR", label: "Argentina",},
    {id: "AM", label: "Armenia",},
    {id: "AW", label: "Aruba",},
    {id: "AU", label: "Australia",},
    {id: "AT", label: "Austria",},
    {id: "AZ", label: "Azerbaijan Republic",},
    {id: "BS", label: "Bahamas",},
    {id: "BH", label: "Bahrain",},
    {id: "BD", label: "Bangladesh",},
    {id: "BB", label: "Barbados",},
    {id: "BE", label: "Belgium",},
    {id: "BZ", label: "Belize",},
    {id: "BJ", label: "Benin",},
    {id: "BM", label: "Bermuda",},
    {id: "BT", label: "Bhutan",},
    {id: "BO", label: "Bolivia",},
    {id: "BA", label: "Bosnia and Herzegovina",},
    {id: "BW", label: "Botswana",},
    {id: "BR", label: "Brazil",},
    {id: "VG", label: "British Virgin Islands",},
    {id: "BN", label: "Brunei",},
    {id: "BG", label: "Bulgaria",},
    {id: "BF", label: "Burkina Faso",},
    {id: "BI", label: "Burundi",},
    {id: "KH", label: "Cambodia",},
    {id: "CA", label: "Canada", states: STATES_OF_CANADA},
    {id: "CV", label: "Cape Verde",},
    {id: "KY", label: "Cayman Islands",},
    {id: "TD", label: "Chad",},
    {id: "CL", label: "Chile",},
    {id: "CN", label: "China",},
    {id: "CO", label: "Colombia",},
    {id: "KM", label: "Comoros",},
    {id: "CK", label: "Cook Islands",},
    {id: "CR", label: "Costa Rica",},
    {id: "HR", label: "Croatia",},
    {id: "CY", label: "Cyprus",},
    {id: "CZ", label: "Czech Republic",},
    {id: "CD", label: "Democratic Republic of the Congo",},
    {id: "DK", label: "Denmark",},
    {id: "DJ", label: "Djibouti",},
    {id: "DM", label: "Dominica",},
    {id: "DO", label: "Dominican Republic",},
    {id: "EC", label: "Ecuador",},
    {id: "SV", label: "El Salvador",},
    {id: "EG", label: "Egypt",},
    {id: "ER", label: "Eritrea",},
    {id: "EE", label: "Estonia",},
    {id: "ET", label: "Ethiopia",},
    {id: "FK", label: "Falkland Islands",},
    {id: "FO", label: "Faroe Islands",},
    {id: "FM", label: "Federated States of Micronesia",},
    {id: "FJ", label: "Fiji",},
    {id: "FI", label: "Finland",},
    {id: "FR", label: "France",},
    {id: "GF", label: "French Guiana",},
    {id: "PF", label: "French Polynesia",},
    {id: "GA", label: "Gabon Republic",},
    {id: "GM", label: "Gambia",},
    {id: "DE", label: "Germany",},
    {id: "GI", label: "Gibraltar",},
    {id: "GR", label: "Greece",},
    {id: "GL", label: "Greenland",},
    {id: "GD", label: "Grenada",},
    {id: "GP", label: "Guadeloupe",},
    {id: "GT", label: "Guatemala",},
    {id: "GN", label: "Guinea",},
    {id: "GW", label: "Guinea Bissau",},
    {id: "GY", label: "Guyana",},
    {id: "HN", label: "Honduras",},
    {id: "HK", label: "Hong Kong",},
    {id: "HU", label: "Hungary",},
    {id: "IS", label: "Iceland",},
    {id: "IN", label: "India",},
    {id: "ID", label: "Indonesia",},
    {id: "IE", label: "Ireland",},
    {id: "IL", label: "Israel",},
    {id: "IT", label: "Italy", states: STATES_OF_ITALIA},
    {id: "JM", label: "Jamaica",},
    {id: "JP", label: "Japan",},
    {id: "JO", label: "Jordan",},
    {id: "KZ", label: "Kazakhstan",},
    {id: "KE", label: "Kenya",},
    {id: "KI", label: "Kiribati",},
    {id: "KW", label: "Kuwait",},
    {id: "KG", label: "Kyrgyzstan",},
    {id: "LA", label: "Laos",},
    {id: "LV", label: "Latvia",},
    {id: "LB", label: "Lebanon",},
    {id: "LS", label: "Lesotho",},
    {id: "LI", label: "Liechtenstein",},
    {id: "LT", label: "Lithuania",},
    {id: "LU", label: "Luxembourg",},
    {id: "MG", label: "Madagascar",},
    {id: "MW", label: "Malawi",},
    {id: "MY", label: "Malaysia",},
    {id: "MV", label: "Maldives",},
    {id: "ML", label: "Mali",},
    {id: "MT", label: "Malta",},
    {id: "MH", label: "Marshall Islands",},
    {id: "MQ", label: "Martinique",},
    {id: "MR", label: "Mauritania",},
    {id: "MU", label: "Mauritius",},
    {id: "YT", label: "Mayotte",},
    {id: "MX", label: "Mexico",},
    {id: "MN", label: "Mongolia",},
    {id: "MC", label: "Monaco",},
    {id: "ME", label: "Montenegro",},
    {id: "MS", label: "Montserrat",},
    {id: "MA", label: "Morocco",},
    {id: "MZ", label: "Mozambique",},
    {id: "NA", label: "Namibia",},
    {id: "NR", label: "Nauru",},
    {id: "NP", label: "Nepal",},
    {id: "NL", label: "Netherlands",},
    {id: "AN", label: "Netherlands Antilles",},
    {id: "NC", label: "New Caledonia",},
    {id: "NZ", label: "New Zealand",},
    {id: "NI", label: "Nicaragua",},
    {id: "NE", label: "Niger",},
    {id: "NU", label: "Niue",},
    {id: "NF", label: "Norfolk Island",},
    {id: "MK", label: "North Macedonia",},
    {id: "NO", label: "Norway",},
    {id: "OM", label: "Oman",},
    {id: "PK", label: "Pakistan",},
    {id: "PW", label: "Palau",},
    {id: "PA", label: "Panama",},
    {id: "PG", label: "Papua New Guinea",},
    {id: "PY", label: "Paraguay",},
    {id: "PE", label: "Peru",},
    {id: "PH", label: "Philippines",},
    {id: "PN", label: "Pitcairn Islands",},
    {id: "PL", label: "Poland",},
    {id: "PT", label: "Portugal",},
    {id: "QA", label: "Qatar",},
    {id: "CG", label: "Republic of the Congo",},
    {id: "RE", label: "Reunion",},
    {id: "RO", label: "Romania",},
    {id: "RU", label: "Russia",},
    {id: "RW", label: "Rwanda",},
    {id: "VC", label: "Saint Vincent and the Grenadines",},
    {id: "WS", label: "Samoa",},
    {id: "SM", label: "San Marino",},
    {id: "ST", label: "S&atilde;o Tom&eacute; and Pr&iacute;ncipe",},
    {id: "SA", label: "Saudi Arabia",},
    {id: "SN", label: "Senegal",},
    {id: "RS", label: "Serbia",},
    {id: "SC", label: "Seychelles",},
    {id: "SL", label: "Sierra Leone",},
    {id: "SG", label: "Singapore",},
    {id: "SK", label: "Slovakia",},
    {id: "SI", label: "Slovenia",},
    {id: "SB", label: "Solomon Islands",},
    {id: "SO", label: "Somalia",},
    {id: "ZA", label: "South Africa",},
    {id: "KR", label: "South Korea",},
    {id: "ES", label: "Spain",},
    {id: "LK", label: "Sri Lanka",},
    {id: "SH", label: "St. Helena",},
    {id: "KN", label: "St. Kitts and Nevis",},
    {id: "LC", label: "St. Lucia",},
    {id: "PM", label: "St. Pierre and Miquelon",},
    {id: "SR", label: "Suriname",},
    {id: "SJ", label: "Svalbard and Jan Mayen Islands",},
    {id: "SZ", label: "Swaziland",},
    {id: "SE", label: "Sweden",},
    {id: "CH", label: "Switzerland",},
    {id: "TW", label: "Taiwan",},
    {id: "TJ", label: "Tajikistan",},
    {id: "TZ", label: "Tanzania",},
    {id: "TH", label: "Thailand",},
    {id: "TG", label: "Togo",},
    {id: "TO", label: "Tonga",},
    {id: "TT", label: "Trinidad and Tobago",},
    {id: "TN", label: "Tunisia",},
    {id: "TR", label: "Turkey",},
    {id: "TM", label: "Turkmenistan",},
    {id: "TC", label: "Turks and Caicos Islands",},
    {id: "TV", label: "Tuvalu",},
    {id: "UG", label: "Uganda",},
    {id: "UA", label: "Ukraine",},
    {id: "AE", label: "United Arab Emirates",},
    {id: "GB", label: "United Kingdom",},
    {id: "US", label: "United States", states: STATES_OF_USA},
    {id: "UY", label: "Uruguay",},
    {id: "VU", label: "Vanuatu",},
    {id: "VA", label: "Vatican City State",},
    {id: "VE", label: "Venezuela",},
    {id: "VN", label: "Vietnam",},
    {id: "WF", label: "Wallis and Futuna Islands",},
    {id: "YE", label: "Yemen",},
    {id: "ZM", label: "Zambia",},
    {id: "ZW", label: "Zimbabwe",},
];


