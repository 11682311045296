import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {oneTimePurchaseFlowReducers, PRODUCT_STORE_NAME} from "./reducers";
import {EffectsModule} from "@ngrx/effects";
import {AutoRefillSubscriptionFlowFacade, OneTimePurchaseFlowFacade} from "./services";
import {OneTimePurchaseFlowEffects} from "./effects/one-time-purchase-flow.effects";
import {AutoRefillSubscriptionFlowEffects} from "./effects/auto-refill-subscription-flow.effects";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(PRODUCT_STORE_NAME, oneTimePurchaseFlowReducers),
        EffectsModule.forFeature([OneTimePurchaseFlowEffects, AutoRefillSubscriptionFlowEffects]),
    ],
    providers: [
        OneTimePurchaseFlowFacade,
        AutoRefillSubscriptionFlowFacade
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProductStoreModule {

}
