import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs';
import {
    BBOAuthenticateAPIPayload,
    IAuthenticateRepository
} from 'src/app/authenticate/infrastructure/authenticate.interface';
import {environment} from 'src/environments/environment';

@Injectable()
export class AuthenticateRepository implements IAuthenticateRepository {

    constructor(
        private http: HttpClient
    ) {}

    login(username: string, password: string) {
        return this.http.post<BBOAuthenticateAPIPayload>(
            `${environment.apiURL}/authenticate`,
            {username, password},
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        );
    }

    invalidateRefreshToken(refreshToken: string) {
        return this.http.post<{refreshToken: string}>(
            `${environment.apiURL}/authenticate/invalidate`,
            {"refreshToken": refreshToken},
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        ).pipe(
          map(() => undefined)
        );
    }

    refresh(refreshToken: string) {
        return this.http.post<BBOAuthenticateAPIPayload>(
            `${environment.apiURL}/authenticate/refresh`,
            {"refreshToken": refreshToken},
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        );
    }

}
