import { Component, Input } from '@angular/core';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';
import { BboPlusGetResponse } from '../../infrastructure/bbo-plus.interface';
import { Nullable } from 'src/app/@core';
import { BBO_PLUS_ADVANTAGES_LIST } from '../../bbo-plus.constants';

@Component({
    selector: 'bbo-join-bbo-plus',
    templateUrl: './join-bbo-plus.component.html',
    styleUrls: ['./join-bbo-plus.component.scss']
})
export class JoinBboPlusComponent {

    advantagesList = BBO_PLUS_ADVANTAGES_LIST;
    xlationCodes = XlationCodes;

    @Input() bboPlus: Nullable<BboPlusGetResponse> = null;
    @Input() bboPlusTrial: {remainingDays: number | null} | null = null;
    /** in cents */
    @Input() price: number | null = null;
}
