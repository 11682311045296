import {NgModule} from '@angular/core';
import {BalanceModule} from 'src/app/balance/balance.module';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {LayoutModule} from '@angular/cdk/layout';
import {RouterModule} from '@angular/router';
import {BboStoreHeaderComponent} from './bbo-store-header.component';
import {SideNavComponent} from './side-nav/side-nav.component';
import {SideNavigationMenuItemComponent} from './side-navigation-menu-item/side-navigation-menu-item.component';
import {CommonModule} from '@angular/common';
import {AuthenticateModule} from 'src/app/authenticate/authenticate.module';
import { TranslateModule } from '../shared/translations/translate.module';

@NgModule({
    imports: [
        CommonModule,
        BalanceModule,
        MatSidenavModule,
        MatDividerModule,
        MatButtonModule,
        MatIconModule,
        LayoutModule,
        RouterModule,
        AuthenticateModule,
        TranslateModule
    ],
    exports: [
        BboStoreHeaderComponent
    ],
    declarations: [
        BboStoreHeaderComponent,
        SideNavComponent,
        SideNavigationMenuItemComponent
    ]
})
export class BboStoreHeaderModule { }
