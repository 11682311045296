<div [formGroup]="billingAddressForm">
    <!-- email -->
    <div class="bbo-form-field">
        <label class="bbo-form-label" for="email">{{xlationCodes.email | translate}}</label>
        <mat-form-field appearance="outline">
            <input email id="email" formControlName="email" autocomplete="email" matInput [placeholder]="xlationCodes.emailPlaceholder | translate">
            <mat-error *ngIf="getBillingEmail?.hasError('required')">{{xlationCodes.formRequiredError | translate}}</mat-error>
            <mat-error *ngIf="getBillingEmail?.hasError('email')">{{xlationCodes.badEmailFormat | translate}}</mat-error>
        </mat-form-field>
    </div>
    <!-- address -->
    <div class="bbo-form-field">
        <label class="bbo-form-label" for="address">{{xlationCodes.address | translate}}</label>
        <mat-form-field appearance="outline">
            <input id="address" formControlName="address" autocomplete="address-level1" matInput [placeholder]="xlationCodes.addressPlaceholder | translate">
            <mat-error *ngIf="getBillingAddressLine1?.hasError('required')">{{xlationCodes.formRequiredError | translate}}</mat-error>
        </mat-form-field>
    </div>

    <!-- address (line 2) -->
    <div class="bbo-form-field">
        <label class="bbo-form-label" for="address-2">{{xlationCodes.address2 | translate}}</label>
        <mat-form-field appearance="outline">
            <input id="address-2" formControlName="address-2" autocomplete="address-level2" matInput [placeholder]="xlationCodes.address2Placeholder | translate">
            <mat-error *ngIf="getBillingAddressLine2?.hasError('required')">{{xlationCodes.formRequiredError | translate}}</mat-error>
        </mat-form-field>
    </div>

    <div class="bbo-meta-form-field">
        <!-- country -->
        <div class="bbo-form-field">
            <label class="bbo-form-label" id="country-field-label">{{xlationCodes.country | translate}}</label>
            <mat-form-field appearance="outline">
                <mat-select formControlName="country" aria-labelledby="country-field-label" autocomplete="country-name" [placeholder]='xlationCodes.countryPlaceholder | translate'>
                    <mat-option *ngFor="let country of countries" [value]="country">{{country.label}}</mat-option>
                </mat-select>
                <mat-error *ngIf="getBillingAddressCountry?.hasError('required')">{{xlationCodes.formRequiredError | translate}}</mat-error>
            </mat-form-field>
        </div>
        <!-- State -->
        <div class="bbo-form-field">
            <label class="bbo-form-label" id="region-field-label" for="region">{{xlationCodes.state | translate}}</label>
            <mat-form-field appearance="outline">
                <!--
                    For some countries the backend expect a specific id to describe the state
                    Thus we show a select.
                    Else we show a freeform input (maybe with proposed choices) where user can
                    write the region's name.
                -->
                <mat-select formControlName="region" aria-labelledby="region-field-label" [placeholder]="xlationCodes.statePlaceholder | translate" *ngIf="getBillingAddressCountry?.value?.states?.aspect === 'select'">
                    <mat-option *ngFor="let state of statesForSelect" [value]="state.id">{{state.label}}</mat-option>
                </mat-select>
                <ng-container *ngIf="getBillingAddressCountry?.value?.states?.aspect !== 'select'">
                    <mat-autocomplete #auto="matAutocomplete" aria-labelledby="region">
                        <mat-option *ngFor="let state of filteredStatesOptions | async" [value]="state">{{state}}</mat-option>
                    </mat-autocomplete>
                    <input type="text"
                        formControlName="region"
                        [placeholder]="xlationCodes.statePlaceholder | translate"
                        matInput
                        [matAutocomplete]="auto"
                        id="region"
                    >
                </ng-container>
                <mat-error *ngIf="getBillingAddressRegion?.hasError('required')">{{xlationCodes.formRequiredError | translate}}</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="bbo-meta-form-field">
        <!-- City -->
        <div class="bbo-form-field">
            <label class="bbo-form-label" for="city">{{xlationCodes.city | translate}}</label>
            <mat-form-field appearance="outline">
                <input id="city" formControlName="city" autocomplete="address-level2" matInput [placeholder]="xlationCodes.cityPlaceholder | translate">
                <mat-error *ngIf="getBillingAddressCity?.hasError('required')">{{xlationCodes.formRequiredError | translate}}</mat-error>
            </mat-form-field>
        </div>
        <!-- ZIP/Post code -->
        <div class="bbo-form-field">
            <label class="bbo-form-label" for="zip-code">{{xlationCodes.zipCode | translate}}</label>
            <mat-form-field appearance="outline">
                <input id="zip-code" formControlName="postalCode" autocomplete="postal-code" matInput [placeholder]="xlationCodes.zipCodePlaceholder | translate">
                <mat-error *ngIf="getBillingAddressPostalCode?.hasError('required')">{{xlationCodes.formRequiredError | translate}}</mat-error>
                <mat-error *ngIf="getBillingAddressPostalCode?.hasError('invalidZipCode')">{{xlationCodes.zipCodeMaxlengthError | translate}}</mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
