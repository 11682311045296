import {Component} from '@angular/core';
import { XlationCodes } from '../../shared/translations/xlation.codes';

@Component({
    selector: 'bbo-delete-current-plan-confirmation-modal',
    styleUrls: ['./delete-current-plan-confirmation-modal.component.scss'],
    templateUrl: 'delete-current-plan-confirmation-modal.component.html'
})

export class DeleteCurrentPlanConfirmationModalComponent {
    xlationCodes = XlationCodes;
}
