import {Component, Input} from '@angular/core';
import {ProductAutoRefillPlanOffer} from "../../domain";
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';

@Component({
  selector: 'bbo-auto-refill-offer',
  templateUrl: './auto-refill-offer.component.html',
  styleUrls: ['./auto-refill-offer.component.scss']
})
export class AutoRefillOfferComponent {
  xlationCodes = XlationCodes;
  @Input()
  offer!: ProductAutoRefillPlanOffer;
}
