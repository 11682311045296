<mat-card class="purchase-complete-card">
    <mat-card-content class="bbo-medium-card">
        <div class="card-header">
            <span class="bbo-headline-3 bbo-headline-2-md-up">{{xlationCodes.orderComplete | translate}}</span>
        </div>
        <div class="card-content">
            <div class="purchased-item">
                <mat-icon svgIcon="bbocoin" class="badge"></mat-icon>
                <div class="amount bbo-headline-2">+{{amount}} BB$</div>
            </div>
            <div class="confirmation-by-mail-content bbo-typography color-gray">
                <div class="mobile-hidden">{{xlationCodes.sendingConfirmationEmail | translate}}</div>
                <div>{{xlationCodes.creditedSoon | translate}}</div>
            </div>
            <div class="auto-refill-content" *ngIf="!(currentAutoRefillPlan$ | async) && !(currentAutoRefillPlanLoading$ | async)">
                <div class="title bbo-headline-6 font-weight-bold">{{xlationCodes.subscribeToAutoRefill | translate}}</div>
                <div class="content">
                    <div class="auto-refill-text bbo-body-2">
                        {{xlationCodes.purchaseCompleteAutoRefillAdvise | translate}}
                    </div>
                    <button class="bbo-button bbo-small-button" mat-button color="primary" routerLink="/auto-refill">{{xlationCodes.chooseAPlan | translate}}</button>
                </div>
            </div>
            <button mat-flat-button class="bbo-button" color="primary" (click)="goBackToBBO()">{{buttonXlationKey | translate}}</button>
        </div>
    </mat-card-content>
</mat-card>