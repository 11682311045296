import { Component, Input } from '@angular/core';
import { XlationCodes } from '../../shared/translations/xlation.codes';

@Component({
    selector: 'bbo-bbo-plus-tile',
    templateUrl: './bbo-plus-tile.component.html',
    styleUrls: ['./bbo-plus-tile.component.scss']
})
export class BboPlusTileComponent {
    /** in cents */
    @Input() amount: number | null = null;

    xlationCodes = XlationCodes;
}
