import {ActionCreator, createReducer, on} from '@ngrx/store';
import {ReducerTypes} from '@ngrx/store/src/reducer_creator';
import {isApiHttpError, isHttpError, isNotNil, Nullable} from '../base';
import {EntityActions} from './generic-object-actions';
import {StateSlice} from "../base/state";
import {HttpErrorResponse} from "@angular/common/http";
import {ApiErrorBase, ApiFailure} from "../base/api-error-base";

/**
 * @description
 *  Type params:
 *  - D for Data - represents the basic DTO entity type
 *  - R for Result from submit
 *  - Ext for Extension - represents possible additional data in store
 */
export interface EntityStateSlice<
    D,
    R,
    Ext extends object = never,
> extends StateSlice<Nullable<D>> {
    readonly extension?: Ext;
    readonly result: R;
}

/**
 * @description
 *  Type params:
 *  - D for Data - represents the basic DTO entity type
 *  - P for Patch - represents the type of input used to update entity
 *  - Ext for Extension - represents possible additional data in store
 */
export type EntityReducerConfig<
    D extends object,
    R extends object,
    P extends object = never,
    Q extends object = never,
    Ext extends object = never,
    E = Nullable<ApiErrorBase>,
    AE = ApiFailure
> = {
    readonly mapError?: (err?: AE) => AE;
    readonly setData?: (data: D, stateData: Nullable<D>) => D;
    readonly updateData?: (
        patch: P,
        updated: D | undefined,
        stateData: Nullable<D>
    ) => Nullable<D>;
    readonly initialEntity?: Nullable<D>;
    readonly initialResult?: Nullable<R>;
    readonly initialExtension?: Ext;
};

/**
 * @description
 *  Type params:
 *  - D for Data - represents the basic DTO entity type
 *  - Q for Query - represents params required to get item, e.g. `{ id: string }`
 *  - P for Patch - represents the type of input used to update entity
 *  - Ext for Extension - represents possible additional data in store
 */
export const createGenericObjectReducer =
    <
        D extends object,
        R extends object = never,
        P extends object = never,
        Q extends object = never,
        Ext extends object = never,
        AE = ApiFailure
    >(
        a: EntityActions<D, R, P, Q, AE>,
        {
            mapError = error =>
                isNotNil(error) && (isHttpError(error) || isApiHttpError(error as HttpErrorResponse)) ? (error as any)?.error : null,
            setData = data => data,
            updateData = (patch, data, stateData) =>
                data || {
                    ...(stateData || {}),
                    ...(patch as unknown as D)
                },
            initialEntity = null,
            initialResult = null,
            initialExtension: extension
        }: EntityReducerConfig<D, R, P, Q, Ext, AE> = {}
    ) =>
        (...ons: ReducerTypes<EntityStateSlice<D, Nullable<R>, Ext>, ActionCreator[]>[]) => {
            const initialState: EntityStateSlice<D, Nullable<R>, Ext> = {
                result: initialResult,
                extension,
                data: initialEntity,
                loaded: false,
                loading: false,
                error: null,
            };

            const reducer = createReducer(
                initialState,

                on(a.reset, () => initialState),

                on(a.load, (state, { payload }) => ({
                    ...state,
                    loading: true,
                    loaded: false
                })),

                on(a.loadSuccess, (state, { payload }) => ({
                    ...state,
                    data: setData(payload.data, state.data),
                    error: null,
                    loading: false,
                    loaded: true,
                    query: payload.query || ({} as Q)
                })),

                on(a.update, (state, {payload}) => {
                    return ({
                        ...state,
                        data: updateData(payload.patch, payload.updated, state.data),
                        loading: false,
                        loaded: true,
                    })
                }),
                on(a.submit, (state) => {
                    return ({
                        ...state,
                        loading: true,
                        loaded: false,
                    })
                }),
                on(a.submitSuccess, (state, payload) => {
                    return ({
                        ...state,
                        loading: false,
                        loaded: true,
                        result: payload.result,
                        error: null
                    })
                }),
                on(a.submitFailure, (state, {error}) => {
                    return ({
                        ...state,
                        loading: false,
                        loaded: false,
                        error: mapError(error as Error)
                    })
                }),
                ...ons
            );

            return {initialState, reducer};
        };
