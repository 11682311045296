<mat-card class="product-autorefill">
    <mat-card-content class="bbo-medium-card">
        <article>
            <div class="product-head">
                <mat-icon svgIcon="refill"></mat-icon>
                <span class="bbo-headline-3">{{xlationCodes.autoRefill | translate}}</span>
            </div>
            <div class="bbo-body-1 font-weight-semibold divider-after">{{xlationCodes.subscribeToAutoRefillHint | translate}}</div>
            <div class="bbo-body-2 color-gray">
                {{xlationCodes.autoRefillDescription | translate}}
            </div>
            <div class="product-actions">
                <button mat-flat-button class="bbo-button width-100" routerLink="/auto-refill" color="primary">{{xlationCodes.choosePlan | translate}}</button>
            </div>
        </article>
    </mat-card-content>
</mat-card>