import {Inject, Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {
    BBO_AUTHENTICATE_REPOSITORY,
    IAuthenticateRepository
} from "src/app/authenticate/infrastructure/authenticate.interface";
import {AccessTokenService} from "src/app/authenticate/service/access-token.service";
import {AuthenticateService} from "src/app/authenticate/service/authenticate.service";
import {IUseCase} from "src/base/usecase";
import {UserService} from '../../shared/services/user.service';
import { GeneralStoreFacade } from "src/app/bbo-store/store";
import { PaymentHistoryFlowFacade } from "src/app/payment-history/store";
import { OneTimePurchaseFlowFacade } from "src/app/product/store";
import { AutoRefillSubscriptionFlowFacade } from '../../product/store/services/auto-refill-subscription-flow.facade';
import { AnalyticsService } from "src/app/@core/analytics/analytics.service";

interface Data {
    invalidateRefreshToken?: boolean;
}

@Injectable({
    providedIn: "root"
})
export class LogoutUseCase implements IUseCase<Data, void> {

    constructor(
        @Inject(BBO_AUTHENTICATE_REPOSITORY) private repository: IAuthenticateRepository,
        private userService: UserService,
        private tokenService: AccessTokenService,
        private authenticateService: AuthenticateService,
        private router: Router,
        private generalStoreFacade: GeneralStoreFacade,
        private paymentHistoryFacade: PaymentHistoryFlowFacade,
        private oneTimePurchaseFlowFacade: OneTimePurchaseFlowFacade,
        private autoRefillSubscriptionFlowFacade: AutoRefillSubscriptionFlowFacade,
        private analytics: AnalyticsService
    ) {}

    /**
     * @param invalidateRefreshToken true by default so try to invalidate stored refresh token if possible
     */
    execute({invalidateRefreshToken = true}: Data): void {
        if (invalidateRefreshToken) {
            const refreshToken = this.tokenService.getRefreshToken();
            if (refreshToken) {
                this.repository.invalidateRefreshToken(refreshToken).subscribe({error: (err) => console.error(err)});
            }
        }
        this.authenticateService.setToken(null);
        this.authenticateService.setRefreshToken(null);
        this.userService.setUserName(null);
        this.resetStores();
        this.analytics.onLogout();
        this.router.navigateByUrl("/login");
    }

    private resetStores(): void {
        this.paymentHistoryFacade.resetFlow();
        this.oneTimePurchaseFlowFacade.resetFlow();
        this.autoRefillSubscriptionFlowFacade.resetFlow();
        this.generalStoreFacade.resetFlow();
        this.generalStoreFacade.resetCreditCard()
        this.generalStoreFacade.resetSavedBillingAddress()
        this.generalStoreFacade.resetCurrentAutoRefillPlan()
        this.generalStoreFacade.resetBboPlus();
    }
}
