import {AbstractControl, ValidationErrors} from "@angular/forms";

export function customZipCodeValidator(control: AbstractControl): ValidationErrors | null {
    const countryCode = control.get('country')?.value?.id;
    const zipCode = control.get('postalCode')?.value;

    if (!zipCode) {
        return {required: true};
    }

    if (countryCode === 'US') {
        const usZipCodePattern = /^[0-9]{5}(?:[- ]*[0-9]{4})?$/;
        control.get('postalCode')?.setErrors(usZipCodePattern.test(zipCode) ? null : {invalidZipCode: true});
    } else if (countryCode === 'CA') {
        const caZipCodePattern = /^(?:[A-Z][- ]*[0-9][- ]*){3}$/;
        control.get('postalCode')?.setErrors(caZipCodePattern.test(zipCode) ? null : {invalidZipCode: true});
    } else if (countryCode === 'IT') {
        const itZipCodePattern = /^(?:IT?-)?[0-9]{5}$/;
        control.get('postalCode')?.setErrors(itZipCodePattern.test(zipCode) ? null : {invalidZipCode: true});
    }
    return null;
}