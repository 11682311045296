import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, defer, Observable} from 'rxjs';
import {filter, first, map} from 'rxjs/operators';
import {
    BBO_AUTHENTICATE_REPOSITORY,
    IAuthenticateRepository
} from 'src/app/authenticate/infrastructure/authenticate.interface';
import {AccessTokenService} from 'src/app/authenticate/service/access-token.service';

export interface RefreshTokenResult {
    token: string;
    refreshToken: string;
}

const NO_TOKEN_VALUE = null;

@Injectable()
export class AuthenticateService {

    constructor(
        private tokenStore: AccessTokenService,
        @Inject(BBO_AUTHENTICATE_REPOSITORY) private repository: IAuthenticateRepository,
    ) {
        this.tokenSubject$.next(tokenStore.getToken());
    }

    private tokenSubject$ = new BehaviorSubject<string | null>(NO_TOKEN_VALUE);

    token$ = this.tokenSubject$.pipe(
        // token is string -> not null in that case
        filter((token): token is string => token !== NO_TOKEN_VALUE),
    );

    refreshToken$: Observable<string> = defer(() => {
        if (this.tokenSubject$.value === NO_TOKEN_VALUE) {
            return this.token$.pipe(first());
        }
        // Defer allows us to easily execute some action when the Observable
        // is subscribed. Here, we set the current token to `null` until the
        // refresh operation is complete. This ensures no requests will be
        // sent with a known bad token.
        this.tokenSubject$.next(NO_TOKEN_VALUE);

        return this.refreshToken().pipe(
            map((tokens) => tokens.token)
        );
    });

    refreshToken(): Observable<RefreshTokenResult> {
        const refreshToken = this.tokenStore.getRefreshToken() || '';
        return this.repository.refresh(refreshToken);
    }

    setToken(value: string | null): void {
        if (value === null) {
            this.tokenStore.setToken(null);
            this.tokenSubject$.next(NO_TOKEN_VALUE);
        } else {
            this.tokenStore.setToken(value);
            this.tokenSubject$.next(value);
        }
    }

    setRefreshToken(value: string | null): void {
        if (value === null) {
            this.tokenStore.setRefreshToken(null);
        } else {
            this.tokenStore.setRefreshToken(value);
        }
    }

    isLocalUserLoggedIn(): boolean {
        return !!this.tokenSubject$.getValue();
    }
}
