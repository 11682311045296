import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatStepper} from "@angular/material/stepper";
import {combineLatest, map, of, Subject, takeUntil, zip} from "rxjs";
import {GetAutoRefillOffersUseCase, ProductAutoRefillPlan} from "../product/domain";
import {STEP_STATE, StepperSelectionEvent} from '@angular/cdk/stepper';
import {BboStoreHeaderService} from '../bbo-store-header/service/bbo-store-header.service';
import {filter, first, mergeMap, skip, tap} from "rxjs/operators";
import {Nullable, isNotNil} from "../@core";
import {AutoRefillSubscriptionFlow, AutoRefillSubscriptionFlowFacade} from "../product/store";
import {XlationCodes} from '../shared/translations/xlation.codes';
import { DeactivatableComponent } from '../@core/deactivatable-component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SavingPurchaseProcessModalComponent } from '../product/ui/saving-purchase-process-modal/saving-purchase-process-modal.component';
import {isNil} from "lodash";

@Component({
    selector: 'bbo-auto-refill-subscription',
    templateUrl: './auto-refill-subscription.component.html',
    styleUrls: ['./auto-refill-subscription.component.scss']
})
export class AutoRefillSubscriptionComponent implements OnInit, AfterViewInit, OnDestroy, DeactivatableComponent {
    xlationCodes = XlationCodes;
    @ViewChild(MatStepper) stepper!: MatStepper;

    readonly offers$ = this.getOffersUseCase.execute().pipe(
        map((response) => {
            return response.data;
        })
    );

    readonly selectedPlan$ = this.store.selectedPlan$;
    readonly isSelectedPlan$ = this.store.selectedPlan$
        .pipe(map(value => (value) ? Boolean(isNotNil(value)) : false));
    readonly selectedPayment$ = this.store.selectedPayment$;
    readonly paymentTaxes$ = this.store.selectedPlanPriceTax$;
    readonly isSelectedPayment$ = this.store.selectedPayment$
        .pipe(map(value => (value) ? Boolean(isNotNil(value)) : false));
    readonly submitError$ = this.store.submitError$.pipe(map(v => isNotNil(v)));
    readonly submitResult$ = this.store.submitResult$.pipe(map(v => isNotNil(v)));
    readonly isLoading$ = this.store.isLoading$;
    readonly isSubmitReady$ = combineLatest(
        [this.submitError$, this.submitResult$]
    ).pipe(map(value => value[0] || value[1]))

    state: string = STEP_STATE.NUMBER;

    isPaymentWorkflowCompleted = false;

    private unsubscribeSubject: Subject<void> = new Subject<void>();

    // eslint-disable-next-line class-methods-use-this
     get doneStepState(){
        return STEP_STATE.DONE;
    }

    constructor(
        private readonly getOffersUseCase: GetAutoRefillOffersUseCase,
        private readonly bboStoreHeaderService: BboStoreHeaderService,
        private readonly cdr: ChangeDetectorRef,
        private readonly store: AutoRefillSubscriptionFlowFacade,
        private readonly matDialog: MatDialog
    ) {
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();

        if (this.isPaymentWorkflowCompleted) {
            this.store.resetFlow();
        }
    }

    // eslint-disable-next-line class-methods-use-this
    ngOnInit(): void {
        const dataLayer = (window as any).dataLayer || [];
        dataLayer.push({
            event: 'Shop Initiated checkout',
            "product_category": "Subscription",
        });
    }

    ngAfterViewInit(): void {
        this.setHeaderBackAction(0);
        this.stepper.selectionChange
            .pipe(takeUntil(this.unsubscribeSubject))
            .subscribe((event: StepperSelectionEvent) => {
                this.state = STEP_STATE.NUMBER;
                this.setHeaderBackAction(event.selectedIndex);
            })

        this.selectedPlan$.pipe(
            takeUntil(this.unsubscribeSubject),
            filter(isNotNil)
        ).subscribe(() => this.stepForward());

        this.selectedPayment$.pipe(
            takeUntil(this.unsubscribeSubject),
            filter(isNotNil)
        ).subscribe(() => this.stepForward());

        this.selectedPayment$.pipe(
            // prevent the init event
            skip(1),
            takeUntil(this.unsubscribeSubject),
            filter(v => isNil(v?.creditCard))
        ).subscribe(() => this.oneStepBack());

        this.isSubmitReady$.pipe(
            takeUntil(this.unsubscribeSubject),
            filter(Boolean)
        ).subscribe(() => {
            this.state = STEP_STATE.DONE;
        })

        this.submitResult$.pipe(
            takeUntil(this.unsubscribeSubject),
            filter(isNotNil)
        ).subscribe((value) => {
            this.isPaymentWorkflowCompleted = value;
            this.setHeaderBackAction();
        })
    }

    setHeaderBackAction(stepperIndex = 0): void {
        if (stepperIndex === 0) {
            this.bboStoreHeaderService.setHeaderBackAction({
                fontIcon: "arrow_back",
                className: "back-action",
                callback: () => this.bboStoreHeaderService.backToHomePage()
            })
        } else {
            this.bboStoreHeaderService.setHeaderBackAction({
                fontIcon: "arrow_back",
                className: "back-action",
                callback: () => this.oneStepBack()
            })
        }
    }

    stepForward() {
        this.cdr.detectChanges();
        this.stepper.next();
    }

    userWantsToPay() {
        this.store.submit();
    }

    oneStepBack() {
        this.stepper.previous();
    }

    /**
     * If there is an autorefill purchase flow in progress open the confirmation modal
     * If the user want to delete the pending process so reset the flow in any case we return true at the end to don't block navigation
     * We use CanDeactivate guard as a confirmation step before navigation
     */
    canDeactivate() {
        return zip([
            this.store.submitResult$,
            this.store.flowState$
        ]).pipe(
            first(),
            mergeMap((result: [Nullable<ProductAutoRefillPlan>, Nullable<AutoRefillSubscriptionFlow>]) => {
                // if we have a submit result it mean we have finish the flow so no need of this modal
                if (result[0]) {
                    return of(true);
                }
                // if we don't have any data in the flow no need for this modal
                if (!result[1]) {
                    return of(true)
                }
                const matDialogConfig: MatDialogConfig = {
                    disableClose: false
                }
                return this.matDialog.open<SavingPurchaseProcessModalComponent>(SavingPurchaseProcessModalComponent, matDialogConfig)
                    .afterClosed()
                    .pipe(
                        tap((result: boolean) => {
                            if (result) {
                                this.store.resetFlow();
                            }
                        }),
                        map(() => true)
                    );
            })
        )
    }
}
