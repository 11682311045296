import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './authenticate/ui/login/login.component';
import {authenticateGuard} from './authenticate/service/authenticate.guard';

const routes: Routes = [
    {
        path: "login",
        component: LoginComponent
    },
    {
        path: "",
        loadChildren: () => import('./bbo-store/bbo-store.module').then(mod => mod.BboStoreModule),
        canActivate: [authenticateGuard]
    },
    {
        path: "**",
        redirectTo: ""
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
