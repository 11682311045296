import {Inject, Injectable} from '@angular/core';
import {catchError, map, Observable, throwError} from 'rxjs';
import {Response} from 'src/base/response';
import {IUseCase} from 'src/base/usecase';
import {BBO_BALANCE_REPOSITORY, IBalanceRepository} from '../infrastructure/balance.interface';
import {GenericError} from 'src/app/authenticate/domain/login.usecase';
import {BalanceModule} from 'src/app/balance/balance.module';

export interface BalanceRequest {
    uid: string;
}

export interface BalanceResponse {
    owner: {
        uid: number
    },
    amount: number;
}


@Injectable({
    providedIn: BalanceModule
})
export class GetBalanceUseCase implements IUseCase<BalanceRequest, Observable<Response<BalanceResponse>>> {

    constructor(
        @Inject(BBO_BALANCE_REPOSITORY) private repository: IBalanceRepository
    ) {}

    execute(data: BalanceRequest): Observable<Response<BalanceResponse>> {
        return this.repository.getBalance(data.uid).pipe(
            catchError((error) => {
                console.error(error);
                return throwError(() => new Response(
                    false,
                    null,
                    new GenericError(error)
                ));
            }),
            map((balance: BalanceResponse) => {
                return new Response(
                    true,
                    balance,
                    null
                );
            })
        );
    }

}
