<!-- Carousel case -->
<swiper-container
    *ngIf="shouldDisplaySwipper$ | async"
    #swiper
    grab-cursor="true"
    space-between="20px"
    navigation="true"
    slides-per-view="auto"
    centered-slides="true"
    scrollbar="true"
    [attr.initial-slide]="initialSlideIndex"
>
    <swiper-slide *ngFor="let offer of _offers; let i = index">
        <ng-container *ngTemplateOutlet="cardTemplate; context: {offer, i}"></ng-container>
    </swiper-slide>
</swiper-container>

<!-- simple cards display case -->
<div class="offers-container" *ngIf="!(shouldDisplaySwipper$ | async)">
    <ng-container class="offer-content" *ngFor="let offer of _offers; let i = index">
        <ng-container *ngTemplateOutlet="cardTemplate; context: {offer, i}"></ng-container>
    </ng-container>
</div>

<ng-template #cardTemplate let-offer="offer" let-i="i">
    <mat-card class="bbo-small-card onetime-purchase-card" [ngClass]="{'first-time-purchase': offer.type === OfferTypes.firstTimePurchase}">
        <mat-card-content>
            <ng-container *ngIf="!offer.type; else firstTimePurchase">
                <div class="foreground">
                    <!-- money svg -->
                    <div class="title">
                        <mat-icon svgIcon="bbocoin" class="badge"></mat-icon>
                    </div>
                    <div class="bbo-currency">
                        <span class="amount">{{offer.bboCurrencyAmount}}</span>
                        <span class="currency">BB$</span>
                    </div>
                    <span class="price color-accent">${{offer.price}} + {{xlationCodes.taxesIfApplicable | translate}}</span>
                    <div class="actions">
                        <button
                            mat-flat-button
                            class="bbo-button width-100"
                            color="primary"
                            (click)="userWantsToBuy.emit(offer)"
                        >{{offer.price}} USD</button>
                    </div>
                    <div class="our-recommendation bbo-body-2" *ngIf="offer.recommended">{{xlationCodes.ourRecommendation | translate}} </div>
                </div>
                <!-- background svg -->
                <mat-icon class="decoration" [svgIcon]="getImg(i)"></mat-icon>
            </ng-container>
            <ng-template #firstTimePurchase>
                <!-- money svg -->
                <div class="title">
                    <mat-icon svgIcon="first-time-purchase" class="first-time-purchase-badge"></mat-icon>
                    <div class="title-label color-coral">{{xlationCodes.specialOffer | translate}}</div>
                </div>
                <div class="bbo-currency">
                    <span class="amount">{{offer.bboCurrencyAmount}}</span>
                    <span class="currency">BB$</span>
                </div>
                <span class="price previous-price">${{offer.bboCurrencyAmount}} + {{xlationCodes.taxes | translate}}</span>
                <div class="price color-sunset">${{offer.price}} USD + {{xlationCodes.taxes | translate}}</div>
                <div class="actions">
                    <button
                        mat-flat-button
                        class="bbo-button width-100"
                        color="coral"
                        (click)="userWantsToBuy.emit(offer)"
                    >{{xlationCodes.takeDeal | translate}}</button>
                </div>
                <div class="first-time-purchase-hint bbo-body-2"> {{xlationCodes.firstTimePurchaseOnly | translate}}</div>
            </ng-template>
        </mat-card-content>
    </mat-card>
</ng-template>
