import {Injectable} from "@angular/core";
import {IUseCase} from "../../../base/usecase";
import {catchError, map, Observable, throwError} from "rxjs";
import {ApplicationError, IResponse, Response} from "../../../base/response";
import {PaypalOrderRepository} from "../infrastructure/paypal-order-repository.service";

export interface OneTimePurchasePaypalOrderRequest {
  id: number;
}

export interface OneTimePurchasePaypalOrderResponse {
  paymentType: "paypal",
  paymentStatus: string;
  amount: number,
  taxAmount: number,
  paypalOrderId: string;
  cybersourcePaymentId: string;
}

@Injectable()
export class PaypalOneTimePurchaseOrderUseCase implements IUseCase<OneTimePurchasePaypalOrderRequest, Observable<IResponse<OneTimePurchasePaypalOrderResponse>>> {

  constructor(private readonly repository: PaypalOrderRepository) {
  }


  execute(data: OneTimePurchasePaypalOrderRequest): Observable<IResponse<OneTimePurchasePaypalOrderResponse>> {
    return this.repository.createOneTimePurchaseOrder(data.id).pipe(
        catchError((error) => {
          return throwError(() => new Response(
              false,
              null,
              new ApplicationError(111, `PaypalOneTimePurchaseOrderUseCase unexpected error`, error)))
        }),
        map((response) => {
          return new Response(true, response);
        })
    );
  }

}
