import { createReducer, on } from "@ngrx/store";
import { bboPlusTrialActions } from "../actions/bbo-plus-trial.actions";

export interface BboPlusTrialState {
    data: {
        remainingDays: number | null;
    } | null,
    loaded: boolean | null,
    loading: boolean | null,
    error: any | null,
}

const initialState: BboPlusTrialState = {
    data: null,
    loaded: null,
    loading: null,
    error: null,
};

export const bboPlusTrialReducer = createReducer(initialState,
    on(bboPlusTrialActions.load, (state): BboPlusTrialState => ({
        ...state,
        loading: true,
        loaded: false
    })),
    on(bboPlusTrialActions.loadSuccess, (state, {payload}): BboPlusTrialState => ({
        ...state,
        data: {
            remainingDays: payload.value
        },
        error: null,
        loading: false,
        loaded: true,
    })),
    on(bboPlusTrialActions.loadFail, (state, {error}): BboPlusTrialState => ({
        ...state,
        data: null,
        error,
        loading: false,
        loaded: false,
    })),
);
