<div class="bbo-plus-details-header">
    <span class="bbo-headline-4 bbo-large-line-height">{{xlationCodes.subscriptionDetails | translate}}</span>
</div>
<div class="bbo-plus-details-content">
    <div class="bbo-plus-tile">
        <bbo-bbo-plus-tile [amount]="amount"></bbo-bbo-plus-tile>
    </div>
    <div class="detail-container bbo-body-2 color-gray">
        <div class="detail-item">
            <div class="detail-label font-weight-semibold">{{xlationCodes.subtotal | translate}}</div>
            <div class="detail-value">{{amount | currency:'USD'}}</div>
        </div>
        <div class="detail-item">
            <div class="detail-label font-weight-semibold">{{xlationCodes.bboPlusTaxes | translate}}</div>
            <div class="detail-value">-</div>
        </div>
    </div>
    <div class="detail-item bbo-body-2">
        <div class="detail-label font-weight-semibold">{{xlationCodes.total | translate}}*</div>
        <div class="detail-value">{{amount | currency: 'USD'}}</div>
    </div>
    <div>
        <div class="bbo-body-2 color-gray">
            {{xlationCodes.bboPlusSubscriptionDetailsDescription | translate: [(amount | number) ?? ""]}}
        </div>
    </div>
</div>