import {Injectable} from "@angular/core";
import {IUseCase} from "../../../base/usecase";
import {PaypalOrderRepository} from "../infrastructure";
import {catchError, map, Observable, throwError} from "rxjs";
import {ApplicationError, IResponse, Response} from "../../../base/response";

export interface CancelOrderRequest {
  id: string;
}

@Injectable()
export class PaypalCancelOrderUseCase implements IUseCase<CancelOrderRequest, void> {

  constructor(private readonly repository: PaypalOrderRepository) {
  }

  execute(data: CancelOrderRequest): Observable<IResponse<Record<string, void>>> {
    return this.repository.cancelOrder(data.id).pipe(
      catchError((error) => {
        return throwError(() => new Response(false, null, new ApplicationError(111, `PaypalCancelOrderUseCase unexpected error`, error)))
      }),
      map((response) => {
        return new Response(true, {});
      })
    )
  }

}
