import { Component } from '@angular/core';
import { XlationCodes } from '../../../shared/translations/xlation.codes';
import { BboPlusFromV3Service } from '../../services/bbo-plus-from-v3.service';
import { Router } from '@angular/router';

@Component({
  selector: 'bbo-plus-subscription-completed',
  templateUrl: './bbo-plus-subscription-completed.component.html',
  styleUrls: ['./bbo-plus-subscription-completed.component.scss']
})
export class BboPlusSubscriptionCompletedComponent {
    xlationCodes = XlationCodes;

    constructor(
        public bboPlusFromV3Service: BboPlusFromV3Service,
        private router: Router
    ) {}

    returnTo(): void {
        if (this.bboPlusFromV3Service.isFromV3) {
            window.close();
        } else {
            this.router.navigateByUrl("/");
        }
    }
}
