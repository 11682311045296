import {IUseCase} from "../../../base/usecase";
import {PaypalOrderRepository} from "../infrastructure/paypal-order-repository.service";
import {Injectable} from "@angular/core";
import {catchError, map, Observable, throwError} from "rxjs";
import {ApplicationError, IResponse, Response} from "../../../base/response";

export interface PaypalResourceCheckResponse {
    active: boolean
}

@Injectable()
export class PaypalResourceCheckUseCase implements IUseCase<void, Observable<IResponse<PaypalResourceCheckResponse>>> {

    constructor(private readonly repository: PaypalOrderRepository) {
    }

    execute(): Observable<IResponse<PaypalResourceCheckResponse>> {
        return this.repository.checkResource().pipe(
            catchError((error) => {
                return throwError(() =>
                    new Response(false, null,
                        new ApplicationError(111, 'PaypalResourceCheckUseCase unexpected error', error)))
            }),
            map((response) => {
                return new Response(true, response);
            })
        );
    }
}
