import { Component, Input } from '@angular/core';
import { XlationCodes } from '../../../shared/translations/xlation.codes';

@Component({
    selector: 'bbo-purchase-failure',
    templateUrl: './purchase-failure.component.html',
    styleUrls: ['./purchase-failure.component.scss']
})
export class PurchaseFailureComponent {
    xlationCodes = XlationCodes;
}
