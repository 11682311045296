import {Injectable} from '@angular/core';
import {map, mergeMap, Observable} from 'rxjs';
import {GetTaxesUseCase, Taxes, TaxesParameters} from '../../payment/domain/get-payment-taxes.usecase';
import {IResponse} from 'src/base/response';
import {
    BillingAddressGetResponse,
    GetBillingAddressUseCase
} from 'src/app/billing-address/domain/get-billing-address.usecase';

const getTaxesRequestParameters = (billingAdress: BillingAddressGetResponse, amount: number): TaxesParameters => {
    const amoutInCents = amount * 100;
    return {
        amount: amoutInCents,
        countryCode: billingAdress.countryCode,
        zipCode: billingAdress.zipCode,
        state: billingAdress.state,
        city: billingAdress.city,
        address: billingAdress.addressLine1
    }
}

@Injectable({
    providedIn: "root"
})
export class TaxesService {
    constructor(
        private getBillingAddressUseCase: GetBillingAddressUseCase,
        private getTaxesUseCase: GetTaxesUseCase
    ) {
    }

    /**
     * get the taxes from the billing address data and the amount of the product
     * @param amount amount of the purchase associated to the taxes
     * @param billingAddress optional billing address when we want to use another instead of the user's saved one
     * @returns an Observable of Taxes corresponding of the taxes for an given amount
     */
    calculateTaxes(amount: number, billingAddress?: BillingAddressGetResponse): Observable<number> {
        return (billingAddress) ?
            this.getTaxes(billingAddress, amount)
            : this.getBillingAddressUseCase.execute().pipe(
                map((response: IResponse<BillingAddressGetResponse>) => response.data),
                mergeMap((billingAddress: BillingAddressGetResponse) => {
                    return this.getTaxes(billingAddress, amount);
                }),
            )
    }

    private getTaxes(billingAddress: BillingAddressGetResponse, amount: number) {
        return this.getTaxesUseCase.execute(getTaxesRequestParameters(billingAddress, amount)).pipe(
            map((response: IResponse<Taxes>) => response.data.taxAmount)
        );
    }
}
