<mat-stepper orientation="horizontal" [linear]="true" labelPosition="bottom" #stepper color="primary">
    <ng-template matStepperIcon="done">
        <mat-icon>done</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="number" let-index="index">
    </ng-template>
    <ng-template matStepperIcon="edit" let-index="index">
        {{index + 1}}
    </ng-template>
    <mat-step [editable]="!(submitResult$ | async)" [completed]="(isSelectedOffer$ | async) === true">
        <ng-template matStepLabel>{{xlateCodes.option | translate}}</ng-template>
        <ng-container *ngIf="(offers$ | async) as offers">
            <bbo-one-time-purchase-list
                    [offers]="offers"
                    (userWantsToBuy)="userSelectOfferFromOfferList($event)"
                    [bboShouldDispplaySwipper]
                    [offersNumber]="offers.length"
            ></bbo-one-time-purchase-list>
        </ng-container>
        <div *ngIf="!currentAutoRefillPlan && !gettingAutoRefillPlan" class="promote-autorefill-message">
            <p class="bbo-body-2"><span class="font-weight-semibold">{{xlateCodes.noCurrentAutoRefillPlan | translate}}</span>{{xlateCodes.subscribeNowHint | translate}}</p>
            <button class="bbo-button bbo-small-button" mat-button color="primary" routerLink="/auto-refill">{{xlateCodes.chooseAPlan | translate}}</button>
        </div>
    </mat-step>

    <mat-step [editable]="!(submitResult$ | async)" [completed]="(selectedPaymentMethod$ | async) === true">
        <ng-template matStepLabel>{{xlateCodes.payment | translate}}</ng-template>
        <div class="payment-selection">
            <bbo-payment-method-selection
                (methodSelected)="userSelectsHisPayment($event)"
            ></bbo-payment-method-selection>
            <bbo-one-time-purchase-order-details class="mobile-hidden"></bbo-one-time-purchase-order-details>
        </div>
    </mat-step>

    <mat-step [editable]="!(submitResult$ | async)" [completed]="isSubmitReady$ | async" [state]="state">
        <ng-template matStepLabel>{{xlateCodes.checkout | translate}}</ng-template>
        <div *ngIf="!(isSubmitReady$ | async)" class="payment-review-container">
            <bbo-one-time-purchase-checkout
            >
            </bbo-one-time-purchase-checkout>
            <bbo-one-time-purchase-order-details class="mobile-hidden">
            </bbo-one-time-purchase-order-details>
        </div>
    </mat-step>
</mat-stepper>

<bbo-one-time-purchase-complete [amount]="selectedOfferPrice$ | async"
                                *ngIf="(submitResult$ | async) === true"
></bbo-one-time-purchase-complete>
<bbo-purchase-failure
    *ngIf="(submitError$ | async)=== true"
    (tryAgain)="retryPayment()"
    [isLoading]="loading$ | async"
></bbo-purchase-failure>
