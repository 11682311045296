import {Inject, Injectable} from "@angular/core";
import {BBO_PRODUCT_REPOSITORY, IProductRepository} from "src/app/product/infrastructure/product.interface";
import {IUseCase} from "src/base/usecase";
import {UserService} from '../../shared/services/user.service';
import {ApplicationError, Response} from 'src/base/response';
import {catchError, Observable, throwError} from "rxjs";
import {HttpErrorResponse, HttpStatusCode} from "@angular/common/http";
import { GeneralStoreFacade } from "src/app/bbo-store/store";

export enum DeleteCurrentAutoRefillPlanUseCaseError {
    LOCAL_UID_INVALID,
    GENERIC_ERROR,
    NOT_FOUND
}
@Injectable({
    providedIn: "root"
})
export class DeleteCurrentAutoRefillPlanUseCase implements IUseCase<void, Observable<void>> {

    constructor(
        @Inject(BBO_PRODUCT_REPOSITORY) private repository: IProductRepository,
        private readonly generalStore: GeneralStoreFacade,
        private userService: UserService,
    ) {}

    execute(): Observable<void> {
        const localUid = this.userService.getUId();

        if (!localUid) {
            return throwError(() => new Response(
                false,
                null,
                new ApplicationError(DeleteCurrentAutoRefillPlanUseCaseError.LOCAL_UID_INVALID, "Trying to delete current auto refill plan for local user but uid is falsy")
            ));
        }

        return this.repository.deleteCurrentAutoRefillPlan(localUid ?? '').pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.NotFound) {
                    const response = new Response(
                        false,
                        null,
                        new ApplicationError(
                            DeleteCurrentAutoRefillPlanUseCaseError.NOT_FOUND,
                            'DeleteCurrentAutoRefillPlan not found for uid ' + localUid,
                        )
                    );
                    return throwError(() => response);
                }
                return throwError(() => error);
            }),
            catchError((error) => {
                // already handled: skip
                if (error instanceof Response) {
                    return throwError(() => error);
                }
                return throwError(() => new Response(
                        false,
                        null,
                        new ApplicationError(
                            DeleteCurrentAutoRefillPlanUseCaseError.GENERIC_ERROR,
                            'DeleteCurrentAutoRefillPlan usecase unexpected error',
                            error
                        )
                ));
            })
        );
    }
}
