<div class="history-details-part">
    <div class="details-header">
        <span class="bbo-headline-4">{{xlationCodes.order | translate}}</span>
    </div>
    <div class="details-content bbo-body-2">
        <div class="detail-item">
            <div class="detail-label font-weight-semibold">{{xlationCodes.amount | translate}}</div>
            <div class="detail-value">{{paymentHistoryItem.amount}} BB$</div>
        </div>
    </div>
</div>
<div class="history-details-part">
    <div class="details-header">
        <span class="bbo-headline-4">{{xlationCodes.amount | translate}}</span>
    </div>
    <div class="details-content bbo-body-2">
        <div class="detail-item">
            <div class="detail-label font-weight-semibold">{{xlationCodes.paymentMethod | translate}}</div>
            <div class="detail-value">{{paymentHistoryItem.displayedMethod | translate}}</div>
        </div>
        <div class="detail-item">
            <div class="detail-label font-weight-semibold">{{xlationCodes.date | translate}}</div>
            <div class="detail-value">{{paymentHistoryItem.dateTime | date:'dd MMM. YYYY'}}</div>
        </div>
        <div class="detail-item">
            <div class="detail-label font-weight-semibold">{{xlationCodes.status | translate}}</div>
            <div class="detail-value">{{paymentHistoryItem.displayedStatus | translate}}</div>
        </div>
        <mat-divider></mat-divider>
        <div class="detail-item">
            <div class="detail-label font-weight-semibold">{{xlationCodes.total | translate}}</div>
            <div class="detail-value">${{paymentHistoryItem.amount}} USD</div>
        </div>
    </div>
</div>
<button mat-button class="bbo-button" mat-button color="primary" (click)="downloadReceipt()">
    <mat-icon class="button-icon">arrow_circle_down</mat-icon>
    <span class="button-label">{{xlationCodes.downloadReceipt | translate}}</span>
</button>