import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {map, Observable} from "rxjs";
import {environment} from "src/environments/environment";
import {ICybersourceRepository} from "src/app/payment/infrastructure/cybersource.interface";

@Injectable()
export class CybersourceRepository implements ICybersourceRepository {

    constructor(
        private http: HttpClient
    ) {}

    getTokenForSecureForm(): Observable<string> {
        return this.http.get<{token: string}>(
            environment.apiURL+"/cybersource/token",
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        ).pipe(
            map((response) => response.token)
        );
    }
}
