import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {UserService} from '../shared/services/user.service';
import {BboStoreHeaderService} from './service/bbo-store-header.service';
import { XlationCodes } from '../shared/translations/xlation.codes';
import {GeneralStoreFacade} from "../bbo-store/store";
import { AnalyticsService, FeatureFlags } from '../@core/analytics/analytics.service';
import { map, Subject, takeUntil, tap } from 'rxjs';

interface NavBarItem {
    xlationCode: string;
    route: string;
    cssClasses: string;
}

@Component({
    selector: 'bbo-store-header',
    templateUrl: 'bbo-store-header.component.html',
    styleUrls: ['./bbo-store-header.component.scss']
})
export class BboStoreHeaderComponent implements OnInit, OnDestroy {
    navBarItemsList: NavBarItem[] = [{
        xlationCode: XlationCodes.home,
        route: '/',
        cssClasses: ''
    }, {
        xlationCode: XlationCodes.buyBB$,
        route: '/buy-bb$',
        cssClasses: ''
    }, {
        xlationCode: XlationCodes.autoRefill,
        route: '/auto-refill',
        cssClasses: ''
    }];

    menuOpened = false;
    userName: string | null = '';
    balanceAmount$ = this.generalStoreFacade.currentBalance$;

    backAction$ = this.bboStoreHeaderService.backAction$;

    onDestroy$ = new Subject<boolean>();

    constructor(
        private readonly router: Router,
        private userService: UserService,
        private generalStoreFacade: GeneralStoreFacade,
        private bboStoreHeaderService: BboStoreHeaderService,
        private analytics: AnalyticsService
    ) { }

    ngOnInit(): void {
        this.userName = this.userService.getUserName();

        this.analytics.getFeatureFlagStream(FeatureFlags.BBO_PLUS).pipe(
            map(flag => flag.enabled),
            tap((flagEnabled) => {
                const ROUTE = "/bbo-plus";
                const isItemAlreadyInNav = this.navBarItemsList.find((i) => i.route === ROUTE);
                if (flagEnabled && !isItemAlreadyInNav) {
                    this.navBarItemsList.push({
                        xlationCode: XlationCodes.bboPlus,
                        route: ROUTE,
                        cssClasses: 'purple'
                    });
                } else if (!flagEnabled) {
                    this.navBarItemsList = this.navBarItemsList.filter((i) => i.route !== ROUTE);
                }
            }),
            takeUntil(this.onDestroy$)
        ).subscribe();

        this.analytics.logFeatureFlagImpression(FeatureFlags.BBO_PLUS);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }

    goToHomePage(): void {
        if (this.router.routerState.snapshot.url === '/') {
            window.location.reload();
        } else {
            this.router.navigateByUrl('/');
        }
    }

    navigate(route: string): void {
        this.router.navigateByUrl(route);
    }

    toggleMenuAside(): void {
        this.menuOpened = !this.menuOpened;
    }
}
