
<div class="order-details-header">
    <span class="bbo-headline-4">{{xlationCodes.orderDetails | translate}}</span>
</div>
<div class="order-details-content">
    <div class="selected-product">
        <bbo-order-amount-tile [amount]="orderAmount"></bbo-order-amount-tile>
    </div>
    <div class="detail-container bbo-body-2 divider-after">
        <div class="detail-item">
            <div class="detail-label font-weight-semibold">{{xlationCodes.currentBalance | translate}}</div>
            <div class="detail-value">{{balance}} BB$</div>
        </div>
        <div class="detail-item">
            <div class="detail-label font-weight-semibold">{{xlationCodes.order | translate}}</div>
            <div class="detail-value">{{orderAmount}} BB$</div>
        </div>
        <div class="detail-item">
            <div class="detail-label font-weight-semibold">{{xlationCodes.newBalance | translate}}</div>
            <div class="detail-value">{{newBalance}} BB$</div>
        </div>
    </div>
    <div class="detail-container bbo-body-2 color-gray">
        <div class="detail-item">
            <div class="detail-label font-weight-semibold">{{xlationCodes.subtotal | translate}}</div>
            <div class="detail-value">{{orderPrice}} USD</div>
        </div>
        <div class="detail-item">
            <div class="detail-label font-weight-semibold">{{xlationCodes.taxesIfApplicable | translate}}</div>
            <div class="detail-value">
                {{taxAmount | currency:'USD'}}
                <span *ngIf="taxAmount === undefined" class="detail-item-description">{{xlationCodes.calculatedOnCheckout | translate}}</span>
            </div>
        </div>
    </div>
    <div class="detail-item bbo-body-2">
        <div class="detail-label font-weight-semibold">{{xlationCodes.total | translate}}</div>
        <div class="detail-value">{{total}} USD</div>
    </div>
</div>
