import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MatDialog} from "@angular/material/dialog";
import {CreditCardErrorModalComponent} from "../../shared/ui/credit-card-error-modal/error-modal.component";
import {LanguageService} from "../../shared/translations/language.service";
import {XlationCodes} from "../../shared/translations/xlation.codes";

@Injectable({providedIn: 'root'})
export class HttpCreditCardInterceptor implements HttpInterceptor {

    readonly xlationCodes = XlationCodes;

    constructor(private readonly dialog: MatDialog,
                private readonly languageService: LanguageService) {
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Check if the request URL matches the one you want to intercept
        if (request.url.includes('/credit-card')) {
            return next.handle(request)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 400) {
                            const errorMessage: {
                                status: string,
                                reason: string,
                                description?: string
                            } = JSON.parse(error.error.detail);
                            this.dialog.open(CreditCardErrorModalComponent, {
                                data: {
                                    errorMessage: errorMessage
                                }
                            });
                            return throwError(() => new Error(errorMessage.status));
                        }
                        return next.handle(request);
                    })
                )
        }

        // If the URL doesn't match, forward the request without modifying it
        return next.handle(request);
    }
}
