import {createGenericObjectReducer} from "../../../@core";
import {CurrentAutoRefillPlanActions, currentAutoRefillPlanActions} from "../actions";
import {on} from "@ngrx/store";
import { ProductAutoRefillPlan } from "src/app/product/domain";

const {initialState, reducer} =
    createGenericObjectReducer<ProductAutoRefillPlan, ProductAutoRefillPlan, Partial<ProductAutoRefillPlan>>(currentAutoRefillPlanActions)(
        on(currentAutoRefillPlanActions.replace, (state) => ({
            ...state,
            loading: true,
            loaded: false
        })),
        on(currentAutoRefillPlanActions.replaceSuccess, (state, {payload}) => ({
            ...state,
            loading: false,
            data: payload,
            loaded: true
        })),
        on(currentAutoRefillPlanActions.loadFail, (state, {error}) => ({
            ...state,
            data: null,
            loading: false,
            loaded: true,
            error
        })),
        on(currentAutoRefillPlanActions.replaceFail, (state, {error}) => ({
            ...state,
            loading: false,
            loaded: true,
            error
        })),
        on(currentAutoRefillPlanActions.remove, (state) => ({
            ...state,
            loading: true,
            loaded: false
        })),
        on(currentAutoRefillPlanActions.removeSuccess, (state) => ({
            ...state,
            loading: false,
            loaded: true
        })),
        on(currentAutoRefillPlanActions.removeFail, (state, {error}) => ({
            ...state,
            loading: false,
            loaded: true,
            error
        }))
    );

export type CurrentAutoRefillPlanState = typeof initialState;
export {initialState};

export function currentAutoRefillPlanReducer(
    state: CurrentAutoRefillPlanState | undefined,
    action: CurrentAutoRefillPlanActions
) {
    return reducer(state, action);
}
