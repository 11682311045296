import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Option } from 'src/app/@core';
import { BillingAddressGetResponse, BillingAddressRequest } from 'src/app/billing-address/infrastructure/billing-address.interface';
import { CreditCard } from 'src/app/payment';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';

@Component({
    selector: 'bbo-plus-checkout',
    templateUrl: './bbo-plus-checkout.component.html',
    styleUrls: ['./bbo-plus-checkout.component.scss']
})
export class BboPlusCheckoutComponent {
    xlationCodes = XlationCodes;
    @Input() savedCreditCard: Option<CreditCard>;
    @Input() billingAddress: Option<BillingAddressGetResponse>;
    /** in cents */
    @Input() amount: number | null = null;

    @Output() updateCard: EventEmitter<{ creditCard: CreditCard; billingAddress: BillingAddressRequest }> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();
    @Output() subscribeToBboPlus: EventEmitter<void> = new EventEmitter();
}
