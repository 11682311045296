import {createFeatureSelector, createSelector} from "@ngrx/store";
import {GENERAL_STORE_NAME, GeneralStoreState} from "../reducers";
import {genericObjectSelectors} from "../../../@core";

const selectFeature = createFeatureSelector<GeneralStoreState>(GENERAL_STORE_NAME);
const selectGeneralStoreStateSelectors = genericObjectSelectors(
    createSelector(
        selectFeature,
        s1 => s1.generalStore
    ));
const selectSavedCreditCardStateSelectors = genericObjectSelectors(
    createSelector(
        selectFeature,
        s1 => s1.savedCreditCard
    ));
const selectSavedBillingAddressStateSelectors = genericObjectSelectors(
    createSelector(
        selectFeature,
        s1 => s1.savedBillingAddress
    ));
const selectBboPlusSelectors = genericObjectSelectors(
    createSelector(
        selectFeature,
        s1 => s1.bboPlus
    ));
const selectedCurrentAutoRefillPlanStateSelectors = genericObjectSelectors(
    createSelector(
        selectFeature,
        s1 => s1.currentAutoRefillPlan
    ));
const selectedCurrentAutoRefillPlan = createSelector(selectedCurrentAutoRefillPlanStateSelectors.data, s1 => s1);
const selectedBillingAddress = createSelector(selectSavedBillingAddressStateSelectors.data, s1 => s1);
const selectCurrentAccountBalance = genericObjectSelectors(createSelector(selectFeature, s1 => s1.currentAccountBalance));
const selectCurrentAccountBalanceAmount = createSelector(selectCurrentAccountBalance.data, s1 => s1?.amount);

const selectBboPlusTrialSelectors = {
    data: createSelector(selectFeature, (state) => state.bboPlusTrial.data),
    loading: createSelector(selectFeature, (state) => state.bboPlusTrial.loading),
    loaded: createSelector(selectFeature, (state) => state.bboPlusTrial.loaded),
    error: createSelector(selectFeature, (state) => state.bboPlusTrial.error),
};

const selectBboPlusPriceSelectors = {
    data: createSelector(selectFeature, (state) => state.bboPlusPrice.data),
    loading: createSelector(selectFeature, (state) => state.bboPlusPrice.loading),
    loaded: createSelector(selectFeature, (state) => state.bboPlusPrice.loaded),
    error: createSelector(selectFeature, (state) => state.bboPlusPrice.error),
};

export const generalStoreSelectors = {
    ...selectGeneralStoreStateSelectors,
    selectSavedCreditCardStateSelectors,
    selectedCurrentAutoRefillPlan,
    selectedBillingAddress,
    selectCurrentAccountBalance,
    selectCurrentAccountBalanceAmount,
    selectBboPlusSelectors,
    selectBboPlusTrialSelectors,
    selectBboPlusPriceSelectors,
}
