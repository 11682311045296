import { Injectable } from '@angular/core';
import { BrazeService } from './braze.service';
import { AnalyticsClient } from './analytics.interface';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import * as braze from "@braze/web-sdk";

type Event<Id, Payload = undefined> = { name: Id, data?: Payload };

export type AnalyticEvent =
    Event<"foo">

export enum FeatureFlags {
    BBO_PLUS = 'enable_store_bbo-plus',
}

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    private clients: AnalyticsClient[] = [];

    constructor(
        private braze: BrazeService
    ) {
    }

    private addClient(client: AnalyticsClient) {
        this.clients.push(client);
        client.init();
    }

    init() {
        if (environment.braze) {
            this.addClient(this.braze);
        }
    }

    onUserLoggedIn(data: {username: string, userUniqueIdentifier: string}): void {
        this.clients.forEach((client) => client.onUserLoggedIn(data));
    }

    onLogout(): void {
        this.clients.forEach((client) => client.onLogout());
    }

    trackEvent(event: AnalyticEvent): void {
        this.clients.forEach((client) => client.trackEvent(event));
    }

    getFeatureFlag(flag: FeatureFlags): boolean {
        return this.braze.getFeatureFlag(flag);
    }

    logFeatureFlagImpression(flag: FeatureFlags) {
        return this.braze.logFeatureFlagImpression(flag);
    }

    getFeatureFlagStream(flagId: string): Observable<braze.FeatureFlag> {
        return this.braze.getFeatureFlagStream(flagId);
    }

    getFeatureFlagsStream(): Observable<braze.FeatureFlag[]> {
        return this.braze.getFeatureFlagsStream();
    }
}