<bbo-checkout
    [creditCard]="selectedCreditCard$ | async"
    [billingAddress]="savedBillingAddress$ | async"
    [paymentMethod]="selectedPaymentMethod$ | async"
    [enablePayWithOtherCard]="(isSelectedCardSavedCard$ | async) && (selectedPaymentMethod$ | async) !== 'paypal'"
    [price]="offerPrice$ | async"
    [enablePaymentMethodEdit]="true"
    [enableSaveCardOnAdd]="true"
    (userWantsToChangePaymentMethod)="changePaymentMethod()"
    (deleteCard)="deleteCard()"
    (addCardAsPayment)="addPaymentCard($event)"
    (updateCard)="updateCreditCard($event)"
>
    <ng-container order-details>
        <bbo-order-details class="desktop-hidden" [orderValue]="offerPrice$ | async" [balance]="balance$ | async" [taxAmount]="taxAmount$ | async"></bbo-order-details>
    </ng-container>
    <ng-container actions *ngIf="selectedPaymentMethod$ | async as selectedPaymentMethod">

        <ng-container *ngIf="selectedPaymentMethod === 'paypal'" class="paypal-actions">
            <ng-container *ngIf="!(paypalOrderId$ | async)"  class="loading-container">
                <mat-progress-spinner [diameter]="30" mode="indeterminate"></mat-progress-spinner>
            </ng-container>
            <bbo-paypal *ngIf="(paypalOrderId$ | async) && !(isPaypalOrderApproved$ | async)"
                        (orderApproved)="approvePaypalOrder()" [orderId]="paypalOrderId$ | async"></bbo-paypal>
            <ng-container *ngIf="(isPaypalOrderApproved$ | async) === true">
                <button mat-button class="bbo-button mobile-hidden" color="warn"
                        (click)="cancelPaypalOrder()">{{xlationCodes.cancel | translate}}
                </button>
                <button mat-icon-button class="menu-icon desktop-hidden" (click)="cancelPaypalOrder()">
                    <mat-icon class="material-icons-outlined" fontIcon="cancel"></mat-icon>
                </button>
                <button mat-flat-button class="menu-icon pay-now-button"
                        color="primary"
                        (click)="proceedToPayment()" [disabled]="isOrderSubmitted">{{xlationCodes.payNow | translate}}
                </button>
            </ng-container>
        </ng-container>

        <ng-container actions *ngIf="selectedPaymentMethod !== 'paypal'">
            <button mat-button class="bbo-button mobile-hidden" color="warn" (click)="changePaymentMethod()">{{xlationCodes.cancel | translate}}
            </button>
            <button mat-icon-button class="menu-icon desktop-hidden" (click)="changePaymentMethod()">
                <mat-icon class="material-icons-outlined" fontIcon="cancel"></mat-icon>
            </button>
            <ng-container>
                <button mat-flat-button class="bbo-button" style="flex:1;"
                        color="primary"
                        (click)="proceedToPayment()"
                        [disabled]="!(selectedCreditCard$ | async) || isOrderSubmitted">{{ xlationCodes.payNow | translate }}
                </button>
            </ng-container>
        </ng-container>

    </ng-container>
</bbo-checkout>
