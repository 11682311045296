import {createGenericObjectActions, EntityAction} from "src/app/@core";
import { BboPlusGetResponse } from "src/app/bbo-plus-home/domain/get-bbo-plus.usecase";
import { BillingAddressGetResponse } from "src/app/billing-address/infrastructure/billing-address.interface";
import { ProductAutoRefillPlan } from "src/app/product/domain";

export interface GeneralStore {
    readonly currentAutoRefillPlan: ProductAutoRefillPlan;
    readonly currentBalance: number;
    readonly billingAddress: BillingAddressGetResponse;
    readonly bboPlus: BboPlusGetResponse;
}

export const generalStoreActions =
    createGenericObjectActions<GeneralStore, GeneralStore, Partial<GeneralStore>>('General Store');
export type GeneralStoreActions = EntityAction<GeneralStore, GeneralStore, Partial<GeneralStore>>;
