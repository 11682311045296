import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AutoRefillSubscriptionFlowFacade} from "../../store";
import {XlationCodes} from 'src/app/shared/translations/xlation.codes';
import {GeneralStoreFacade} from "../../../bbo-store/store";
import {CreditCard} from "../../../payment";
import {BillingAddressRequest} from "../../../billing-address/domain/get-billing-address.usecase";

@Component({
    selector: 'bbo-auto-refill-subscription-checkout',
    templateUrl: './auto-refill-subscription-checkout.component.html',
    styleUrls: ['./auto-refill-subscription-checkout.component.scss']
})
export class AutoRefillSubscriptionCheckoutComponent implements OnInit {
    xlationCodes = XlationCodes;
    readonly selectedPaymentMethod$ = this.store.selectedPaymentMethod$;
    readonly selectedCreditCard$ = this.store.selectedCreditCard$;
    readonly selectedBillingAddress$ = this.generalStore.billingAddress$;
    readonly paymentTaxes$ = this.store.selectedPlanPriceTax$;
    readonly paypalOrderId$ = this.store.paypalOrderId$;
    readonly price$ = this.store.selectedPlanPrice$;
    readonly taxes$ = this.store.selectedPlanPriceTax$;
    readonly isLoading$ = this.store.isLoading$;
    readonly selectedPlan$ = this.store.selectedPlan$;
    @Output()
    userWantsToChangePaymentMethod: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    userWantsToPay: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private readonly store: AutoRefillSubscriptionFlowFacade,
                private readonly generalStore: GeneralStoreFacade) {
    }

    ngOnInit(): void {
        this.generalStore.loadSavedBillingAddress();
        this.generalStore.loadSavedCreditCard();
    }


    updateCard(data: { creditCard: CreditCard; billingAddress: BillingAddressRequest }) {
        this.generalStore.updateSavedCreditCard(data.creditCard);
        this.generalStore.updateSavedBillingAddress(data.billingAddress);
    }

    deleteCard() {
        this.store.updateFlow({selectedPayment: {creditCard: undefined}});
        this.generalStore.deleteCreditCard();
        this.generalStore.deleteCurrentAutoRefillPlan();
        this.generalStore.resetSavedBillingAddress();
    }
}
