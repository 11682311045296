import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IPaymentHistoryRepository } from './payment-history.interface';
import { environment } from '../../../environments/environment';
import { PaymentHistoryItem } from '../domain/get-payment-history.usecase';
import { Response } from 'src/base/response';

@Injectable()
export class PaymentHistoryRepository implements IPaymentHistoryRepository {

    constructor(
        private http: HttpClient
    ) {}

    getPaymentHistory(uid: string): Observable<PaymentHistoryItem[]> {
        return this.http.get<PaymentHistoryItem[]>(
            `${environment.apiURL}/users/${uid}/payment-history`,
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        ).pipe(
            map((paymentHistory: PaymentHistoryItem[]) => {
                paymentHistory.forEach((paymentHistoryItem: PaymentHistoryItem) => {
                    paymentHistoryItem.amount = paymentHistoryItem.amount / 100;
                })
                return paymentHistory;
            })
        );
    }
}
