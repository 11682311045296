import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, take, takeUntil, map } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {Option} from "../../../@core";
import { XlationCodes } from '../../../shared/translations/xlation.codes';
import { CurrentAccountBalance, GeneralStoreFacade } from 'src/app/bbo-store/store';

@Component({
    selector: 'bbo-one-time-purchase-complete',
    templateUrl: './one-time-purchase-complete.component.html',
    styleUrls: ['./one-time-purchase-complete.component.scss']
})
export class OneTimePurchaseCompleteComponent implements OnInit, OnDestroy {

    @Input() amount: Option<number>;
    xlationCodes = XlationCodes;

    buttonXlationKey = "";
    currentAutoRefillPlan$ = this.store.currentAutoRefillPlan$;
    currentAutoRefillPlanLoading$ = this.store.loading$;
    private unsubscribeSubject: Subject<void> = new Subject<void>();

    constructor(
        private readonly responsive: BreakpointObserver,
        private readonly store: GeneralStoreFacade
    ) {
        this.store.loadCurrentAutoRefillPlan();
    }

    ngOnInit(): void {
        this.responsive.observe([
            Breakpoints.Handset
        ])
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe(result => {
            this.buttonXlationKey = (result.matches) ? XlationCodes.goToBBO : XlationCodes.returnToBBO;
        });
        this.store.currentBalance$
            .pipe(take(1))
            .subscribe((currentBalance: number | undefined) => {
                if (currentBalance && this.amount) {
                    this.store.updateCurrentAccountBalance({ amount: currentBalance + this.amount })
                }
            })
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }

    // eslint-disable-next-line class-methods-use-this
    goBackToBBO(): void {
        window.open("https://www.bridgebase.com/v3/", "_self");
    }
}
