import {Component, HostBinding, Input} from '@angular/core';
import {Option} from "../../../@core";

@Component({
    selector: 'bbo-balance',
    templateUrl: './balance.component.html',
    styleUrls: ['./balance.component.scss']
})
export class BalanceComponent {

    @Input() size: '' | 'medium' | 'large' = '';

    @Input() balanceAmount: Option<number>;

    @HostBinding('class.medium-balance')
    public get isMediumBalance(): boolean {
        return this.size === 'medium';
    }

    @HostBinding('class.large-balance')
    public get isLargeBalance(): boolean {
        return this.size === 'large';
    }

}
