<!-- Carousel case -->
<swiper-container
    *ngIf="shouldDisplaySwipper$ | async"
    #swiper
    grab-cursor="true"
    scrollbar="true"
    space-between="20px"
    navigation="true"
    slides-per-view="auto"
    centered-slides="true"
    [attr.initial-slide]="slideIndex"
>
    <swiper-slide *ngFor="let offer of _offers; let i = index">
        <ng-container>
            <bbo-auto-refill-offer [offer]="offer">
                <ng-container *ngIf="!offer.selected">
                    <button
                        mat-flat-button
                        class="bbo-button width-100"
                        color="primary"
                        (click)="selectPlan(offer)">
                        {{xlationCodes.choosePlan | translate}}
                    </button>
                    <div class="card-tips bbo-body-2" *ngIf="offer.recommended">{{xlationCodes.ourRecommendation | translate}}</div>
                </ng-container>

                <ng-container *ngIf="offer.selected">
                    <button
                        mat-button
                        class="bbo-button width-100"
                        color="warn"
                        (click)="cancelCurrentPlan()">
                        {{xlationCodes.cancelPlan | translate}}
                    </button>
                    <div class="card-tips bbo-body-2">{{xlationCodes.currentPlan | translate}}</div>
                </ng-container>
            </bbo-auto-refill-offer>
        </ng-container>
    </swiper-slide>
</swiper-container>


<div *ngIf="((shouldDisplaySwipper$ | async) === false)" class="offers-container">
    <ng-container *ngFor="let offer of _offers">
        <bbo-auto-refill-offer [offer]="offer">

            <ng-container *ngIf="!offer.selected">
                <button
                    mat-flat-button
                    class="bbo-button width-100"
                    color="primary"
                    (click)="selectPlan(offer)">
                    {{xlationCodes.choosePlan | translate}}
                </button>
                <div class="card-tips bbo-body-2" *ngIf="offer.recommended">{{xlationCodes.ourRecommendation | translate}}</div>
            </ng-container>

            <ng-container *ngIf="offer.selected">
                <button
                    mat-button
                    class="bbo-button width-100"
                    color="warn"
                    (click)="cancelCurrentPlan()">
                    {{xlationCodes.cancelPlan | translate}}
                </button>
                <div class="card-tips bbo-body-2">{{xlationCodes.currentPlan | translate}}</div>
            </ng-container>
        </bbo-auto-refill-offer>
    </ng-container>
</div>
