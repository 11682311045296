import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {MatDialog} from "@angular/material/dialog";
import {catchError} from "rxjs/operators";
import {XlationCodes} from "../../shared/translations/xlation.codes";
import {LanguageService} from "../../shared/translations/language.service";
import { GeneralErrorModalComponent } from 'src/app/shared/ui/general-error-modal/general-error-modal.component';

@Injectable({providedIn: 'root'})
export class GeneralErrorInterceptor implements HttpInterceptor {

    readonly xlationCodes = XlationCodes;

    constructor(private readonly dialog: MatDialog,
                private readonly languageService: LanguageService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Check if the request URL matches the one you want to exclude
        if (request.url.includes('/credit-card')) {
            // If the URL matches, forward the request without modifying it
            return next.handle(request);
        }

        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status > 499) {
                        const errorMessage = error.error.detail;
                        this.dialog.open(GeneralErrorModalComponent, {
                            data: {
                                errorMessage: errorMessage,
                                title: this.languageService.getXlation(this.xlationCodes.unexpected_error_occurred)
                            }
                        });
                        return throwError(() => new Error(errorMessage));
                    }
                    return next.handle(request);
                })
            )
    }
}
