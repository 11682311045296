import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {GetBillingAddressUseCase} from "../../../billing-address/domain/get-billing-address.usecase";
import {SaveBillingAddressUseCase} from "../../../billing-address/domain/save-billing-address.usecase";
import {genericObjectLoadEffect} from "../../../@core/ngrx/generic-object/generic-object-load-effect";
import {savedBillingAddressActions} from "../actions";
import {map} from "rxjs";
import {genericObjectUpdateEffect} from "../../../@core/ngrx/generic-object/generic-object-update-effect";

@Injectable()
export class SavedBillingAddressEffects {

    constructor(private readonly actions$: Actions,
                private readonly getBillingAddressUseCase: GetBillingAddressUseCase,
                private readonly updateBillingAddressUseCase: SaveBillingAddressUseCase) {
    }

    loadSavedBillingAddress$ = genericObjectLoadEffect(
        this.actions$,
        savedBillingAddressActions,
        () => this.getBillingAddressUseCase.execute().pipe(
            map(value => value.data)
        )
    )

    updateSavedBillingAddress$ = genericObjectUpdateEffect(
        this.actions$,
        savedBillingAddressActions,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ({payload: {patch}}) => this.updateBillingAddressUseCase.execute({...patch}).pipe(
            map(value => value.data)
        )
    )
}
