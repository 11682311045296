import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {
    BillingAddressGetResponse,
    BillingAddressRequest,
    BillingAddressSaveResponse,
    IBillingAddressRepository
} from './billing-address.interface';

@Injectable()
export class BillingAddressRepository implements IBillingAddressRepository {

    constructor(
        private http: HttpClient
    ) {}

    getBillingAddress(uid: string): Observable<BillingAddressGetResponse> {
        return this.http.get<BillingAddressGetResponse>(
            `${environment.apiURL}/users/${uid}/billing-address`,
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        );
    }

    postBillingAddress(uid: string, body: BillingAddressRequest): Observable<BillingAddressSaveResponse> {
        return this.http.post<BillingAddressSaveResponse>(
            `${environment.apiURL}/users/${uid}/billing-address`,
            body,
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        )
    }
}
