import {Injectable} from "@angular/core";
import {IUseCase} from "../../../base/usecase";
import {catchError, map, Observable, throwError} from "rxjs";
import {ApplicationError, IResponse, Response,} from 'src/base/response';
import {PaypalOrderRepository} from "../infrastructure/paypal-order-repository.service";

export interface ApplyOrderTaxesRequest {
  id: string;
}

export interface ApplyOrderTaxesResponse {
  id: string;
  payload: {
      id: string;
      create_time: Date;
      intent: string;
      links: any[];
      payer: object;
      payment_source: object;
      purchase_units: {
          amount: { currency_code: string; value: string, breakdown: { item_total: { currency_code: string; value: string }, tax_total: { currency_code: string; value: string } } };
      }[]
  };
}

@Injectable()
export class PaypalApplyTaxesUseCase implements IUseCase<ApplyOrderTaxesRequest, Observable<IResponse<ApplyOrderTaxesResponse>>> {

  constructor(private readonly repository: PaypalOrderRepository) {
  }


    execute(data: ApplyOrderTaxesRequest): Observable<IResponse<ApplyOrderTaxesResponse>> {
        return this.repository.applyTaxes(data.id).pipe(
            catchError((error) => {
                return throwError(() => new Response(
                    false, null,
                    // TODO define an error
                    new ApplicationError(111, 'PaypalApplyTaxesUseCase usecase unexpected error', error)))
            }),
            map((response) => {
                return new Response(true, response);
            })
        );
    }

}
