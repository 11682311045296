import {createGenericObjectReducer} from "../../../@core";
import {CurrentAccountBalance, CurrentAccountBalanceActions, currentAccountBalanceActions} from "../actions";
import {BalanceResponse} from "../../../balance/domain/get-balance.usecase";

const {
    initialState,
    reducer
} = createGenericObjectReducer<CurrentAccountBalance, BalanceResponse, Partial<CurrentAccountBalance>>(currentAccountBalanceActions)();
export type CurrentAccountBalanceState = typeof initialState;
export {initialState};

export function currentAccountBalanceReducer(
    state: CurrentAccountBalanceState | undefined,
    action: CurrentAccountBalanceActions
) {
    return reducer(state, action);
}
