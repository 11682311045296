import { Component, Input } from '@angular/core';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';
import { BboPlusUnsubscribeComponent } from '../../bbo-plus-unsubscribe/bbo-plus-unsubscribe.component';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs';
import { GeneralStoreFacade } from 'src/app/bbo-store/store';
import { BBO_PLUS_ADVANTAGES_LIST } from '../../bbo-plus.constants';
import { Option } from 'src/app/@core';
import { BboPlusGetResponse } from '../../infrastructure/bbo-plus.interface';

@Component({
    selector: 'bbo-plus-details',
    templateUrl: './bbo-plus-details.component.html',
    styleUrls: ['./bbo-plus-details.component.scss']
})
export class BboPlusDetailsComponent {

    advantagesList = BBO_PLUS_ADVANTAGES_LIST;

    xlationCodes = XlationCodes;

    @Input() bboPlus: Option<BboPlusGetResponse>;
    /** in cents */
    @Input() amount: number | null = null;

    constructor(
        private matDialog: MatDialog,
        private generalStoreFacade: GeneralStoreFacade
    ) {}

    openUnsubscribeDialog(): void {
        this.matDialog.open<BboPlusUnsubscribeComponent, undefined, boolean>(
            BboPlusUnsubscribeComponent,
            {
                backdropClass: 'bbo-backdrop-blurred',
                panelClass: ['bbo-dialog'],
                ariaModal: true,
                autoFocus: false
            }
        )
        .afterClosed()
        .pipe(take(1))
        .subscribe((confirmation: any) => {
            if (confirmation) {
                this.generalStoreFacade.unsubscribeBboPlus();
            }
        });
    }
}
