import {CommonModule} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import {ModuleWithProviders, NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDialogModule} from "@angular/material/dialog";
import {MatDividerModule} from "@angular/material/divider";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatRadioModule} from "@angular/material/radio";
import {MatSelectModule} from "@angular/material/select";
import {MatStepperModule} from "@angular/material/stepper";
import {RouterModule} from "@angular/router";
import {AuthenticateModule} from "src/app/authenticate/authenticate.module";
import {BalanceModule} from "src/app/balance/balance.module";
import {BillingAddressModule} from "src/app/billing-address/billing-address.module";
import {GetCreditCardUseCase} from "src/app/payment/domain/get-credit-card.usecase";
import {GetTaxesUseCase} from "src/app/payment/domain/get-payment-taxes.usecase";
import {BBO_PAYMENT_REPOSITORY} from "src/app/payment/infrastructure/payment.interface";
import {PaymentRepository} from "src/app/payment/infrastructure/payment.repository";
import {CreditCardFormComponent} from "src/app/payment/ui/credit-card-form/credit-card-form.component";
import {BBO_TAXES_REPOSITORY} from "src/app/payment/infrastructure/taxes.interface";
import {SharedModule} from "src/app/shared/shared.module";
import {TaxesRepository} from "src/app/payment/infrastructure/taxes.repository";
import {CreditCardFormDialogComponent} from './ui/credit-card-form-dialog/credit-card-form-dialog.component';
import {BBO_CYBERSOURCE_REPOSITORY} from "src/app/payment/infrastructure/cybersource.interface";
import {CybersourceRepository} from "src/app/payment/infrastructure/cybersource.repository";
import {GetTokenForSecureFormUseCase} from "src/app/payment/domain/get-token-for-secure-form.usecase";
import {CreateCreditCardUseCase} from "src/app/payment/domain/create-credit-card.usecase";
import {EditCreditCardUseCase} from "src/app/payment/domain/edit-credit-card.usecase";
import {RefillUseCase} from "./domain/refill-use-case";
import {
    DeleteCurrentPlanConfirmationModalComponent
} from "../auto-refill-subsription/delete-current-plan-confirmation-modal/delete-current-plan-confirmation-modal.component";
import { DeleteCreditCardConfirmationModalComponent } from "./ui/delete-credit-card-confirmation-modal/delete-credit-card-confirmation-modal.component";
import { TranslateModule } from "../shared/translations/translate.module";
import {CreditCardService} from "./service/credit-card.service";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from "@angular/material/core";
import { ExpirationDateInputTypeDirective } from "./ui/credit-card-form/expiration-date-input-format.directive";

@NgModule({
  declarations: [
    CreditCardFormComponent,
    CreditCardFormDialogComponent,
    DeleteCurrentPlanConfirmationModalComponent,
    DeleteCreditCardConfirmationModalComponent,
    ExpirationDateInputTypeDirective
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AuthenticateModule,
    BalanceModule,
    MatIconModule,
    MatDividerModule,
    HttpClientModule,
    RouterModule,
    MatStepperModule,
    BillingAddressModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatAutocompleteModule,
    TranslateModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
})
export class PaymentModule {

    static forRoot(): ModuleWithProviders<PaymentModule> {
        return {
            ngModule: PaymentModule,
            providers: [
                { provide: BBO_PAYMENT_REPOSITORY, useClass: PaymentRepository },
                { provide: BBO_TAXES_REPOSITORY, useClass: TaxesRepository },
                { provide: BBO_CYBERSOURCE_REPOSITORY, useClass: CybersourceRepository },
                GetCreditCardUseCase,
                CreateCreditCardUseCase,
                GetTaxesUseCase,
                GetTokenForSecureFormUseCase,
                EditCreditCardUseCase,
                RefillUseCase,
                CreditCardService
            ]
        };
    }

}
