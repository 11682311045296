import {Component, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'bbo-app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = "bbo-store-client";

    constructor(
        matIconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer
    ) {
        matIconRegistry.addSvgIcon('oneoff-purchase', sanitizer.bypassSecurityTrustResourceUrl('/assets/oneoff-purchase.svg'));
        matIconRegistry.addSvgIcon('order-details-oneoff-purchase', sanitizer.bypassSecurityTrustResourceUrl('/assets/order-detail-oneoff-purchase.svg'));
        matIconRegistry.addSvgIcon('refill', sanitizer.bypassSecurityTrustResourceUrl('/assets/refill.svg'));
        matIconRegistry.addSvgIcon('refill-offer-badge', sanitizer.bypassSecurityTrustResourceUrl('/assets/refill-offer-badge.svg'));
        matIconRegistry.addSvgIcon('refill-circle', sanitizer.bypassSecurityTrustResourceUrl('/assets/refill-circle.svg'));
        matIconRegistry.addSvgIcon('refill-triangle-grey', sanitizer.bypassSecurityTrustResourceUrl('/assets/refill-triangle-grey.svg'));
        matIconRegistry.addSvgIcon('refill-triangle-green', sanitizer.bypassSecurityTrustResourceUrl('/assets/refill-triangle-green.svg'));
        matIconRegistry.addSvgIcon('bbocoin', sanitizer.bypassSecurityTrustResourceUrl('/assets/bbocoin.svg'));
        matIconRegistry.addSvgIcon('club', sanitizer.bypassSecurityTrustResourceUrl('/assets/club.svg'));
        matIconRegistry.addSvgIcon('diamond', sanitizer.bypassSecurityTrustResourceUrl('/assets/diamond.svg'));
        matIconRegistry.addSvgIcon('heart', sanitizer.bypassSecurityTrustResourceUrl('/assets/heart.svg'));
        matIconRegistry.addSvgIcon('spade', sanitizer.bypassSecurityTrustResourceUrl('/assets/spade.svg'));
        matIconRegistry.addSvgIcon('paypal', sanitizer.bypassSecurityTrustResourceUrl('/assets/paypal.svg'));
        matIconRegistry.addSvgIcon('bbo-logo', sanitizer.bypassSecurityTrustResourceUrl('/assets/bbo-logo.svg'));
        matIconRegistry.addSvgIcon('bbo-logo-full-size', sanitizer.bypassSecurityTrustResourceUrl('/assets/bbo-logo-full-size.svg'));
        matIconRegistry.addSvgIcon('bbo-plus', sanitizer.bypassSecurityTrustResourceUrl('/assets/bbo-plus.svg'));
        matIconRegistry.addSvgIcon('alert-circle', sanitizer.bypassSecurityTrustResourceUrl('/assets/alert-circle.svg'));
    }

    // eslint-disable-next-line class-methods-use-this
    ngOnInit(): void {

        const dataLayer =(window as any).dataLayer || [];
        // event must be triggered once per session
        dataLayer.push({
            event: 'Shop Visited',
        });
    }

}
