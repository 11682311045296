import {createFeatureSelector, createSelector} from "@ngrx/store";
import {PRODUCT_STORE_NAME, ProductState} from "../reducers";
import {genericObjectSelectors} from "../../../@core";

const selectFeature = createFeatureSelector<ProductState>(PRODUCT_STORE_NAME);
const selectAutoRefillSubscriptionFlowStateSelectors = genericObjectSelectors(
    createSelector(
        selectFeature,
        s1 => s1.autoRefillSubscriptionFlow
    ));
const selectedPlan = createSelector(selectAutoRefillSubscriptionFlowStateSelectors.data, s1 => s1?.selectedOffer);
const paymentTaxes = createSelector(selectAutoRefillSubscriptionFlowStateSelectors.data, s1 => s1?.selectedPayment?.taxes);
const selectedPlanPrice = createSelector(selectAutoRefillSubscriptionFlowStateSelectors.data, s1 => s1?.selectedOffer?.amount);
const selectedPayment = createSelector(selectAutoRefillSubscriptionFlowStateSelectors.data, s1 => s1?.selectedPayment);
const paypalOrderId = createSelector(selectAutoRefillSubscriptionFlowStateSelectors.data, s1 => s1?.selectedPayment?.paypal?.paypalOrderId);
const selectedPaymentMethod = createSelector(selectAutoRefillSubscriptionFlowStateSelectors.data, s1 => s1?.selectedPayment?.method);
const selectedCreditCard = createSelector(selectAutoRefillSubscriptionFlowStateSelectors.data, s1 => s1?.selectedPayment?.creditCard);
const selectedPlanTreshold = createSelector(selectAutoRefillSubscriptionFlowStateSelectors.data, s1 => s1?.selectedOffer?.threshold);

export const autoRefillSubscriptionFlowSelectors = {
    ...selectAutoRefillSubscriptionFlowStateSelectors,
    selectedPlan,
    selectedPayment,
    selectedPlanPrice,
    selectedCreditCard,
    selectedPaymentMethod,
    paypalOrderId,
    paymentTaxes,
    selectedPlanTreshold
}
