import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {catchMap} from '../base';
import {EntityActions, UpdateEntityProps} from './generic-object-actions';
import {ApiFailure} from "../base/api-error-base";

/**
 * @description
 *  Type params:
 *  - D for Data - represents the basic DTO entity type
 *  - Q for Query - represents params required to get item, e.g. `{ id: string }`
 *  - P for Patch - represents the type of input used to update entity
 *  - E for Error
 */
export const genericObjectUpdateEffect = <
    D extends object,
    R extends object,
    P extends object = never,
    E = ApiFailure,
    Q extends object = never,
>(
    actions$: Actions,
    creators: EntityActions<D, R, P, Q, E>,
    apiAction: (props: UpdateEntityProps<P, Q>) => Observable<D | undefined>
) =>
    createEffect(() =>
        actions$.pipe(
            ofType(creators.update),
            switchMap(props =>
                apiAction(props).pipe(
                    map(data => ({
                        query: props.payload.query,
                        patch: props.payload.patch,
                        updated: data
                    }))
                )
            ),
            map(payload => ({payload})),
            map(creators.updateSuccess),
            catchMap<Action, E>(error => creators.updateFail({error}))
        )
    );
