import { SavedCreditCardEffects } from "./saved-credit-card.effects";
import { SavedBillingAddressEffects } from "./saved-billing-address.effects";
import { CurrentAutoRefillPlanEffects } from "./current-auto-refill-plan.effects";
import { CurrentAccountBalanceEffects } from "./current-account-balance.effects";
import { TrackingEffects } from "src/app/bbo-store/store/effects/tracking.effects";
import { BboPlusEffects } from "./bbo-plus.effects";

export const GENERAL_STORE_EFFECTS = [
    SavedCreditCardEffects,
    SavedBillingAddressEffects,
    CurrentAutoRefillPlanEffects,
    CurrentAccountBalanceEffects,
    TrackingEffects,
    BboPlusEffects
];
