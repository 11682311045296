import {ActionReducerMap} from "@ngrx/store";
import {
    paymentHistoryFlowReducer,
    PaymentHistoryFlowState
} from "./payment-history-flow.reducer";

export interface PaymentHistoryState {
    readonly paymentHistoryFlow:PaymentHistoryFlowState;
}

export const PAYMENT_HISTORY_STORE_NAME = 'payment-history';

export interface PaymentHistoryStateSlice {
    readonly [PAYMENT_HISTORY_STORE_NAME]: PaymentHistoryState;
}

export const paymentHistoryFlowReducers: ActionReducerMap<PaymentHistoryState, any> = {
    paymentHistoryFlow: paymentHistoryFlowReducer
}
