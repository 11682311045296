<mat-stepper orientation="horizontal" [linear]="true" [ngClass]="{'height-auto': (submitResult$ | async) === true || !!(submitError$ | async) && onLastStep}" labelPosition="bottom" #stepper color="primary">
    <ng-template matStepperIcon="done">
        <mat-icon>done</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="number" let-index="index">
    </ng-template>
    <ng-template matStepperIcon="edit" let-index="index">
        {{index + 1}}
    </ng-template>
    <mat-step [editable]="!(submitResult$ | async)" [completed]="(isSelectedOffer$ | async) === true">
        <ng-template matStepLabel>{{xlateCodes.option | translate}}</ng-template>
        <ng-container *ngIf="(offers$ | async) as offers">
            <bbo-one-time-purchase-list
                    [offers]="offers"
                    (userWantsToBuy)="userSelectOfferFromOfferList($event)"
                    [bboShouldDispplaySwipper]
                    [offersNumber]="offers.length"
            ></bbo-one-time-purchase-list>
        </ng-container>
        <div *ngIf="!currentAutoRefillPlan && !gettingAutoRefillPlan" class="promote-autorefill-message">
            <p class="bbo-body-2"><span class="font-weight-semibold">{{xlateCodes.noCurrentAutoRefillPlan | translate}}</span>{{xlateCodes.subscribeNowHint | translate}}</p>
            <button class="bbo-button bbo-small-button" mat-button color="primary" routerLink="/auto-refill">{{xlateCodes.chooseAPlan | translate}}</button>
        </div>
    </mat-step>

    <mat-step [editable]="!(submitResult$ | async)" [completed]="(selectedPaymentMethod$ | async) === true">
        <ng-template matStepLabel>{{xlateCodes.payment | translate}}</ng-template>
        <div class="payment-selection">
            <bbo-payment-method-selection
                (methodSelected)="userSelectsHisPayment($event)"
            ></bbo-payment-method-selection>
            <bbo-one-time-purchase-order-details class="mobile-hidden"></bbo-one-time-purchase-order-details>
        </div>
    </mat-step>

    <mat-step [editable]="!(submitResult$ | async)" [completed]="(isSubmitReady$ | async)" [state]="state">
        <ng-template matStepLabel>{{xlateCodes.checkout | translate}}</ng-template>
        <div *ngIf="!(isSubmitReady$ | async)" class="payment-review-container">
            <bbo-one-time-purchase-checkout></bbo-one-time-purchase-checkout>
            <bbo-one-time-purchase-order-details class="mobile-hidden"></bbo-one-time-purchase-order-details>
        </div>
    </mat-step>
</mat-stepper>

<bbo-one-time-purchase-complete [amount]="selectedOfferAmount$ | async"
                                *ngIf="(submitResult$ | async) === true"
></bbo-one-time-purchase-complete>
<bbo-purchase-failure
    *ngIf="!!(submitError$ | async) && onLastStep">
    <ng-container description>
        <ng-container *ngIf="errorType === ErrorTypes.GenericError">
            <div class="bbo-large-line-height">{{xlateCodes.cantValidatePayment | translate}}</div>
            <div class="bbo-large-line-height">{{xlateCodes.pleaseVerifyPaymentInformations | translate}}</div>
        </ng-container>
        <ng-container *ngIf="errorType === ErrorTypes.NotFound">
            <div class="bbo-large-line-height" [innerHTML]="xlateCodes.offerNotFound | translate"></div>
        </ng-container>
    </ng-container>
    <ng-container actions>
        <button *ngIf="errorType === ErrorTypes.NotFound" mat-flat-button class="bbo-button" color="primary" (click)="goBackOnOffers()">{{xlateCodes.backToOffers | translate}}</button>
        <button *ngIf="errorType === ErrorTypes.GenericError" mat-flat-button class="bbo-button" color="primary" [bboLoading]="(loading$ | async)" (click)="retryPayment()">{{xlateCodes.tryAgain | translate}}</button>
    </ng-container>
</bbo-purchase-failure>
