import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {bindCallback, catchError, EMPTY, map, Observable, tap} from 'rxjs';
import {GetTokenForSecureFormUseCase} from 'src/app/payment/domain/get-token-for-secure-form.usecase';
import { LanguageService } from 'src/app/shared/translations/language.service';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';
import {ApplicationError, Response} from 'src/base/response';
import { CREDIT_CARD_EXPIRATION_DATE_FORMATS } from '..';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';


export interface ICreditCardForm {
    firstName: FormControl<string | null>;
    lastName: FormControl<string | null>;
    type: FormControl<string | null>;
    cardNumber: FormControl<string | null>;
    cvv: FormControl<string | null>;
    expirationDate: FormControl<moment.Moment | null>;
}

@Component({
    selector: 'bbo-credit-card-form',
    templateUrl: './credit-card-form.component.html',
    styleUrls: ['./credit-card-form.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        }, {
            provide: MAT_DATE_FORMATS, useValue: CREDIT_CARD_EXPIRATION_DATE_FORMATS
        },
    ]
})
export class CreditCardFormComponent implements OnInit {
    xlationCodes = XlationCodes;
    readonly CYBERSOURCE_CARD_NUMBER_FIELD_ID = "number-container";
    readonly CYBERSOURCE_CVV_FIELD_ID = "security-code-container";
    readonly CARD_PROVIDERS = CreditCardFormComponent.CARD_PROVIDERS;

    static DEFAULT_SECURITY_CODE_FORM_FIELD_LABEL = "CVN";
    static readonly CARD_PROVIDERS: {id: string, label: string}[] = [
        {id: "visa", label: "Visa"},
        {id: "mastercard", label: "MasterCard"},
        {id: "amex", label: "American Express"},
        {id: "discover", label: "Discover"},
    ];

    /** If true then some fields will be disabled and show hidden data */
    @Input() editMode ?= false;
    @Input() creditCardForm!: FormGroup<ICreditCardForm>;

    constructor(
        @Inject(GetTokenForSecureFormUseCase) private getTokenForSecureForm: GetTokenForSecureFormUseCase,
        private languageService: LanguageService
    ) {}

    get creditCardHolderFirstNameFormControl() {
        return this.creditCardForm.get('firstName');
    }
    get creditCardHolderLastNameFormControl() {
        return this.creditCardForm.get('lastName');
    }
    get creditCardTypeFormControl() {
        return this.creditCardForm.get('type');
    }
    get creditCardCVVFormControl() {
        return this.creditCardForm.get('cvv');
    }
    get creditCardExpirationDateFormControl() {
        return this.creditCardForm.get('expirationDate');
    }
    get creditCardNumberFormControl() {
        return this.creditCardForm.get('cardNumber');
    }

    @Output() cancel = new EventEmitter<null>();
    @Output() done = new EventEmitter<null>();
    @Output() failure = new EventEmitter<null>();
    @Output() secureFormCreated = new EventEmitter<string>();

    @ViewChildren('cybersourceFormFieldContainer', {read: ElementRef}) cybersourceFormFieldContainerList: QueryList<ElementRef<HTMLElement>> | undefined = undefined;

    securityCodeFormFieldLabel = CreditCardFormComponent.DEFAULT_SECURITY_CODE_FORM_FIELD_LABEL;
    private cybersourceSecureForm: any;

    captureToken = "";
    minExpirationDate = moment().startOf("month").toDate();

    ngOnInit(): void {
            this.getTokenForSecureForm.execute().pipe(
                catchError((response) => {
                    if (response instanceof Response) {
                        console.error(response.error, response)
                        if (response.error instanceof ApplicationError) {
                            console.error(response.error.innerError);
                        }
                    } else {
                        console.error(response);
                    }
                    this.failure.emit();
                    return EMPTY;
                }),
                tap((response) => {
                    const captureToken = response.data;
                    this.instanciateSecureCardForm(captureToken);
                    this.replaceMatInputWithCybersourceInput();
                    // used to start a 15 token expiration timeout if the token expire then close the modal
                    this.secureFormCreated.emit(captureToken);
                })
            ).subscribe();
    }

    /**
     * workaround to have the same style
     */
    private replaceMatInputWithCybersourceInput(): void {
        const containerIndexToCybersourceElementId = [
            this.CYBERSOURCE_CARD_NUMBER_FIELD_ID, // first container
            this.CYBERSOURCE_CVV_FIELD_ID // second container
        ];

        this.cybersourceFormFieldContainerList?.forEach((container, index) => {
            const input = container.nativeElement.querySelector('input');
            if (!input) {
                throw new Error('An input must be present');
            }
            const cybersourceSecureInputContainer = document.getElementById(containerIndexToCybersourceElementId[index]);
            if (!containerIndexToCybersourceElementId) {
                throw new Error(`cybersourceFormFieldContainer of index ${index} doesn't have a corresponding cybersource secure input container ID. (1) You added an unexpected new secure form or (2) you need to declare the associated secure input container ID.`);
            }
            input.replaceWith(cybersourceSecureInputContainer as Node);
        });

        // now that the cybersource secure inputs are in place unhide them
        // we hide them so as to avoif layout shifts
        containerIndexToCybersourceElementId.forEach((elementId) => {
            document.getElementById(elementId)?.classList.remove('container-hide');
        });
    }

    onYearSelected(momentYear: moment.Moment) {
        let expirationDateValue = this.creditCardForm.controls.expirationDate.value;
        if (!expirationDateValue) {
            expirationDateValue = moment();
        }
        expirationDateValue?.year(momentYear.year());
        this.creditCardForm.controls.expirationDate.setValue(expirationDateValue);
    }

    onMonthSelected(momentMonth: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
        const expirationDateValue = this.creditCardForm.controls.expirationDate.value;
        expirationDateValue?.month(momentMonth.month());
        this.creditCardForm.controls.expirationDate.setValue(expirationDateValue);
        datepicker.close();
    }

    private instanciateSecureCardForm(token: string): { secureCode: any, cardNumber: any } {
        const customStyles = {
            'input': {
              'font-size': '16px',
              'font-family': '"Inter", sans-serif',
              'color': '#4d4d4d'
            },
            '::placeholder': {
              'color': '#222222'
            },
            ':disabled': {
              'cursor': 'not-allowed',
            },
          };

        const secureTransactionToken = token;
        const flex = new (window as any as {Flex: any}).Flex(secureTransactionToken);
        this.cybersourceSecureForm = flex.microform({
            styles: customStyles
        });
        const microform = this.cybersourceSecureForm;
        const cardNumber = microform.createField('number', { placeholder: this.languageService.getXlation(XlationCodes.cardNumberPlaceholder) });
        const cardSecretCode = microform.createField('securityCode', { placeholder: this.languageService.getXlation(XlationCodes.securityCodePlaceholder) });

        cardNumber.load(`#${this.CYBERSOURCE_CARD_NUMBER_FIELD_ID}`);
        cardSecretCode.load(`#${this.CYBERSOURCE_CVV_FIELD_ID}`);

        [
            {cybersourceSecureInput: cardNumber, matFormFieldContainer: this.cybersourceFormFieldContainerList?.get(0)},
            {cybersourceSecureInput: cardSecretCode, matFormFieldContainer: this.cybersourceFormFieldContainerList?.get(1)}
        ].forEach(({cybersourceSecureInput, matFormFieldContainer}) => {
            cybersourceSecureInput.on('focus', () => {
                matFormFieldContainer
                    ?.nativeElement.querySelector('.mat-mdc-text-field-wrapper')
                    ?.classList.add('mdc-text-field--focused');
            });

            cybersourceSecureInput.on('blur', () => {
                matFormFieldContainer
                    ?.nativeElement.querySelector('.mat-mdc-text-field-wrapper')
                    ?.classList.remove('mdc-text-field--focused');
            });

            // UX improvement: The cybersource secure input is smaller then our styled inputs
            // so if user clicks inside our inputs but outside the cybersource secure input
            // then focus on the cybersource input
            matFormFieldContainer?.nativeElement.querySelector("mat-form-field")?.addEventListener('click', () => {
                cybersourceSecureInput.focus();
            })
            // UX improvement: On label click, focus the corresponding cybersource secure input
            matFormFieldContainer?.nativeElement.querySelector("label")?.addEventListener('click', () => {
                cybersourceSecureInput.focus();
            });
        });

        cardNumber.on('autocomplete', (data: {firstName?:string; lastName?:string}) => {
            if (data.firstName) {
                this.creditCardForm.patchValue({firstName: data.firstName});
            }
            if (data.lastName) {
                this.creditCardForm.patchValue({lastName: data.lastName});
            }
        });

        cardNumber.on('blur', () => {
            this.creditCardNumberFormControl?.markAsTouched();
        });
        cardSecretCode.on('blur', () => {
            this.creditCardCVVFormControl?.markAsTouched();
        });
        cardNumber.on('change', (data: any) => {
            console.log("ici", data);
            this.creditCardNumberFormControl?.markAsDirty();
        });

        cardSecretCode.on('change', () => {
            this.creditCardCVVFormControl?.markAsDirty();
        });
        cardNumber.on('load', () => {
            this.creditCardNumberFormControl?.setErrors({required: true});
        });
        cardSecretCode.on('load', () => {
            this.creditCardCVVFormControl?.setErrors({required: true});
        });

        cardSecretCode.on('change', (data: any) => {
            console.log("ici2", data);
            const errors: {required?: boolean, missingDigits?: boolean} = {};
            if (data.empty) {
                errors.required = true;
            } else if (data.valid === false) {
                // I assume that it's valid when the code has the right length
                // length is determined by the third party script according to
                // the card type (based on card number)
                errors.missingDigits = true;
            }
            this.creditCardCVVFormControl?.setErrors(Object.keys(errors).length ? errors : null);
        });

        cardNumber.on('change', (data: any) => {
            const errors: {required?: true, invalidCardNumber?: true} = {};
            if (data.empty) {
                errors.required = true;
            } else if (data.valid === false) {
                errors.invalidCardNumber = true;
            }
            this.creditCardNumberFormControl?.setErrors(Object.keys(errors).length ? errors : null);
        });

        // Update your security code label to match the detected card type's terminology
        cardNumber.on('change', (data: any) => {
            // No need to update the secret code max length because
            // the secure inputs already does it by itself based on the credit card number
            const card = data.card?.[0];
            if (card) {
                this.securityCodeFormFieldLabel = card.securityCode.name;
                const correspondingCardProvider = CreditCardFormComponent.CARD_PROVIDERS.find((provider) => provider.id === card.name);
                if (correspondingCardProvider) {
                    this.creditCardForm.patchValue({type: correspondingCardProvider.id});
                }
            } else {
                this.securityCodeFormFieldLabel = CreditCardFormComponent.DEFAULT_SECURITY_CODE_FORM_FIELD_LABEL;
            }
        });

        return {
            cardNumber,
            secureCode: cardSecretCode
        };
    }

    exportSecureToken(): Observable<{transientToken: string, expirationMonth: string, expirationYear: string}> {
        const expirationMonth = this.creditCardExpirationDateFormControl?.value?.format("MM").toString() ?? '';
        const expirationYear = this.creditCardExpirationDateFormControl?.value?.year().toString() ?? '';
        const bound = bindCallback(this.cybersourceSecureForm.createToken as (opts: any, callback: (err: any, token: any) => void) => void).bind(this.cybersourceSecureForm);
        return bound({
            expirationMonth,
            expirationYear,
        }).pipe(
            map(([error, transientToken]: [any, string]) => {
                if (error) {
                    switch (error.reason) {
                        case 'CREATE_TOKEN_NO_FIELDS_LOADED':
                        case 'CREATE_TOKEN_TIMEOUT':
                        case 'CREATE_TOKEN_NO_FIELDS':
                        case 'CREATE_TOKEN_VALIDATION_PARAMS':
                        case 'CREATE_TOKEN_VALIDATION_FIELDS':
                        case 'CREATE_TOKEN_VALIDATION_SERVERSIDE':
                        case 'CREATE_TOKEN_UNABLE_TO_START':
                        default:
                            throw error;
                    }
                } else {
                    return {
                        transientToken,
                        expirationMonth,
                        expirationYear
                    };
                }
            })
        );
    }
}
