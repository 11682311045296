import {InjectionToken} from '@angular/core';
import {Observable} from "rxjs";
import {ProductAutoRefillPlanOffer} from "../domain/get-auto-refill-offers-usecase";

export const BBO_PRODUCT_REPOSITORY = new InjectionToken<IProductRepository>("BBO_PRODUCT_REPOSITORY");

export interface CurrentAutoRefillPlan {
    owner: { uid: number },
    amountRefill: number;
    threshold: number;
    outdated: boolean;
}

export interface IProductRepository {
    getCurrentAutoRefillPlan(uid: string): Observable<CurrentAutoRefillPlan>;

    deleteCurrentAutoRefillPlan(uid: string): Observable<void>;

    getOneTimePurchaseProductList(): Observable<{ productId: number, price: number, recommended: boolean }[]>;

    getAutoRefillOptions(): Observable<ProductAutoRefillPlanOffer[]>;

    subscribeToAutoRefillPlan(uid: string, autoRefillPlanId: number): Observable<CurrentAutoRefillPlan>;
}
