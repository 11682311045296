import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from "moment";
import { BboSupportComponent } from './bbo-support-modal/bbo-support.component';
import { XlationCodes } from '../../translations/xlation.codes';

@Component({
    selector: 'bbo-store-footer',
    styleUrls: ["./bbo-store-footer.component.scss"],
    templateUrl: 'bbo-store-footer.component.html'
})

export class BboStoreFooterComponent {

    constructor(private dialog: MatDialog) {}

    year = moment().year();

    xlationCodes = XlationCodes

    // eslint-disable-next-line class-methods-use-this
    openLink(url: string): void {
        window.open(url, "_blank");
    }

    openSupportModal(): void {
        this.dialog.open(BboSupportComponent)
    }
}
