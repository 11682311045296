import { Component, Input } from '@angular/core';
import {Option} from "../../../../@core";

@Component({
  selector: 'bbo-order-amount-tile',
  templateUrl: './order-amount-tile.component.html',
  styleUrls: ['./order-amount-tile.component.scss']
})
export class OrderAmountTileComponent {
    @Input() amount: Option<number>;
}
