<mat-icon class="brand-logo" [ngClass]="(isMobileDisplay) ? 'mobile-display' : 'desktop-display'" [svgIcon]="(isMobileDisplay) ? 'bbo-logo': 'bbo-logo-full-size'"></mat-icon>
<div class="login-container">
    <mat-card>
        <mat-card-content class="bbo-medium-card">
            <div class="header text-align-center">
                <h1 class="bbo-headline-4 bbo-headline-3-md-up">BBO Store</h1>
                <p class="bbo-headline-6 color-accent-darker">{{xlationCodes.signInToBuy| translate}}</p>
            </div>
    
            <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
                <div class="bbo-form-field" >
                    <label class="bbo-form-label" for="username">{{xlationCodes.userName | translate}}</label>
                    <mat-form-field appearance="outline">
                        <input id="username" formControlName="username" maxlength="10" autocomplete="username" matInput [placeholder]="xlationCodes.userName | translate">
                        <mat-error *ngIf="form.hasError('unknownCredential')">{{xlationCodes.loginFormWrongCombinaisonError | translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="bbo-form-field">
                    <label class="bbo-form-label" for="password">{{xlationCodes.password | translate}}</label>
                    <mat-form-field class="password-form-field" appearance="outline">
                        <input matInput formControlName="password" maxlength="10" id="password" autocomplete="current-password" [type]="hide ? 'password' : 'text'" [placeholder]="xlationCodes.password | translate">
                        <button class="hide-password-button no-ripple" mat-icon-button matSuffix type="button" (click)="hide = !hide" [attr.aria-label]="xlationCodes.hidePassword | translate" [attr.aria-pressed]="hide">
                          <mat-icon class="material-icons-outlined">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error class="error-above-button" *ngIf="form.hasError('unknownCredential')">{{xlationCodes.loginFormWrongCombinaisonError | translate}}</mat-error>
                      </mat-form-field>
                </div>
    
                <mat-error *ngIf="form.hasError('genericError')">{{xlationCodes.loginFormError | translate}}</mat-error>
                <mat-error *ngIf="form.hasError('errorWithMessage')">{{form.getError('errorWithMessage')}}</mat-error>
    
                <button mat-button class="bbo-button" type="button" color="primary" (click)="forgotPasswordButtonClick()">{{xlationCodes.forgetPassword | translate}}</button>
    
                <button mat-flat-button style="width: 100%" class="bbo-button" type="submit" color="primary">{{xlationCodes.signIn | translate}}</button>
            </form>
    
        </mat-card-content>
    </mat-card>
</div>

<ng-template #resetPasswordTemplate>
    <div mat-dialog-content class="forget-password-dialog">
        <p>{{xlationCodes.resetPasswordDescription | translate}}</p>
        <ol>
            <li [innerHTML]="xlationCodes.resetPasswordFirstStep | translate"></li>
            <li [innerHTML]="xlationCodes.resetPasswordSecondStep | translate"></li>
        </ol>
    </div>
    <div class="dialog-actions" mat-dialog-actions align="end">
      <button mat-button class="close-button bbo-button" color="primary" mat-dialog-close cdkFocusInitial>{{xlationCodes.close | translate}}</button>
    </div>
</ng-template>
<bbo-store-footer></bbo-store-footer>