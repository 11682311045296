import {NgModule} from '@angular/core';

import {BboStoreComponent} from './bbo-store.component';
import {RouterModule} from '@angular/router';
import {BboStoreHeaderModule} from '../bbo-store-header/bbo-store-header.module';
import {BboStoreRoutingModule} from './bbo-store-routing.module';
import {ProductModule} from '../product/product.module';
import {BillingAddressModule} from '../billing-address/billing-address.module';
import {PaymentModule} from 'src/app/payment/payment.module';
import {PaymentHistoryModule} from '../payment-history/payment-history.module';
import {GeneralStoreModule} from './store/general-store.module';
import {PaymentMethodsModule} from "../payment-methods/payment-methods.module";
import {SharedModule} from '../shared/shared.module';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {GeneralErrorInterceptor, HttpCreditCardInterceptor} from "../@core/interceptors";
import {AuthenticateInterceptor} from "../authenticate/service/authenticate.interceptor";
import { BboPlusModule } from '../bbo-plus-home/bbo-plus.module';

@NgModule({
    imports: [
        RouterModule,
        BboStoreRoutingModule,
        BboStoreHeaderModule,
        PaymentHistoryModule,
        ProductModule.forRoot(),
        BillingAddressModule.forRoot(),
        PaymentModule.forRoot(),
        GeneralStoreModule,
        PaymentMethodsModule,
        BboPlusModule.forRoot(),
        SharedModule
    ],
    declarations: [
        BboStoreComponent
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: HttpCreditCardInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: GeneralErrorInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AuthenticateInterceptor, multi: true},
    ]
})
export class BboStoreModule {
}
