<mat-card>
    <mat-card-content class="bbo-medium-card">
        <div class="title-content bbo-large-line-height font-weight-light">
            <div class="bbo-headline-3 bbo-headline-2-md-up bbo-large-line-height color-gray-900">{{xlationCodes.welcomeOnBoard | translate}}</div>
            <div class="color-gray-700">{{xlationCodes.bboPlusWelcomeDescription | translate}}</div>
        </div>
        <div class="order-summary-container bbo-large-line-height">
            <div class="color-gray-900">{{xlationCodes.orderSummary | translate}}</div>
            <div>
                <div class="bbo-headline-4 color-purple">{{xlationCodes.bboPlusSubscription | translate}}</div>
                <div class="bbo-body-2 color-gray-700 bbo-large-line-height">{{xlationCodes.bboPlusOrderPricePerMonth | translate:["7.99"]}}</div>
            </div>
            <div class="summary-item bbo-body-2 bbo-large-line-height">
                <div class="font-weight-semibold">{{xlationCodes.subtotal | translate}}</div>
                <div>$7.99 USD</div>
            </div>
            <div class="summary-item bbo-body-2 bbo-large-line-height">
                <div class="font-weight-semibold">{{xlationCodes.bboPlusTaxes | translate}}</div>
                <div>{{xlationCodes.noTaxesValue | translate}}</div>
            </div>
            <div class="summary-item bbo-body-2 bbo-large-line-height">
                <div class="font-weight-semibold color-gray-900">{{xlationCodes.total | translate}}</div>
                <div class="font-weight-bold bbo-body-1 color-gray-900">$7.99 USD</div>
            </div>
        </div>
        <div class="actions">
            <button mat-flat-button class="bbo-button return-to-bbo" color="primary" (click)="returnTo()">{{((bboPlusFromV3Service.isFromV3) ? xlationCodes.returnToBBO : xlationCodes.returnToHome) | translate}}</button>
        </div>
    </mat-card-content>
</mat-card>