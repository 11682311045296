import {Component, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {Option} from "../../../@core";

@Component({
    selector: 'bbo-radio-selector',
    templateUrl: './radio-selector.component.html',
    styleUrls: ['./radio-selector.component.scss']
})
export class RadioSelectorComponent {
    @HostBinding('class.selected') @Input() selected = false;

    @HostBinding('class.dashed') dashed = false;
    @HostBinding('class.selectable') @Input() selectable = false;
    @HostListener('click', ['$event'])
    onClick() {
        this.selectedItem.emit()
    }

    @Input()
    label: Option<string>;
    @Input()
    prefixIcon: Option<string>;
    @Input()
    loading: Option<boolean>;
    @Output()
    selectedItem: EventEmitter<void> = new EventEmitter<void>();
}
