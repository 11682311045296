import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {QUERY_PARAM_RETURN_PAGE_LOGIN} from 'src/app/authenticate/service/authenticate.constants';
import {AuthenticateService} from 'src/app/authenticate/service/authenticate.service';

export const authenticateGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const authenticateService = inject(AuthenticateService);
    const router = inject(Router);

    if (authenticateService.isLocalUserLoggedIn()) {
        const dataLayer =(window as any).dataLayer || [];
        dataLayer.push({
            event: 'Shop Logged in',
        });

        return true;
    }
    return router.createUrlTree(
        ['/login'],
        {queryParams: {[QUERY_PARAM_RETURN_PAGE_LOGIN]: state.url}});
};
