import { Component, Input } from '@angular/core';
import { Option } from 'src/app/@core';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';

@Component({
    selector: 'bbo-product-one-time-purchase',
    templateUrl: './product-one-time-purchase.component.html',
    styleUrls: ['./product-one-time-purchase.component.scss']
})
export class ProductOneTimePurchaseComponent {
    xlationCodes = XlationCodes;

    @Input() layout: "tiny" | "normal" = "normal";

    @Input() isFirstTimePurchaseAvailable: Option<boolean> = false;
}
