import {Injectable} from "@angular/core";
import {LocalStorageService} from '../../@core/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    static USERNAME_STORAGE_KEY = "username";
    static USERID_STORAGE_KEY = "uid";

    private userName: string | null = null;
    private uid: string | null = null;

    constructor(private readonly localStorageService: LocalStorageService) {}

    setUserName(userName: string | null): void {
        this.userName = userName;
        if (userName === null) {
            this.localStorageService.remove(UserService.USERNAME_STORAGE_KEY);
        } else {
            this.localStorageService.save(UserService.USERNAME_STORAGE_KEY, userName);
        }
    }

    getUserName(): string | null {
        if (this.userName) {
            return this.userName;
        }
        return this.localStorageService.get(UserService.USERNAME_STORAGE_KEY);
    }

    setUId(uid: string | null): void {
        this.uid = uid;
        if (uid === null) {
            this.localStorageService.remove(UserService.USERID_STORAGE_KEY);
        } else {
            this.localStorageService.save(UserService.USERID_STORAGE_KEY, uid);
        }
    }

    getUId(): string | null {
        if (this.uid) {
            return this.uid;
        }
        return this.localStorageService.get(UserService.USERID_STORAGE_KEY);
    }
}
