import {NgModule} from "@angular/core";
import {PaymentMethodsComponent} from "./payment-methods.component";
import {ReactiveFormsModule} from "@angular/forms";
import {AsyncPipe, CommonModule} from "@angular/common";
import {MatCardModule} from "@angular/material/card";
import {MatRadioModule} from "@angular/material/radio";
import {MatIconModule} from "@angular/material/icon";
import {SharedModule} from "../shared/shared.module";
import {MatButtonModule} from "@angular/material/button";

@NgModule({
    declarations: [
        PaymentMethodsComponent
    ],
    imports: [
        SharedModule,
        MatIconModule,
        MatRadioModule,
        MatCardModule,
        MatButtonModule,
        AsyncPipe,
        ReactiveFormsModule,
        CommonModule
    ],
    providers: []
})
export class PaymentMethodsModule {

}
