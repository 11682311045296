import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';


interface ParsedToken {
    ctx: Array<{
        data: {
            clientLibrary: string;
            clientLibraryIntegrity: string;
        }
    }>;
    exp: number;
    iat: number;
    uid: number;
    username: string;
}

@Injectable({providedIn: 'root'})
export class CybersourceInitializerService {

    constructor(
        private httpClient: HttpClient
    ) { }

    initialize() {
        this.getTokenForSecureForm().subscribe((token: string) => {
            const jwtTokenData: ParsedToken = jwt_decode(token);
            const clientLibrary = jwtTokenData.ctx[0].data.clientLibrary;
            const clientLibraryIntegrity = jwtTokenData.ctx[0].data.clientLibraryIntegrity;
            const script = document.createElement('script');
            script.src = clientLibrary;
            script.integrity = clientLibraryIntegrity;
            script.crossOrigin = "anonymous";
            document.head.appendChild(script);
        });
    }

    getTokenForSecureForm(): Observable<string> {
        return this.httpClient.get<{token: string}>(
            environment.apiURL+"/cybersource/token",
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        ).pipe(
            map((response: any) => response.token)
        );
    }
}