import { Component } from '@angular/core';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';

@Component({
    selector: 'bbo-saving-purchase-process',
    templateUrl: 'saving-purchase-process-modal.component.html',
    styleUrls: ['./saving-purchase-process-modal.component.scss']
})
export class SavingPurchaseProcessModalComponent {
    xlationCodes = XlationCodes;
}