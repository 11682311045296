import {ICreditCardForm} from "./credit-card-form/credit-card-form.component";
import {CreditCard} from "../models";
import {IBillingAddressForm} from "../../billing-address/ui/billing-address-form/billing-address-form.component";
import {BillingAddressRequest} from "../../billing-address/domain/save-billing-address.usecase";
import {CreditCardEditRequest} from "../domain/edit-credit-card.usecase";

export const mapperCreateCreditCardFormToRequest = (
    form: ICreditCardForm,
    expirationMonth = "",
    expirationYear = "",
    last4Digits = "",
    captureToken: string,
    transientToken: string
): CreditCard => {
    return {
        type: form.type.value!,
        firstName: form.firstName.value!,
        lastName: form.lastName.value!,
        last4DigitsCardNumber: form.cardNumber?.value?.substring(form.cardNumber?.value?.length - 4) || last4Digits,
        expirationMonth: form.expirationDate.value?.format("MM").toString() || expirationMonth,
        expirationYear: form.expirationDate.value?.year().toString() || expirationYear,
        captureToken: captureToken,
        transientToken: transientToken
    };
}

export const mapperBillingAddressFormToRequest = (form: IBillingAddressForm): BillingAddressRequest => {
    return {
        addressLine1: (form.address.value ?? '').trim(),
        addressLine2: (form['address-2'].value ?? '').trim(),
        city: form.city.value ?? '',
        countryCode: form.country.value?.id ?? '',
        zipCode: form.postalCode.value ?? '',
        state: form.region.value ?? '',
        email: form.email.value ?? ""
    };
}

export const mapperEditCreditCardFormToRequest = (form: ICreditCardForm, expirationMonth = "", expirationYear = ""): CreditCardEditRequest => {
    return {
        type: form.type.value!,
        firstName: form.firstName.value!,
        lastName: form.lastName.value!,
        expirationMonth: form.expirationDate.value?.format("MM").toString() || expirationMonth,
        expirationYear: form.expirationDate.value?.year().toString() || expirationYear
    };
}

export const mapperCreditCardFormIntoCreditCard = (
    form: ICreditCardForm,
    transientToken: string,
    captureToken: string
): CreditCard => {
    return {
        owner: {
            id: -1
        },
        firstName: form.firstName.value!,
        lastName: form.lastName.value!,
        type: form.type.value!,
        expirationMonth: form.expirationDate.value!.format("MM")!.toString()!,
        expirationYear: form.expirationDate.value!.year()!.toString()!,
        last4DigitsCardNumber: form.cardNumber.value!,
        transientToken,
        captureToken
    };
}

export const CREDIT_CARD_EXPIRATION_DATE_FORMATS = {
    parse: {
      dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
  };
