<mat-card class="purchase-complete-card">
  <mat-card-content class="bbo-medium-card">
    <div class="card-header">
      <span class="bbo-headline-3 bbo-headline-2-md-up">{{xlationCodes.subscriptionComplete | translate}}</span>
    </div>
    <div class="card-content">
      <div class="purchased-item">
        <mat-icon svgIcon="refill-offer-badge" class="badge"></mat-icon>
        <div class="amount bbo-headline-2">{{amount$ | async}} BB$</div>
      </div>
      <div class="confirmation-by-mail-content bbo-typography color-gray">
        <div>{{xlationCodes.subscriptionActivationCondition | translate: [(treshold$ | async)?.toString() ?? ""]}}</div>
      </div>
      <button mat-flat-button class="bbo-button" color="primary" (click)="goBackToBBO()">{{xlationCodes.goToBBO | translate}}</button>
    </div>
  </mat-card-content>
</mat-card>
