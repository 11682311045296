import {createGenericObjectReducer} from "../../../@core";
import {
    GeneralStore,
    GeneralStoreActions,
    generalStoreActions
} from "../actions";

const {initialState, reducer} =
    createGenericObjectReducer<GeneralStore, GeneralStore, Partial<GeneralStore>>(generalStoreActions)();

export type GeneralStoreReducerState = typeof initialState;
export {initialState};

export function generalStoreReducer(
    state: GeneralStoreReducerState | undefined,
    action: GeneralStoreActions
) {
    return reducer(state, action);
}
