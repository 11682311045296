import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {genericObjectLoadEffect} from "../../../@core/ngrx/generic-object/generic-object-load-effect";
import {bboPlusActions} from "../actions";
import {map, switchMap} from "rxjs";
import { GetBboPlusUseCase } from "src/app/bbo-plus-home/domain/get-bbo-plus.usecase";
import { UnsubscribeBboPlusUseCase } from "src/app/bbo-plus-home/domain/unsubscribe-bbo-plus.usecase";
import { catchMap } from "src/app/@core";
import { Action } from "@ngrx/store";
import { SubscribeBboPlusUseCase } from "src/app/bbo-plus-home/domain/subscribe-bbo-plus.usecase";

@Injectable()
export class BboPlusEffects {

    constructor(private readonly actions$: Actions,
                private readonly unsubscribeBboPlusUseCase: UnsubscribeBboPlusUseCase,
                private readonly subscribeBboPlusUseCase: SubscribeBboPlusUseCase,
                private readonly getBboPlusUseCase: GetBboPlusUseCase) {
    }

    loadBboPlus$ = genericObjectLoadEffect(
        this.actions$,
        bboPlusActions,
        () => this.getBboPlusUseCase.execute().pipe(
            map(value => value.data)
        )
    )

    unsubscribeBboPlus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bboPlusActions.unSubscribe),
            switchMap(() =>
                this.unsubscribeBboPlusUseCase.execute().pipe(map(value =>
                    ({result: value.data})))
            ),
            map(result => bboPlusActions.submitSuccess(result)),
            catchMap<Action, Error>(error => bboPlusActions.submitFailure({error}))
        ));

    subscribeBboPlus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bboPlusActions.subscribe),
            switchMap(() =>
                this.subscribeBboPlusUseCase.execute().pipe(map(value =>
                    ({result: value.data})))
            ),
            map(result => bboPlusActions.submitSuccess(result)),
            catchMap<Action, Error>(error => bboPlusActions.submitFailure({error}))
        ));
}
