<mat-card class="purchase-failure-card">
    <mat-card-content class="bbo-medium-card">
        <div class="card-header">
            <span class="bbo-headline-3 bbo-headline-2-md-up">{{xlationCodes.orderCancelled | translate}}</span>
        </div>
        <div class="card-content">
            <div class="failure-header">
                <div class="failure-icon mobile-hidden">
                    <mat-icon fontIcon="block" class="badge"></mat-icon>
                </div>
                <div class="bbo-headline-4 bbo-headline-3-sm-up bbo-headline-2-md-up">{{xlationCodes.paymentFailed | translate}}</div>
            </div>
            <div class="failure-message bbo-typography color-gray">
                <div>{{xlationCodes.cantValidatePayment | translate}}</div>
                <div>{{xlationCodes.pleaseVerifyPaymentInformations | translate}}</div>
            </div>
            <button mat-flat-button class="bbo-button" color="primary" [bboLoading]="(isLoading$ | async)" (click)="tryAgain.emit()">{{xlationCodes.tryAgain | translate}}</button>
        </div>
    </mat-card-content>
</mat-card>