import {Injectable} from "@angular/core";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";
import {AutoRefillSubscriptionFlowFacade} from "../services";
import {catchError, filter} from "rxjs/operators";
import {isNotNil} from "../../../@core";
import {map, mergeMap, of, switchMap} from "rxjs";
import {autoRefillSubscriptionFlowActions} from "../actions";
import {SubscribeToAutoRefillPlanUseCase} from "../../domain";
import {TaxesService} from "../../service/taxes.service";
import {isNil} from "lodash";
import {CreditCard} from "../../../payment";
import {savedCreditCardActions} from "../../../bbo-store/store";

@Injectable()
export class AutoRefillSubscriptionFlowEffects {

    constructor(private readonly actions$: Actions,
                private readonly store: AutoRefillSubscriptionFlowFacade,
                private readonly subscribeToAutoRefillPlanUseCase: SubscribeToAutoRefillPlanUseCase,
                private readonly cardTaxesService: TaxesService) {
    }

    readonly submit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(autoRefillSubscriptionFlowActions.submit),
            concatLatestFrom(() => [
                this.store.selectedPlan$.pipe(filter(isNotNil), map(value => value.id))
            ]),
            mergeMap(
                ([
                     flowstate,
                     autoRefillPlanId,
                 ]) => this.subscribeToAutoRefillPlanUseCase.execute({autoRefillPlanId}).pipe(
                    map(value => value.data),
                    map(result => autoRefillSubscriptionFlowActions.submitSuccess({result})),
                    catchError(error => of(autoRefillSubscriptionFlowActions.submitFailure({error})))
                )
            )
        );
    })

    readonly applyCardPaymentTaxes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(autoRefillSubscriptionFlowActions.update),
            map(value => value.payload.patch.selectedPayment),
            filter(isNotNil),
            filter(value => value.method !== 'paypal' && isNil(value.taxes)),
            concatLatestFrom(() => [
                this.store.selectedPlan$.pipe(filter(isNotNil), map(value => value.amount)),
                this.store.selectedPayment$.pipe(filter(isNotNil))
            ]),
            switchMap(value => this.cardTaxesService.calculateTaxes(value[1])
                .pipe(
                    map(taxes => autoRefillSubscriptionFlowActions.update(
                        {payload: {patch: {selectedPayment: {...value[2], ...{taxes: (taxes/100)}}}}}
                    )),
                    catchError(error => of(autoRefillSubscriptionFlowActions.submitFailure({error})))
                )
            )
        )
    })

    readonly updateCreditCardFromUpdateFlow$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(savedCreditCardActions.updateSuccess),
            filter(value => isNotNil(value.payload.patch)),
            map(value => value.payload.patch),
            switchMap(creditCard =>
                of(autoRefillSubscriptionFlowActions.updateCreditCard({payload: {creditCard: creditCard as CreditCard}}))
            )
        )
    });

    readonly applyCardPaymentTaxesOnCardUpdate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(autoRefillSubscriptionFlowActions.updateCreditCard),
            map(value => value.payload.creditCard),
            concatLatestFrom(() => [
                this.store.selectedPlan$.pipe(filter(isNotNil), map(value => value.amount)),
                this.store.selectedPayment$.pipe(filter(isNotNil))
            ]),
            switchMap(value => this.cardTaxesService.calculateTaxes(value[1])
                .pipe(
                    map(taxes => autoRefillSubscriptionFlowActions.update(
                        {payload: {patch: {selectedPayment: {...value[2], ...{taxes: (taxes/100)}}}}}
                    )),
                    catchError(error => of(autoRefillSubscriptionFlowActions.submitFailure({error})))
                )
            )
        )
    })
}
