import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {BehaviorSubject, combineLatest, EMPTY, forkJoin, map, mergeMap, of, Subject, takeUntil, tap, zip,} from 'rxjs';
import {ProductAutoRefillPlan} from 'src/app/product/domain/get-current-auto-refill-plan.usecase';
import {GetOneTimePurchaseOffersUseCase} from 'src/app/product/domain/get-one-time-purchase-offers.usecase';
import {ProductOneTimePurchaseOffer} from '../../domain';
import {STEP_STATE, StepperSelectionEvent} from '@angular/cdk/stepper';
import {BboStoreHeaderService} from '../../../bbo-store-header/service/bbo-store-header.service';
import {CompleteOrderResponse, PaypalOneTimePurchaseOrderUseCase} from "../../../paypal";
import {OneTimePurchaseFlow, OneTimePurchaseFlowFacade} from "../../store";
import {catchError, distinctUntilChanged, filter, first, skip, switchMap, take} from 'rxjs/operators';
import {isNotNil, Nullable, Option} from "../../../@core";
import {CreditCard, PaymentMethod} from "../../../payment";
import {XlationCodes} from 'src/app/shared/translations/xlation.codes';
import {GeneralStoreFacade} from "../../../bbo-store/store";
import {isNil} from "lodash";
import { DeactivatableComponent } from 'src/app/@core/deactivatable-component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SavingPurchaseProcessModalComponent } from '../saving-purchase-process-modal/saving-purchase-process-modal.component';
import { RefillResponse } from 'src/app/payment/domain/refill-use-case';
import { HttpStatusCode } from '@angular/common/http';

enum ERROR_TYPES {
    NotFound,
    GenericError
}

@Component({
    selector: 'bbo-one-time-purchase',
    templateUrl: './one-time-purchase.component.html',
    styleUrls: ['./one-time-purchase.component.scss']
})
export class OneTimePurchaseComponent implements OnInit, AfterViewInit, OnDestroy, DeactivatableComponent {

    private unsubscribeSubject: Subject<void> = new Subject<void>();
    readonly selectedOfferProductId$ = this.storeFacade.selectedOfferProductId$;
    readonly selectedOffer$ = this.storeFacade.flowState$;
    readonly isSelectedOffer$ = this.storeFacade.selectedOffer$
        .pipe(map(value => (value) ? Boolean(isNotNil(value)) : false));
    readonly selectedOfferPrice$ = this.storeFacade.selectedOfferPrice$;
    readonly selectedOfferAmount$ = this.storeFacade.selectedOfferAmount$;
    readonly selectedPayment$ = this.storeFacade.selectedPayment$;
    readonly selectedPaymentMethod$ = this.storeFacade.selectedPaymentMethod$.pipe(
        map(value => (value) ? Boolean(isNotNil(value)) : false)
    );
    readonly offers$ = this.getOffersUseCase.execute().pipe(
        map((response) => {
            return response.data;
        })
    );
    readonly savedCreditCard$ = this.generalStoreFacade.storedCreditCard$
    ErrorTypes = ERROR_TYPES;
    errorType: any = null;

    readonly submitError$ = this.storeFacade.submitError$
        .pipe(
            takeUntil(this.unsubscribeSubject),
            tap((error) => console.log(error)),
            tap((error) => this.errorType = this.getErrorType(error)),
            map(error => isNotNil(error))
        );
    readonly submitResult$ = this.storeFacade.submitResult$.pipe(map(v => isNotNil(v)));
    readonly isSubmitReady$ = combineLatest(
        [this.submitError$, this.submitResult$]
    ).pipe(map(value => !!value[0] || value[1]))

    xlateCodes = XlationCodes;
    @ViewChild(MatStepper) stepper!: MatStepper;
    state: string = STEP_STATE.NUMBER;
    loading$ = new BehaviorSubject(false);

    currentAutoRefillPlan: ProductAutoRefillPlan | null = null;
    gettingAutoRefillPlan = true;

    paymentSucceeded = false;

    onLastStep = false;

    constructor(
        private readonly getOffersUseCase: GetOneTimePurchaseOffersUseCase,
        private readonly bboStoreHeaderService: BboStoreHeaderService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly paypalOrderUseCase: PaypalOneTimePurchaseOrderUseCase,
        private readonly storeFacade: OneTimePurchaseFlowFacade,
        private readonly generalStoreFacade: GeneralStoreFacade,
        private readonly matDialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.generalStoreFacade.loadCurrentAutoRefillPlan();
        this.generalStoreFacade.loadSavedCreditCard();
        this.generalStoreFacade.loadSavedBillingAddress();
        this.setHeaderBackAction(0);

        const dataLayer = (window as any).dataLayer || [];
        dataLayer.push({
            event: 'Shop Initiated checkout',
            "product_category": "BB$",
        });
    }

    ngAfterViewInit(): void {
        this.stepper.selectionChange
            .pipe(takeUntil(this.unsubscribeSubject))
            .subscribe((event: StepperSelectionEvent) => {
                this.state = STEP_STATE.NUMBER;
                this.setHeaderBackAction(event.selectedIndex);
                this.onLastStep = event.selectedIndex === 2;
            })

        this.storeFacade.submitResult$.pipe(
            takeUntil(this.unsubscribeSubject),
            filter(isNotNil),
            map(value => (value) ? Boolean(isNotNil(value)) : false)
        ).subscribe((value) => {
            this.paymentSucceeded = value;
            this.setHeaderBackAction();
        });

        this.selectedOffer$.pipe(
            takeUntil(this.unsubscribeSubject),
            filter(isNotNil)
        ).subscribe(() => this.stepForward());

        this.selectedPaymentMethod$.pipe(
            takeUntil(this.unsubscribeSubject),
            filter(isNotNil)
        ).subscribe(() => this.stepForward());

        this.selectedPaymentMethod$.pipe(
            takeUntil(this.unsubscribeSubject),
            filter(value => value === false)
        ).subscribe(() => this.oneStepBack());

        this.selectedPayment$.pipe(
            // prevent the init event
            skip(1),
            takeUntil(this.unsubscribeSubject),
            filter(isNil)
        ).subscribe(() => this.oneStepBack());

        this.selectedPayment$.pipe(
            filter(isNotNil),
            filter(value => value.method === 'paypal'),
            filter(value => !value.paypal),
            switchMap(() => this.selectedOfferProductId$.pipe(take(1), filter(isNotNil))),
            switchMap(id =>
                forkJoin([
                    this.paypalOrderUseCase.execute({id})
                        .pipe(
                            take(1),
                            map(value => value.data.paypalOrderId),
                            catchError((error) => {
                                this.storeFacade.submitFailure(error)
                                return EMPTY;
                            }),
                        ),
                    this.selectedPayment$.pipe(take(1))
                ])
            ),
            takeUntil(this.unsubscribeSubject)
        ).subscribe((value) =>
            this.storeFacade.updateFlow({
                selectedPayment: {
                    ...value[1],
                    ...{paypal: {paypalOrderId: value[0], approved: false}}
                }
            })
        );

        this.isSubmitReady$.pipe(
            distinctUntilChanged(),
            takeUntil(this.unsubscribeSubject)
        ).subscribe((isReady) => {
            this.state = (isReady) ? STEP_STATE.DONE : STEP_STATE.NUMBER;
        })
    }

    stepForward() {
        this.changeDetectorRef.detectChanges();
        this.stepper.next();
    }

    ngOnDestroy(): void {
        if (this.paymentSucceeded) {
            this.storeFacade.resetFlow();
        }
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }

    setHeaderBackAction(stepperIndex = 0): void {
        if (stepperIndex === 0) {
            this.bboStoreHeaderService.setHeaderBackAction({
                fontIcon: "arrow_back",
                className: "back-action",
                callback: () => this.bboStoreHeaderService.backToHomePage()
            })
        } else {
            this.bboStoreHeaderService.setHeaderBackAction({
                fontIcon: "arrow_back",
                className: "back-action",
                callback: () => this.oneStepBack()
            })
        }
    }

    oneStepBack(): void {
        this.loading$.next(false);
        this.stepper.previous();
    }

    userSelectOfferFromOfferList(selectedOffer: ProductOneTimePurchaseOffer): void {
        this.storeFacade.updateFlow({selectedOffer});
    }

    userSelectsHisPayment(method: Option<PaymentMethod>): void {
        this.storeFacade.selectPaymentMethod(method);
        this.sendTrackingEventForOnPaymentMethodSelect(method);
        if (method === 'credit-card') {
            this.savedCreditCard$.pipe(take(1), filter(isNotNil))
            .subscribe((creditCard) =>
                this.storeFacade.updateCreditCard(
                    creditCard as CreditCard
                    // here we set initiatedByUser parameter to true because updateCreditCard is called to update the user interface
                ))
        } else {
            this.storeFacade.updateFlow({selectedPayment: {method: 'paypal'}});
        }
    }

    private sendTrackingEventForOnPaymentMethodSelect(paymentMethod: Option<PaymentMethod>): void {
        if (isNil(paymentMethod)) {
            return;
        }

        const paymentMethodToTrackingValue = (paymentMethod === "paypal") ? "Paypal" : "Credit card";
        this.storeFacade.selectedOfferPrice$.pipe(
            // selectedPlanPrice can emit undefined to reset its state
            // that doesn't suit us for the tracking
            filter((price) => price !== undefined && price !== null),
            tap((price) => {
                const dataLayer =(window as any).dataLayer || [];
                dataLayer.push({
                    event: 'Shop Payment selected',
                    product_category: 'BB$',
                    payment_mode: paymentMethodToTrackingValue,
                    product_price: price
                });
            }),
            takeUntil(this.unsubscribeSubject)
        ).subscribe();
    }

    retryPayment() {
        this.storeFacade.submit();
    }

    private getErrorType(error: any): ERROR_TYPES {
        if (this.isChooseNotFoundError(error)) {
            return ERROR_TYPES.NotFound;
        }
        return ERROR_TYPES.GenericError;
    }

     // eslint-disable-next-line class-methods-use-this
     private isChooseNotFoundError(error: any): boolean {
        return (
            error?.innerError &&
            error.innerError.status === HttpStatusCode.NotFound &&
            error.innerError.url.includes("refill") &&
            error.innerError.url.includes("choose")
        )
    }

    goBackOnOffers(): void {
        this.storeFacade.resetFlow();
        this.stepper.reset();
    }

    /**
     * If there is a one time purchase flow in progress open the confirmation modal
     * If the user want to delete the pending process so reset the flow in any case we return true at the end to don't block navigation
     * We use CanDeactivate guard as a confirmation step before navigation
     */
    canDeactivate() {
        return zip([
            this.storeFacade.submitResult$,
            this.storeFacade.flowState$
        ]).pipe(
            first(),
            mergeMap((result: [Nullable<RefillResponse | CompleteOrderResponse>, Nullable<OneTimePurchaseFlow>]) => {
                // if we have a submit result it mean we have finish the flow so no need of this modal
                if (result[0]) {
                    return of(true);
                }
                // if we don't have any data in the flow no need for this modal
                if (!result[1]) {
                    return of(true);
                }
                const matDialogConfig: MatDialogConfig = {
                    disableClose: false
                }
                return this.matDialog.open<SavingPurchaseProcessModalComponent>(SavingPurchaseProcessModalComponent, matDialogConfig)
                    .afterClosed()
                    .pipe(
                        tap((result: boolean) => {
                            if (result) {
                                this.storeFacade.resetFlow();
                            }
                        }),
                        map(() => true)
                    );
            })
        )
    }
}
