import {createGenericObjectReducer} from "../../../@core";
import {
    AutoRefillSubscriptionFlow,
    AutoRefillSubscriptionFlowActions,
    autoRefillSubscriptionFlowActions
} from "../actions";
import {ProductAutoRefillPlan} from "../../domain";
import {on} from "@ngrx/store";

const {initialState, reducer} =
    createGenericObjectReducer<AutoRefillSubscriptionFlow, ProductAutoRefillPlan, Partial<AutoRefillSubscriptionFlow>>(autoRefillSubscriptionFlowActions)(
        on(autoRefillSubscriptionFlowActions.updateCreditCard, (state, {payload}) => ({
            ...state,
            data: {
                ...state.data,
                selectedPayment: {
                    method: 'credit-card',
                    taxes: state.data?.selectedPayment?.taxes,
                    creditCard: {...state.data?.selectedPayment?.creditCard, ...payload.creditCard}
                }
            }
        })),
    );

export type AutoRefillSubscriptionFlowState = typeof initialState;
export {initialState};

export function autoRefillSubscriptionFlowReducer(
    state: AutoRefillSubscriptionFlowState | undefined,
    action: AutoRefillSubscriptionFlowActions
) {
    return reducer(state, action);
}
