import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {paymentHistoryFlowReducers, PAYMENT_HISTORY_STORE_NAME} from "./reducers";
import {PaymentHistoryFlowFacade} from "./services";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(PAYMENT_HISTORY_STORE_NAME, paymentHistoryFlowReducers),
    ],
    providers: [
        PaymentHistoryFlowFacade
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PaymentHistoryStoreModule {

}
