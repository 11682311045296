import {Observable} from "rxjs";
import {ApplyOrderTaxesResponse} from "../domain/paypal-apply-taxes-use-case.service";
import {OneTimePurchasePaypalOrderResponse} from "../domain/paypal-one-time-purchase-order-use-case";
import {PaypalResourceCheckResponse,} from "../domain/paypal-resource-check-use-case.service";
import {CompleteOrderResponse} from "../domain/paypal-complete-order-use-case.service";

export interface IPaypalOrderRepository {
  createOneTimePurchaseOrder(optionId: number): Observable<OneTimePurchasePaypalOrderResponse>;

  applyTaxes(orderId: string): Observable<ApplyOrderTaxesResponse>;

  cancelOrder(orderId: string): Observable<void>;

  completeOrder(orderId: string): Observable<CompleteOrderResponse>;

  checkResource(): Observable<PaypalResourceCheckResponse>;
}
