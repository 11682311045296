import {BillingAddressRequest} from "../billing-address/infrastructure/billing-address.interface";
import {Option} from "../@core";

export interface CreditCard {
    owner?: {
        id: number
    };
    type: string,
    firstName: string,
    lastName: string,
    last4DigitsCardNumber?: Option<string>,
    expirationMonth: string,
    expirationYear: string,
    captureToken?: Option<string>,
    transientToken?: Option<string>,
}

export interface OneTimePaymentInformations {
    creditCard: CreditCard,
    billingAddress: BillingAddressRequest
}

export interface CreditCardPaymentInformation {
    creditCard: CreditCard,
    billingAddress: BillingAddressRequest
}

export interface PayPalPaymentInformation {
    paypalOrderId: Option<string>,
    approved: Option<boolean>
}

export type PaymentMethod = 'paypal' | 'credit-card' | 'other-card';

export interface Payment {
    method?: Option<PaymentMethod>,
    creditCard?: Option<CreditCard>,
    paypal?: Option<{ paypalOrderId?: Option<string>, approved?: Option<boolean> }>,
    taxes?: Option<number>
}

export function creditCardInfoToPayment(info: CreditCardPaymentInformation): Payment {
    return {
        method: 'credit-card',
        creditCard: info.creditCard,
    }
}

export function paypalInfoToPayment(info: PayPalPaymentInformation): Payment {
    return {
        method: 'paypal',
        paypal: {...info}
    }
}
