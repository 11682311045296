import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {map, startWith, Subject, tap} from 'rxjs';
import {COUNTRIES, CountryDefinition} from '../../domain/countries';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';
import { Option } from 'src/app/@core';

export interface IBillingAddressForm {
    address: FormControl<string | null>,
    'address-2': FormControl<string | null>,
    city: FormControl<string | null>,
    region: FormControl<string | null>,
    postalCode: FormControl<string | null>,
    country: FormControl<CountryDefinition | null>,
    email: FormControl<string | null>
}

interface SelectOptionItem {
    id: string,
    label: string
}

@Component({
  selector: 'bbo-billing-address-form',
  templateUrl: './billing-address-form.component.html',
  styleUrls: ['./billing-address-form.component.scss']
})
export class BillingAddressFormComponent implements OnInit {
    xlationCodes = XlationCodes;
    @Input() billingAddressForm!: FormGroup<IBillingAddressForm>;

    get getBillingAddressLine1() {
        return this.billingAddressForm.get('address');
    }
    get getBillingAddressLine2() {
        return this.billingAddressForm.get('.address-2');
    }
    get getBillingAddressCity() {
        return this.billingAddressForm.get('city');
    }
    get getBillingAddressRegion() {
        return this.billingAddressForm.get('region');
    }
    get getBillingAddressPostalCode() {
        return this.billingAddressForm.get('postalCode');
    }
    get getBillingAddressCountry() {
        return this.billingAddressForm.get('country');
    }
    get getBillingEmail() {
        return this.billingAddressForm.get('email');
    }

    countries: SelectOptionItem[] = COUNTRIES;
    statesForSelect: SelectOptionItem[] = [];
    statesForAutocomplete: string[] = [];
    filteredStatesOptions = new Subject<string[]>();

    ngOnInit(): void {
        this.checkForCountryStates(this.getBillingAddressCountry?.value);
        this.getBillingAddressCountry!.valueChanges.pipe(
            tap(() => this.getBillingAddressRegion?.setValue('')),
            map((country: CountryDefinition | null) => this.checkForCountryStates(country))
        ).subscribe({
            error: (error) => {
                console.error(error);
            }
        });

        // update the autocomplete options when user type something in the field
        this.getBillingAddressRegion!.valueChanges.pipe(
            startWith(''),
            map((value) => {
                const filterValue = value?.toLowerCase() ?? '';
                if (this.statesForAutocomplete.length) {
                    return this.statesForAutocomplete.filter((state) => state.toLowerCase().includes(filterValue));
                }
                return this.statesForAutocomplete;
            })
        ).subscribe({
            next: (value) => this.filteredStatesOptions.next(value),
            error: (error) => {
                console.error(error);
            }
        });
    }

    private checkForCountryStates(country: Option<CountryDefinition>): void {
        if (country?.states?.aspect === "select") {
            this.statesForSelect = country.states.values ?? [];
        } else {
            this.statesForAutocomplete = country?.states?.values ?? [];
            this.filteredStatesOptions.next(this.statesForAutocomplete);
        }
    }
}
