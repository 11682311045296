import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";
import {ITaxesRepository} from "./taxes.interface";
import {Taxes, TaxesParameters} from '../../payment/domain/get-payment-taxes.usecase';

@Injectable()
export class TaxesRepository implements ITaxesRepository {

    constructor(
        private http: HttpClient
    ) {}

    getTaxes(parameters: TaxesParameters): Observable<Taxes> {
        return this.http.post<Taxes>(
            `${environment.apiURL}/taxes/calculate`,
            parameters,
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        );
    }
}
