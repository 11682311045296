<mat-stepper orientation="horizontal" [linear]="true" labelPosition="bottom" #stepper color="primary">
    <ng-template matStepperIcon="done">
        <mat-icon>done</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="number" let-index="index">
    </ng-template>
    <ng-template matStepperIcon="edit" let-index="index">
        {{index + 1}}
    </ng-template>

    <mat-step [editable]="!(submitResult$ | async)" [completed]="(isSelectedPlan$ | async) === true">
        <ng-template matStepLabel>{{xlationCodes.option | translate}}</ng-template>

        <ng-container *ngIf="(offers$ | async) as offers">
            <bbo-auto-refill-offer-list [offers]="offers"
                                        [bboShouldDispplaySwipper]
                                        [offersNumber]="offers.length"
            ></bbo-auto-refill-offer-list>
        </ng-container>

    </mat-step>

    <mat-step [editable]="!(submitResult$ | async)" [completed]="(isSelectedPayment$ | async) === true">
        <ng-template matStepLabel>{{xlationCodes.subscription | translate}}</ng-template>
        <bbo-auto-refill-subscription-overview></bbo-auto-refill-subscription-overview>
    </mat-step>

    <mat-step [editable]="!(submitResult$ | async)" [completed]="(isSubmitReady$ | async)" [state]="state">
        <ng-template matStepLabel>{{xlationCodes.checkout | translate}}</ng-template>
        <ng-container *ngIf="!(isSubmitReady$ | async)">
            <div class="subscription-review-container">
                <bbo-auto-refill-subscription-checkout
                    (userWantsToPay)="userWantsToPay()"
                    (userWantsToChangePaymentMethod)="oneStepBack()"
                ></bbo-auto-refill-subscription-checkout>
                <bbo-auto-refill-subscription-details class="bbo-medium-card mobile-hidden"></bbo-auto-refill-subscription-details>
            </div>
        </ng-container>
        <ng-container *ngIf="(isSubmitReady$ | async)">
            <bbo-auto-refill-subscription-purchase-complete
                *ngIf="(submitResult$ | async)"></bbo-auto-refill-subscription-purchase-complete>
            <bbo-purchase-failure *ngIf="(submitError$ | async)"
                                  [isLoading]="isLoading$ | async" (tryAgain)="userWantsToPay()"></bbo-purchase-failure>
        </ng-container>
    </mat-step>
</mat-stepper>
