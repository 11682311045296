import {Component} from '@angular/core';

@Component({
    selector: 'bbo-store',
    styleUrls: ['./bbo-store.component.scss'],
    templateUrl: 'bbo-store.component.html'
})

export class BboStoreComponent {

}
