import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CreateOrderActions, CreateOrderData, loadScript} from "@paypal/paypal-js";
import {environment} from "../../../../environments/environment";
import {PayPalButtonsComponent} from "@paypal/paypal-js/types/components/buttons";
import {PaypalCancelOrderUseCase} from "../../domain/paypal-cancel-order-use-case.service";
import { take } from 'rxjs';
import {Option} from "../../../@core";

@Component({
    selector: 'bbo-paypal',
    templateUrl: './paypal.component.html'
})
export class PaypalComponent implements OnChanges {

  @ViewChild('paypalDiv', { static: true }) paypalButtonDiv!: ElementRef;

  private paypalButton!: PayPalButtonsComponent;

  constructor(private readonly paypalOrderCancel: PaypalCancelOrderUseCase) {
    }


    @Input()
    orderId: Option<string>;
    @Output()
    orderApproved: EventEmitter<void> = new EventEmitter<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['orderId']) {

      this.paypalButtonDiv.nativeElement.innerHTML = '';

      if(changes['orderId'].previousValue) {
        this.paypalOrderCancel.execute({id: changes['orderId'].previousValue}).pipe(take(1)).subscribe();
      }

      this.orderId = changes['orderId'].currentValue;

      loadScript({
        clientId: environment.paypalClientId,
        disableFunding: 'credit,card',
        commit: false,
      }).then((value) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.paypalButton = value?.Buttons({
          createOrder: (
            data: CreateOrderData,
            actions: CreateOrderActions
          ) => {
            return new Promise(resolve => resolve(this.orderId as string));
          },
          onApprove: (data, actions) => {
            return new Promise((resolve) => {
              this.orderApproved.emit();
              resolve();
            });
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onCancel(data, actions) {
          },
          onError: (error) => {
            console.error(`Error Approving PayPal `, error);
          }
        });
        this.paypalButton.render('#paypal-container');
      });
    }
  }
}
