<div class="side-nav-content">
    <div class="aside-header">
        <div class="user-profil-container">
            <div class="welcome-container">
                <div class="welcome-label">{{xlationCodes.welcome | translate}}</div>
                <div class="user-name">{{userName}}</div>
            </div>
        </div>
        <button class='close-button' mat-icon-button (click)="onCloseAside()">
            <mat-icon class="menu-icon" fontIcon="close"></mat-icon>
        </button>
    </div>
    <div class="aside-content">
        <div class="balance-containter" *ngIf="balanceAmount">
            <div class="balance-label">{{xlationCodes.yourCurrentBalance | translate}}</div>
            <bbo-balance [balanceAmount]="balanceAmount" [size]="isMobileResolution ? 'large' : 'medium'"></bbo-balance>
        </div>
        <div class="divider-container mobile-hidden">
            <mat-divider></mat-divider>
        </div>
        <div class="menu-container">
            <bbo-side-navigation-menu-item class="navigation-menu-item-hidden" url='' [itemLabel]="xlationCodes.home | translate" itemIcon='home' (click)="onCloseAside()"></bbo-side-navigation-menu-item>
            <mat-divider class="navigation-menu-item-hidden"></mat-divider>
            <bbo-side-navigation-menu-item class="navigation-menu-item-hidden" url='/buy-bb$' [itemLabel]="xlationCodes.buyBB$ | translate" itemIcon='shopping_cart' (click)="onCloseAside()"></bbo-side-navigation-menu-item>
            <bbo-side-navigation-menu-item class="navigation-menu-item-hidden" url='/auto-refill' [itemLabel]="xlationCodes.autoRefill | translate" itemIcon='forward_media' (click)="onCloseAside()"></bbo-side-navigation-menu-item>
            <bbo-side-navigation-menu-item *ngIf="showBboPlus$ | async" class="navigation-menu-item-hidden" url='/bbo-plus' [itemLabel]="xlationCodes.bboPlus | translate" itemIcon='forward_media' (click)="onCloseAside()"></bbo-side-navigation-menu-item>
            <mat-divider class="navigation-menu-item-hidden"></mat-divider>
            <bbo-side-navigation-menu-item url='/payment-history' [itemLabel]="xlationCodes.paymentHistory | translate" itemIcon='schedule' (click)="onCloseAside()"></bbo-side-navigation-menu-item>
            <bbo-side-navigation-menu-item url='/payment-methods' [itemLabel]="xlationCodes.paymentMethods | translate" itemIcon='credit_card' (click)="onCloseAside()"></bbo-side-navigation-menu-item>
        </div>
    </div>
    <div class="sign-out-container">
        <button mat-button class="sign-out" (click)="logout()">
            <mat-icon fontIcon="logout"></mat-icon>
            {{xlationCodes.signOut | translate}}
        </button>
    </div>
</div>