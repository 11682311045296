import {Component, Input} from '@angular/core';
import { Option } from 'src/app/@core';
import { ProductAutoRefillPlanOffer } from 'src/app/product/domain';
import { XlationCodes } from '../../../translations/xlation.codes';

@Component({
  selector: 'bbo-auto-refill-amount-tile',
  templateUrl: './auto-refill-amount-tile.component.html',
  styleUrls: ['./auto-refill-amount-tile.component.scss']
})
export class AutoRefillAmountTileComponent {
  @Input() autoRefillPlan: Option<ProductAutoRefillPlanOffer>;
  xlationCodes = XlationCodes;
}
