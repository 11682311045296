import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {XlationCodes} from '../../../translations/xlation.codes';
import {Option} from 'src/app/@core';
import {ProductAutoRefillPlanOffer} from 'src/app/product/domain';

@Component({
    selector: 'bbo-auto-refill-order-details',
    templateUrl: './auto-refill-order-details.component.html',
    styleUrls: ['./auto-refill-order-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoRefillOrderDetailsComponent {
    @Input() taxes: Option<number>;
    @Input() selectedPlan: Option<ProductAutoRefillPlanOffer>;
    xlationCodes = XlationCodes;

    get total(){
        return Number(Number(this.selectedPlan?.amount) + Number(this.taxes || 0)).toFixed(2);
    }
}
