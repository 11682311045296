import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {generalStoreReducers, GENERAL_STORE_NAME} from "./reducers";
import {GeneralStoreFacade} from "./services";
import {EffectsModule} from "@ngrx/effects";
import {GENERAL_STORE_EFFECTS} from "./effects";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(GENERAL_STORE_NAME, generalStoreReducers),
        EffectsModule.forFeature(GENERAL_STORE_EFFECTS)
    ],
    providers: [
        GeneralStoreFacade
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GeneralStoreModule {

}
