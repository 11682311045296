import { Injectable } from '@angular/core';
import { AuthenticateService } from './authenticate.service';
import { UserService } from '../../shared/services/user.service';
import jwt_decode from 'jwt-decode';
import { AnalyticsService } from '../../@core/analytics/analytics.service';

interface ParsedToken {
    exp: number;
    iat: number;
    uid: number;
    username: string;
}

// we can access to bbo-store directly from v3 application by passing a "t" query parameter for the auth token
@Injectable({providedIn: 'root'})
export class AutoLoginService {
    constructor(
        private authenticateService: AuthenticateService,
        private userService: UserService,
        private analyticsService: AnalyticsService
    ) { }

    initialize() {
        const url = new URL(window.location.href);
        const token = url.searchParams.get('t');
        if (token) {
            try {
                const jwtTokenData: ParsedToken = jwt_decode(token);
                const username = jwtTokenData.username;
                const userUniqueIdentifier = jwtTokenData.uid.toString();
                this.userService.setUserName(jwtTokenData.username);
                this.userService.setUId(jwtTokenData.uid.toString());
                this.authenticateService.setToken(token);
                this.analyticsService.onUserLoggedIn({username, userUniqueIdentifier});
            } catch (error) {
                // automatically redirect to the login page
                console.error("invalide token format", token);
            }
        }
    }
}