import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { GeneralStoreFacade } from 'src/app/bbo-store/store';
import { BBO_PLUS_REPOSITORY, BboPlusGetResponse, IBboPlusRepository } from '../infrastructure/bbo-plus.interface';
import { Nullable, isNotNil } from 'src/app/@core';
import { EMPTY, Subject, catchError, filter, takeUntil, tap } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
    selector: 'bbo-plus',
    templateUrl: './bbo-plus.component.html',
    styleUrls: ['./bbo-plus.component.scss']
})
export class BboPlusComponent implements OnInit, OnDestroy {

    bboPlus: Nullable<BboPlusGetResponse> = null;
    bboPlusTrial$ = this.generalStoreFacade.bboPlusTrialResult$;
    bboPlusPrice$ = this.generalStoreFacade.bboPlusPriceResult$;
    private readonly unsubscribeSubject: Subject<void> = new Subject<void>();

    constructor(
        private generalStoreFacade: GeneralStoreFacade,
        @Inject(BBO_PLUS_REPOSITORY) private bboPlusRepository: IBboPlusRepository,
        private userService: UserService,
    ) {
        generalStoreFacade.bboPlus$
            .pipe(
                filter(isNotNil),
                takeUntil(this.unsubscribeSubject)
            )
        .subscribe((bboPlus) => {
            this.bboPlus = bboPlus;
        });
    }

    ngOnInit(): void {
        const localUid = this.userService.getUId();
        if (!localUid) throw new Error("localUid not found");
        this.generalStoreFacade.loadBboPlusTrial();
        this.bboPlusRepository.getRemainingDaysTrial(localUid).pipe(
            tap((response) => this.generalStoreFacade.saveBboPlusTrial(response)),
            catchError(error => {
                this.generalStoreFacade.failBboPlusTrial(error);
                return EMPTY;
            }),
            takeUntil(this.unsubscribeSubject)
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }
}
