import {InjectionToken} from '@angular/core';
import {Observable} from "rxjs";
import {CreditCardEditRequest} from '../domain/edit-credit-card.usecase';
import {CreditCard} from '../models';
import {RefillRequest, RefillResponse} from '../domain/refill-use-case';

export const BBO_PAYMENT_REPOSITORY = new InjectionToken<IPaymentRepository>("BBO_PAYMENT_REPOSITORY");

export interface IPaymentRepository {
    getTokenForSecureForm(): Observable<string>;
    getCreditCard(uid: string): Observable<CreditCard>;
    createCreditCard(uid: string, payload: CreditCard): Observable<CreditCard>;
    editCreditCard(uid: string, payload: CreditCardEditRequest): Observable<CreditCard>;
    deleteCreditCard(uid: string): Observable<void>;
    refill(refillOptionId: number | null, payload: RefillRequest): Observable<RefillResponse>;
}
