import { Component } from '@angular/core';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';

@Component({
    selector: 'bbo-delete-credit-card-confirmation-modal',
    templateUrl: 'delete-credit-card-confirmation-modal.component.html',
    styleUrls: ['./delete-credit-card-confirmation-modal.component.scss']
})

export class DeleteCreditCardConfirmationModalComponent {
    xlationCodes = XlationCodes;
}