import {Inject, Injectable} from "@angular/core";
import {IUseCase} from "src/base/usecase";
import {ApplicationError, IResponse, Response,} from 'src/base/response';
import {catchError, map, Observable, throwError} from 'rxjs';
import {BBO_TAXES_REPOSITORY, ITaxesRepository} from "../infrastructure/taxes.interface";

export enum GetTaxesCaseError {
    GENERIC_ERROR
}

export interface TaxesParameters {
    countryCode: string,
    zipCode: string,
    state: string,
    city: string,
    address: string,
    amount: number
}

export interface Taxes {
    taxAmount: number
}

@Injectable()
export class GetTaxesUseCase implements IUseCase<TaxesParameters, Observable<IResponse<Taxes>>> {

    constructor(
        @Inject(BBO_TAXES_REPOSITORY) private repository: ITaxesRepository
    ) {}

    execute(parameters: TaxesParameters): Observable<IResponse<Taxes>> {
        return this.repository.getTaxes(parameters).pipe(
            catchError((error) => {
                return throwError(() => new Response(
                    false,
                    null,
                    new ApplicationError(
                        GetTaxesCaseError.GENERIC_ERROR,
                        'GetTaxesUseCase usecase unexpected error',
                        error
                    )
                ))
            }),
            map((response) => {
                const taxes: Taxes = {
                    taxAmount: response.taxAmount
                };
                return new Response(
                    true,
                    taxes
                );
            })
        );
    }
}
