import {Injectable} from "@angular/core";
import {ProductState} from "../reducers";
import {Store} from "@ngrx/store";
import {OneTimePurchaseFlow, oneTimePurchaseFlowActions} from "../actions";
import {oneTimePurchaseFlowSelectors} from "../selectors";
import {CreditCard, PaymentMethod} from "../../../payment";
import {Option} from "../../../@core";

@Injectable({
    providedIn: "root"
})
export class OneTimePurchaseFlowFacade {

    public readonly flowState$ = this.store.select(oneTimePurchaseFlowSelectors.data);
    public readonly selectedOfferPrice$ = this.store.select(oneTimePurchaseFlowSelectors.offerPriceSelector);
    public readonly selectedOffer$ = this.store.select(oneTimePurchaseFlowSelectors.selectedOffer);
    public readonly selectedOfferProductId$ = this.store.select(oneTimePurchaseFlowSelectors.productIdSelector);
    public readonly selectedPaymentMethod$ = this.store.select(oneTimePurchaseFlowSelectors.selectedPaymentMethod);
    public readonly paypalOrderId$ = this.store.select(oneTimePurchaseFlowSelectors.selectedPayPalOrderId);
    public readonly paypalOrderApproved$ = this.store.select(oneTimePurchaseFlowSelectors.paypalOrderApproved);
    public readonly selectedPayment$ = this.store.select(oneTimePurchaseFlowSelectors.selectedPayment);
    public readonly paymentMethodBillingAddress$ = this.store.select(oneTimePurchaseFlowSelectors.paymentMethodBillingAddress);
    public readonly paymentTaxes$ = this.store.select(oneTimePurchaseFlowSelectors.selectedPaymentTaxes);
    public readonly selectedCreditCard$ = this.store.select(oneTimePurchaseFlowSelectors.selectCreditCard);
    public readonly submitError$ = this.store.select(oneTimePurchaseFlowSelectors.error);
    public readonly isLoading$ = this.store.select(oneTimePurchaseFlowSelectors.loading);
    public readonly submitResult$ = this.store.select(oneTimePurchaseFlowSelectors.result);

    constructor(private readonly store: Store<ProductState>) {
    }

    selectPaymentMethod(selectedPaymentMethod: Option<PaymentMethod>) {
        this.store.dispatch(oneTimePurchaseFlowActions.update({payload: {patch: {selectedPaymentMethod}}}))
    }

    updateFlow(patch: Partial<OneTimePurchaseFlow>) {
        this.store.dispatch(oneTimePurchaseFlowActions.update({payload: {patch}}));
    }

    updateCreditCard(creditCard: CreditCard, initiatedByUser = false) {
        this.store.dispatch(oneTimePurchaseFlowActions.updateCreditCard({
            payload: {creditCard},
            initiatedByUser
        }));
    }

    resetFlow() {
        this.store.dispatch(oneTimePurchaseFlowActions.reset());
    }

    cancelPaypalOrder() {
        this.store.dispatch(oneTimePurchaseFlowActions.cancelPaypalOrder());
    }

    submit() {
        this.store.dispatch(oneTimePurchaseFlowActions.submit());
    }

}
