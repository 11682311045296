import { Injectable } from '@angular/core';

// we can access to bbo-store directly from v3 application by passing a "t" query parameter for the auth token
@Injectable({providedIn: 'root'})
export class BboPlusFromV3Service {

    isFromV3 = false;

    initialize() {
        const url = new URL(window.location.href);
        this.isFromV3 = url.searchParams.get('fromV3') !== null;
    }
}