const actions = new Set<string>();

const RADIX = 32;
const id = () =>
  [
    Math.random().toString(RADIX).substring(4, 10),
    Array.from(actions.size.toString(RADIX))
      .reverse()
      .slice(0, 2)
      .join('')
      .padEnd(2, '0'),
    Date.now().toString(RADIX).substring(2)
  ].join('');

export const actionType = (scope: string) => {
  const uniqueSpace = id();

  return (action: string) => {
    const type = `[${scope}] ${action}`;
    if (actions.has(type)) {
      console.error(`Action with type: "${type}" already created!`);
    } else {
      actions.add(type);
    }

    return `@opd/${uniqueSpace} ${type}`;
  };
};

export const RELOAD = 'Re-Load';
export const LOAD = 'Load';
export const LOAD_SUCCESS = 'Load Success';
export const LOAD_FAIL = 'Load Fail';
export const LOAD_MORE = 'Load More';
export const LOAD_MORE_SUCCESS = 'Load More Success';
export const FAIL = 'Fail';
export const SUCCESS = 'Success';
export const RESET = 'Reset';
export const UPDATE = 'Update';
export const UPDATE_SUCCESS = 'Update Success';
export const UPDATE_FAIL = 'Update Fail';
export const DELETE = 'Delete';
export const DELETE_SUCCESS = 'Delete Success';
export const DELETE_FAIL = 'Delete Fail';
export const SUBMIT = 'Submit';
export const SUBMIT_SUCCESS = 'Submit Success';
export const SUBMIT_FAILURE = 'Submit Failure';

