<div class="order-details-header">
    <span class="bbo-headline-4">{{xlationCodes.subscriptionDetails | translate}}</span>
</div>
<div class="order-details-content">
    <div class="selected-product">
        <bbo-auto-refill-amount-tile [autoRefillPlan]="selectedPlan"></bbo-auto-refill-amount-tile>
    </div>
    <div class="detail-container bbo-body-2 color-gray">
        <div class="detail-item">
            <div class="detail-label font-weight-semibold">{{xlationCodes.subtotal | translate}}</div>
            <div class="detail-value">{{selectedPlan?.amount | currency:'USD'}}</div>
        </div>
        <div class="detail-item">
            <div class="detail-label font-weight-semibold">{{xlationCodes.taxesIfApplicable | translate}}</div>
            <div class="detail-value">{{taxes | currency:'USD'}} <span
                *ngIf="taxes === undefined">{{xlationCodes.calculatedOnCheckout | translate}}</span></div>
        </div>
    </div>
    <div class="detail-item bbo-body-2">
        <div class="detail-label font-weight-semibold">{{xlationCodes.total | translate}}*</div>
        <div class="detail-value">{{total | currency: 'USD'}}</div>
    </div>
    <div>
        <div class="bbo-body-2 color-gray">
            {{xlationCodes.subscriptionDetailsDescription | translate: [(selectedPlan?.amount ?? "").toString(), (selectedPlan?.threshold ?? "").toString()]}}
        </div>
        <div class="bbo-body-2 color-gray additional-info">
            {{xlationCodes.subscriptionDetailsEstimationInformations | translate}}
        </div>
    </div>
</div>
