import {Inject, Injectable} from "@angular/core";
import {IUseCase} from "src/base/usecase";
import {ApplicationError, IResponse, Response,} from 'src/base/response';
import {catchError, map, Observable, throwError} from 'rxjs';
import {BBO_CYBERSOURCE_REPOSITORY, ICybersourceRepository} from "src/app/payment/infrastructure/cybersource.interface";

export enum GetTokenForSecureFormUseCaseError {
    GENERIC_ERROR
}

@Injectable()
export class GetTokenForSecureFormUseCase implements IUseCase<void, Observable<IResponse<string>>> {

    constructor(
        @Inject(BBO_CYBERSOURCE_REPOSITORY) private repository: ICybersourceRepository
    ) {}

    execute(): Observable<IResponse<string>> {
        return this.repository.getTokenForSecureForm().pipe(
            catchError((error) => {
                return throwError(() => new Response(
                    false,
                    null,
                    new ApplicationError(
                        GetTokenForSecureFormUseCaseError.GENERIC_ERROR,
                        'GetTokenForSecureForm usecase unexpected error',
                        error
                    )
                ))
            }),
            map((response) => {
                return new Response(true, response);
            })
        );
    }
}
