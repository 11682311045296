import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {map, Observable} from "rxjs";

import {environment} from "src/environments/environment";
import {IPaymentRepository} from "./payment.interface";
import {CreditCard} from "../models";
import {RefillRequest, RefillResponse} from "../domain/refill-use-case";

@Injectable()
export class PaymentRepository implements IPaymentRepository {

    constructor(
        private http: HttpClient
    ) {}

    getTokenForSecureForm(): Observable<string> {
        console.log("ici");
        return this.http.get<{token: string}>(
            environment.apiURL+"/cybersource/token",
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        ).pipe(
            map((response) => response.token)
        );
    }

    getCreditCard(uid: string): Observable<CreditCard> {
        return this.http.get<{
            cardExpirationMonth: string,
            cardExpirationYear: string
            cardType: string,
            last4DigitsCardNumber: string,
            firstName: string,
            lastName: string,
            owner: {id: number},
        }>(
            `${environment.apiURL}/users/${uid}/credit-card`,
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        ).pipe(
            map((response) => {
                const mappedObject: CreditCard = {
                    expirationMonth: response.cardExpirationMonth,
                    expirationYear: response.cardExpirationYear,
                    last4DigitsCardNumber: response.last4DigitsCardNumber,
                    firstName: response.firstName,
                    lastName: response.lastName,
                    owner: response.owner,
                    type: response.cardType,
                };
                return mappedObject;
            })
        );
    }

    createCreditCard(uid: string, payload: CreditCard): Observable<CreditCard> {
        const mappedObject = {
            // Maybe backend can extract it from the token
            cardExpirationMonth: payload.expirationMonth,
            cardExpirationYear: payload.expirationYear,
            // last4DigitsCardNumber: payload.last4DigitsCardNumber,
            type: payload.type,

            firstName: payload.firstName,
            lastName: payload.lastName,
            captureToken: payload.captureToken,
            transientToken: payload.transientToken,
        };
        return this.http.put<{
            cardExpirationMonth: string,
            cardExpirationYear: string,
            cardType: string,
            last4DigitsCardNumber: string,
            firstName: string,
            lastName: string,
            owner: {id: number},
        }>(
            `${environment.apiURL}/users/${uid}/credit-card`,
            mappedObject,
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        ).pipe(
            map((response) => {
                const mappedObject: CreditCard = {
                    expirationMonth: response.cardExpirationMonth,
                    expirationYear: response.cardExpirationYear,
                    last4DigitsCardNumber: response.last4DigitsCardNumber,
                    firstName: response.firstName,
                    lastName: response.lastName,
                    owner: response.owner,
                    type: response.cardType,
                };
                return mappedObject;
            })
        );
    }

    editCreditCard(uid: string, payload: CreditCard): Observable<CreditCard> {
        const mappedObject = {
            cardExpirationMonth: payload.expirationMonth,
            cardExpirationYear: payload.expirationYear,
            firstName: payload.firstName,
            lastName: payload.lastName,
            type: payload.type,
        };
        return this.http.patch<{
            cardExpirationMonth: string,
            cardExpirationYear: string,
            type: string,
            last4DigitsCardNumber: string,
            firstName: string,
            lastName: string,
            owner: {id: number},
        }>(
            `${environment.apiURL}/users/${uid}/credit-card`,
            mappedObject,
            {
                headers: new HttpHeaders({'Content-Type':'application/merge-patch+json', "Accept": "application/json, text/plain"})
            }
        ).pipe(
            map((response) => {
                const mappedObject: CreditCard = {
                    expirationMonth: response.cardExpirationMonth,
                    expirationYear: response.cardExpirationYear,
                    last4DigitsCardNumber: response.last4DigitsCardNumber,
                    firstName: response.firstName,
                    lastName: response.lastName,
                    owner: response.owner,
                    type: response.type,
                };
                return mappedObject;
            })
        );
    }

    deleteCreditCard(uid: string): Observable<void> {
        return this.http.delete<void>(
            `${environment.apiURL}/users/${uid}/credit-card`,
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            });
    }

    refill(refillOptionId: number, data: RefillRequest): Observable<RefillResponse> {
        return this.http.post<RefillResponse>(
            `${environment.apiURL}/refill/${refillOptionId}/choose`,
            data,
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        );
    }
}
