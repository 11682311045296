import {Component, EventEmitter, Output} from '@angular/core';
import { OneTimePurchaseFlowFacade } from '../../store';
import { XlationCodes } from '../../../shared/translations/xlation.codes';

@Component({
    selector: 'bbo-purchase-failure',
    templateUrl: './purchase-failure.component.html',
    styleUrls: ['./purchase-failure.component.scss']
})
export class PurchaseFailureComponent {
    xlationCodes = XlationCodes;
    @Output() tryAgain = new EventEmitter<void>();
    readonly isLoading$ = this.storeFacade.isLoading$;

    constructor(private readonly storeFacade: OneTimePurchaseFlowFacade) {}
}
