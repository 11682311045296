import {Component, Input} from '@angular/core';

@Component({
    selector: 'bbo-side-navigation-menu-item',
    templateUrl: 'side-navigation-menu-item.component.html',
    styleUrls: ['./side-navigation-menu-item.component.scss']
})

export class SideNavigationMenuItemComponent {
    @Input() itemIcon = '';
    @Input() url = '';
    @Input() itemLabel = '';
}
