import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './ui/login/login.component';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button'
import {SharedModule} from 'src/app/shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BBO_AUTHENTICATE_REPOSITORY} from 'src/app/authenticate/infrastructure/authenticate.interface';
import {AuthenticateRepository} from 'src/app/authenticate/infrastructure/authenticate.repository';
import {MatDialogModule} from '@angular/material/dialog';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthenticateInterceptor} from 'src/app/authenticate/service/authenticate.interceptor';
import {LoginUseCase} from 'src/app/authenticate/domain/login.usecase';
import {LogoutUseCase} from 'src/app/authenticate/domain/logout.usecase';
import {AccessTokenService} from 'src/app/authenticate/service/access-token.service';
import {AuthenticateService} from 'src/app/authenticate/service/authenticate.service';
import {StoreTokenInterceptor} from 'src/app/authenticate/service/store-token.interceptor';
import {TranslateModule} from '../shared/translations/translate.module';


@NgModule({
    declarations: [
        LoginComponent
    ],
    exports: [
        LoginComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatDialogModule,
        TranslateModule
    ],
})
export class AuthenticateModule {

    static forRoot(): ModuleWithProviders<AuthenticateModule> {
        return {
            ngModule: AuthenticateModule,
            providers: [
                { provide: BBO_AUTHENTICATE_REPOSITORY, useClass: AuthenticateRepository },
                { provide: HTTP_INTERCEPTORS, multi: true, useClass: StoreTokenInterceptor },
                { provide: HTTP_INTERCEPTORS, multi: true, useClass: AuthenticateInterceptor },
                LoginUseCase,
                LogoutUseCase,
                AccessTokenService,
                AuthenticateService
            ]
        };
    }

}
