import {Component, OnDestroy} from '@angular/core';
import {AutoRefillSubscriptionFlowFacade} from "../../store";
import {Payment} from "../../../payment";
import { isNil } from 'lodash';
import { filter, Subject, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'bbo-auto-refill-subscription-overview',
    templateUrl: './auto-refill-subscription-overview.component.html',
    styleUrls: ['./auto-refill-subscription-overview.component.scss']
})
export class AutoRefillSubscriptionOverviewComponent implements OnDestroy {

    private unsubscribeSubject: Subject<void> = new Subject<void>();

    constructor(private readonly store: AutoRefillSubscriptionFlowFacade) {
    }

    selectPayment(selectedPayment: Payment) {
        this.store.updateFlow({selectedPayment});
        this.sendTrackingEventForOnPaymentMethodSelect(selectedPayment);
    }

    private sendTrackingEventForOnPaymentMethodSelect(payment: Payment): void {
        if (isNil(payment?.method)) {
            return;
        }

        const paymentMethodToTrackingValue = (payment.method === "paypal") ? "Paypal" : "Credit card";
        this.store.selectedPlanPrice$.pipe(
            // selectedPlanPrice can emit undefined to reset its state
            // that doesn't suit us for the tracking
            filter((price) => price !== undefined && price !== null),
            tap((price) => {
                const dataLayer =(window as any).dataLayer || [];
                dataLayer.push({
                    event: 'Shop Payment selected',
                    product_category: 'Subscription',
                    payment_mode: paymentMethodToTrackingValue,
                    product_price: price
                });
            }),
            takeUntil(this.unsubscribeSubject)
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }
}
