import {Component} from '@angular/core';
import {AutoRefillSubscriptionFlowFacade} from "../../store";
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';

@Component({
    selector: 'bbo-auto-refill-subscription-purchase-complete',
    templateUrl: './auto-refill-subscription-purchase-complete.component.html',
    styleUrls: ['./auto-refill-subscription-purchase-complete.component.scss']
})
export class AutoRefillSubscriptionPurchaseCompleteComponent {
    xlationCodes = XlationCodes;
    readonly amount$ = this.store.selectedPlanPrice$;
    readonly treshold$ = this.store.selectedPlanTreshold$;

    constructor(private readonly store: AutoRefillSubscriptionFlowFacade) {
    }

    // eslint-disable-next-line class-methods-use-this
    goBackToBBO(): void {
        window.open("https://www.bridgebase.com/v3/", "_self");
    }
}
