import {createFeatureSelector, createSelector} from "@ngrx/store";
import {PRODUCT_STORE_NAME, ProductState} from "../reducers";
import {genericObjectSelectors} from "../../../@core";

const selectFeature = createFeatureSelector<ProductState>(PRODUCT_STORE_NAME);
const oneTimePurchaseFlowStateSelectors = genericObjectSelectors(
    createSelector(
        selectFeature,
        s1 => s1.oneTimePurchaseFlow
    ));
const selectSelectedPayment = createSelector(oneTimePurchaseFlowStateSelectors.data, s1 => s1?.selectedPayment);
const selectSelectedOffer = createSelector(oneTimePurchaseFlowStateSelectors.data, s1 => s1?.selectedOffer);
const selectOfferPriceSelector = createSelector(oneTimePurchaseFlowStateSelectors.data, s1 => s1?.selectedOffer?.price);
const selectOfferAmountSelector = createSelector(oneTimePurchaseFlowStateSelectors.data, s1 => s1?.selectedOffer?.bboCurrencyAmount);
const selectProductIdSelector = createSelector(oneTimePurchaseFlowStateSelectors.data, s1 => s1?.selectedOffer?.productId);
const selectCreditCard = createSelector(oneTimePurchaseFlowStateSelectors.data, s1 => s1?.selectedPayment?.creditCard);
const selectSelectedPayPalOrderId = createSelector(oneTimePurchaseFlowStateSelectors.data, s1 => s1?.selectedPayment?.paypal?.paypalOrderId);
const selectPaypalOrderApproved = createSelector(oneTimePurchaseFlowStateSelectors.data, s1 => s1?.selectedPayment?.paypal?.approved);
const selectSelectedPaymentMethodBillingAddress = createSelector(oneTimePurchaseFlowStateSelectors.data, s1 => s1?.billingAddress);
const selectedPaymentTaxes = createSelector(oneTimePurchaseFlowStateSelectors.data, s1 => s1?.selectedPayment?.taxes);
const selectedPaymentMethod = createSelector(oneTimePurchaseFlowStateSelectors.data, s1 => s1?.selectedPaymentMethod);

export const oneTimePurchaseFlowSelectors = {
    ...oneTimePurchaseFlowStateSelectors,
    selectedOffer: selectSelectedOffer,
    offerPriceSelector: selectOfferPriceSelector,
    offerAmountSelector: selectOfferAmountSelector,
    productIdSelector: selectProductIdSelector,
    selectedPayment: selectSelectedPayment,
    selectedPayPalOrderId: selectSelectedPayPalOrderId,
    paymentMethodBillingAddress: selectSelectedPaymentMethodBillingAddress,
    selectCreditCard: selectCreditCard,
    selectedPaymentTaxes: selectedPaymentTaxes,
    paypalOrderApproved: selectPaypalOrderApproved,
    selectedPaymentMethod
}
