import { Component, ElementRef, Input, ViewChild, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ProductAutoRefillPlan, ProductAutoRefillPlanOffer } from "../../domain";
import { SwipperDisplayService } from "../../../shared/services/swipper-display.service";
import Swiper from 'swiper';
import { AutoRefillSubscriptionFlowFacade } from "../../store";
import { Subject } from "rxjs";
import { takeUntil, tap } from 'rxjs/operators';
import { Option } from "../../../@core";
import { GeneralStoreFacade } from 'src/app/bbo-store/store';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';
import { MatDialog } from '@angular/material/dialog';
import { DeleteCurrentPlanConfirmationModalComponent } from 'src/app/auto-refill-subsription/delete-current-plan-confirmation-modal/delete-current-plan-confirmation-modal.component';

const mapCurrentAutoRefillPlanToOffer = (currentPlan: ProductAutoRefillPlan): ProductAutoRefillPlanOffer => {
    return {
        id: 0,
        amount: currentPlan.amount,
        threshold: currentPlan.threshold,
        outdated: currentPlan.outdated,
        recommended: false,
        selected: true
    }
}

@Component({
    selector: 'bbo-auto-refill-offer-list',
    templateUrl: './auto-refill-offer-list.component.html',
    styleUrls: ['./auto-refill-offer-list.component.scss']
})
export class AutoRefillOfferListComponent implements OnDestroy, OnInit {

    private readonly unsubscribeSubject = new Subject<void>();
    xlationCodes = XlationCodes;

    @Input()
    set offers(value: ProductAutoRefillPlanOffer[]) {
        this._offers = value;

        this.slideIndex = value.findIndex((offer) => offer.amount === 50);

        // after viewInit
        const swiper = (this.swiper?.nativeElement as { swiper?: Swiper })?.swiper;
        if (swiper) {
            swiper.slideTo(this.slideIndex);
        }
    }

    _offers: ProductAutoRefillPlanOffer[] = [];

    @ViewChild("swiper") swiper: ElementRef<Swiper> | undefined;

    slideIndex = 0;
    readonly shouldDisplaySwipper$ = this.swipperDisplayService.shouldDisplayTheSwipper$.pipe(
        tap((shouldDisplaySwipper: boolean) => {
            if (shouldDisplaySwipper) {
                setTimeout(() => {
                    this.swipeToIndex();
                })
            }
        })
    );

    constructor(readonly swipperDisplayService: SwipperDisplayService,
        private readonly generalStore: GeneralStoreFacade,
        private readonly matDialog: MatDialog,
        private readonly cdr: ChangeDetectorRef,
        private readonly store: AutoRefillSubscriptionFlowFacade) {
    }

    ngOnInit(): void {
        this.generalStore.loadCurrentAutoRefillPlan();
        this.generalStore.currentAutoRefillPlan$.pipe(
            takeUntil(this.unsubscribeSubject)
        ).subscribe((currentAutoRefillPlan) => this.onCurrentAutoRefillPlanChanges(currentAutoRefillPlan));
    }

    private swipeToIndex(): void {
        const swiper = (this.swiper?.nativeElement as { swiper?: Swiper })?.swiper;
        if (swiper) {
            swiper.slideTo(this.slideIndex);
        }
    }

    private onCurrentAutoRefillPlanChanges(currentAutoRefillPlan: Option<ProductAutoRefillPlan>): void {
        // special case if we unsubscribe to our current autorefill plan we have to remove the current plan card from the list
        if (!currentAutoRefillPlan && this._offers) {
            this.offers = this._offers
                .map((offer) => {
                    offer.selected = false
                    return offer;
                })
                .filter((offer) => !offer.outdated);
            this.updateSwipper();
        } else {
            const correspondingOffer = this._offers?.find((offer) => offer.amount === currentAutoRefillPlan?.amount);
            if (!correspondingOffer && currentAutoRefillPlan?.outdated) {
                this.offers = [mapCurrentAutoRefillPlanToOffer(currentAutoRefillPlan), ...this._offers];
                this.updateSwipper();
            } else if (correspondingOffer) {
                correspondingOffer.selected = true;
            }
        }
    }

    private updateSwipper(): void {
        const swiper = (this.swiper?.nativeElement as { swiper?: Swiper })?.swiper;
        setTimeout(() => {
            swiper?.update();
        });
    }

    selectPlan(selectedAutoRefillPlanOffer: ProductAutoRefillPlanOffer) {
        const selectedOffer = Object.assign({}, selectedAutoRefillPlanOffer);
        this.store.updateFlow({selectedOffer: selectedOffer})
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }

    cancelCurrentPlan(): void {
        const dialog = this.matDialog.open<DeleteCurrentPlanConfirmationModalComponent>(
            DeleteCurrentPlanConfirmationModalComponent,
            {
                backdropClass: 'bbo-backdrop-blurred',
                // visually-hidden -> hide the element until the setup is finished
                // to avoid flash of content
                panelClass: ['bbo-dialog'],
                ariaModal: true,
                autoFocus: false
            }
        );

        dialog.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.generalStore.deleteCurrentAutoRefillPlan();
            }
        });
    }
}
