<mat-dialog-content class="bbo-dialog-content">

    <ng-container *ngIf="cybersourceFormInstanciateError">
        <p class="color-warn">{{xlationCodes.creditCardFormCreationCriticalError | translate}}</p>
        <button mat-flat-button class="bbo-button" type="button" color="primary" (click)="onCancelClick()">{{xlationCodes.close| translate}}</button>
    </ng-container>

    <form *ngIf="!cybersourceFormInstanciateError" [formGroup]="form" (ngSubmit)="onSubmitForm()">
        <div class="bbo-headline-3 bbo-dialog-header">{{formTitleKey | translate}}</div>

        <bbo-credit-card-form [editMode]="data && !!(data.creditCard)" [creditCardForm]="form.controls.creditCard" (failure)="cybersourceFormInstanciateError = true" (secureFormCreated)="onSecureFormCreated($event)"></bbo-credit-card-form>
         <mat-checkbox
             *ngIf="data && data.showSaveCard"
             [checked]="saveCardDetails"
             (change)="saveCardDetails=$event.checked"
         >{{xlationCodes.saveCardForLaterPurchases | translate}}
        </mat-checkbox>

        <mat-divider></mat-divider>

        <div class="bbo-headline-4 bbo-dialog-header">{{xlationCodes.billingAddress | translate}}</div>

        <bbo-billing-address-form [billingAddressForm]="form.controls.billingAddress"></bbo-billing-address-form>
        <button *ngIf="!!(data?.creditCard)" mat-button class="bbo-button delete-credit-card" type="button" color="warn" (click)="deleteSavedCard()">{{xlationCodes.deleteThisCreditCard | translate}}</button>
        <mat-divider></mat-divider>

        <!-- error summary and critical error -->
        <div class="color-warn">
            <p *ngFor="let errorMessage of globalErrorMessageList">{{errorMessage}}</p>
            <!-- Show the 2 below erros after the users submitted at least one time the form -->
            <p *ngIf="form.controls.creditCard.invalid && form.controls.creditCard.touched && atLeastOneNgSubmit">{{xlationCodes.creditCardFormError | translate}}</p>
            <p *ngIf="form.controls.billingAddress.invalid && form.controls.billingAddress.touched && atLeastOneNgSubmit">{{xlationCodes.billingAddressFormError | translate}}</p>
        </div>

        <div class="actions">
            <button mat-button class="bbo-button" type="button" color="warn" (click)="onCancelClick()">{{xlationCodes.cancel | translate}}</button>
            <button mat-flat-button class="bbo-button" type="submit" color="primary" [bboLoading]="(loading$ | async) && (savedCreditCardLoading$ | async)">{{formSubmitButtonKey | translate}}</button>
        </div>
    </form>
</mat-dialog-content>
