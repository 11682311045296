import {
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import {CreditCard, Payment, PaymentMethod} from 'src/app/payment/models';
import {Option} from "../../../@core";
import { XlationCodes } from '../../translations/xlation.codes';

@Component({
    selector: 'bbo-payment-item',
    templateUrl: './payment-item.component.html',
    styleUrls: ['./payment-item.component.scss']
})
export class PaymentItemComponent implements OnChanges {
    xlationCodes = XlationCodes;
    @HostListener('click', ['$event'])
    onClick() {
        if (this.paymentItemClicked) {
            this.paymentItemClicked.emit({method: this.type as any, creditCard: this.creditCard});
        }
    }
    @HostBinding('class.selected') @Input() selected = false;

    @HostBinding('class.dashed') dashed = false;
    @HostBinding('class.selectable') selectable = false;
    @Output() paymentItemClicked: EventEmitter<Payment> = new EventEmitter();
    @Output() addPaymentItemClicked:EventEmitter<void> = new EventEmitter<void>();
    @Input() creditCard: Option<CreditCard>;
    // when possible move the add-credit-card out of that component
    @Input() type: Option<PaymentMethod>;
    @Input() loading: Option<boolean>;

    public ngOnChanges(changes: SimpleChanges): void {
        const type = changes['type']?.currentValue;
        this.dashed = type === 'other-card';
        this.selectable = (['paypal', 'credit-card']).indexOf(type) > -1;
    }

    openAddCardModal() {
        this.addPaymentItemClicked.emit();
    }
}
