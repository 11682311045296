import {Inject, Injectable} from "@angular/core";
import {BBO_PRODUCT_REPOSITORY, IProductRepository} from "src/app/product/infrastructure/product.interface";
import {IUseCase} from "src/base/usecase";
import {UserService} from '../../shared/services/user.service';
import {ApplicationError, IResponse, Response} from 'src/base/response';
import {catchError, map, Observable, throwError} from "rxjs";
import {HttpErrorResponse, HttpStatusCode} from "@angular/common/http";
import { GeneralStoreFacade } from "src/app/bbo-store/store";

export enum GetCurrentAutoRefillPlanUseCaseError {
    LOCAL_UID_INVALID,
    GENERIC_ERROR,
    NOT_FOUND
}

export interface ProductAutoRefillPlan{
    amount: number;
    bboCurrency: number;
    threshold: number;
    outdated: boolean;
}

@Injectable()
export class GetCurrentAutoRefillPlanUseCase implements IUseCase<void, Observable<IResponse<ProductAutoRefillPlan>>> {

    constructor(
        @Inject(BBO_PRODUCT_REPOSITORY) private repository: IProductRepository,
        private readonly generalStore: GeneralStoreFacade,
        private userService: UserService,
    ) {}

    execute(): Observable<IResponse<ProductAutoRefillPlan>> {
        const localUid = this.userService.getUId();

        if (!localUid) {
            return throwError(() => new Response(
                false,
                null,
                new ApplicationError(GetCurrentAutoRefillPlanUseCaseError.LOCAL_UID_INVALID, "Trying to get current auto refill plan for local user but uid is falsy")
            ));
        }

        return this.repository.getCurrentAutoRefillPlan(localUid ?? '').pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.NotFound) {
                    const response = new Response(
                        false,
                        null,
                        new ApplicationError(
                            GetCurrentAutoRefillPlanUseCaseError.NOT_FOUND,
                            'getCurrentAutoRefillPlan not found for uid ' + localUid,
                        )
                    );
                    return throwError(() => response);
                }
                return throwError(() => error);
            }),
            map((response) => {
                const data: ProductAutoRefillPlan = {
                    amount: response.amountRefill,       // front supports more data than the back
                    bboCurrency: response.amountRefill, // at the moment
                    threshold: response.threshold,
                    outdated: response.outdated
                };
                this.generalStore.updateGeneralStore({currentAutoRefillPlan: data});
                return new Response(
                    true,
                    data
                );
            }),
            catchError((error) => {
                // already handled: skip
                if (error instanceof Response) {
                    return throwError(() => error);
                }
                return throwError(() => new Response(
                        false,
                        null,
                        new ApplicationError(
                            GetCurrentAutoRefillPlanUseCaseError.GENERIC_ERROR,
                            'getCurrentAutoRefillPlan usecase unexpected error',
                            error
                        )
                ));
            })
        );
    }
}
