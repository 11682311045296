<div class="title bbo-headline-3 headline-2-md-up text-center" [innerHTML]="xlationCodes.joinBboPlusCommunity | translate"></div>
<div class="bbo-plus-cards-container bbo-large-line-height">
    <mat-card>
        <mat-card-content class="bbo-plus-card bbo-medium-card">
            <div class="card-content">
                <div class="card-header">
                    <div class="color-accent-darker font-weight-bold">{{xlationCodes.bboPlus | translate}}</div>
                    <ng-container *ngIf="bboPlusTrial?.remainingDays; else NoFreeTrial">
                        <div class="price-container trial">
                            <div><span class="bbo-headline-4 color-purple">{{xlationCodes.freeTrial | translate}}</span> <span class="color-gray">{{xlationCodes.forXDays | translate:[bboPlusTrial?.remainingDays?.toString()!]}}</span></div>
                            <div class="color-gray">{{xlationCodes.thenXPerMonth | translate:[xlationCodes.BB$ | translate:[(price | number) ?? ""]]}}</div>
                        </div>
                    </ng-container>
                    <ng-template #NoFreeTrial>
                        <div class="price-container">
                            <span class="bbo-headline-4 font-weight-light">{{price | number}} BB$</span>&#x20;
                            <span class="color-gray">{{xlationCodes.perMonth | translate}}</span>
                        </div>
                    </ng-template>
                </div>
                <div class="card-description">
                    <div class="width-100 divider-after"></div>
                    <div class="description text-center">
                        {{xlationCodes.bboPlusOfferCardDescription | translate}}
                    </div>
                </div>
                <div class="card-advantages-container">
                    <div class="bbo-body-2 text-center color-purple font-weight-semibold bbo-large-line-height" *ngFor="let advantage of advantagesList">
                        {{advantage.titleTranslationKey | translate}}
                    </div>
                </div>
            </div>
            <button mat-flat-button class="bbo-button width-100" color="purple" routerLink="/bbo-plus/subscribe">
                {{(bboPlusTrial?.remainingDays ? xlationCodes.tryBboPlus : xlationCodes.joinBboPlus) | translate}}
            </button>
        </mat-card-content>
    </mat-card>
    <div class="advantages-container mobile-hidden">
        <div class="font-weight-semibold bbo-large-line-height">{{xlationCodes.bboPlusBenefits | translate}}</div>
        <div class="advantage" *ngFor="let advantage of advantagesList">
            <div class="bbo-headline-6 color-purple bbo-large-line-height font-weight-light">{{advantage.titleTranslationKey | translate}}</div>
            <div class="bbo-body-2 bbo-large-line-height">{{advantage.descriptionTranslationKey | translate}}</div>
        </div>
    </div>
</div>