import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {map, Observable, tap} from "rxjs";
import {CurrentAutoRefillPlan, IProductRepository} from "src/app/product/infrastructure/product.interface";
import {environment} from "src/environments/environment";
import {OfferTypes, ProductAutoRefillPlanOffer} from "../domain";

@Injectable()
export class ProductRepository implements IProductRepository {

    constructor(
        private http: HttpClient
    ) {}

    getCurrentAutoRefillPlan(uid: string): Observable<CurrentAutoRefillPlan> {
        return this.http.get<CurrentAutoRefillPlan>(
            `${environment.apiURL}/users/${uid}/auto-refill-plan/current`,
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        ).pipe(
            tap((response) => {
                // we don't want to manipulate cents in the front app ...
                response.amountRefill = response.amountRefill / 100;
                response.threshold = response.threshold / 100;
            })
        );
    }

    deleteCurrentAutoRefillPlan(uid:string): Observable<void> {
        return this.http.delete<void>(
            `${environment.apiURL}/users/${uid}/auto-refill-plan/current`,
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        );
    }

    getOneTimePurchaseProductList(): Observable<{ productId: number; price: number; quantity: number, type: OfferTypes, recommended: boolean}[]> {
        return this.http.get<{id: number, amount: number, quantity: number, type: OfferTypes, recommended: boolean}[]>(
            `${environment.apiURL}/refill/options`,
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        ).pipe(
            map((res) => res.map(({id, amount, quantity, type, recommended}) => ({productId: id, price: amount, quantity, type, recommended})))
        )
    }

    getAutoRefillOptions(): Observable<ProductAutoRefillPlanOffer[]> {
        return this.http.get<ProductAutoRefillPlanOffer[]>(
           `${environment.apiURL}/auto-refill/options`,
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        );
    }

    subscribeToAutoRefillPlan(uid: string, autoRefillPlanId: number): Observable<CurrentAutoRefillPlan> {
        const body = { autoRefillPlanId };
        return this.http.post<CurrentAutoRefillPlan>(
            `${environment.apiURL}/users/${uid}/auto-refill-plan/subscribe`,
            body,
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        ).pipe(
            tap((response) => {
                // we don't want to manipulate cents in the front app ...
                response.amountRefill = response.amountRefill / 100;
                response.threshold = response.threshold / 100;
            })
        );
    }
}
