import {createGenericObjectActions, EntityAction} from "src/app/@core";
import {CreditCard, Payment} from "src/app/payment";
import {ProductAutoRefillPlan, ProductAutoRefillPlanOffer} from "../../domain";
import {createAction, props} from "@ngrx/store";

export interface AutoRefillSubscriptionFlow {
    readonly selectedOffer?: ProductAutoRefillPlanOffer;
    readonly selectedPayment?: Payment;
}

const updateCreditCard = createAction('[Auto Refill Purchase Flow] Update Credit Card', props<{
    payload: { creditCard: CreditCard }
}>());

export const autoRefillSubscriptionFlowActions = {
    ...(createGenericObjectActions<AutoRefillSubscriptionFlow, ProductAutoRefillPlan, Partial<AutoRefillSubscriptionFlow>>('Auto Refill Subscription Flow')),
    updateCreditCard
}
export type AutoRefillSubscriptionFlowActions = EntityAction<AutoRefillSubscriptionFlow, ProductAutoRefillPlan, Partial<AutoRefillSubscriptionFlow>>;
