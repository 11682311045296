import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BboStoreModule } from './bbo-store/bbo-store.module';
import { AuthenticateModule } from './authenticate/authenticate.module';
import { SharedModule } from './shared/shared.module';
import { ProductModule } from "./product/product.module";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { LanguageService } from './shared/translations/language.service';
import { Observable } from 'rxjs';
import { AutoLoginService } from './authenticate/service/auto-login.service';
import { BboPlusFromV3Service } from './bbo-plus-home/services/bbo-plus-from-v3.service';
import { AnalyticsInitializerService } from './@core/analytics/analytics-initializer.service';

function initializeAppFactory(languageService: LanguageService): () => Observable<any> {
    return () => languageService.init();
}

export function initializeAnalyticsFactory(analyticsInitializerService: AnalyticsInitializerService): () => Promise<void> {
    analyticsInitializerService.initialize();
    return () => Promise.resolve();
}

export function initializeAutoLoginFactory(autoLoginService: AutoLoginService): () => Promise<void> {
    autoLoginService.initialize();
    return () => Promise.resolve();
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BboStoreModule,
        AuthenticateModule.forRoot(),
        SharedModule,
        BrowserAnimationsModule,
        ProductModule,
        StoreModule.forRoot({}, {
            runtimeChecks: {
                strictActionImmutability: true,
                strictStateImmutability: true
            }
        }),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({name:'bbo-store'}),
    ],
    providers: [
        // By default material angular form field only show one line of the errors
        // we cant to show more text characters so we use the subscriptSizing parameter
        // and in the style we are going to work around that
        // https://github.com/angular/components/issues/4580
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                subscriptSizing: 'dynamic'
            }
        }, {
            provide: APP_INITIALIZER,
            useFactory: initializeAnalyticsFactory,
            deps: [AnalyticsInitializerService],
            multi: true
        }, {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [LanguageService],
            multi: true
        }, {
            provide: APP_INITIALIZER,
            useFactory: initializeAutoLoginFactory,
            deps: [AutoLoginService],
            multi: true
        }, {
            provide: APP_INITIALIZER,
            useFactory: initializeAutoLoginFactory,
            deps: [BboPlusFromV3Service],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
