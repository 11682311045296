import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {GetBalanceUseCase} from "../../../balance/domain/get-balance.usecase";
import {genericObjectLoadEffect} from "../../../@core/ngrx/generic-object/generic-object-load-effect";
import {currentAccountBalanceActions} from "../actions";
import {map} from "rxjs";
import {UserService} from "../../../shared/services/user.service";

@Injectable()
export class CurrentAccountBalanceEffects {

    constructor(private readonly actions$: Actions,
                private readonly currentAccountBalanceUseCase: GetBalanceUseCase,
                private readonly userService: UserService) {
    }


    loadCurrentAutoRefillPlan$ = genericObjectLoadEffect(
        this.actions$,
        currentAccountBalanceActions,
        () => {
            return this.currentAccountBalanceUseCase.execute({uid: this.userService.getUId() || ''}).pipe(
                map(value => ({amount: value.data.amount}))
            )
        }
    )
}
