import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {map, switchMap} from "rxjs";
import {genericObjectLoadEffect} from "../../../@core/ngrx/generic-object/generic-object-load-effect";
import {catchMap} from "../../../@core";
import {Action} from "@ngrx/store";
import { DeleteCurrentAutoRefillPlanUseCase } from '../../../product/domain/delete-current-auto-refill-plan.usecase';
import { GetCurrentAutoRefillPlanUseCase } from '../../../product/domain/get-current-auto-refill-plan.usecase';
import { SubscribeToAutoRefillPlanUseCase } from "src/app/product/domain";
import { currentAutoRefillPlanActions } from "../actions/current-auto-refill-plan.actions";
import { savedCreditCardActions } from "../actions";

@Injectable()
export class CurrentAutoRefillPlanEffects {

    constructor(private readonly actions$: Actions,
                private readonly subscribeToAutoRefillPlanUseCase: SubscribeToAutoRefillPlanUseCase,
                private readonly getCurrentAutoRefillPlanUseCase: GetCurrentAutoRefillPlanUseCase,
                private readonly deleteCurrentAutoRefillPlanUseCase: DeleteCurrentAutoRefillPlanUseCase) {
    }

    replaceAutoRefillPlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(currentAutoRefillPlanActions.replace),
            switchMap(props =>
                this.subscribeToAutoRefillPlanUseCase.execute(props.payload).pipe(map(value =>
                    ({payload: value.data})))
            ),
            map(currentAutoRefillPlanActions.replaceSuccess),
            catchMap<Action, Error>(error => currentAutoRefillPlanActions.replaceFail({error}))
        ));

    loadCurrentAutoRefillPlan$ = genericObjectLoadEffect(
        this.actions$,
        currentAutoRefillPlanActions,
        () => {
            return this.getCurrentAutoRefillPlanUseCase.execute().pipe(
                map(value => value.data)
            )
        }
    )

    unsubscribeToAutoRefillPlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(currentAutoRefillPlanActions.remove),
            switchMap(() => this.deleteCurrentAutoRefillPlanUseCase.execute()),
            map(currentAutoRefillPlanActions.removeSuccess),
            catchMap<Action, Error>(error => currentAutoRefillPlanActions.removeFail({error}))
        ));

    reloadOnAutoRefillDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(currentAutoRefillPlanActions.removeSuccess),
            map(() => currentAutoRefillPlanActions.reload({}))
        ));

    reloadOnCreditCardDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(savedCreditCardActions.removeSuccess),
            map(() => currentAutoRefillPlanActions.reload({}))
        ));
}
