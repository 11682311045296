import {Component, Input} from '@angular/core';
import {OneTimePurchaseFlowFacade} from "../../store";
import {Option} from "../../../@core";
import { Observable } from 'rxjs';
import { GeneralStoreFacade } from 'src/app/bbo-store/store';

@Component({
    selector: 'bbo-one-time-purchase-order-details',
    templateUrl: './one-time-purchase-order-details.component.html',
    styleUrls: ['./one-time-purchase-order-details.component.scss']
})
export class OneTimePurchaseOrderDetailsComponent {
    public readonly offerPrice$ = this.storeFacade.selectedOfferPrice$;
    public readonly paymentTax$ = this.storeFacade.paymentTaxes$;
    public readonly balance$: Observable<Option<number>> = this.generalStore.currentBalance$;

    constructor(private readonly generalStore: GeneralStoreFacade,
                private readonly storeFacade: OneTimePurchaseFlowFacade) {
    }
}
