import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Option } from 'src/app/@core';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';

@Component({
  selector: 'bbo-plus-subscription-failed',
  templateUrl: './bbo-plus-subscription-failed.component.html',
  styleUrls: ['./bbo-plus-subscription-failed.component.scss']
})
export class BboPlusSubscriptionFailedComponent {
    @Input() error: Option<Error>;
    @Output() closeErrorCard: EventEmitter<void> = new EventEmitter();
    xlationCodes = XlationCodes;
}
