import {Injectable} from "@angular/core";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";
import { autoRefillSubscriptionFlowActions, AutoRefillSubscriptionFlowFacade, oneTimePurchaseFlowActions, OneTimePurchaseFlowFacade } from "src/app/product/store";
import { filter, tap } from "rxjs";

@Injectable()
export class TrackingEffects {

    constructor(
        private readonly actions$: Actions,
        private oneTimePurchaseStore: OneTimePurchaseFlowFacade,
        private subscriptionStore: AutoRefillSubscriptionFlowFacade,
    ) {}

    // eslint-disable-next-line class-methods-use-this
    private dataLayer(): any {
        return (window as any).dataLayer || [];
    }

    trackSelectedOneTimePurchaseOffer$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(oneTimePurchaseFlowActions.update),
                tap(({payload: {patch}}) => {
                    if (patch.selectedOffer) {
                        this.dataLayer().push({
                            event: 'Shop Product selected',
                            product_category: 'BB$',
                            product_price: patch.selectedOffer.bboCurrencyAmount
                        });
                    }
                })
            );
        },
        {dispatch: false}
    );

    trackSelectedSubscriptionOffer$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(autoRefillSubscriptionFlowActions.update),
                tap(({payload: {patch}}) => {
                    if (patch.selectedOffer) {
                        this.dataLayer().push({
                            event: 'Shop Product selected',
                            product_category: 'Subscription',
                            product_price: patch.selectedOffer.amount
                        });
                    }
                })
            );
        },
        {dispatch: false}
    );

    trackOneTimePurchasePaymentAdded = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(oneTimePurchaseFlowActions.updateCreditCard),
                concatLatestFrom(() => [
                    this.oneTimePurchaseStore.selectedOfferPrice$,
                ]),
                tap(([{initiatedByUser}, price]) => {
                    if (initiatedByUser) {
                        this.dataLayer().push({
                            event: 'Shop Payment added',
                            product_category: 'BB$',
                            product_price: price,
                            payment_mode: "Credit card"
                        });
                    }
                }),
            );
        },
        {dispatch: false}
    );

    trackSubscriptionPaymentAdded = createEffect(
        () => {
            return this.actions$.pipe(
                // Note about accuracy:
                // Compared to One time purchase flow, the auto refill subscription has
                // much less actions so it's harder to track the state
                // With autoRefillSubscriptionFlow we have the same problem with oneTimePurchaseFlow,
                // updateCreditCard action is used to update the user interface.
                // The quick fix for oneTimePurchaseFlowActions was to add an attribute to the updateCreditCard action
                // so as mark actions dispatched from a real user activity.
                // But for autoRefillSubscriptionFlow it's harder to use the same fix
                // so we just send a tracking event for each updateCreditCard at the moment.
                ofType(autoRefillSubscriptionFlowActions.updateCreditCard),
                concatLatestFrom(() => [
                    this.subscriptionStore.selectedPlanPrice$,
                ]),
                // autoRefillSubscriptionFlowActions.updateCreditCard can be dispatched (for data syncronisation purposes)
                // when user is inside the one time purchase funnel.
                // That doesn't suit us for the tracking so we skip it. One way to know if the user is really
                // inside the auto refill funnel is to check if price is set because when payment added event is tracked
                // the price should be available.
                filter(([payload, price]) => price !== undefined && price !== null),
                tap(([payload, price]) => {
                    this.dataLayer().push({
                        event: 'Shop Payment added',
                        product_category: 'Subscription',
                        product_price: price,
                        payment_mode: "Credit card"
                    });
                }),
            );
        },
        {dispatch: false}
    );

    trackOneTimePurchaseSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(oneTimePurchaseFlowActions.submitSuccess),
                concatLatestFrom(() => [
                    this.oneTimePurchaseStore.selectedOfferPrice$,
                    this.oneTimePurchaseStore.selectedPaymentMethod$
                ]),
                tap(([submit, price, paymentMethod]) => {
                    const paymentModeForTracking = (paymentMethod === 'paypal') ? 'Paypal' : 'Credit card';
                    this.dataLayer().push({
                        event: 'Shop Payment confirmed',
                        product_category: 'BB$',
                        product_price: price,
                        payment_mode: paymentModeForTracking
                    });
                })
            );
        },
        {dispatch: false}
    );

    trackOneTimePurchaseFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(oneTimePurchaseFlowActions.submitFailure),
                concatLatestFrom(() => [
                    this.oneTimePurchaseStore.selectedOfferPrice$,
                    this.oneTimePurchaseStore.selectedPaymentMethod$
                ]),
                tap(([submit, price, paymentMethod]) => {
                    const paymentModeForTracking = (paymentMethod === 'paypal') ? 'Paypal' : 'Credit card';
                    this.dataLayer().push({
                        event: 'Shop Payment failed',
                        product_category: 'BB$',
                        product_price: price,
                        payment_mode: paymentModeForTracking
                    });
                })
            );
        },
        {dispatch: false}
    );

    trackSubscriptionPurchaseSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(autoRefillSubscriptionFlowActions.submitSuccess),
                concatLatestFrom(() => [
                    this.subscriptionStore.selectedPlanPrice$,
                    this.subscriptionStore.selectedPaymentMethod$
                ]),
                tap(([submit, price, paymentMethod]) => {
                    const paymentModeForTracking = (paymentMethod === 'paypal') ? 'Paypal' : 'Credit card';
                    this.dataLayer().push({
                        event: 'Shop Payment confirmed',
                        product_category: 'Subscription',
                        product_price: price,
                        payment_mode: paymentModeForTracking
                    });
                })
            );
        },
        {dispatch: false}
    );

    trackSubscriptionPurchaseFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(autoRefillSubscriptionFlowActions.submitFailure),
                concatLatestFrom(() => [
                    this.subscriptionStore.selectedPlanPrice$,
                    this.subscriptionStore.selectedPaymentMethod$
                ]),
                tap(([submit, price, paymentMethod]) => {
                    const paymentModeForTracking = (paymentMethod === 'paypal') ? 'Paypal' : 'Credit card';
                    this.dataLayer().push({
                        event: 'Shop Payment failed',
                        product_category: 'Subscription',
                        product_price: price,
                        payment_mode: paymentModeForTracking
                    });
                })
            );
        },
        {dispatch: false}
    );
}
