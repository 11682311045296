import {NgModule} from '@angular/core';

import {BalanceComponent} from './ui/balance/balance.component';
import {BalanceRepository} from './infrastructure/balance.repository';
import {BBO_BALANCE_REPOSITORY} from './infrastructure/balance.interface';
import {DecimalPipe} from "@angular/common";

@NgModule({
    imports: [DecimalPipe],
    exports: [BalanceComponent],
    declarations: [BalanceComponent],
    providers: [
        {provide: BBO_BALANCE_REPOSITORY, useClass: BalanceRepository}
    ]
})
export class BalanceModule {
}
