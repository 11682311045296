import {createGenericObjectReducer} from "../../../@core";
import {SavedCreditCardActions, savedCreditCardActions} from "../actions";
import {CreditCard} from "../../../payment";
import {on} from "@ngrx/store";
import {state} from "@angular/animations";

const {initialState, reducer} =
    createGenericObjectReducer<CreditCard, CreditCard, Partial<CreditCard>>(savedCreditCardActions)(
        on(savedCreditCardActions.replace, (state) => ({
            ...state,
            loading: true,
            loaded: false
        })),
        on(savedCreditCardActions.replaceSuccess, (state, {payload}) => ({
            ...state,
            loading: false,
            data: payload,
            loaded: true
        })),
        on(savedCreditCardActions.replaceFail, (state, {error}) => ({
            ...state,
            loading: false,
            loaded: true,
            error
        })),
        on(savedCreditCardActions.remove, (state) => ({
            ...state,
            loading: true,
            loaded: false
        })),
        on(savedCreditCardActions.removeSuccess, (state) => ({
            ...state,
            loading: false,
            loaded: true
        })),
        on(savedCreditCardActions.removeFail, (state, {error}) => ({
            ...state,
            loading: false,
            loaded: true,
            error
        }))
    );

export type SavedCreditCardState = typeof initialState;
export {initialState};

export function savedCreditCardReducer(
    state: SavedCreditCardState | undefined,
    action: SavedCreditCardActions
) {
    return reducer(state, action);
}
