<div class="details">
    <ng-container *ngIf="!loading">
        <ng-container *ngIf="type === 'paypal'">
            <mat-icon fontIcon="paypal"></mat-icon>
            <div class="paypal-label">
                Paypal
            </div>
        </ng-container>
        <ng-container *ngIf="creditCard">
            <mat-icon fontIcon="credit_card"></mat-icon>
            <div class="label">
                <div class="card-label bbo-body-1 font-weight-semibold">{{creditCard.type}} - {{xlationCodes.endingIn | translate: [creditCard.last4DigitsCardNumber?.toString() ?? ""]}}</div>
                <div class="card-expiration-date bbo-body-2 color-gray">{{xlationCodes.expiring | translate}} {{creditCard.expirationMonth}}/{{creditCard.expirationYear}}</div>
            </div>
        </ng-container>
        <ng-container *ngIf="!creditCard && type==='credit-card'">
            <mat-icon fontIcon="credit_card"></mat-icon>
            <div class="paypal-label">{{xlationCodes.noCreditCardSaved | translate}}</div>
            <button mat-button class="bbo-button" color="primary" (click)="openAddCardModal()">{{xlationCodes.add | translate}}</button>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="loading">
        <div class="loader-container">
            <mat-progress-spinner [diameter]="30" mode="indeterminate"></mat-progress-spinner>
        </div>
    </ng-container>
</div>
<ng-content></ng-content>
