import {Inject, Injectable} from "@angular/core";
import {IUseCase} from "src/base/usecase";
import {catchError, map, Observable, throwError} from "rxjs";
import {ApplicationError, IResponse, Response} from "src/base/response";
import {BBO_BILLING_ADDRESS_REPOSITORY, IBillingAddressRepository} from "../infrastructure/billing-address.interface";
import {HttpErrorResponse, HttpStatusCode} from "@angular/common/http";
import {UserService} from "src/app/shared/services/user.service";
import { GeneralStoreFacade } from "src/app/bbo-store/store";

export enum SaveBillingAddressUseCaseError {
    LOCAL_UID_INVALID,
    GENERIC_ERROR,
    BAD_REQUEST
}

export interface BillingAddressGetResponse {
    owner: {
        id: number
    };
    addressLine1: string;
    addressLine2: string;
    state: string;
    city: string;
    countryCode: string;
    zipCode: string;
    email: string;
}

export interface BillingAddressSaveResponse {
    owner: {
        id: number
    };
    addressLine1: string;
    addressLine2: string;
    state: string;
    city: string;
    countryCode: string;
    zipCode: string;
    email: string;
}

export interface BillingAddressRequest {
    addressLine1: string;
    addressLine2: string;
    state: string;
    city: string;
    countryCode: string;
    zipCode: string;
    email: string;
}

@Injectable()
export class SaveBillingAddressUseCase implements IUseCase<BillingAddressRequest, Observable<IResponse<BillingAddressSaveResponse>>> {
    constructor(
        private userService: UserService,
        @Inject(BBO_BILLING_ADDRESS_REPOSITORY) private repository: IBillingAddressRepository,
        private generalStore: GeneralStoreFacade
    ) {}

    execute(payload: BillingAddressRequest): Observable<IResponse<BillingAddressSaveResponse>> {
        const localUid = this.userService.getUId();

        if (!localUid) {
            return throwError(() => new Response(
                false,
                null,
                new ApplicationError(SaveBillingAddressUseCaseError.LOCAL_UID_INVALID, "Trying to save billing address for local user but uid is falsy")
            ));
        }

        return this.repository.postBillingAddress(localUid, payload).pipe(
            catchError((error) => {
                let applicationError: ApplicationError | null = null;
                if (error instanceof HttpErrorResponse && (error.status === HttpStatusCode.BadRequest || error.status === HttpStatusCode.UnprocessableEntity)) {
                    applicationError = new ApplicationError(
                        SaveBillingAddressUseCaseError.BAD_REQUEST,
                        `${error.error.title}: ${error.error.detail}`,
                        error
                    )
                } else {
                    applicationError = new ApplicationError(
                        SaveBillingAddressUseCaseError.GENERIC_ERROR,
                        "An error occured while trying to save the billing address for local user",
                        error
                    );
                }
                return throwError(() => new Response(
                    false,
                    null,
                    applicationError
                ))
            }),
            map((billingAddress: BillingAddressSaveResponse) => {
                this.generalStore.updateGeneralStore({ billingAddress });
                return new Response(
                    true,
                    billingAddress
                );
            }),
        );
    }

}
