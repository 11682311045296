import {Component} from '@angular/core';
import {XlationCodes} from '../../translations/xlation.codes';
import {AutoRefillSubscriptionFlowFacade} from "../../../product/store";

@Component({
    selector: 'bbo-auto-refill-subscription-details',
    templateUrl: './auto-refill-subscription-details.component.html',
    styleUrls: ['./auto-refill-subscription-details.component.scss']
})
export class AutoRefillSubscriptionDetailsComponent {
    public readonly selectedPlan$ = this.store.selectedPlan$;
    public readonly taxes$ = this.store.selectedPlanPriceTax$;
    xlationCodes = XlationCodes;

    constructor(private readonly store: AutoRefillSubscriptionFlowFacade) {
    }

}
