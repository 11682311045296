<!-- Handles the case when user already has an autorefill -->
<!-- And when user has an autorefill but it's outdated -->
<mat-card>
    <mat-card-content class="bbo-medium-card">
        <article>
            <div class="product-head">
                <mat-icon svgIcon="refill" color="red"></mat-icon>
                <span class="bbo-headline-3">{{xlationCodes.autoRefill | translate}}</span>
            </div>
            <div class="bbo-body-1 font-weight-semibold">{{xlationCodes.subscribedTo | translate}}</div>
            <div class="product-mini-card">
                <div class="foreground">
                    <div class="product-mini-card__bbo-currency">
                        <span class="amount">{{bboCurrencyAmount}}</span>
                        <span class="currency">BB$</span>
                    </div>
                    <div class="product-mini-card__price">${{price}} + {{xlationCodes.taxesIfApplicable | translate}}</div>
                    <div class="product-mini-card__info">
                        {{xlationCodes.billedCondition | translate: [refillTreshold.toString()] }}
                    </div>
                </div>
                <mat-icon class="decoration" [svgIcon]="outdated ? 'refill-triangle-grey' : 'refill-circle'"></mat-icon>
            </div>
            <div class="product-outdated-info" *ngIf="outdated">
                {{xlationCodes.outdatedPlanHint | translate }}
            </div>
            <div class="product-actions">
                <ng-content></ng-content>
            </div>
        </article>
    </mat-card-content>
</mat-card>
