<div class="main-content">
    <p id="welcome">
        <span class="bbo-headline-3 bbo-headline-1-md-up">{{xlationCodes.welcomeMessage | translate}}</span>
        <br>
        <span class="bbo-headline-1 color-accent-darker">BBO Store</span>
    </p>
    <div id="welcome-subtext" class="bbo-headline-4">{{xlationCodes.yourCurrentBalance | translate}}<bbo-balance size="medium" [balanceAmount]="balance$ | async"></bbo-balance></div>

    <div class="product-list" [ngClass]="{'has-bbo-plus-card': showBuyBBOPlusCard$ | async}">
        <bbo-product-one-time-purchase [layout]="(showBuyBBOPlusCard$ | async) ? 'tiny' : 'normal'"></bbo-product-one-time-purchase>

        <bbo-product-bbo-plus
            *ngIf="showBuyBBOPlusCard$ | async"
        ></bbo-product-bbo-plus>

        <ng-container *ngIf="(currentAutoRefillPlan$ | async) as currentAutoRefillPlan; else buyAutoRefillPlan">
            <bbo-current-autorefill-card
                class="product-autorefill"
                [bboCurrencyAmount]="currentAutoRefillPlan.bboCurrency"
                [price]="currentAutoRefillPlan.amount"
                [outdated]="currentAutoRefillPlan.outdated"
                [refillTreshold]="currentAutoRefillPlan.threshold"
            >
                <button mat-flat-button routerLink="/auto-refill" class="bbo-button width-100" color="primary">{{xlationCodes.editPlan | translate}}</button>
            </bbo-current-autorefill-card>
        </ng-container>
    </div>

    <ng-template #buyAutoRefillPlan>
        <bbo-product-autorefill-plan></bbo-product-autorefill-plan>
    </ng-template>
</div>
