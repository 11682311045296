import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {catchError, from, map, Observable, switchMap, throwError} from 'rxjs';
import {ApplicationError, Response} from 'src/base/response';
import {IUseCase} from 'src/base/usecase';
import {BBO_AUTHENTICATE_REPOSITORY, IAuthenticateRepository} from '../infrastructure/authenticate.interface';
import {UserService} from '../../shared/services/user.service';

export interface LoginRequest {
    username: string;
    password: string;
    /** The next page to go to when user is logged in */
    successURL?: string;
}

export class WrongCredentialsError extends ApplicationError {
    // eslint-disable-next-line no-magic-numbers
    static ERROR_CODE = 1;
    constructor() {
        super(WrongCredentialsError.ERROR_CODE, "login wrong credentials given");
    }
}

export class GenericError extends ApplicationError {
    // eslint-disable-next-line no-magic-numbers
    static ERROR_CODE = 2;
    constructor(innerError?: unknown) {
        super(GenericError.ERROR_CODE, "unexpected error", innerError);
    }
}

export class GenericErrorWithMessage extends ApplicationError {
    // eslint-disable-next-line no-magic-numbers
    static ERROR_CODE = 3;
    constructor(message: string, innerError?: unknown) {
        super(GenericErrorWithMessage.ERROR_CODE, message, innerError);
    }
}

@Injectable({
    providedIn: 'root'
})
export class LoginUseCase implements IUseCase<LoginRequest, Observable<Response<null>>> {

    constructor(
        @Inject(BBO_AUTHENTICATE_REPOSITORY) private repository: IAuthenticateRepository,
        private userService: UserService,
        private router: Router
    ) {}

    execute(data: LoginRequest): Observable<Response<null>> {
        return this.repository.login(data.username, data.password).pipe(
            catchError((error) => {
                // bad credentials
                if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
                    return throwError(() => new Response(
                        false,
                        null,
                        new WrongCredentialsError()
                    ));
                } else if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Forbidden) {
                    // .detail is for new response format
                    // .message is for old response format
                    const extractedMessage = error.error?.detail || error.error?.message;
                    return throwError(() => new Response(
                        false,
                        null,
                        new GenericErrorWithMessage(extractedMessage)
                    ));
                }
                console.error(error);
                return throwError(() => new Response(
                    false,
                    null,
                    new GenericError(error)
                ));
            }),
            switchMap(() => {
                const nextURL = data.successURL || "/";
                return from(this.router.navigateByUrl(nextURL)).pipe(
                    catchError((error) => {
                        console.error(error);
                        return throwError(() => new Response(
                            false,
                            null,
                            new GenericError(error)
                        ));
                    }),
                    map(() => {
                        return new Response(
                            true,
                            null,
                            null
                        );
                    })
                )
            }),
        );
    }

}
