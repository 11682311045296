<div class="title-container">
    <div class="bbo-headline-3 headline-2-md-up text-center" [innerHTML]="xlationCodes.yourBboMembership | translate"></div>
    <div class="subscription-cancelled-warning" *ngIf="!bboPlus?.autoRenew">
        <mat-icon svgIcon="alert-circle"></mat-icon>
        <div class="bbo-body-2">{{xlationCodes.bboPlusMembershipCanceledMessage | translate}}</div>
    </div>
</div>

<div class="bbo-plus-details-container bbo-large-line-height">
    <mat-card>
        <mat-card-content class="bbo-medium-card">
            <div class="card-content">
                <div class="card-header">
                    <div class="color-accent-darker font-weight-bold">{{xlationCodes.bboPlus | translate}}</div>
                </div>
                <div class="card-description">
                    <bbo-bbo-plus-tile [amount]="amount"></bbo-bbo-plus-tile>
                </div>
                <div class="bbo-body-2 text-center font-weight-bold cancelled" *ngIf="!bboPlus?.autoRenew">
                    {{xlationCodes.expireOn | translate:[(bboPlus?.expiresAt | date:'MMM. d YYYY') || ""]}}
                </div>
                <div class="bbo-body-2 text-center font-weight-bold active" *ngIf="bboPlus?.autoRenew">
                    {{xlationCodes.active | translate}}
                </div>
            </div>
            <button *ngIf="bboPlus?.autoRenew" mat-flat-button class="bbo-button width-100 secondary" color="purple" (click)="openUnsubscribeDialog()">{{xlationCodes.cancelMembership | translate}}</button>
            <button *ngIf="!bboPlus?.autoRenew" mat-flat-button class="bbo-button width-100" color="purple" routerLink="/bbo-plus/subscribe">{{xlationCodes.renewSubscribtion | translate}}</button>
        </mat-card-content>
    </mat-card>
    <div class="advantages-container mobile-hidden">
        <div class="font-weight-semibold bbo-large-line-height">{{xlationCodes.bboPlusBenefits | translate}}</div>
        <div class="advantage" *ngFor="let advantage of advantagesList">
            <div class="bbo-headline-6 color-purple bbo-large-line-height font-weight-light">{{advantage.titleTranslationKey | translate}}</div>
            <div class="bbo-body-2 bbo-large-line-height">{{advantage.descriptionTranslationKey | translate}}</div>
        </div>
    </div>
</div>