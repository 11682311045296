import {IUseCase} from "../../../base/usecase";
import {catchError, map, Observable, throwError} from "rxjs";
import {ApplicationError, IResponse, Response} from "../../../base/response";
import {Inject, Injectable} from "@angular/core";
import {BBO_PRODUCT_REPOSITORY, IProductRepository} from "../infrastructure/product.interface";
import {UserService} from "src/app/shared/services/user.service";
import {ProductAutoRefillPlan} from "./get-current-auto-refill-plan.usecase";
import { GeneralStoreFacade } from "src/app/bbo-store/store";

export enum SubscribeToAutoRefillPlanUseCaseError {
    LOCAL_UID_INVALID,
    GENERIC_ERROR,
    NOT_FOUND
}

export interface SubscribeToAutoRefillPlanRequest {
    autoRefillPlanId: number
}

@Injectable({providedIn: 'root'})
export class SubscribeToAutoRefillPlanUseCase implements IUseCase<SubscribeToAutoRefillPlanRequest, Observable<IResponse<ProductAutoRefillPlan>>> {

    constructor(
        @Inject(BBO_PRODUCT_REPOSITORY) private repository: IProductRepository,
        private readonly generalStore: GeneralStoreFacade,
        private readonly userService: UserService
    ) { }

    execute(data: SubscribeToAutoRefillPlanRequest): Observable<IResponse<ProductAutoRefillPlan>> {
        const localUid = this.userService.getUId();
        if (!localUid) {
            return throwError(() => new Response(
                false,
                null,
                new ApplicationError(SubscribeToAutoRefillPlanUseCaseError.LOCAL_UID_INVALID, "Trying to subscribe to an autorefill plan for local user but uid is falsy")
            ));
        }
        return this.repository.subscribeToAutoRefillPlan(localUid, data.autoRefillPlanId).pipe(
            map((response) => {
                const data: ProductAutoRefillPlan = {
                    amount: response.amountRefill,
                    bboCurrency: response.amountRefill,
                    threshold: response.threshold,
                    outdated: response.outdated
                };
                this.generalStore.updateGeneralStore({ currentAutoRefillPlan: data });
                return new Response(
                    true,
                    data
                );
            }),
            catchError((error) => {
                // already handled: skip
                if (error instanceof Response) {
                return throwError(() => error);
                }
                return throwError(() => new Response(
                false,
                null,
                new ApplicationError(
                    // TODO check can we use something generic for every domain
                    SubscribeToAutoRefillPlanUseCaseError.GENERIC_ERROR,
                    'subscribeToAutoRefillPlan usecase unexpected error',
                    error
                )
                ));
            })
        );
    }

}
