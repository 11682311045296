import { Environment } from 'src/environments/environment.interface';

export const environment: Environment = {
    apiURL: "https://dev-bbo-api.bridgebase.com",
    webutilURL: "https://devbenoit.bridgebase.com",
    paypalClientId: "AdSMsMdWSni04aszpnWROroTfeEU5pCqLP8q1KRk-wk_ZjAFUqvETugdI33-ODCrA87GrxD5krzHQnx1",
    braze: {
        baseUrl: "sdk.fra-02.braze.eu",
        apiKey: "36b3b7f5-cf7f-439b-b341-591022f8c2e2"
    }
};
