import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {XlationCodes} from "../../translations/xlation.codes";
import {map, Subject, takeUntil} from "rxjs";
import {PaypalResourceCheckUseCase} from "../../../paypal";
import {PaymentMethod} from "../../../payment";
import {Option} from "../../../@core";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'bbo-payment-method-selection',
    templateUrl: './payment-method-selection.component.html',
    styleUrls: ['./payment-method-selection.component.scss']
})
export class PaymentMethodSelectionComponent implements OnDestroy {

    @Output()
    methodSelected: EventEmitter<Option<PaymentMethod>> = new EventEmitter();

    private readonly unsubscribeSubject: Subject<void> = new Subject<void>();

    readonly form = new FormGroup({
        paymentMethod : new FormControl<Option<PaymentMethod>>(null, {validators: [Validators.required]})
    })

    readonly isPaypalAvailable$ =
        this.paypalResource.execute().pipe(takeUntil(this.unsubscribeSubject), map(value => value.data.active));
    readonly xlationCodes = XlationCodes;

    constructor(private readonly paypalResource: PaypalResourceCheckUseCase) {
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }

    selectCreditCard() {
        this.form.controls.paymentMethod.patchValue('credit-card');
    }

    selectPaypal() {
        this.form.controls.paymentMethod.patchValue('paypal');
    }

    completed() {
        this.methodSelected.emit(this.form.controls.paymentMethod.value);
    }
}
