import { Component, ViewEncapsulation } from "@angular/core";

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "shadow-dom",
    template: "<ng-content></ng-content>",
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class ShadowDomComponent {

}