import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {map, Subject, switchMap, takeUntil, tap, zip} from 'rxjs';
import {OneTimePurchaseFlowFacade} from "../../store";
import {filter, take} from "rxjs/operators";
import {isNotNil} from "../../../@core";
import {XlationCodes} from 'src/app/shared/translations/xlation.codes';
import {GeneralStoreFacade} from 'src/app/bbo-store/store';
import {isNil} from "lodash";
import {
    CreditCardFormDialogOutput
} from "../../../payment/ui/credit-card-form-dialog/credit-card-form-dialog.component";
import {CreditCard} from "../../../payment";
import {BillingAddressRequest} from "../../../billing-address/domain/save-billing-address.usecase";

@Component({
    selector: 'bbo-one-time-purchase-checkout',
    templateUrl: './one-time-purchase-checkout.component.html',
    styleUrls: ['./one-time-purchase-checkout.component.scss']
})
export class OneTimePurchaseCheckoutComponent implements OnInit, OnDestroy {
    xlationCodes = XlationCodes;
    readonly offerPrice$ = this.oneTimePurchaseFacade.selectedOfferPrice$;
    readonly selectedCreditCard$ = this.oneTimePurchaseFacade.selectedCreditCard$;
    readonly savedCreditCard$ = this.generalStoreFacade.storedCreditCard$;
    readonly isSelectedCardSavedCard$ = zip([
        this.selectedCreditCard$,
        this.savedCreditCard$
    ]).pipe(
        map(value => {
            const card1 = value[0];
            const card2 = value[1];
            return card1?.last4DigitsCardNumber === card2?.last4DigitsCardNumber &&
                card1?.expirationYear === card2?.expirationYear &&
                card1?.firstName === card2?.firstName &&
                card1?.lastName === card2?.lastName &&
                card1?.expirationYear === card2?.expirationYear &&
                card1?.expirationMonth === card2?.expirationMonth &&
                isNotNil(card1) && isNotNil(card2)
        }));
    readonly savedBillingAddress$ = this.oneTimePurchaseFacade.paymentMethodBillingAddress$.pipe(
        filter(isNil),
        switchMap(() => this.generalStoreFacade.billingAddress$),
        filter(isNotNil)
    );
    readonly selectedPaymentMethod$ = this.oneTimePurchaseFacade.selectedPaymentMethod$;
    readonly selectedPayment$ = this.oneTimePurchaseFacade.selectedPayment$;
    readonly paypalOrderId$ = this.oneTimePurchaseFacade.paypalOrderId$;
    readonly isPaypalOrderApproved$ = this.oneTimePurchaseFacade.paypalOrderApproved$;
    readonly taxAmount$ = this.oneTimePurchaseFacade.paymentTaxes$;
    readonly balance$ = this.generalStoreFacade.currentBalance$;
    private readonly unsubscribeSubject: Subject<void> = new Subject<void>();

    isOrderSubmitted = false;

    @Output() orderCompleted: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private readonly oneTimePurchaseFacade: OneTimePurchaseFlowFacade,
        private readonly generalStoreFacade: GeneralStoreFacade
    ) {
    }

    ngOnInit(): void {
        this.generalStoreFacade.loadSavedCreditCard();
        this.generalStoreFacade.loadSavedBillingAddress();
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }

    approvePaypalOrder() {
        this.selectedPayment$.pipe(take(1), filter(isNotNil), filter(value => isNotNil(value.paypal)))
            .subscribe(value => this.oneTimePurchaseFacade.updateFlow({
                selectedPayment: {
                    ...value,
                    ...{paypal: {paypalOrderId: value?.paypal?.paypalOrderId, approved: true}}
                }
            }))
    }

    proceedToPayment() {
        this.isOrderSubmitted = true;
        this.oneTimePurchaseFacade.submit();
    }


    changePaymentMethod() {
        this.oneTimePurchaseFacade.updateFlow({selectedPayment: undefined, selectedPaymentMethod: undefined});
    }

    cancelPaypalOrder() {
        this.oneTimePurchaseFacade.cancelPaypalOrder();
    }

    deleteCard() {
        this.oneTimePurchaseFacade.updateFlow({selectedPayment: undefined});
        this.isSelectedCardSavedCard$.pipe(takeUntil(this.unsubscribeSubject))
            .subscribe((isSelectedCardSavedCard) => {
                if (isSelectedCardSavedCard) {
                    this.generalStoreFacade.resetCreditCard();
                    this.generalStoreFacade.resetSavedBillingAddress();
                }
            })
    }

    addPaymentCard(event: CreditCardFormDialogOutput) {
        if (event.saveCardDetails) {
            this.generalStoreFacade.replaceSavedCreditCard(event.creditCard as CreditCard);
            this.generalStoreFacade.updateSavedBillingAddress(event.billingAddress as BillingAddressRequest);
            this.oneTimePurchaseFacade.updateFlow({
                selectedPayment: {creditCard: event.creditCard as CreditCard, method: 'credit-card'},
                billingAddress: event.billingAddress
            })
        } else {
            this.oneTimePurchaseFacade.updateFlow({
                selectedPayment: {creditCard: event.creditCard as CreditCard, method: 'credit-card'},
                billingAddress: event.billingAddress
            })
        }
    }

    updateCreditCard(data: CreditCardFormDialogOutput) {
        this.isSelectedCardSavedCard$.pipe(take(1))
            .subscribe((isSelectedCardSavedCard) => {
                if (data.creditCard && data.billingAddress && isSelectedCardSavedCard) {
                    this.generalStoreFacade.updateSavedCreditCard(data.creditCard);
                    this.generalStoreFacade.updateSavedBillingAddress(data.billingAddress);
                }
                this.oneTimePurchaseFacade.updateCreditCard(data.creditCard as CreditCard, true);
                this.oneTimePurchaseFacade.updateFlow({
                    billingAddress: data.billingAddress
                })
            });
    }
}
