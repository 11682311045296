import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaypalComponent} from "./ui/paypal/paypal.component";
import {PaypalOneTimePurchaseOrderUseCase} from "./domain/paypal-one-time-purchase-order-use-case";
import {PaypalCompleteOrderUseCase} from "./domain/paypal-complete-order-use-case.service";
import {PaypalCancelOrderUseCase} from "./domain/paypal-cancel-order-use-case.service";
import {PaypalApplyTaxesUseCase} from "./domain/paypal-apply-taxes-use-case.service";
import {PaypalOrderRepository} from "./infrastructure/paypal-order-repository.service";
import {PaypalResourceCheckUseCase} from "./domain/paypal-resource-check-use-case.service";


@NgModule({
  declarations: [
    PaypalComponent
  ],
  exports: [
    PaypalComponent
  ],
  imports: [
    CommonModule
  ],
  providers:[
    PaypalOneTimePurchaseOrderUseCase,
    PaypalCompleteOrderUseCase,
    PaypalCancelOrderUseCase,
    PaypalApplyTaxesUseCase,
    PaypalResourceCheckUseCase,
    PaypalOrderRepository
  ]
})
export class PaypalModule { }
