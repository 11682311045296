import {createGenericObjectActions, EntityAction} from "../../../@core";
import {CreditCard} from "../../../payment";
import {createAction, props} from "@ngrx/store";
import {CreateCreditCardUseCase} from "../../../payment/domain/create-credit-card.usecase";

export interface SavedCreditCardStore {
    readonly card: CreditCard;
}

const replace = createAction('[General Store] Saved Card Replace', props<{ payload: CreditCard }>());
const replaceSuccess = createAction('[General Store] Saved Card Replace Success', props<{ payload: CreditCard }>());
const replaceFail = createAction('[General Store] Saved Card Replace Fail', props<{ error: Error }>());
const remove = createAction('[General Store] Saved Card Remove');
const removeSuccess = createAction('[General Store] Saved Card Remove Success');
const removeFail = createAction('[General Store] Saved Card Replace Fail', props<{ error: Error }>());
export const savedCreditCardActions = ({
    ...createGenericObjectActions<CreditCard, CreditCard, Partial<CreditCard>>('Saved Card'),
    replace,
    replaceSuccess,
    replaceFail,
    remove,
    removeSuccess,
    removeFail
});
export type SavedCreditCardActions = EntityAction<CreditCard, CreditCard, Partial<CreditCard>>;
