import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LogoutUseCase} from 'src/app/authenticate/domain/logout.usecase';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';
import {Option} from "../../@core";
import { AnalyticsService, FeatureFlags } from 'src/app/@core/analytics/analytics.service';
import { map } from 'rxjs';

@Component({
    selector: 'bbo-side-nav',
    templateUrl: 'side-nav.component.html',
    styleUrls: ['./side-nav.component.scss']
})

export class SideNavComponent implements OnInit {

    @Output() closeAside: EventEmitter<void> = new EventEmitter();
    @Input() userName: string | null = '';
    @Input() balanceAmount: Option<number>;
    xlationCodes = XlationCodes
    isMobileResolution = false;

    readonly showBboPlus$ = this.analytics.getFeatureFlagStream(FeatureFlags.BBO_PLUS).pipe(
        map(flag => flag.enabled),
    );

    constructor(
        private readonly logoutUseCase: LogoutUseCase,
        private readonly responsive: BreakpointObserver,
        private analytics: AnalyticsService
    ) { }

    ngOnInit() {
        this.responsive.observe([
            Breakpoints.Handset
        ])
        .subscribe(result => {
            this.isMobileResolution = result.matches;
        });
    }

    onCloseAside(): void {
        this.closeAside.emit();
    }

    logout(): void {
        this.logoutUseCase.execute({});
    }
}
