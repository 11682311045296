import {Inject, Injectable} from "@angular/core";
import {IUseCase} from "src/base/usecase";
import {catchError, map, Observable, throwError} from "rxjs";
import {ApplicationError, IResponse, Response} from "src/base/response";
import {HttpErrorResponse, HttpStatusCode} from "@angular/common/http";
import {UserService} from "src/app/shared/services/user.service";
import { GeneralStoreFacade } from "src/app/bbo-store/store";
import { BBO_PLUS_REPOSITORY, IBboPlusRepository } from "../infrastructure/bbo-plus.interface";

export enum SubscribeBboPlusUseCaseError {
    LOCAL_UID_INVALID,
    GENERIC_ERROR,
    NOT_FOUND,
    ALREADY_BBO_PLUS
}

export interface BboPlusSubscribeResponse {
    owner: {
        id: number
    },
    active: boolean,
    autoRenew: boolean,
    billingType: string,
    expiresAt: Date
}

@Injectable()
export class SubscribeBboPlusUseCase implements IUseCase<void, Observable<IResponse<BboPlusSubscribeResponse>>> {
    constructor(
        private userService: UserService,
        @Inject(BBO_PLUS_REPOSITORY) private repository: IBboPlusRepository,
        private generalStore: GeneralStoreFacade
    ) {}

    execute(): Observable<IResponse<BboPlusSubscribeResponse>> {
        const localUid = this.userService.getUId();

        if (!localUid) {
            return throwError(() => new Response(
                false,
                null,
                new ApplicationError(SubscribeBboPlusUseCaseError.LOCAL_UID_INVALID, "Trying to subscribe bbo plus for local user but uid is falsy")
            ));
        }

        return this.repository.subscribeBboPlus(localUid).pipe(
            catchError((error) => {
                let applicationError: ApplicationError | null = null;
                if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.NotFound) {
                    applicationError = new ApplicationError(
                        SubscribeBboPlusUseCaseError.NOT_FOUND,
                        'Fail your user is not registered as a bbo plus member' + localUid,
                    )
                } else if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Conflict) {
                    applicationError = new ApplicationError(
                        SubscribeBboPlusUseCaseError.ALREADY_BBO_PLUS,
                        "You are already register as a bbo+ member"
                    );
                } else {
                    applicationError = new ApplicationError(
                        SubscribeBboPlusUseCaseError.GENERIC_ERROR,
                        "Fail trying to Subscribe bbo plus for local user"
                    );
                }
                return throwError(() => new Response(
                    false,
                    null,
                    applicationError
                ))
            }),
            map((bboPlus: BboPlusSubscribeResponse) => {
                this.generalStore.updateGeneralStore({ bboPlus });
                return new Response(
                    true,
                    bboPlus,
                    null
                );
            }),
        );
    }

}
