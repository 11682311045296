import {Inject, Injectable} from "@angular/core";
import {IUseCase} from "src/base/usecase";
import {catchError, map, Observable, throwError} from "rxjs";
import {ApplicationError, IResponse, Response} from "src/base/response";
import {UserService} from "src/app/shared/services/user.service";
import { GeneralStoreFacade } from "src/app/bbo-store/store";
import { BBO_PLUS_REPOSITORY, BboPlusGetPriceResponse, IBboPlusRepository } from "../infrastructure/bbo-plus.interface";

export enum GetBboPlusUseCaseError {
    LOCAL_UID_INVALID,
    GENERIC_ERROR,
    NOT_FOUND
}

export interface BboPlusGetResponse {
    owner: {
        id: number
    },
    active: boolean,
    autoRenew: boolean,
    billingType: string,
    expiresAt: Date
}

@Injectable()
export class GetBboPlusPriceUseCase implements IUseCase<void, Observable<IResponse<BboPlusGetPriceResponse>>> {
    constructor(
        private userService: UserService,
        @Inject(BBO_PLUS_REPOSITORY) private repository: IBboPlusRepository,
        private generalStore: GeneralStoreFacade
    ) {}

    execute(): Observable<IResponse<BboPlusGetPriceResponse>> {
        const localUid = this.userService.getUId();

        if (!localUid) {
            return throwError(() => new Response(
                false,
                null,
                new ApplicationError(GetBboPlusUseCaseError.LOCAL_UID_INVALID, "Trying to get bbo plus price for local user but uid is falsy")
            ));
        }

        return this.repository.getPrice(localUid).pipe(
            map((response: BboPlusGetPriceResponse) => {
                const price = response.value / 100;
                this.generalStore.updateBboPlusPrice({
                    value: price
                });
                return new Response(
                    true,
                    {value: price},
                    null
                );
            }),
            catchError(err => {
                this.generalStore.failBboPlusPrice(err);
                return throwError(() => new Response(
                    false,
                    null,
                    err
                ));
            }),
        );
    }

}
