import { Component } from '@angular/core';
import { XlationCodes } from '../../../translations/xlation.codes';

@Component({
    selector: 'bbo-support',
    styleUrls: ['./bbo-support.component.scss'],
    templateUrl: 'bbo-support.component.html'
})

export class BboSupportComponent {
    xlationCodes = XlationCodes;
}