<bbo-checkout [price]="price$ | async"
              [paymentMethod]="selectedPaymentMethod$ | async"
              [enablePaymentMethodEdit]="false"
              [paypalOrderId]="paypalOrderId$ | async"
              [creditCard]="selectedCreditCard$ | async"
              [billingAddress]="selectedBillingAddress$ | async"
              (userWantsToChangePaymentMethod)="userWantsToChangePaymentMethod.emit($event)"
              (updateCard)="updateCard($event)"
              (deleteCard)="deleteCard()"
>
    <ng-container order-details>
        <bbo-auto-refill-order-details class="desktop-hidden" [selectedPlan]="selectedPlan$ | async" [taxes]="taxes$ | async"></bbo-auto-refill-order-details>
    </ng-container>
    <ng-container actions>
        <button mat-flat-button class="bbo-button" color="primary" [disabled]="(this.isLoading$ | async)" (click)="userWantsToPay.emit()">{{xlationCodes.subscribe | translate}}</button>
    </ng-container>
</bbo-checkout>
