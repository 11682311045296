import { Directive, HostListener } from '@angular/core';

/**
 * used to restrict characters and only accept numerics and / entry in input
 * also disable past event in the input
 */
@Directive({
    selector: '[bboExpirationDateInputType]',
})
export class ExpirationDateInputTypeDirective {
    regexStr = '^[0-9/]*$';

    @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
        return new RegExp(this.regexStr).test(event.key);
    }

    // eslint-disable-next-line class-methods-use-this
    @HostListener('paste', ['$event']) preventPaste(event: KeyboardEvent) {
        event.preventDefault();
    }
}
