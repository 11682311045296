import {createGenericObjectReducer} from "../../../@core";
import { PaymentHistoryItem } from "../../domain/get-payment-history.usecase";
import {
    PaymentHistoryFlow,
    PaymentHistoryFlowActions,
    paymentHistoryFlowActions
} from "../actions";

const {initialState, reducer} =
    createGenericObjectReducer<PaymentHistoryFlow, PaymentHistoryItem, Partial<PaymentHistoryFlow>>(paymentHistoryFlowActions)();

export type PaymentHistoryFlowState = typeof initialState;
export {initialState};

export function paymentHistoryFlowReducer(
    state: PaymentHistoryFlowState | undefined,
    action: PaymentHistoryFlowActions
) {
    return reducer(state, action);
}
