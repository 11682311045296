import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from './language.service';

@Pipe({
    name: 'translate',
    pure: true
})
export class TranslatePipe implements PipeTransform {

    constructor(
        private languageService: LanguageService
    ) {}

    transform(key: string, params: string[] | null = null, replaceCRLFs = true): string {
        if (!key) {
            return key;
        }
        return this.languageService.getXlation(key, params, replaceCRLFs);
    }
}
