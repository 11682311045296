import {createFeatureSelector, createSelector} from "@ngrx/store";
import {PAYMENT_HISTORY_STORE_NAME, PaymentHistoryState} from "../reducers";
import {genericObjectSelectors} from "../../../@core";

const selectFeature = createFeatureSelector<PaymentHistoryState>(PAYMENT_HISTORY_STORE_NAME);
const paymentHistoryFlowStateSelectors = genericObjectSelectors(
    createSelector(
        selectFeature,
        s1 => s1.paymentHistoryFlow
    ));
    const selectedPaymentHistoryItem = createSelector(paymentHistoryFlowStateSelectors.data, s1 => s1?.selectedPaymentHistoryItem);

export const paymentHistoryFlowSelectors = {
    ...paymentHistoryFlowStateSelectors,
    selectedPaymentHistoryItem
}
