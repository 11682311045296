
import { Component, Input } from '@angular/core';
import { PaymentHistoryItem } from '../../domain/get-payment-history.usecase';
import { XlationCodes } from '../../../shared/translations/xlation.codes';
import { DownloadReceiptService } from '../../service/download-receipt.service';

@Component({
    selector: 'bbo-history-details',
    templateUrl: 'history-details.component.html',
    styleUrls: ['./history-details.component.scss']
})

export class HistoryDetailsComponent {
    xlationCodes = XlationCodes;
    @Input() paymentHistoryItem!: PaymentHistoryItem;


    constructor(
        private downloadReceiptService: DownloadReceiptService
    ) {}

    downloadReceipt(): void {
        this.downloadReceiptService.convertReceiptToPdf(this.paymentHistoryItem);
    }
}