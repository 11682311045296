<mat-card class="payment-selection-card">
    <mat-card-content class="bbo-medium-card">
        <div class="payment-selection-header">
            <span class="bbo-headline-3 bbo-headline-2-md-up">{{xlationCodes.paymentMethods | translate}}</span>
        </div>
        <form class="payment-selection-content" [formGroup]="form" >
            <mat-radio-group class="payments-group" formControlName="paymentMethod">
                <div class="payment-selection-type">
                    <div class="payment-type-title">
                        <div class="bbo-body-1 font-weight-semibold">{{xlationCodes.payment | translate}} {{xlationCodes.cards | translate}}</div>
                    </div>
                    <ng-container *ngIf="(creditCard$ | async) as creditCard; else AddCreditCardButton">
                        <bbo-payment-item type="credit-card" [creditCard]="creditCard" [selected]="form.controls.paymentMethod.value === 'credit-card'">
                            <div class="actions mobile-hidden">
                                <button mat-button class="bbo-button" color="primary" (click)="onEditCreditCardClicked()">{{xlationCodes.edit | translate}}</button>
                            </div>
                        </bbo-payment-item>
                        <div class="buttons-container desktop-hidden">
                            <button mat-button class="bbo-button bbo-small-button" color="primary" (click)="onEditCreditCardClicked()">
                                <mat-icon>edit</mat-icon>
                                {{xlationCodes.editCard | translate}}
                            </button>
                        </div>
                    </ng-container>

                    <ng-template #AddCreditCardButton>
                        <bbo-payment-item
                            [loading]="(loading$ | async)"
                            type="credit-card"
                            (paymentItemClicked)="onAddCreditCardClicked()">
                        </bbo-payment-item>
                    </ng-template>
                </div>
            </mat-radio-group>
        </form>
    </mat-card-content>
</mat-card>
