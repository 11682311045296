import {Injectable} from "@angular/core";
import {PaymentHistoryState} from "../reducers";
import {Store} from "@ngrx/store";
import {PaymentHistoryFlow, paymentHistoryFlowActions} from "../actions";
import { paymentHistoryFlowSelectors } from "../selectors";

@Injectable({
    providedIn: "root"
})
export class PaymentHistoryFlowFacade {

    public readonly flowState$ = this.store.select(paymentHistoryFlowSelectors.data);
    public readonly selectedPaymentHistoryItem$ = this.store.select(paymentHistoryFlowSelectors.selectedPaymentHistoryItem);
    public readonly submitError$ = this.store.select(paymentHistoryFlowSelectors.error);
    public readonly isLoading$ = this.store.select(paymentHistoryFlowSelectors.loading);
    public readonly submitResult$ = this.store.select(paymentHistoryFlowSelectors.result);

    constructor(private readonly store: Store<PaymentHistoryState>) {
    }

    updateFlow(patch: Partial<PaymentHistoryFlow>) {
        this.store.dispatch(paymentHistoryFlowActions.update({payload: {patch}}));
    }

    resetFlow() {
        this.store.dispatch(paymentHistoryFlowActions.reset());
    }

    submit() {
        this.store.dispatch(paymentHistoryFlowActions.submit());
    }

}
