import { Injectable } from '@angular/core';
import { AnalyticsService } from 'src/app/@core/analytics/analytics.service';

// we can access to bbo-store directly from v3 application by passing a "t" query parameter for the auth token
@Injectable({providedIn: 'root'})
export class AnalyticsInitializerService {
    constructor(
        private analyticsService: AnalyticsService
    ) { }

    initialize() {
        this.analyticsService.init();
    }
}