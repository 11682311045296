import { ProductAutoRefillPlan, SubscribeToAutoRefillPlanRequest } from "src/app/product/domain";
import {createGenericObjectActions, EntityAction} from "../../../@core";
import {createAction, props} from "@ngrx/store";

const replace = createAction('[General Store] Current auto-refill plan Replace', props<{ payload: SubscribeToAutoRefillPlanRequest }>());
const replaceSuccess = createAction('[General Store] Current auto-refill plan Replace Success', props<{ payload: ProductAutoRefillPlan }>());
const replaceFail = createAction('[General Store] Current auto-refill plan Replace Fail', props<{ error: Error }>());
const remove = createAction('[General Store] Current auto-refill plan Remove');
const removeSuccess = createAction('[General Store] Current auto-refill plan Remove Success');
const removeFail = createAction('[General Store] Current auto-refill plan Replace Fail', props<{ error: Error }>());
export const currentAutoRefillPlanActions = ({
    ...createGenericObjectActions<ProductAutoRefillPlan, ProductAutoRefillPlan, Partial<ProductAutoRefillPlan>>('Current auto-refill plan'),
    replace,
    replaceSuccess,
    replaceFail,
    remove,
    removeSuccess,
    removeFail
});
export type CurrentAutoRefillPlanActions = EntityAction<ProductAutoRefillPlan, ProductAutoRefillPlan, Partial<ProductAutoRefillPlan>>;
