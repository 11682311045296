import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Injectable} from "@angular/core";
import {ApplyOrderTaxesResponse} from "../domain/paypal-apply-taxes-use-case.service";
import {Observable} from "rxjs";
import {CompleteOrderResponse} from "../domain/paypal-complete-order-use-case.service";
import {IPaypalOrderRepository} from "./paypal-order.interface";
import {OneTimePurchasePaypalOrderResponse} from "../domain/paypal-one-time-purchase-order-use-case";
import {PaypalResourceCheckResponse,} from "../domain/paypal-resource-check-use-case.service";

@Injectable()
export class PaypalOrderRepository implements IPaypalOrderRepository {

  private readonly paypalBasePath = '/paypal';
  private readonly oneTimePurchaseBasePath = '/refill';

  constructor(
    private readonly http: HttpClient
  ) {
  }

  applyTaxes(orderId: string): Observable<ApplyOrderTaxesResponse> {
    return this.http.patch<ApplyOrderTaxesResponse>(
        `${environment.apiURL}${this.paypalBasePath}/order/${orderId}/apply-taxes`, {},
        {headers:new HttpHeaders({'Content-Type':'application/merge-patch+json', "Accept": "application/json, text/plain"})}
    );
  }

  cancelOrder(orderId: string): Observable<void> {
    return this.http.patch<void>(
      `${environment.apiURL}${this.paypalBasePath}/order/${orderId}/cancel`,
      {},
      {headers: new HttpHeaders({'Content-Type': 'application/merge-patch+json', "Accept": "application/json, text/plain"})}
    );
  }

  completeOrder(orderId: string): Observable<CompleteOrderResponse> {
    return this.http.patch<CompleteOrderResponse>(
      `${environment.apiURL}${this.paypalBasePath}/order/${orderId}/complete`,
      {},
      {headers: new HttpHeaders({'Content-Type': 'application/merge-patch+json', "Accept": "application/json, text/plain"})}
    );
  }

  createOneTimePurchaseOrder(optionId: number): Observable<OneTimePurchasePaypalOrderResponse> {
      return this.http.post<OneTimePurchasePaypalOrderResponse>(
        `${environment.apiURL}${this.oneTimePurchaseBasePath}/${optionId}/choose`,
        {paymentType: 'paypal'},
        {
          headers: new HttpHeaders().append("Accept", "application/json, text/plain")
        }
      );
  }

    checkResource(): Observable<PaypalResourceCheckResponse> {
      return this.http.get<PaypalResourceCheckResponse>(
        `${environment.apiURL}${this.paypalBasePath}`,
        {
          headers: new HttpHeaders().append("Accept", "application/json, text/plain")
        }
      )
  }

}
