import {ActivatedRoute} from '@angular/router';
import {GenericErrorWithMessage, LoginRequest, LoginUseCase, WrongCredentialsError} from 'src/app/authenticate/domain/login.usecase';
import {QUERY_PARAM_RETURN_PAGE_LOGIN} from 'src/app/authenticate/service/authenticate.constants';
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Response} from 'src/base/response';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';

@Component({
  selector: 'bbo-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    xlationCodes = XlationCodes;
    hide = true;
    form = this.formBuilder.nonNullable.group({
        username: [localStorage.getItem("userID") ?? ''],
        password: [localStorage.getItem("password") ?? ''],
    },
    {updateOn: 'submit'});

    @ViewChild('resetPasswordTemplate') resetPasswordTemplate: TemplateRef<unknown> | undefined;

    isMobileDisplay = false;

    private returnURL = '';

    constructor(
        private formBuilder: FormBuilder,
        private loginUseCase: LoginUseCase,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private responsive: BreakpointObserver
    ) {}

    ngOnInit(): void {
        this.returnURL = this.route.snapshot.queryParams[QUERY_PARAM_RETURN_PAGE_LOGIN];

        this.responsive.observe([
            Breakpoints.Handset
        ])
        .subscribe((result) => {
            this.isMobileDisplay = result.matches;
        });
    }

    onFormSubmit(): void {
        const LoginRequest: LoginRequest = {
            username: this.form.value.username || '',
            password: this.form.value.password || '',
            successURL: this.returnURL
        };
        this.loginUseCase.execute(LoginRequest)
        .subscribe({
            error: (response: Response<unknown>) => {
                if (response?.error instanceof WrongCredentialsError) {
                    this.showUnknownCredentialError();
                } else if (response?.error instanceof GenericErrorWithMessage) {
                    this.showGlobalErrorWithMessage(response.error.message);
                } else {
                    this.showGenericError();
                }
            },
        });
    }

    private showUnknownCredentialError(): void {
        this.form.get('username')?.setErrors({unknownCredential: true});
        this.form.get('password')?.setErrors({unknownCredential: true});
        this.form.setErrors({unknownCredential: true});
    }

    private showGlobalErrorWithMessage(message: string): void {
        this.form.setErrors({errorWithMessage: message});
    }

    private showGenericError(): void {
        this.form.setErrors({genericError: true});
    }

    forgotPasswordButtonClick(): void {
        if (this.resetPasswordTemplate) {
            this.dialog.open(this.resetPasswordTemplate);
        }
    }

}
