import jwt_decode from "jwt-decode";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import { AnalyticsService } from 'src/app/@core/analytics/analytics.service';
import {BBOAuthenticateAPIPayload} from 'src/app/authenticate/infrastructure/authenticate.interface';
import {AuthenticateService} from 'src/app/authenticate/service/authenticate.service';
import { UserService } from 'src/app/shared/services/user.service';

interface ParsedToken {
    exp: number;
    iat: number;
    uid: number;
    username: string;
}

/**
 * Catch authentication payloads and store the tokens
 */
@Injectable()
export class StoreTokenInterceptor implements HttpInterceptor {
    constructor(
        private authenticateService: AuthenticateService,
        private userService: UserService,
        private analytics: AnalyticsService
    ) { }

    static handle(request: HttpRequest<unknown>): boolean {
        return request.url.endsWith("/authenticate")
            || request.url.endsWith("/authenticate/refresh")
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<BBOAuthenticateAPIPayload>> {
        if (StoreTokenInterceptor.handle(request)) {
            return next.handle(request).pipe(
                tap((event) => {
                    if (event instanceof HttpResponse && event.ok) {
                        const body = event.body;
                        if (body) {
                            const parsedJwtToken: ParsedToken = jwt_decode(body.token);
                            const payload = {
                                username: parsedJwtToken.username,
                                userUniqueIdentifier: parsedJwtToken.uid.toString()
                            };

                            this.authenticateService.setToken(body.token);
                            this.authenticateService.setRefreshToken(body.refreshToken);

                            this.userService.setUserName(payload.username);
                            this.userService.setUId(payload.userUniqueIdentifier);

                            this.analytics.onUserLoggedIn(payload);
                        }
                    }
                })
            );
        }
        return next.handle(request);
    }
}
