import {Inject, Injectable} from "@angular/core";
import {IUseCase} from "src/base/usecase";
import {UserService} from '../../shared/services/user.service';
import {ApplicationError, IResponse, Response} from 'src/base/response';
import {catchError, map, Observable, throwError} from "rxjs";
import {HttpErrorResponse, HttpStatusCode} from "@angular/common/http";
import {BBO_PAYMENT_REPOSITORY, IPaymentRepository} from "../infrastructure/payment.interface";
import {CreditCard} from "../models";
import { GeneralStoreFacade } from "src/app/bbo-store/store";

export enum GetCreditCardUseCaseError {
    LOCAL_UID_INVALID,
    GENERIC_ERROR,
    NOT_FOUND
}

@Injectable()
export class GetCreditCardUseCase implements IUseCase<void, Observable<IResponse<CreditCard>>> {

    constructor(
        @Inject(BBO_PAYMENT_REPOSITORY) private repository: IPaymentRepository,
        private userService: UserService,
        private generalStore: GeneralStoreFacade
    ) {}

    execute(): Observable<IResponse<CreditCard>> {
        const localUid = this.userService.getUId();

        if (!localUid) {
            return throwError(() => new Response(
                false,
                null,
                new ApplicationError(GetCreditCardUseCaseError.LOCAL_UID_INVALID, "Trying to get credit card for local user but uid is falsy")
            ));
        }

        return this.repository.getCreditCard(localUid ?? '').pipe(
            catchError((error) => {
                let response: Response<null>;
                if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.NotFound) {
                    response = new Response(
                        false,
                        null,
                        new ApplicationError(
                            GetCreditCardUseCaseError.NOT_FOUND,
                            'no credit card founded for uid ' + localUid,
                        )
                    );
                } else {
                    response = new Response(
                        false,
                        null,
                        new ApplicationError(
                            GetCreditCardUseCaseError.GENERIC_ERROR,
                            'get credit card usecase unexpected error',
                            error
                        )
                    )
                }
                return throwError(() => response);
            }),
            map((patch) => {
                return new Response(
                    true,
                    patch
                );
            })
        );
    }
}
