import { createReducer, on } from "@ngrx/store";
import { bboPlusPriceActions } from "../actions/bbo-plus-price.actions";

export interface BboPlusPriceState {
    data: {
        price: number | null;
    } | null,
    loaded: boolean | null,
    loading: boolean | null,
    error: any | null,
}

const initialState: BboPlusPriceState = {
    data: null,
    loaded: null,
    loading: null,
    error: null,
};

export const bboPlusPriceReducer = createReducer(initialState,
    on(bboPlusPriceActions.load, (state): BboPlusPriceState => ({
        ...state,
        loading: true,
        loaded: false
    })),
    on(bboPlusPriceActions.loadSuccess, (state, {payload}): BboPlusPriceState => ({
        ...state,
        data: {
            price: payload.value
        },
        error: null,
        loading: false,
        loaded: true,
    })),
    on(bboPlusPriceActions.loadFail, (state, {error}): BboPlusPriceState => ({
        ...state,
        data: null,
        error,
        loading: false,
        loaded: false,
    })),
);
