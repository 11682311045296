<bbo-offer-card>
  <ng-container>
    <div>
      <div class="foreground">
        <!--top icon-->
        <mat-icon svgIcon="refill-offer-badge" class="badge"></mat-icon>
        <div class=  "bbo-currency">
          <span class="amount">{{offer.amount}}</span>
          <span class="currency">BB$</span>
        </div>
        <span class="price color-gray">{{offer.amount | currency:'USD'}} + {{xlationCodes.taxes | translate}}</span>
        <div class="additional-info">
          {{xlationCodes.billedCondition | translate: [offer.threshold.toString()]}}
        </div>
        <div class="actions">
          <ng-content></ng-content>
        </div>
      </div>
      <!--background svg-->
      <mat-icon class="decoration" [svgIcon]="'refill-triangle-green'"></mat-icon>
    </div>
  </ng-container>
</bbo-offer-card>
