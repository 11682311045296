<mat-card class="payment-selection-card">
    <mat-card-content class="bbo-medium-card">
        <div class="payment-selection-header">
            <span class="bbo-headline-3 bbo-headline-2-md-up">{{xlationCodes.paymentMethod | translate}}</span>
        </div>
        <form class="payment-selection-content" [formGroup]="form" >
            <mat-radio-group class="payments-group" formControlName="paymentMethod">
                <div class="payment-selection-type">
                    <div class="payment-type-title">
                        <div class="bbo-body-1 font-weight-semibold">{{xlationCodes.creditCard | translate}}</div>
                        <button
                            *ngIf="(creditCard$ | async) && enableDifferentCardPayment"
                            class="mobile-hidden bbo-button bbo-small-button"
                            mat-button color="primary"
                            (click)="onUseADifferentCreditCard()"
                        >
                            <mat-icon>add_card</mat-icon>
                            {{xlationCodes.useDifferentCreditCard | translate}}
                        </button>
                    </div>
                    <ng-container *ngIf="(creditCard$ | async) as creditCard; else AddCreditCardButton">
                        <bbo-payment-item [loading]="savedCreditCardLoading$ | async" (paymentItemClicked)="onPaymentItemClicked($event)" type="credit-card" [creditCard]="creditCard" [selected]="form.controls.paymentMethod.value === 'credit-card'">
                            <div class="actions">
                                <mat-radio-button checked=true class="bbo-radio-button" value="credit-card"></mat-radio-button>
                                <!-- put the buttons after the radio button so that the keyboard navigation is valid -->
                                <button mat-button class="bbo-button show-on-hover mobile-hidden" color="primary" (click)="onEditCreditCardClicked()">{{xlationCodes.edit | translate}}</button>
                            </div>
                        </bbo-payment-item>
                        <div class="buttons-container desktop-hidden">
                            <button mat-button class="bbo-button bbo-small-button" color="primary" (click)="onEditCreditCardClicked()">
                                <mat-icon>edit</mat-icon>
                                {{xlationCodes.editCard | translate}}
                            </button>
                            <button *ngIf="enableDifferentCardPayment" class="add-credit-card-button bbo-button bbo-small-button" mat-button color="primary" (click)="onUseADifferentCreditCard()">
                                <mat-icon>add_card</mat-icon>
                                {{xlationCodes.useDifferentCreditCard | translate}}
                            </button>
                        </div>
                    </ng-container>

                    <ng-template #AddCreditCardButton>
                        <bbo-payment-item
                            type="credit-card"
                            (paymentItemClicked)="onAddCreditCardClicked()">
                        </bbo-payment-item>
                    </ng-template>
                </div>
                <div class="payment-selection-type" *ngIf="(isPaypalAvailable$ | async) && paypal">
                    <div class="bbo-body-1 font-weight-semibold">{{xlationCodes.otherWaysToPay | translate}}</div>
                    <bbo-payment-item (paymentItemClicked)="onPaymentItemClicked($event)" type="paypal" [selected]="form.controls.paymentMethod.value === 'paypal'">
                        <mat-radio-button class="bbo-radio-button" value="paypal"></mat-radio-button>
                    </bbo-payment-item>
                </div>
            </mat-radio-group>
        </form>
        <button mat-flat-button class="bbo-button width-100 go-to-checkout-button" color="primary" (click)="goToCheckout()" [disabled]="form.invalid || (savedCreditCardLoading$ | async)">{{xlationCodes.continueToCheckout | translate}}</button>
    </mat-card-content>
</mat-card>
