import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {Option} from "../../../@core";
import {XlationCodes} from '../../translations/xlation.codes';

@Component({
    selector: 'bbo-order-details',
    templateUrl: './order-details.component.html',
    styleUrls: ['./order-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailsComponent {

  constructor(private readonly cdr:ChangeDetectorRef) {
  }
    xlationCodes = XlationCodes;
    @Input()
    orderValue: Option<number>;

    @Input()
    balance: Option<number>;

    @Input()
    taxAmount: Option<number>;

    get newBalance(){
        return Number(Number(this.orderValue) + Number(this.balance)).toFixed(2);
    }

    get total(){
      return Number(Number(this.orderValue) + Number(this.taxAmount|| 0)).toFixed(2);
    }

}
