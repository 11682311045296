import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BackgroundComponent} from './background/background.component';
import {OrderDetailsComponent} from './ui/order-details/order-details.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatIconModule} from '@angular/material/icon';
import {PaymentItemComponent} from './ui/payment-item/payment-item.component';
import {OrderAmountTileComponent} from './ui/order-details/order-amount-tile/order-amount-tile.component';
import {MatButtonModule} from '@angular/material/button';
import {ShouldDisplaySwipperDirective} from './should-display-carousel.directive';
import {OfferCardComponent} from './ui/offer-card/offer-card.component';
import {MatCardModule} from "@angular/material/card";
import {ButtonLoaderDirective} from './ui/button-loader.directive';
import {
    AutoRefillSubscriptionDetailsComponent
} from "./ui/auto-refill-subscription-details/auto-refill-subscription-details.component";
import {
    AutoRefillAmountTileComponent
} from "./ui/auto-refill-subscription-details/auto-refill-amount-tile/auto-refill-amount-tile.component";
import {CheckoutComponent} from "./ui/checkout/checkout.component";
import {TranslateModule} from './translations/translate.module';
import {
    AutoRefillOrderDetailsComponent
} from './ui/auto-refill-subscription-details/auto-refill-order-details/auto-refill-order-details.component';
import {BboStoreFooterComponent} from './ui/bbo-store-footer/bbo-store-footer.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PaymentMethodSelectionComponent} from './ui/payment-method-selection/payment-method-selection.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RadioSelectorComponent} from './ui/radio-selector/radio-selector.component';
import { CreditCardErrorModalComponent } from './ui/credit-card-error-modal/error-modal.component';
import {MatDialogModule} from "@angular/material/dialog";
import { BboSupportComponent } from './ui/bbo-store-footer/bbo-support-modal/bbo-support.component';
import { ShadowDomComponent } from './shadow-dom.component';
import { GeneralErrorModalComponent } from './ui/general-error-modal/general-error-modal.component';

@NgModule({
    declarations: [
        ShouldDisplaySwipperDirective,
        BackgroundComponent,
        OrderDetailsComponent,
        PaymentItemComponent,
        OrderAmountTileComponent,
        OfferCardComponent,
        AutoRefillAmountTileComponent,
        AutoRefillSubscriptionDetailsComponent,
        AutoRefillOrderDetailsComponent,
        ButtonLoaderDirective,
        CheckoutComponent,
        BboStoreFooterComponent,
        PaymentMethodSelectionComponent,
        RadioSelectorComponent,
        CreditCardErrorModalComponent,
        GeneralErrorModalComponent,
        BboSupportComponent,
        ShadowDomComponent,
    ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule
    ],
    exports: [
        ShouldDisplaySwipperDirective,
        BackgroundComponent,
        OrderDetailsComponent,
        AutoRefillSubscriptionDetailsComponent,
        AutoRefillOrderDetailsComponent,
        OfferCardComponent,
        PaymentItemComponent,
        ButtonLoaderDirective,
        CheckoutComponent,
        TranslateModule,
        BboStoreFooterComponent,
        PaymentMethodSelectionComponent,
        RadioSelectorComponent,
        ShadowDomComponent,
    ]
})
export class SharedModule {
}
