import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

class Xlation {
	constructor(public key:string, public value:string) {}
}

const getLanguage = (): string => {
	if (navigator.language === null) {
		return "en";
	}
	let language: string = navigator.language.toLowerCase();
	const hyphenIndex = language.indexOf("-");
	// split because we support zh-cn and zh-tw
	// otherwise fallback to the primary country (fr-FR -> fr)
	if (hyphenIndex !== -1 && language.substr(0, 2) !== "zh") {
		language = language.substr(0, hyphenIndex);
	}
	return language;
}

const textToXML = (text: string): Document => {
	const parser: DOMParser = new DOMParser();
	const document: Document = parser.parseFromString(text, 'text/xml');
	return document;
}

const getXMLAttribute = (xml: any, attributeName: string): string => {
	if (xml && xml.attributes && attributeName) {
		const attribute: Attr = xml.attributes.getNamedItem(attributeName);
		if (attribute) {
			return attribute.value;
		}
	}
	return "";
}

const replaceParams = (xlation: string, params: Array<string> | null = null, replaceCRLFs = true): string => {
	if (replaceCRLFs && xlation) {
		const crlf = /{r}{n}/g;
		xlation = xlation.replace(crlf, "---CRLF------CRLF---");
	}
	if (params === null || params.length === 0 || !xlation) {
		return xlation;
	}
	let temp: string;
	for (let index = 0; index<params.length; index++) {
		const replace = "{"+(index+1).toString();
		const offset: number = xlation.indexOf(replace);
		if (offset >= 0) {
			const end: number = xlation.indexOf("}", offset);
			if (end > index) {
				temp = xlation.substring(0, offset) + params[index] + xlation.substring(end + 1);
				xlation = temp;
			}
		} else {
			break;
		}
	}
	return xlation;
}

@Injectable({
	providedIn: "root"
})
export class LanguageService {

	xlations: Array<Xlation>=new Array<Xlation>();
	defaultXlations: Array<Xlation>=new Array<Xlation>();
	defaultLanguage = "en";

	constructor(private http: HttpClient) { }

	public init(): Observable<null> {
		const localLanguage = getLanguage();
		if (localLanguage === this.defaultLanguage) {
			return this.requestLanguageXlates(this.defaultLanguage).pipe(map(() => null));
		}
		return forkJoin([
			this.requestLanguageXlates(this.defaultLanguage),
			this.requestLanguageXlates(localLanguage)
		]).pipe(map(() => null));
	}

	requestLanguageXlates(language = ""): Observable<Document> {
		const params = `lang=${language}`;
		const httpParams = new HttpParams({ fromString: params });
		return this.http.get(`${environment.webutilURL}/v2/languages/lang.php`, { responseType: 'text', params: httpParams })
		.pipe(
			map((response: string) => textToXML(response)),
			tap((translationsAsXml) => this.languageXMLReceived(translationsAsXml, language))
		)
	}

	public getXlation(key: string, params: Array<string> | null = null, replaceCRLFs = true): string {
		const xlation = this.xlations.find((xl: Xlation) => xl.key === key);
		if (xlation){
			return replaceParams(xlation.value, params, replaceCRLFs);
		}
		const defaultXlation = this.defaultXlations.find((xl: Xlation) => xl.key === key);
		return (defaultXlation)
			? replaceParams(defaultXlation.value, params, replaceCRLFs)
			: replaceParams(key, params, replaceCRLFs);
	}

	private languageXMLReceived(xml:any, language: string) {
		const xlations: NodeList = xml.getElementsByTagName("m");
		for (let index = 0; index < xlations.length; index++) {
			const xlationNode: Node | null = xlations.item(index);
			const key: string = getXMLAttribute(xlationNode, "i");
			const value: string = getXMLAttribute(xlationNode, "d");
			const xlation: Xlation = new Xlation(key, value);
			if (key && value) {
				if (language === this.defaultLanguage) {
					this.defaultXlations.push(xlation);
				} else {
					this.xlations.push(xlation);
				}
			}
		}
	}
}
