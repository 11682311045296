import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LogoutUseCase} from 'src/app/authenticate/domain/logout.usecase';
import { GeneralStoreFacade } from 'src/app/bbo-store/store';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';
import { BboStoreHeaderService } from '../../../bbo-store-header/service/bbo-store-header.service';
import { AnalyticsService, FeatureFlags } from 'src/app/@core/analytics/analytics.service';
import { filter, map, merge, share, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { BboPlusGetResponse, GetBboPlusUseCaseError } from 'src/app/bbo-plus-home/domain/get-bbo-plus.usecase';
import { GetOneTimePurchaseOffersUseCase, OfferTypes } from '../../domain';

@Component({
  selector: 'bbo-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject();

    balance$ = this.generalStore.currentBalance$;
    xlationCodes = XlationCodes;

    currentAutoRefillPlan$ = this.generalStore.currentAutoRefillPlan$;

    isFirstTimePurchaseAvailable$ = this.getOffersUseCase.execute().pipe(
        map((response) => {
            return response.data.some((offer) => offer.type === OfferTypes.firstTimePurchase);
        })
    );

    showBuyBBOPlusCard$ = merge(
        this.generalStoreFacade.bboPlus$.pipe(
            filter((v): v is BboPlusGetResponse => !!v),
            map(({active}) => active),
            filter((active) => active === false), // not bbo+ subscriber
        ),
        this.generalStoreFacade.bboPlusError$.pipe(
            filter(v => !!v),
            map((error: any) => {
                if (error?.error?.code === GetBboPlusUseCaseError.NOT_FOUND) {
                    return true;
                }
                return false;
            }),
        )
    ).pipe(
        switchMap(() =>
            this.analytics.getFeatureFlagStream(FeatureFlags.BBO_PLUS).pipe(
                map(flag => flag.enabled)
        )),
        takeUntil(this.onDestroy$),
        share(),
    );

    bboPlus$ = this.generalStoreFacade.bboPlus$;


    constructor(
        private router: Router,
        private logoutUseCase: LogoutUseCase,
        private generalStore: GeneralStoreFacade,
        private bboStoreHeaderService: BboStoreHeaderService,
        private analytics: AnalyticsService,
        private generalStoreFacade: GeneralStoreFacade,
        private getOffersUseCase: GetOneTimePurchaseOffersUseCase,
    ) { }

    ngOnInit(): void {
        this.bboStoreHeaderService.setHeaderBackAction();
        this.generalStore.loadCurrentAutoRefillPlan();
        this.generalStore.loadCurrentAccountBalance();
        this.analytics.logFeatureFlagImpression(FeatureFlags.BBO_PLUS);
        this.analytics.getFeatureFlagStream(FeatureFlags.BBO_PLUS).pipe(
            take(1),
            tap(({enabled}) => {
                if (enabled) {
                    this.generalStoreFacade.loadBboPlus();
                }
            }),
            takeUntil(this.onDestroy$)
        ).subscribe();
    }

    goToLogin(): void {
        this.router.navigateByUrl('/login');
    }

    logout(): void {
        this.logoutUseCase.execute({});
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
}
