import { Component } from '@angular/core';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';

@Component({
    selector: 'bbo-product-autorefill-plan',
    templateUrl: './product-autorefill-plan.component.html',
    styleUrls: ['./product-autorefill-plan.component.scss']
})
export class ProductAutorefillPlanComponent {
    xlationCodes = XlationCodes;
}
