import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';

import { PaymentHistoryComponent } from './payment-history.component';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { PaymentHistoryRepository } from './infrastructure/payment-history.repository';
import { BBO_PAYMENT_HISTORY_REPOSITORY } from './infrastructure/payment-history.interface';
import { SharedModule } from '../shared/shared.module';
import { HistoryDetailsComponent } from './ui/history-details/history-details.component';
import { MatButtonModule } from '@angular/material/button';
import { PaymentHistoryStoreModule } from './store/payment-history-store.module';

@NgModule({
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatPaginatorModule,
        MatTableModule,
        MatDividerModule,
        SharedModule,
        PaymentHistoryStoreModule
    ],
    exports: [],
    declarations: [
        PaymentHistoryComponent,
        HistoryDetailsComponent
    ],
    providers: [
        DatePipe,
        {provide: BBO_PAYMENT_HISTORY_REPOSITORY, useClass: PaymentHistoryRepository}
    ],
})
export class PaymentHistoryModule { }
