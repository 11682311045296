import {PaypalOrderRepository} from "../infrastructure/paypal-order-repository.service";
import {Injectable} from "@angular/core";
import {IUseCase} from "../../../base/usecase";
import {catchError, map, Observable, throwError} from "rxjs";
import {ApplicationError, IResponse, Response} from "../../../base/response";

export interface CompleteOrderRequest {
  id: string;
}

export interface CompleteOrderResponse {
  id: string;
  payload: string[];
}

@Injectable()
export class PaypalCompleteOrderUseCase implements IUseCase<CompleteOrderRequest, Observable<IResponse<CompleteOrderResponse>>> {

  constructor(private readonly repository: PaypalOrderRepository) {
  }

  execute(data: CompleteOrderRequest): Observable<IResponse<CompleteOrderResponse>> {
    return this.repository.completeOrder(data.id).pipe(
        catchError((error) => {
          return throwError(() =>
              new Response(false, null,
                  new ApplicationError(111, `PaypalCompleteOrderUseCase unexpected error`, error)))
        }),
        map((response) => {
          return new Response(true, response);
        })
    );
  }

}
