<mat-card class="payment-selection-card">
    <mat-card-content class="bbo-medium-card">
        <div class="payment-selection-header">
            <span class="bbo-headline-3 bbo-headline-2-md-up">{{xlationCodes.paymentMethod | translate}}</span>
        </div>
        <form class="payment-selection-content" [formGroup]="form">
            <mat-radio-group class="payments-group" formControlName="paymentMethod">
                <div class="payments-group">
                    <div class="payment-selection-type">
                        <div class="payment-type-title">
                            <div class="bbo-body-1 font-weight-semibold">{{xlationCodes.creditCard | translate}}</div>
                        </div>
                        <bbo-radio-selector [selectable]="true" [label]="xlationCodes.creditCard| translate" prefixIcon="credit_card" (selectedItem)="selectCreditCard()" [selected]="form.controls.paymentMethod.value === 'credit-card'">
                            <ng-container radio-button>
                                <mat-radio-button class="bbo-radio-button" value="credit-card"></mat-radio-button>
                            </ng-container>
                        </bbo-radio-selector>
                    </div>
                    <div class="payment-selection-type" *ngIf="(isPaypalAvailable$ | async)">
                        <div class="bbo-body-1 font-weight-semibold">{{xlationCodes.otherWaysToPay | translate}}</div>
                        <bbo-radio-selector [selectable]="true" label="PayPal" prefixIcon="paypal" (selectedItem)="selectPaypal()" [selected]="form.controls.paymentMethod.value === 'paypal'">
                            <ng-container radio-button>
                                <mat-radio-button class="bbo-radio-button" value="paypal"></mat-radio-button>
                            </ng-container>
                        </bbo-radio-selector>
                    </div>
                </div>
                <button mat-flat-button
                        class="bbo-button width-100 go-to-checkout-button"
                        color="primary"
                        [disabled]="form.controls.paymentMethod.invalid"
                        (click)="completed()"
                >{{xlationCodes.continueToCheckout | translate}}</button>
            </mat-radio-group>
        </form>
    </mat-card-content>
</mat-card>
