import {Inject, Injectable} from "@angular/core";
import {IUseCase} from "src/base/usecase";
import {UserService} from '../../shared/services/user.service';
import {ApplicationError, IResponse, Response} from 'src/base/response';
import {catchError, map, Observable, throwError} from "rxjs";
import {HttpErrorResponse, HttpStatusCode} from "@angular/common/http";
import {BBO_PAYMENT_REPOSITORY, IPaymentRepository} from "../infrastructure/payment.interface";
import {CreditCard} from "../models";
import { GeneralStoreFacade } from "src/app/bbo-store/store";


export enum CreateCreditCardUseCaseError {
    LOCAL_UID_INVALID,
    BAD_REQUEST,
    GENERIC_ERROR
}


@Injectable()
export class CreateCreditCardUseCase implements IUseCase<CreditCard, Observable<IResponse<CreditCard>>> {

    constructor(
        @Inject(BBO_PAYMENT_REPOSITORY) private repository: IPaymentRepository,
        private userService: UserService,
        private generalStore: GeneralStoreFacade
    ) {}

    execute(data:CreditCard): Observable<IResponse<CreditCard>> {
        const localUid = this.userService.getUId();

        if (!localUid) {
            return throwError(() => new Response(
                false,
                null,
                new ApplicationError(CreateCreditCardUseCaseError.LOCAL_UID_INVALID, "Trying to create credit card for local user but uid is falsy")
            ));
        }

        return this.repository.createCreditCard(localUid ?? '', data).pipe(
            catchError((error) => {
                let applicationError: ApplicationError | null = null;
                if (error instanceof HttpErrorResponse && (error.status === HttpStatusCode.BadRequest || error.status === HttpStatusCode.UnprocessableEntity)) {
                    applicationError = new ApplicationError(
                        CreateCreditCardUseCaseError.BAD_REQUEST,
                        `${error.error.title}: ${error.error.detail}`,
                        error
                    )
                } else {
                    applicationError = new ApplicationError(
                        CreateCreditCardUseCaseError.GENERIC_ERROR,
                        "An error occured while trying to save the credit card for local user",
                        error
                    );
                }
                return throwError(() => new Response(false, null, applicationError));
            }),
            map((patch) => {
                this.generalStore.updateSavedCreditCard(patch);
                return new Response(
                    true,
                    data
                );
            })
        );
    }
}
