import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, tap} from 'rxjs';
import {IBalanceRepository} from './balance.interface';
import {environment} from '../../../environments/environment';
import {BalanceResponse} from '../domain/get-balance.usecase';

@Injectable()
export class BalanceRepository implements IBalanceRepository {

    constructor(
        private http: HttpClient
    ) {}

    getBalance(uid: string): Observable<BalanceResponse> {
        return this.http.get<BalanceResponse>(
            `${environment.apiURL}/users/${uid}/balance`,
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        ).pipe(
            tap((response) => {
                // eslint-disable-next-line no-magic-numbers
                response.amount = response.amount / 100; // we don't want to manipulate cents in the front app ...
            })
        );
    }
}
