import { BboPlusGetResponse } from "src/app/bbo-plus-home/infrastructure/bbo-plus.interface";
import {createGenericObjectReducer} from "../../../@core";
import {BboPlusActions, bboPlusActions} from "../actions";
import { on } from "@ngrx/store";

const {initialState, reducer} =
    createGenericObjectReducer<BboPlusGetResponse, BboPlusGetResponse, Partial<BboPlusGetResponse>>(bboPlusActions)(
        on(bboPlusActions.loadFail, (state, {error}) => ({
            ...state,
            data: null,
            loading: false,
            loaded: true,
            error
        })),
        on(bboPlusActions.submitSuccess, (state, {result}) => ({
            ...state,
            data: { ...result },
            result,
            loading: false,
            loaded: true,
            error: null
        })),
        on(bboPlusActions.submitFailure, (state, {error}) => ({
            ...state,
            loading: false,
            loaded: true,
            error
        })),
    );

export type BboPlusState = typeof initialState;
export {initialState};

export function bboPlusReducer(
    state: BboPlusState | undefined,
    action: BboPlusActions
) {
    return reducer(state, action);
}
