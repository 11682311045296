import {Directive, ElementRef, Input} from '@angular/core';
import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {SwipperDisplayService} from './services/swipper-display.service';

@Directive({
    selector: '[bboShouldDispplaySwipper]',
})
export class ShouldDisplaySwipperDirective {

    @Input() offersNumber: number | null = null;
    @Input() cardWidth = 224;

    constructor(
        private el: ElementRef,
        private swipperDisplayService: SwipperDisplayService
    ) {
        this.checkWidth();
        fromEvent(window, 'resize').pipe(
            // eslint-disable-next-line no-magic-numbers
            debounceTime(200)
        ).subscribe(() => {
            this.checkWidth();
        });
    }

    checkWidth(): void {
        setTimeout(() => {
            const viewportWidth = this.el.nativeElement?.getBoundingClientRect().width;
            if (this.offersNumber) {
                // eslint-disable-next-line no-magic-numbers
                const slidesWidth = this.offersNumber * this.cardWidth + (this.offersNumber - 1) * 20;
                const shouldDisplayedTheSwipper = slidesWidth > viewportWidth;
                this.swipperDisplayService.updateDisplayState(shouldDisplayedTheSwipper);
            }
        });
    }
}
