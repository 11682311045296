export interface IResponse<T> {
    success: boolean;
    data: T;
    error?: ResponseError | null;
}

export class Response<T> implements IResponse<T> {
    constructor(
        public success: boolean,
        public data: T,
        public error?: ResponseError | null
    ) {}
}

export interface ResponseError {
    code: number;
    message: string;
}

export class ApplicationError extends Error implements ResponseError {
    constructor(public code: number, message?: string, public innerError?: unknown) {
        super(message);
    }
}
