/* eslint-disable class-methods-use-this */
import { Injectable } from "@angular/core";
import * as braze from "@braze/web-sdk";
import { AnalyticsClient } from "./analytics.interface";
import { AnalyticEvent } from "./analytics.service";
import { environment } from "src/environments/environment";
import { EMPTY, Observable, of, ReplaySubject, switchMap } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class BrazeService implements AnalyticsClient {

    init(): void {
        if (!environment.braze?.apiKey) {
            throw new Error("environment.braze apiKey missing");
        }
        if (!environment.braze?.baseUrl) {
            throw new Error("environment.braze baseUrl missing");
        }
        braze.initialize(environment.braze.apiKey, {
            baseUrl: environment.braze.baseUrl,
        });
        braze.automaticallyShowInAppMessages();
        this.createFeatureFlagsSubscription();
        braze.openSession();
    }

    onUserLoggedIn(data: { username: string, userUniqueIdentifier: string; }): void {
        braze.changeUser(data.userUniqueIdentifier);
        braze.openSession();
        braze.refreshFeatureFlags();
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onLogout(): void {
        this.featureFlags$?.next([]);
    }

    trackEvent(event: AnalyticEvent): void {
        braze.logCustomEvent(event.name, event.data);
    }

    getFeatureFlag(flag: string): boolean {
        return !!(braze.getFeatureFlag(flag)?.enabled);
    }

    private featureFlags$ = new ReplaySubject<braze.FeatureFlag[]>(1);

    private createFeatureFlagsSubscription() {
        braze.subscribeToFeatureFlagsUpdates((update) => {
            this.featureFlags$.next(update);
        });
    }

    getFeatureFlagsStream(): Observable<braze.FeatureFlag[]> {
        return this.featureFlags$.asObservable();
    }

    getFeatureFlagStream(flagId: string): Observable<braze.FeatureFlag> {
        return this.featureFlags$.pipe(
            switchMap((flags) => {
                const flag = flags.find(item => item.id === flagId);
                return flag ? of(flag) : EMPTY;
            }),
        );
    }

    /**
     * @returns Whether or not the impression was successfully logged. Returns undefined if the SDK has not been initialized.
     */
    logFeatureFlagImpression(flag: string): boolean | undefined {
        return braze.logFeatureFlagImpression(flag);
    }
}
