import {Directive, ElementRef, HostBinding, Input, Renderer2} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[mat-button],[mat-flat-button],[mat-raised-button],[mat-stroked-button]',
})
export class ButtonLoaderDirective {

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("bboLoading") set loading(value: boolean | null | undefined) {
        if (value) {
            this.span = this.renderer.createElement("span");
            this.renderer.addClass(this.span, "bbo-3dots-loader")
            this.renderer.appendChild(this.elementRef.nativeElement, this.span);
            this.hideLabel = true;
        } else if (this.span) {
            this.renderer.removeChild(this.elementRef.nativeElement, this.span);
            this.span = undefined;
            this.hideLabel = false;
        }
    }

    @HostBinding("class.mat-button-hide-label") hideLabel = false;

    private span: any | undefined;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) { }
}
