import {createGenericObjectReducer} from "../../../@core";
import {SavedBillingAddressActions, savedBillingAddressActions} from "../actions";
import {BillingAddressGetResponse} from "../../../billing-address/infrastructure/billing-address.interface";

const {initialState, reducer} =
    createGenericObjectReducer<BillingAddressGetResponse, BillingAddressGetResponse, Partial<BillingAddressGetResponse>>(savedBillingAddressActions)();

export type SavedBillingAddressState = typeof initialState;
export {initialState};

export function savedBillingAddressReducer(
    state: SavedBillingAddressState | undefined,
    action: SavedBillingAddressActions
) {
    return reducer(state, action);
}
