import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    // eslint-disable-next-line class-methods-use-this
    public save(key: string, value: string): void {
        window.localStorage.setItem(key, value);
    }

    // eslint-disable-next-line class-methods-use-this
    public remove(key: string): void {
        window.localStorage.removeItem(key);
    }

    // eslint-disable-next-line class-methods-use-this
    public get(key: string): string | null {
        return window.localStorage.getItem(key);
    }
}
