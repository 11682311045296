import {ActionReducerMap} from "@ngrx/store";
import {
    oneTimePurchaseFlowReducer,
    OneTimePurchaseFlowState
} from "./one-time-purchase-flow.reducer";
import {
    autoRefillSubscriptionFlowReducer,
    AutoRefillSubscriptionFlowState
} from "./auto-refill-subscription-flow.reducer";

export interface ProductState {
    readonly oneTimePurchaseFlow: OneTimePurchaseFlowState;
    readonly autoRefillSubscriptionFlow:AutoRefillSubscriptionFlowState;
}

export const PRODUCT_STORE_NAME = 'product';

export interface ProductStateSlice {
    readonly [PRODUCT_STORE_NAME]: ProductState;
}

export const oneTimePurchaseFlowReducers: ActionReducerMap<ProductState, any> = {
    oneTimePurchaseFlow: oneTimePurchaseFlowReducer,
    autoRefillSubscriptionFlow: autoRefillSubscriptionFlowReducer
}
