import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SwipperDisplayService {

    shouldDisplayTheSwipper$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    updateDisplayState(shouldDisplay: boolean): void {
        if (this.shouldDisplayTheSwipper$.value !== shouldDisplay) {
            this.shouldDisplayTheSwipper$.next(shouldDisplay);
        }
    }
}
