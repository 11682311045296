import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {XlationCodes} from "../../translations/xlation.codes";

@Component({
    selector: 'bbo-general-error-modal',
    templateUrl: './general-error-modal.component.html',
    styleUrls: ['./general-error-modal.component.scss']
})
export class GeneralErrorModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: { errorMessage: string, title: string }) {
    }

    protected readonly xlationCodes = XlationCodes;
}
