<div class="bbo-medium-card">
    <div class="card-header">
        <div class="bbo-headline-4 bbo-headline-3-sm-up bbo-headline-2-md-up">{{xlationCodes.creditCardErrorModalTitle | translate}}</div>
    </div>
    <div class="card-content">
        <div class="description">{{xlationCodes.creditCardErrorModalDescription | translate}}</div>
        <div class="details-container">
            <div class="detail">Error code:&nbsp;400</div>
            <div class="detail">Status:&nbsp;{{data.errorMessage.status}}</div>
            <div class="detail">Reason:&nbsp;{{data.errorMessage.reason}}</div>
        </div>
        <div class="description">{{xlationCodes.creditCardErrorModalCallSupport | translate}}</div>
        <div class="action-container">
            <button mat-flat-button class="bbo-button" color="primary" mat-dialog-close>{{xlationCodes.creditCardErrorModalCloseButton | translate}}</button>
        </div>
    </div>
</div>