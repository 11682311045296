import { GetPaymentHistoryUseCase, PaymentHistoryItem } from './domain/get-payment-history.usecase';
import { Response } from 'src/base/response';
import { Subject, map, takeUntil } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { BboStoreHeaderService } from '../bbo-store-header/service/bbo-store-header.service';
import { PaymentHistoryFlowFacade } from './store';
import { Option } from '../@core';
import { XlationCodes } from '../shared/translations/xlation.codes';

@Component({
    selector: 'bbo-payment-history',
    templateUrl: './payment-history.component.html',
    styleUrls: ['./payment-history.component.scss']
})

export class PaymentHistoryComponent implements OnInit, OnDestroy {
    xlationCodes = XlationCodes;
    DISPLAYED_STATUS = new Map<string, string>([
        ['paid', XlationCodes.paid],
        ['failed', XlationCodes.failed]
    ]);

    DISPLAYED_METHOD = new Map<string, string>([
        ['cybersource', XlationCodes.creditCard],
        ['paypal', 'Paypal']
    ]);

    displayedColumns: any[] = ['type', 'date', 'method', 'status', 'amount'];
    dataSource = new MatTableDataSource<PaymentHistoryItem>([]);
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    selectedHistoryItem: Option<PaymentHistoryItem> = null;

    // on small resolutions we hide the table if a history item is selected.
    shouldHideHistoryList$ = this.responsive.observe(Breakpoints.XSmall)
        .pipe(map((result: BreakpointState) => result.matches));

    // used to hide some table informations for small resoltions
    lessDetailsInHistoryList$ = this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small])
        .pipe(map((result: BreakpointState) => result.matches));

    private unsubscribeSubject: Subject<void> = new Subject<void>();

    constructor(
        private readonly getPaymentHistoryUseCase: GetPaymentHistoryUseCase,
        private readonly responsive: BreakpointObserver,
        private readonly bboStoreHeaderService: BboStoreHeaderService,
        private readonly store: PaymentHistoryFlowFacade
    ) {}

    ngOnInit(): void {
        this.getPaymentHistoryUseCase.execute()
            .pipe(
                map((response: Response<PaymentHistoryItem[]>) => response.data)
            ).subscribe((history: PaymentHistoryItem[]) => {
                history.map((paymentHistoryItem: PaymentHistoryItem) => {
                    paymentHistoryItem.displayedMethod = this.DISPLAYED_METHOD.get(paymentHistoryItem.method) ?? paymentHistoryItem.method;
                    paymentHistoryItem.displayedStatus = this.DISPLAYED_STATUS.get(paymentHistoryItem.status) ?? paymentHistoryItem.status;
                    return paymentHistoryItem;
                })
                this.dataSource = new MatTableDataSource<PaymentHistoryItem>(history);
                this.dataSource.paginator = this.paginator;
            });
        this.store.selectedPaymentHistoryItem$
            .pipe(takeUntil(this.unsubscribeSubject))
            .subscribe((selectedHistoryItem: PaymentHistoryItem | undefined) => {
                this.selectedHistoryItem = selectedHistoryItem;
            });
        this.updateHeaderBackAction();
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }

    onHistoryItemClick(selectedPaymentHistoryItem: PaymentHistoryItem): void {
        if (this.selectedHistoryItem?.id !== selectedPaymentHistoryItem.id) {
            this.store.updateFlow({ selectedPaymentHistoryItem });
        } else {
            this.store.updateFlow({ selectedPaymentHistoryItem: undefined });
        }
        this.updateHeaderBackAction();
    }

    updateHeaderBackAction(): void {
        this.bboStoreHeaderService.setHeaderBackAction({
            fontIcon: "arrow_back",
            className: "back-action",
            callback: () => (this.selectedHistoryItem) ? this.resetSelectedHistoryItem() : this.bboStoreHeaderService.backToHomePage()
        })
    }

    resetSelectedHistoryItem(): void {
        this.store.updateFlow({ selectedPaymentHistoryItem: undefined });
        this.updateHeaderBackAction()
    }
}
