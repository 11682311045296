<mat-card class="product-one-time-purchase">
    <mat-card-content class="bbo-medium-card">
        <article>
            <div class="product-head">
                <mat-icon svgIcon="oneoff-purchase"></mat-icon>
                <span class="bbo-headline-3">{{xlationCodes.buyBB$ | translate}}</span>
            </div>
            <div *ngIf="layout === 'normal'" class="bbo-body-1 font-weight-semibold divider-after">{{xlationCodes.oneOffPurchase | translate}}</div>
            <div class="bbo-body-2 color-gray">
                {{
                    (
                        layout === 'normal'
                        ? xlationCodes.buyBB$Description
                        : xlationCodes.buyBB$DescriptionShort
                    ) | translate
                }}
            </div>
            <div class="first-time-purchase" *ngIf="isFirstTimePurchaseAvailable">
                <mat-icon svgIcon="first-time-purchase"></mat-icon>
                <span class="bbo-body-2">
                    {{xlationCodes.firstTimePurchaseHomepage | translate}}
                </span>
            </div>
            <div class="product-actions">
                <button mat-flat-button class="bbo-button width-100" color="primary" routerLink="/buy-bb$">{{xlationCodes.buyBB$ | translate}}</button>
            </div>
        </article>
    </mat-card-content>
</mat-card>