import {ModuleWithProviders, NgModule} from '@angular/core';
import {BBO_BILLING_ADDRESS_REPOSITORY} from './infrastructure/billing-address.interface';
import {BillingAddressRepository} from './infrastructure/billing-address.repository';
import {
    BillingAddressFormComponent
} from 'src/app/billing-address/ui/billing-address-form/billing-address-form.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {GetBillingAddressUseCase} from 'src/app/billing-address/domain/get-billing-address.usecase';
import {MatSelectModule} from '@angular/material/select';
import {SaveBillingAddressUseCase} from 'src/app/billing-address/domain/save-billing-address.usecase';
import { TranslateModule } from '../shared/translations/translate.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatSelectModule,
        TranslateModule
    ],
    declarations: [
        BillingAddressFormComponent
    ],
    exports: [
        BillingAddressFormComponent
    ]
})
export class BillingAddressModule {
    static forRoot(): ModuleWithProviders<BillingAddressModule> {
        return {
            ngModule: BillingAddressModule,
            providers: [
                {provide: BBO_BILLING_ADDRESS_REPOSITORY, useClass: BillingAddressRepository},
                GetBillingAddressUseCase,
                SaveBillingAddressUseCase
            ]
        };
    }
}
