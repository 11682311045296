import { Component } from '@angular/core';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';

@Component({
    selector: 'bbo-product-bbo-plus',
    templateUrl: './product-bbo-plus.component.html',
    styleUrls: ['./product-bbo-plus.component.scss']
})
export class ProductBboPlusComponent {
    xlationCodes = XlationCodes;
}
