import {createGenericObjectActions, EntityAction} from "../../../@core";
import {BillingAddressGetResponse} from "../../../billing-address/infrastructure/billing-address.interface";

export interface SavedBillingAddressStore {
    readonly address: BillingAddressGetResponse;
}

export const savedBillingAddressActions =
    createGenericObjectActions<BillingAddressGetResponse, BillingAddressGetResponse, Partial<BillingAddressGetResponse>>('Saved Billing Address');
export type SavedBillingAddressActions = EntityAction<BillingAddressGetResponse, BillingAddressGetResponse, Partial<BillingAddressGetResponse>>;
