import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {map, shareReplay, Subject, takeUntil, tap} from 'rxjs';
import {AbstractControl, FormBuilder, FormControl, Validators} from '@angular/forms';
import {PaypalResourceCheckUseCase} from "../../../paypal";
import {CreditCard, creditCardInfoToPayment, Payment} from 'src/app/payment';
import {Option} from "../../../@core";
import {GeneralStoreFacade} from 'src/app/bbo-store/store';
import {XlationCodes} from '../../../shared/translations/xlation.codes';
import {CreditCardService} from "../../../payment/service/credit-card.service";
import { BillingAddressRequest } from 'src/app/billing-address/infrastructure/billing-address.interface';

@Component({
    selector: 'bbo-payment-selection',
    templateUrl: './payment-selection.component.html',
    styleUrls: ['./payment-selection.component.scss']
})
export class PaymentSelectionComponent implements OnInit, OnDestroy {
    @Input() paypal = false;
    @Input() enableDifferentCardPayment = true;
    @Input() enableSaveNewCard = false;
    @Input() shouldSaveCreditCard = false;
    xlationCodes = XlationCodes;
    @Output() userSelectsHisPayment: EventEmitter<Payment> = new EventEmitter();
    @Output() userSelectsHisBillingAddress: EventEmitter<BillingAddressRequest> = new EventEmitter();
    selectedCreditCard: Option<CreditCard>;
    readonly savedCreditCardLoading$ = this.generalStoreFacade.savedCreditCardLoading$;

    private unsubscribeSubject: Subject<void> = new Subject<void>();

    readonly form = this.formBuilder.group({
        paymentMethod: new FormControl<string | null>(null, [Validators.required])
    });

    readonly creditCard$ = this.generalStoreFacade.storedCreditCard$.pipe(
        takeUntil(this.unsubscribeSubject),
        tap((card) => {
            this.selectedCreditCard = card;
            const paymentMethod = (this.selectedCreditCard) ? "credit-card" : null;
            this.form.controls["paymentMethod"].setValue(paymentMethod);
            this.form.updateValueAndValidity();
        }),
        shareReplay(1) // because multiple async pipe in the template -> trigger request once
    );

    readonly isPaypalAvailable$ =
        this.paypalResource.execute().pipe(takeUntil(this.unsubscribeSubject), map(value => value.data.active));

    constructor(
        private formBuilder: FormBuilder,
        private readonly generalStoreFacade: GeneralStoreFacade,
        private readonly paypalResource: PaypalResourceCheckUseCase,
        private readonly creditCardService: CreditCardService
    ) {
    }

    ngOnInit(): void {
        this.generalStoreFacade.loadSavedCreditCard();
        this.generalStoreFacade.loadSavedBillingAddress();
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }

    goToCheckout(): void {
        const paymentMethod = this.form.controls["paymentMethod"].value;
        // in case of the user doesn't have any credit card registered and directly click on continue to checkout
        if (!paymentMethod) {
            // maybe disable the button in case of no payment method selected to prevent this case
            return;
        }
        this.userSelectsHisPayment.emit({method: paymentMethod as any, creditCard: this.selectedCreditCard});
    }

    onPaymentItemClicked(event: Payment) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.form.controls["paymentMethod"].setValue(event.method!);
        this.selectedCreditCard = event.creditCard as CreditCard;
    }

    @ViewChild('forml', {read: TemplateRef}) formTemplate?: TemplateRef<any>;

    onAddCreditCardClicked(): void {
        this.creditCardService.openFillFormModal({showSaveCard: this.enableSaveNewCard, shouldSaveCreditCard: this.shouldSaveCreditCard}).pipe()
            .subscribe((result) => {
                if (result.saveCardDetails) {
                    this.generalStoreFacade.updateSavedBillingAddress(result.billingAddress as BillingAddressRequest);
                    this.generalStoreFacade.replaceSavedCreditCard(result.creditCard as CreditCard);
                } else {
                    this.userSelectsHisBillingAddress.emit(result.billingAddress);
                    this.userSelectsHisPayment.emit(creditCardInfoToPayment({
                        creditCard: result.creditCard as CreditCard,
                        billingAddress: result.billingAddress as BillingAddressRequest
                    }));
                }
            });
    }

    onUseADifferentCreditCard(): void {
        this.creditCardService.openModalUseDifferentCard();
    }

    onEditCreditCardClicked(): void {
        this.creditCardService.openModalEditSavedCard();
    }
}
