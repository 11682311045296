<mat-card class="product-bbo-plus">
    <mat-card-content class="bbo-medium-card">
        <article>
            <div class="bbo-body-2 color-gray">
                {{xlationCodes.discoverBboPlus| translate}}
            </div>
            <div class="product-actions">
                <button mat-flat-button class="bbo-button width-100" color="purple" routerLink="/bbo-plus">{{xlationCodes.discoverBboPlusButtonLabel | translate}}</button>
            </div>
        </article>
    </mat-card-content>
</mat-card>