import { Inject, Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ApplicationError, Response } from 'src/base/response';
import { IUseCase } from 'src/base/usecase';
import { BBO_PAYMENT_HISTORY_REPOSITORY, IPaymentHistoryRepository } from '../infrastructure/payment-history.interface';
import { UserService } from 'src/app/shared/services/user.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

export interface PaymentHistoryItem {
    id: number,
    owner: string,
    dateTime: string,
    type: string,
    method: string,
    displayedMethod: string,
    status: string,
    displayedStatus: string,
    amount: number
}

export enum GetPaymentHistoryUseCaseError {
    LOCAL_UID_INVALID,
    GENERIC_ERROR,
    NOT_FOUND
}


@Injectable({
    providedIn: "root"
})
export class GetPaymentHistoryUseCase implements IUseCase<void, Observable<Response<PaymentHistoryItem[]>>> {

    constructor(
        @Inject(BBO_PAYMENT_HISTORY_REPOSITORY) private repository: IPaymentHistoryRepository,
        private readonly userService: UserService
    ) {}

    execute(): Observable<Response<PaymentHistoryItem[]>> {
        const localUid = this.userService.getUId();

        if (!localUid) {
            return throwError(() => new Response(
                false,
                null,
                new ApplicationError(GetPaymentHistoryUseCaseError.LOCAL_UID_INVALID, "Trying to get billing address for local user but uid is falsy")
            ));
        }

        return this.repository.getPaymentHistory(localUid).pipe(
            catchError((error) => {
                let applicationError: ApplicationError | null = null;
                if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.NotFound) {
                    applicationError = new ApplicationError(
                        GetPaymentHistoryUseCaseError.NOT_FOUND,
                        'getPaymentHistory informations not found for uid ' + localUid,
                    )
                } else {
                    applicationError = new ApplicationError(
                        GetPaymentHistoryUseCaseError.GENERIC_ERROR,
                        "Fail trying to get payment history informations for local user"
                    );
                }
                return throwError(() => new Response(
                    false,
                    null,
                    applicationError
                ))
            }),
            map((paymentHistory: PaymentHistoryItem[]) => {
                return new Response(
                    true,
                    paymentHistory,
                    null
                );
            }),
        );
    }

}