import {IUseCase} from "../../../base/usecase";
import {catchError, map, Observable, throwError} from "rxjs";
import {ApplicationError, IResponse, Response} from "../../../base/response";
import {Inject, Injectable} from "@angular/core";
import {BBO_PRODUCT_REPOSITORY, IProductRepository} from "../infrastructure/product.interface";
import {GetCurrentAutoRefillPlanUseCaseError} from "./get-current-auto-refill-plan.usecase";


export interface ProductAutoRefillPlanOffer {
  id: number,
  amount: number,
  threshold: number,
  recommended: boolean,
  outdated?: boolean,
  selected?: boolean
}

@Injectable({providedIn: 'root'})
export class GetAutoRefillOffersUseCase implements IUseCase<void, Observable<IResponse<ProductAutoRefillPlanOffer[]>>> {

  constructor(@Inject(BBO_PRODUCT_REPOSITORY) private repository: IProductRepository,) {
  }

  execute(): Observable<IResponse<ProductAutoRefillPlanOffer[]>> {
    return this.repository.getAutoRefillOptions().pipe(
        map((offers) => {
            offers.map(el => el.outdated = false);
            return offers;
        }),
      map((response) => {
        return new Response(
          true,
          response
        ) as IResponse<ProductAutoRefillPlanOffer[]>
      }),
      catchError((error) => {
        // already handled: skip
        if (error instanceof Response) {
          return throwError(() => error);
        }
        return throwError(() => new Response(
          false,
          null,
          new ApplicationError(
            // TODO check can we use something generic for every domain
            GetCurrentAutoRefillPlanUseCaseError.GENERIC_ERROR,
            'getAutoRefillOffers usecase unexpected error',
            error
          )
        ));
      })
    );
  }

}
