import {Component, HostBinding, Input} from '@angular/core';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';

@Component({
  selector: 'bbo-current-autorefill-card',
  templateUrl: './current-auto-refill-card.component.html',
  styleUrls: ['./current-auto-refill-card.component.scss']
})
export class CurrentAutoRefillCardComponent {
    xlationCodes = XlationCodes;
    // eslint-disable-next-line no-magic-numbers
    @Input() price = 0;
    // eslint-disable-next-line no-magic-numbers
    @Input() bboCurrencyAmount = 0;

    @Input() @HostBinding('class.outdated') outdated = false;
    // eslint-disable-next-line no-magic-numbers
    @Input() refillTreshold = 0;
}
