import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {XlationCodes} from "../shared/translations/xlation.codes";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {GeneralStoreFacade} from "../bbo-store/store";
import {CreditCardService} from "../payment/service/credit-card.service";
import { CreditCard } from '../payment/models';
import { BillingAddressRequest } from '../billing-address/infrastructure/billing-address.interface';

@Component({
    selector: 'bbo-payment-methods',
    templateUrl: './payment-methods.component.html',
    styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {
    xlationCodes = XlationCodes;

    readonly form = this.formBuilder.group({
        paymentMethod: new FormControl<string | null>(null, Validators.required)
    });

    readonly creditCard$ = this.generalStoreFacade.storedCreditCard$;
    readonly loading$ = this.generalStoreFacade.savedCreditCardLoading$;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly generalStoreFacade: GeneralStoreFacade,
        private readonly creditCardService: CreditCardService
    ) {
    }

    ngOnInit(): void {
        this.generalStoreFacade.loadSavedCreditCard();
        this.generalStoreFacade.loadSavedBillingAddress();
    }

    @ViewChild('forml', {read: TemplateRef}) formTemplate?: TemplateRef<any>;

    onAddCreditCardClicked(): void {
        this.creditCardService.openFillFormModal({showSaveCard: false})
            .subscribe((result) => {
                this.generalStoreFacade.updateSavedBillingAddress(result.billingAddress as BillingAddressRequest);
                this.generalStoreFacade.replaceSavedCreditCard(result.creditCard as CreditCard);
            });
    }

    onEditCreditCardClicked(): void {
        this.creditCardService.openModalEditSavedCard();
    }
}
