import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from '../product/ui/home/home.component';
import {BboStoreComponent} from './bbo-store.component';
import {AutoRefillSubscriptionComponent} from '../auto-refill-subsription/auto-refill-subscription.component';
import {PaymentHistoryComponent} from '../payment-history/payment-history.component';
import {OneTimePurchaseComponent} from 'src/app/product/ui/one-time-purchase/one-time-purchase.component';
import { PaymentMethodsComponent } from '../payment-methods/payment-methods.component';
import { canDeactivateComponent } from '../@core/one-time-purchase-flow-in-progress.guard';
import { BboPlusComponent } from '../bbo-plus-home/bbo-plus/bbo-plus.component';
import { BboPlusSubscribeComponent } from '../bbo-plus-home/bbo-plus-subscribe/bbo-plus-subscribe.component';
import { BboPlusHomeComponent } from '../bbo-plus-home/bbo-plus-home.component';

const routes: Routes = [
    {
        path: "",
        component: BboStoreComponent,
        children: [{
            path: "",
            component: HomeComponent
        },
        {
            path: "auto-refill",
            component: AutoRefillSubscriptionComponent,
            canDeactivate: [canDeactivateComponent]
        },
        {
            path: "buy-bb$",
            component: OneTimePurchaseComponent,
            canDeactivate: [canDeactivateComponent]
        },
        {
            path: "payment-history",
            component: PaymentHistoryComponent
        },
        {
            path: "payment-methods",
            component: PaymentMethodsComponent
        },
        {
            path: "bbo-plus",
            component: BboPlusHomeComponent,
            children: [
                {
                    path: "",
                    component: BboPlusComponent
                },
                {
                    path: "subscribe",
                    component: BboPlusSubscribeComponent
                },
                {
                    path: "**",
                    redirectTo: ""
                }
            ]
        },
        {
            path: "**",
            redirectTo: ""
        }]
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BboStoreRoutingModule { }
