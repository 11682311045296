/* eslint-disable no-magic-numbers */
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { tap } from 'rxjs';
import { OfferTypes, ProductOneTimePurchaseOffer } from 'src/app/product/domain/get-one-time-purchase-offers.usecase';
import { SwipperDisplayService } from 'src/app/shared/services/swipper-display.service';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';
import Swiper from 'swiper';

@Component({
  selector: 'bbo-one-time-purchase-list',
  templateUrl: './one-time-purchase-list.component.html',
  styleUrls: ['./one-time-purchase-list.component.scss']
})
export class OneTimePurchaseListComponent {
    xlationCodes = XlationCodes;
    OfferTypes = OfferTypes;
    @Input()
    set offers(value: ProductOneTimePurchaseOffer[]) {
        this._offers = value;

        let slideToIndex = 0;

        const recommendedIndex = value.findIndex((offer) => offer.recommended);
        const firstTimePurchaseIndex = value.findIndex((offer) => offer.type === OfferTypes.firstTimePurchase);
        if (firstTimePurchaseIndex > -1) {
            slideToIndex = firstTimePurchaseIndex;
        } else if (recommendedIndex > -1) {
            slideToIndex = recommendedIndex;
        } else { // no first time purchase offer neither recommended offer, let's pick the middle offer
            const middleIndex = Math.floor(Math.max(value.length - 1, 0) / 2);
            slideToIndex = middleIndex;
        }

        this.initialSlideIndex = slideToIndex;
    }

    readonly shouldDisplaySwipper$ = this.swipperDisplayService.shouldDisplayTheSwipper$.pipe(
        tap((shouldDisplaySwipper: boolean) => {
            if (shouldDisplaySwipper) {
                setTimeout(() => {
                    this.updateSwipper();
                });
            }
        })
    )

    _offers : ProductOneTimePurchaseOffer[] = [];

    @Output() userWantsToBuy = new EventEmitter<ProductOneTimePurchaseOffer>();

    @ViewChild("swiper") swiper: ElementRef<unknown> | undefined;

    initialSlideIndex = 0;

    constructor(
        private readonly swipperDisplayService: SwipperDisplayService,
        private readonly cdr: ChangeDetectorRef
    ) { }

    updateSwipper(): void {
        const swiper = (this.swiper?.nativeElement as {swiper?: Swiper})?.swiper;
        if (swiper) {
            swiper.slideTo(this.initialSlideIndex);
            swiper.scrollbar.setTranslate();
        }
    }

    // eslint-disable-next-line class-methods-use-this
    getImg(i: number): string {
        /* eslint-disable no-magic-numbers */
        const modulo = i % 4;
        if (modulo === 0)
            return 'diamond';
        if (modulo === 1)
            return 'club';
        if (modulo === 2)
            return 'spade';
        return 'heart';
    }

}
