import {Injectable} from "@angular/core";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {
    CreditCardFormDialogComponent,
    CreditCardFormDialogInput,
    CreditCardFormDialogOutput
} from "src/app/payment/ui/credit-card-form-dialog/credit-card-form-dialog.component";
import {GeneralStoreFacade} from "src/app/bbo-store/store";
import {isNotNil} from "src/app/@core";
import {filter, take} from "rxjs/operators";
import {CreditCard} from "../models";
import {BillingAddressRequest} from "../../billing-address/infrastructure/billing-address.interface";
import {Observable, shareReplay, Subject, takeUntil, zip} from "rxjs";
import {CreditCardEditRequest} from "../domain/edit-credit-card.usecase";
import {OneTimePurchaseFlowFacade} from "src/app/product/store";

@Injectable({
    providedIn: 'root'
})
export class CreditCardService {

    private unsubscribeSubject: Subject<void> = new Subject<void>();

    private readonly modalLayoutConfig: Partial<MatDialogConfig> = {
        backdropClass: 'bbo-backdrop-blurred',
        // visually-hidden -> hide the element until the setup is finished
        // to avoid flash of content
        panelClass: ['bbo-dialog', 'bbo-scroll-dialog'],
        ariaModal: true,
        autoFocus: false,
    }


    readonly creditCard$ = this.facade.storedCreditCard$.pipe(
        takeUntil(this.unsubscribeSubject),
        filter(isNotNil),
        shareReplay(1) // because multiple async pipe in the template -> trigger request once
    );

    readonly storedBillingAddress$ = this.facade.billingAddress$;

    constructor(
        private readonly facade: GeneralStoreFacade,
        private readonly matDialog: MatDialog,
        private readonly oneTimePurchaseFacade: OneTimePurchaseFlowFacade
    ) {
    }


    openFillFormModal(data?:CreditCardFormDialogInput): Observable<CreditCardFormDialogOutput> {
        return this.matDialog.open<CreditCardFormDialogComponent,
            CreditCardFormDialogInput,
            CreditCardFormDialogOutput
        >(CreditCardFormDialogComponent,
            {...this.modalLayoutConfig, data})
            .afterClosed().pipe(filter(isNotNil));
    }

    openModalUseDifferentCard(){
        this.openFillFormModal({isOneTimeBuying: true})
            .subscribe((payload: CreditCardFormDialogOutput) => {
                this.oneTimePurchaseFacade.updateFlow({
                    selectedPayment: {
                        method: 'credit-card',
                    },
                    billingAddress: payload.billingAddress
                })
                this.oneTimePurchaseFacade.updateCreditCard(payload.creditCard as CreditCard, true);
            })
    }

    openModalEditSavedCard(): boolean | void {
        this.facade.loadSavedCreditCard();
        this.facade.loadSavedBillingAddress();
        zip([
            this.creditCard$.pipe(take(1)),
            this.storedBillingAddress$.pipe(take(1))
        ])
            .subscribe(value => {
                this.matDialog.open <CreditCardFormDialogComponent,
                    CreditCardFormDialogInput,
                    CreditCardFormDialogOutput
                >(CreditCardFormDialogComponent,
                    {
                        ...this.modalLayoutConfig,
                        data: {
                            creditCard: value[0],
                            billingAddress: value[1]
                        }
                    }).afterClosed()
                    .pipe(filter(isNotNil))
                    .subscribe((value) => {
                        if (isNotNil(value.creditCard)) {
                            this.facade.updateSavedCreditCard(value?.creditCard as CreditCardEditRequest)
                            this.facade.updateSavedBillingAddress(value?.billingAddress as BillingAddressRequest)
                        }
                        if (value.deleteCard) {
                            return value.deleteCard;
                        }
                        return false;
                    })
            })
    }
}
