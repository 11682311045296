<div [formGroup]="creditCardForm">
    
    <!-- template is going to be used to create cybersource secure inputs -->
    <ng-template #cybersourceFormFieldTemplate let-label="label" let-formControl="formControl">
        <div #cybersourceFormFieldContainer class="bbo-form-field">
            <label class="bbo-form-label">{{label}}</label>
            <mat-form-field appearance="outline">
                <!-- label for=?? -->
                <input matInput [formControl]="formControl"> <!-- We won't use it, explanation in the ts file -->
                <mat-hint class="hint bbo-body-2" *ngIf="formControl === creditCardCVVFormControl">
                    <mat-icon>info</mat-icon>
                    <div class="label">{{xlationCodes.cvvHint | translate}}</div>
                </mat-hint>
                <mat-error *ngIf="formControl.hasError('invalidCardNumber')">{{xlationCodes.formInvalidCardNumberError | translate}}</mat-error>
                <mat-error *ngIf="formControl.hasError('missingDigits')">{{xlationCodes.formMissingDigitsError | translate}}</mat-error>
                <mat-error *ngIf="formControl.hasError('required')">{{xlationCodes.formRequiredError | translate}}</mat-error>
            </mat-form-field>
        </div>
    </ng-template>

    <!-- card type -->
    <div class="bbo-form-field">
        <label class="bbo-form-label" id="label-card-type">{{xlationCodes.cardType | translate}}</label>
        <mat-form-field appearance="outline">
            <mat-select formControlName="type" aria-labelledby="label-card-type">
                <mat-option *ngFor="let provider of CARD_PROVIDERS" [value]="provider.id">{{provider.label}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="bbo-meta-form-field">
        <!-- first name on card -->
        <div class="bbo-form-field">
            <label class="bbo-form-label" for="first-name">{{xlationCodes.holderFirstName | translate}}</label>
            <mat-form-field appearance="outline">
                <input id="first-name" formControlName="firstName" autocomplete="family-name" matInput [placeholder]="xlationCodes.holderFirstNamePlaceholder | translate">
                <mat-error *ngIf="creditCardHolderFirstNameFormControl?.hasError('required')">{{xlationCodes.formRequiredError | translate}}</mat-error>
            </mat-form-field>
        </div>
    
        <!-- last name on card -->
        <div class="bbo-form-field">
            <label class="bbo-form-label" for="last-name">{{xlationCodes.holderLastName | translate}}</label>
            <mat-form-field appearance="outline">
                <input id="last-name" formControlName="lastName" autocomplete="given-name" matInput [placeholder]="xlationCodes.holderLastNamePlaceholder | translate">
                <mat-error *ngIf="creditCardHolderLastNameFormControl?.hasError('required')">{{xlationCodes.formRequiredError | translate}}</mat-error>
            </mat-form-field>
        </div>
    </div>

    <!-- card number - cybersource secure input -->
    <div [id]="CYBERSOURCE_CARD_NUMBER_FIELD_ID" class="cybersource-container container-hide"></div>
    <ng-container
        *ngTemplateOutlet="
            cybersourceFormFieldTemplate;
            context: {label: xlationCodes.cardNumber | translate, formControl: creditCardNumberFormControl}
        "
    ></ng-container>

    <!-- expiration date -->
    <div class="bbo-meta-form-field">
        <div class="bbo-form-field">
            <label class="bbo-form-label" for="expiration-date">{{xlationCodes.expirationDate | translate}}</label>
            <mat-form-field appearance="outline">
                <input id="expiration-date" bboExpirationDateInputType formControlName="expirationDate" maxlength="7" autocomplete="cc-exp" [min]="minExpirationDate" matInput [matDatepicker]="datepicker" placeholder="MM/YYYY">
                <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker
                    startView="multi-year"
                    (yearSelected)="onYearSelected($event)"
                    (monthSelected)="onMonthSelected($event, datepicker)"
                ></mat-datepicker>
                <mat-error *ngIf="creditCardExpirationDateFormControl?.hasError('required')">{{xlationCodes.formRequiredError | translate}}</mat-error>
                <mat-error *ngIf="creditCardExpirationDateFormControl?.hasError('matDatepickerMin')">{{xlationCodes.expiredCreditCardExpirationDateError | translate}}</mat-error>
            </mat-form-field>
        </div>
        <div class="bbo-form-field">
            <!-- security code - cybersource secure input -->
            <div [id]="CYBERSOURCE_CVV_FIELD_ID" class="cybersource-container container-hide"></div>
            <ng-container
                *ngTemplateOutlet="
                cybersourceFormFieldTemplate;
                    context: {label: securityCodeFormFieldLabel, formControl: creditCardCVVFormControl}
                "
            ></ng-container>
        </div>
    </div>
</div>