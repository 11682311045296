import { XlationCodes } from "../shared/translations/xlation.codes";

export const BBO_PLUS_ADVANTAGES_LIST = [{
    titleTranslationKey: XlationCodes.videoChat,
    descriptionTranslationKey: XlationCodes.videoChatDescription,
}, {
    titleTranslationKey: XlationCodes.advancedRobots,
    descriptionTranslationKey: XlationCodes.advancedRobotsDescription,
}, {
    titleTranslationKey: XlationCodes.customRobots,
    descriptionTranslationKey: XlationCodes.customRobotsDescription,
}, {
    titleTranslationKey: XlationCodes.bridgerama,
    descriptionTranslationKey: XlationCodes.bridgeramaDescription,
}, {
    titleTranslationKey: XlationCodes.bboPlusLounge,
    descriptionTranslationKey: XlationCodes.bboPlusLoungeDescription,
}, {
    titleTranslationKey: XlationCodes.advertFree,
    descriptionTranslationKey: XlationCodes.advertFreeDescription,
}];