import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {ProductState} from "../reducers";
import {AutoRefillSubscriptionFlow, autoRefillSubscriptionFlowActions} from "../actions";
import {autoRefillSubscriptionFlowSelectors} from "../selectors";

@Injectable({
    providedIn: "root"
})
export class AutoRefillSubscriptionFlowFacade {

    public readonly flowState$ = this.store.select(autoRefillSubscriptionFlowSelectors.data);
    public readonly selectedPlan$ = this.store.select(autoRefillSubscriptionFlowSelectors.selectedPlan)
    public readonly paypalOrderId$ = this.store.select(autoRefillSubscriptionFlowSelectors.paypalOrderId)
    public readonly selectedCreditCard$ = this.store.select(autoRefillSubscriptionFlowSelectors.selectedCreditCard);
    public readonly selectedPaymentMethod$ = this.store.select(autoRefillSubscriptionFlowSelectors.selectedPaymentMethod);
    public readonly selectedPlanPrice$ = this.store.select(autoRefillSubscriptionFlowSelectors.selectedPlanPrice);
    public readonly selectedPlanPriceTax$ = this.store.select(autoRefillSubscriptionFlowSelectors.paymentTaxes);
    public readonly selectedPayment$ = this.store.select(autoRefillSubscriptionFlowSelectors.selectedPayment);
    public readonly submitError$ = this.store.select(autoRefillSubscriptionFlowSelectors.error);
    public readonly isLoading$ = this.store.select(autoRefillSubscriptionFlowSelectors.loading);
    public readonly submitResult$ = this.store.select(autoRefillSubscriptionFlowSelectors.result);
    public readonly selectedPlanTreshold$ = this.store.select(autoRefillSubscriptionFlowSelectors.selectedPlanTreshold)

    constructor(private readonly store: Store<ProductState>) {
    }

    updateFlow(patch: Partial<AutoRefillSubscriptionFlow>) {
        this.store.dispatch(autoRefillSubscriptionFlowActions.update({payload: {patch}}));
    }

    resetFlow() {
        this.store.dispatch(autoRefillSubscriptionFlowActions.reset());
    }

    submit() {
        this.store.dispatch(autoRefillSubscriptionFlowActions.submit());
    }
}
