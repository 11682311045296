import {Injectable} from "@angular/core";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";
import {EditCreditCardUseCase} from "../../../payment/domain/edit-credit-card.usecase";
import {genericObjectUpdateEffect} from "../../../@core/ngrx/generic-object/generic-object-update-effect";
import {savedCreditCardActions} from "../actions";
import {iif, map, mergeMap, of, switchMap, throwError} from "rxjs";
import {genericObjectLoadEffect} from "../../../@core/ngrx/generic-object/generic-object-load-effect";
import {GetCreditCardUseCase} from "../../../payment/domain/get-credit-card.usecase";
import {CreateCreditCardUseCase} from "../../../payment/domain/create-credit-card.usecase";
import {catchMap, isNotNil} from "../../../@core";
import {Action} from "@ngrx/store";
import {DeleteCreditCardUseCase} from "../../../payment/domain/delete-credit-card.usecase";
import {GeneralStoreFacade} from "../services";
import {DeleteCurrentAutoRefillPlanUseCase} from "../../../product/domain/delete-current-auto-refill-plan.usecase";
import {catchError, filter} from "rxjs/operators";
import {Response} from "../../../../base/response";

@Injectable()
export class SavedCreditCardEffects {

    constructor(private readonly actions$: Actions,
                private readonly editCreditCardUseCase: EditCreditCardUseCase,
                private readonly replaceCreditCardUseCase: CreateCreditCardUseCase,
                private readonly getCreditCardUseCase: GetCreditCardUseCase,
                private readonly deleteCreditCardUseCase: DeleteCreditCardUseCase,
                private readonly deleteAutoRefillPlan: DeleteCurrentAutoRefillPlanUseCase,
                private readonly store: GeneralStoreFacade) {
    }

    updateSavedCreditCard$ = genericObjectUpdateEffect(
        this.actions$,
        savedCreditCardActions,
        ({payload: {query, patch}}) =>
            this.editCreditCardUseCase.execute({
                expirationMonth: patch.expirationMonth as string,
                expirationYear: patch.expirationYear as string,
                firstName: patch.firstName as string,
                lastName: patch.lastName as string
            }).pipe(map(value => value.data)));

    replaceSavedCreditCard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(savedCreditCardActions.replace),
            switchMap(props =>
                this.replaceCreditCardUseCase.execute(props.payload).pipe(map(value =>
                    ({payload: value.data})))
            ),
            map(savedCreditCardActions.replaceSuccess),
            catchMap<Action, Error>(error => savedCreditCardActions.replaceFail({error}))
        ));

    reloadOnRemoveSuccessUpdateSuccess = createEffect(() =>
        this.actions$.pipe(
            ofType(
                savedCreditCardActions.replaceSuccess,
                savedCreditCardActions.removeSuccess,
                savedCreditCardActions.updateSuccess,
                savedCreditCardActions.updateFail),
            // we need not to fire update when there are cybercource tokens stored,
            // because this means card is just being saved for puchases
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            filter(value => !value.captureToken && !value.transientToken),
            map(value => savedCreditCardActions.reload({}))
        )
    )

    loadSavedCreditCard$ = genericObjectLoadEffect(
        this.actions$,
        savedCreditCardActions,
        () => {
            return this.getCreditCardUseCase.execute().pipe(
                catchError((err, caught) => {
                    if (err instanceof Response) {
                        return of(err);
                    }
                    return throwError(err);
                }),
                map(value => value.data)
            );
        }
    )

    removeSavedCreditCard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(savedCreditCardActions.remove),
            concatLatestFrom(() => [
                this.store.currentAutoRefillPlan$
            ]),
            mergeMap(([action, refillPlan]) =>
                iif(() => isNotNil(refillPlan),
                    this.deleteAutoRefillPlan.execute().pipe(
                        switchMap(() => this.deleteCreditCardUseCase.execute())
                    ),
                    this.deleteCreditCardUseCase.execute()
                )),
            map(savedCreditCardActions.removeSuccess),
            catchMap<Action, Error>(error => savedCreditCardActions.removeFail({error}))
        ));
}
