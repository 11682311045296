import {InjectionToken} from '@angular/core';
import {Observable} from "rxjs";

export const BBO_AUTHENTICATE_REPOSITORY = new InjectionToken<IAuthenticateRepository>("BBO_AUTHENTICATE_REPOSITORY");

export interface BBOAuthenticateAPIPayload {
    token: string;
    refreshToken: string;
}

export interface IAuthenticateRepository {
    login(username: string, password: string): Observable<{token: string; refreshToken: string}>;
    invalidateRefreshToken(refreshToken: string): Observable<void>;
    refresh(refreshToken: string): Observable<{token: string; refreshToken: string}>;
}
