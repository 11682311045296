import {Inject, Injectable} from "@angular/core";
import {IUseCase} from "src/base/usecase";
import {ApplicationError, IResponse, Response} from 'src/base/response';
import {catchError, map, Observable, throwError} from "rxjs";
import {HttpErrorResponse, HttpStatusCode} from "@angular/common/http";
import {BBO_PAYMENT_REPOSITORY, IPaymentRepository} from "../infrastructure/payment.interface";
import {BillingAddressRequest} from "src/app/billing-address/infrastructure/billing-address.interface";
import {CreditCard} from "../models";
import {Option} from "../../@core";


export enum RefillUseCaseError {
    LOCAL_UID_INVALID,
    BAD_REQUEST,
    GENERIC_ERROR
}

export interface RefillRequest {
    paymentType: 'cybersource',
    creditCard?: Option<CreditCard>,
    billingAddress?: BillingAddressRequest
}

export interface RefillResponse {
    paymentType: 'cybersource' | '',
    paymentStatus: string,
    amout: number,
    taxAmount: number,
    paypalOrderId: string,
    cybersourcePaymentId: string
}

@Injectable()
export class RefillUseCase implements IUseCase<{refillData: RefillRequest, refillOptionId: number}, Observable<IResponse<RefillResponse>>> {

    constructor(
        @Inject(BBO_PAYMENT_REPOSITORY) private repository: IPaymentRepository
    ) {}

    execute(data: { refillOptionId: number, refillData: RefillRequest }): Observable<IResponse<RefillResponse>> {
        return this.repository.refill(data.refillOptionId, data.refillData).pipe(
            catchError((error) => {
                let applicationError: ApplicationError | null = null;
                if (error instanceof HttpErrorResponse && (error.status === HttpStatusCode.BadRequest || error.status === HttpStatusCode.UnprocessableEntity)) {
                    applicationError = new ApplicationError(
                        RefillUseCaseError.BAD_REQUEST,
                        `${error.error.title}: ${error.error.detail}`,
                        error
                    )
                } else {
                    applicationError = new ApplicationError(
                        RefillUseCaseError.GENERIC_ERROR,
                        "An error occured while trying to save the credit card for local user",
                        error
                    );
                }
                return throwError(() => new Response(false, null, applicationError));
            }),
            map((response) => {
                const data: RefillResponse = response;
                return new Response(
                    true,
                    data
                );
            })
        );
    }
}
