import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";
import {OneTimePurchaseFlowFacade} from "../services";
import {RefillUseCase} from "../../../payment/domain/refill-use-case";
import {Injectable} from "@angular/core";
import {oneTimePurchaseFlowActions} from "../actions";
import {EMPTY, iif, map, mergeMap, of, switchMap, zip} from "rxjs";
import {catchError, filter} from "rxjs/operators";
import {isNotNil} from "../../../@core";
import {isNil} from "lodash";
import {PaypalApplyTaxesUseCase, PaypalCancelOrderUseCase, PaypalCompleteOrderUseCase} from "../../../paypal";
import {TaxesService} from "../../service/taxes.service";
import {GeneralStoreFacade} from "../../../bbo-store/store";
import {CreditCardService} from "../../../payment/service/credit-card.service";
import {CreditCard} from "../../../payment";
import {XlationCodes} from "../../../shared/translations/xlation.codes";
import {BillingAddressGetResponse} from "../../../billing-address/domain/get-billing-address.usecase";

@Injectable()
export class OneTimePurchaseFlowEffects {

    constructor(private readonly actions$: Actions,
                private readonly store: OneTimePurchaseFlowFacade,
                private readonly generalStoreFacade: GeneralStoreFacade,
                private readonly creditCardService: CreditCardService,
                private readonly refillUseCase: RefillUseCase,
                private readonly paypalApplyTaxesUseCase: PaypalApplyTaxesUseCase,
                private readonly paypalCompleteOrderUseCase: PaypalCompleteOrderUseCase,
                private readonly paypalOrderCancel: PaypalCancelOrderUseCase,
                private readonly cardTaxesService: TaxesService
    ) {
    }

    readonly isSelectedCardSavedCard$ = zip([
        this.store.selectedCreditCard$,
        this.generalStoreFacade.storedCreditCard$
    ]).pipe(
        map(value => {
            const card1 = value[0];
            const card2 = value[1];
            return card1?.last4DigitsCardNumber === card2?.last4DigitsCardNumber
        }));

    readonly submit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(oneTimePurchaseFlowActions.submit),
            concatLatestFrom(() => [
                this.store.selectedOfferProductId$.pipe(filter(isNotNil)),
                this.store.selectedPayment$.pipe(filter(isNotNil)),
                this.store.paymentMethodBillingAddress$,
                this.isSelectedCardSavedCard$
            ]),
            mergeMap(
                ([
                     // eslint-disable-next-line @typescript-eslint/no-unused-vars
                     flowstate,
                     refillOptionId,
                     payment,
                     billingAddress,
                     isSelectedCardSavedCard
                 ]) =>
                    iif(() =>
                            isNil(payment.paypal?.paypalOrderId),
                        // card payment
                        this.refillUseCase.execute({
                            refillOptionId,
                            refillData: {
                                paymentType: 'cybersource',
                                creditCard: payment.creditCard,
                                billingAddress: (isSelectedCardSavedCard) ? undefined : billingAddress
                            }
                        }).pipe(
                            map(value => value.data),
                            map(result => oneTimePurchaseFlowActions.submitSuccess({result})),
                            catchError(error => of(oneTimePurchaseFlowActions.submitFailure({error})))
                        )
                        // paypal payment
                        , (payment?.paypal?.approved) ? this.paypalCompleteOrderUseCase.execute({id: payment.paypal.paypalOrderId as string}).pipe(
                            map(res => res.data),
                            map(result => oneTimePurchaseFlowActions.submitSuccess({result})),
                            catchError(error => of(oneTimePurchaseFlowActions.submitFailure({error})))
                        ) : EMPTY
                    )
            )
        );
    })

    readonly openAddCreditCardModal = createEffect(() => {
        return this.actions$.pipe(
            ofType(oneTimePurchaseFlowActions.update),
            map(value => value.payload.patch.selectedPaymentMethod),
            filter(isNotNil),
            filter(value => value === 'credit-card'),
            concatLatestFrom(() => [
                    this.generalStoreFacade.storedCreditCard$,
                    this.generalStoreFacade.billingAddress$,
                    this.generalStoreFacade.savedCreditCardLoading$
                ]
            ),
            switchMap(value => {
                    return (isNotNil(value[1])) ?
                        of(oneTimePurchaseFlowActions.updateSuccess({
                            payload: {
                                updated: {selectedPaymentMethod: value[0]},
                                patch: {
                                    selectedPaymentMethod: value[0],
                                    selectedPayment: {creditCard: value[1], method: 'credit-card'},
                                }
                            }
                        }))
                        : this.creditCardService.openFillFormModal({
                            submitButtonLabel: XlationCodes.continue,
                            showSaveCard: true
                        }).pipe(
                            map(value =>
                                oneTimePurchaseFlowActions.update(
                                    {
                                        payload: {
                                            patch: {
                                                selectedPayment: {
                                                    method: "credit-card",
                                                    creditCard: value.creditCard as CreditCard
                                                },
                                                billingAddress: value.billingAddress
                                            }
                                        }
                                    }
                                )
                            ),
                        )
                }
            ),
        )
    })

    readonly updateCreditCardFromUpdateFlow$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(oneTimePurchaseFlowActions.update),
            filter(value => isNotNil(value.payload.patch.selectedPayment?.creditCard)),
            map(value => value.payload.patch.selectedPayment?.creditCard),
            switchMap(creditCard =>
                of(oneTimePurchaseFlowActions.updateCreditCard({
                    payload: {creditCard: creditCard as CreditCard},
                    initiatedByUser: false
                }))
            )
        )
    });

    readonly applyCardPaymentTaxes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(oneTimePurchaseFlowActions.updateCreditCard),
            filter(value => isNotNil(value.payload.creditCard)),
            map(value => value.payload.creditCard),
            concatLatestFrom(() => [
                this.store.selectedOfferPrice$.pipe(filter(isNotNil)),
                this.store.selectedPayment$.pipe(filter(isNotNil)),
                this.store.paymentMethodBillingAddress$
            ]),
            switchMap(value =>
                this.cardTaxesService.calculateTaxes(value[1], value[3] as BillingAddressGetResponse)
                    .pipe(
                        map(taxes => oneTimePurchaseFlowActions.updateCreditCardTaxes(
                            {payload: {taxes: (taxes / 100)}}
                        )),
                        catchError(error => of(oneTimePurchaseFlowActions.submitFailure({error})))
                    )
            )
        )
    })

    readonly applyPaypalTaxes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(oneTimePurchaseFlowActions.update),
            map(value => value.payload.patch.selectedPayment),
            filter(isNotNil),
            filter(value => value.paypal?.approved || false),
            filter(value => isNotNil(value.paypal?.paypalOrderId)),
            concatLatestFrom(() => [
                this.store.selectedPayment$.pipe(filter(isNotNil))
            ]),
            switchMap(value => this.paypalApplyTaxesUseCase.execute({id: value[0].paypal?.paypalOrderId as string})
                .pipe(
                    map(value => Number(value.data.payload.purchase_units[0].amount.breakdown.tax_total.value)),
                    map(taxes => oneTimePurchaseFlowActions.updatePaypalTax(
                        {taxes}
                    )),
                    catchError(error => of(oneTimePurchaseFlowActions.submitFailure({error})))
                )
            )
        )
    })

    readonly cancelPaypalOrder$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(oneTimePurchaseFlowActions.cancelPaypalOrder),
            concatLatestFrom(() => [
                this.store.selectedPayment$.pipe(filter(isNotNil),
                    map(value => value.paypal?.paypalOrderId),
                    filter(isNotNil)
                )
            ]),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            switchMap(([action, id]) =>
                this.paypalOrderCancel.execute({id})
                    .pipe(
                        map(value => oneTimePurchaseFlowActions.update({payload: {patch: {selectedPayment: undefined}}})),
                        catchError(error => of(oneTimePurchaseFlowActions.cancelPaypalOrderFailure()))
                    ),
            )
        )
    })
}
