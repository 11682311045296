import {InjectionToken} from '@angular/core';
import {Observable} from "rxjs";

export const BBO_BILLING_ADDRESS_REPOSITORY = new InjectionToken<IBillingAddressRepository>("BBO_BILLING_ADDRESS_REPOSITORY");

export interface BillingAddressGetResponse {
    owner: {
        id: number
    };
    addressLine1: string;
    addressLine2: string;
    state: string;
    city: string;
    countryCode: string;
    zipCode: string;
    email: string;
}

export interface BillingAddressSaveResponse {
    owner: {
        id: number
    };
    addressLine1: string;
    addressLine2: string;
    state: string;
    city: string;
    countryCode: string;
    zipCode: string;
    email: string;
}

export interface BillingAddressRequest {
    addressLine1: string;
    addressLine2: string;
    state: string;
    city: string;
    countryCode: string;
    zipCode: string;
    email: string;
}

export interface IBillingAddressRepository {
    getBillingAddress(uid: string): Observable<BillingAddressGetResponse>;
    postBillingAddress(uid: string, body: BillingAddressRequest): Observable<BillingAddressSaveResponse>;
}
