import {createGenericObjectReducer} from "../../../@core";
import {OneTimePurchaseFlow, OneTimePurchaseFlowActions, oneTimePurchaseFlowActions} from "../actions";
import {RefillResponse} from "../../../payment/domain/refill-use-case";
import {CompleteOrderResponse} from "../../../paypal";
import {on} from "@ngrx/store";
import {CreditCard} from "../../../payment";

const {initialState, reducer} =
    createGenericObjectReducer<OneTimePurchaseFlow, RefillResponse | CompleteOrderResponse, Partial<OneTimePurchaseFlow>>(oneTimePurchaseFlowActions)(
        on(oneTimePurchaseFlowActions.updatePaypalTax, (state, {taxes}) => ({
            ...state,
            data: {
                ...state.data,
                selectedPayment: {
                    ...state.data?.selectedPayment,
                    taxes: taxes
                }
            }
        })),
        on(oneTimePurchaseFlowActions.updateCreditCard, (state, {payload}) => ({
            ...state,
            data: {
                ...state.data,
                selectedPayment: {
                    taxes: state.data?.selectedPayment?.taxes,
                    creditCard: {...state.data?.selectedPayment?.creditCard, ...payload.creditCard}
                }
            }
        })),
        on(oneTimePurchaseFlowActions.updateCreditCardTaxes, (state, {payload}) => ({
            ...state,
            data: {
                ...state.data,
                selectedPayment: {
                    taxes: payload.taxes,
                    creditCard: {
                        ...state.data?.selectedPayment?.creditCard as CreditCard,
                        type: state.data?.selectedPayment?.creditCard?.type as string
                    }
                }
            }
        }))
    );

export type OneTimePurchaseFlowState = typeof initialState;
export {initialState};

export function oneTimePurchaseFlowReducer(
    state: OneTimePurchaseFlowState | undefined,
    action: OneTimePurchaseFlowActions
) {
    return reducer(state, action);
}

