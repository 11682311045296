import {Inject, Injectable} from "@angular/core";
import {BBO_PRODUCT_REPOSITORY, IProductRepository} from "src/app/product/infrastructure/product.interface";
import {IUseCase} from "src/base/usecase";
import {ApplicationError, IResponse, Response} from 'src/base/response';
import {catchError, map, Observable, throwError} from "rxjs";

export enum GetCurrentOneTimePurchaseUseCaseError {
    GENERIC_ERROR,
}

export interface ProductOneTimePurchaseOffer {
    price: number;
    bboCurrencyAmount: number;
    productId: number;
    recommended: boolean;
}

@Injectable()
export class GetOneTimePurchaseOffersUseCase implements IUseCase<void, Observable<IResponse<ProductOneTimePurchaseOffer[]>>> {

    constructor(
        @Inject(BBO_PRODUCT_REPOSITORY) private repository: IProductRepository,
    ) {}

    execute(): Observable<IResponse<ProductOneTimePurchaseOffer[]>> {
        return this.repository.getOneTimePurchaseProductList().pipe(
            map((response) => {
                const offers = response.map((rawOffer) => ({
                    bboCurrencyAmount: rawOffer.price,
                    price: rawOffer.price,
                    productId: rawOffer.productId,
                    recommended: rawOffer.recommended
                }))
                return new Response(
                    true,
                    offers
                );
            }),
            catchError((error) => {
                return throwError(() => new Response(
                        false,
                        null,
                        new ApplicationError(
                            GetCurrentOneTimePurchaseUseCaseError.GENERIC_ERROR,
                            'GetOneTimePurchaseOffersUseCase usecase unexpected error',
                            error
                        )
                ));
            })
        );
    }
}
