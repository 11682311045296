import { HttpErrorResponse } from "@angular/common/http";

export * from "./actions-base";
export * from "./load-failure";
export * from "./load-props-base";
export * from "./raw-action-creator";
export * from "./action-with-props-creator";
export * from "./catch-map";
export * from "./nullable";

export type Nil = null | undefined;
export type Option<T> = T | Nil;
export const isNotNil = <T>(test: Option<T>): test is T =>
    test !== undefined && test !== null;
export const isHttpError = (error: any): error is HttpErrorResponse =>
    error instanceof HttpErrorResponse;
export const isApiHttpError = (error: HttpErrorResponse): error is HttpErrorResponse =>
    (error?.error?.innerError) instanceof HttpErrorResponse;
