import {Component} from '@angular/core';

@Component({
  selector: 'bbo-offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.scss']
})
export class OfferCardComponent {

}
