import {createGenericObjectActions, EntityAction, Option} from "src/app/@core";
import {ProductOneTimePurchaseOffer} from "../../domain";
import {BillingAddressRequest} from "../../../billing-address/infrastructure/billing-address.interface";
import {CreditCard, Payment, PaymentMethod} from "../../../payment";
import {RefillResponse} from "../../../payment/domain/refill-use-case";
import {CompleteOrderResponse} from "../../../paypal";
import {createAction, props} from "@ngrx/store";

export interface OneTimePurchaseFlow {
    readonly selectedOffer?: ProductOneTimePurchaseOffer;
    readonly selectedPaymentMethod?: Option<PaymentMethod>;
    readonly selectedPayment?: Partial<Payment>;
    readonly billingAddress?: BillingAddressRequest;
}

const cancelPaypalOrder = createAction('[OneTime Purchase Flow] Cancel Paypal Order');
const updatePaypalTax = createAction('[OneTime Purchase Flow] PayPal tax update', props<{ taxes: number }>());
const cancelPaypalOrderFailure = createAction('[OneTime Purchase Flow] Cancel Paypal Order Failure');
const updateCreditCard = createAction('[OneTime Purchase Flow] Update Credit Card', props<{
    payload: { creditCard: CreditCard },
    /**
     * For tracking purposes.
     * We want to track when user add or update a credit card while inside the purchase funnel.
     * I tried to create an effect listening to oneTimePurchaseFlowActions.updateCreditCard but
     * updateCreditCard it often dispatched when OneTimePurchaseComponent is created (to update the user interface)
     * so it will create fake positive tracking events.
     *
     * In the future we should remove that field and create more actions.
     */
    initiatedByUser: boolean
}>());
const updateCreditCardTaxes = createAction('[OneTime Purchase Flow] Update Credit Card Taxes', props<{
    payload: { taxes: number }
}>());

export const oneTimePurchaseFlowActions =
    {
        ...(createGenericObjectActions<OneTimePurchaseFlow, RefillResponse | CompleteOrderResponse, Partial<OneTimePurchaseFlow>>('OneTime Purchase Flow')),
        cancelPaypalOrder,
        cancelPaypalOrderFailure,
        updatePaypalTax,
        updateCreditCard,
        updateCreditCardTaxes
    };
export type OneTimePurchaseFlowActions = EntityAction<OneTimePurchaseFlow, RefillResponse | CompleteOrderResponse, Partial<OneTimePurchaseFlow>>;
