import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, catchError, tap, throwError} from 'rxjs';
import {COUNTRIES, CountryDefinition} from 'src/app/billing-address/domain/countries';
import {BillingAddressGetResponse} from 'src/app/billing-address/domain/get-billing-address.usecase';
import {CreditCardFormComponent, ICreditCardForm} from 'src/app/payment/ui/credit-card-form/credit-card-form.component';
import {IBillingAddressForm} from '../../../billing-address/ui/billing-address-form/billing-address-form.component';
import {CreditCard} from '../../models';
import {XlationCodes} from 'src/app/shared/translations/xlation.codes';
import {CreditCardEditRequest} from "../../domain/edit-credit-card.usecase";
import {isNotNil} from "../../../@core";
import jwt_decode from "jwt-decode";
import {
    mapperBillingAddressFormToRequest,
    mapperCreateCreditCardFormToRequest,
    mapperEditCreditCardFormToRequest
} from "../index";
import {BillingAddressRequest} from "../../../billing-address/domain/save-billing-address.usecase";
import {take} from "rxjs/operators";
import {
    DeleteCreditCardConfirmationModalComponent
} from '../delete-credit-card-confirmation-modal/delete-credit-card-confirmation-modal.component';
import {GeneralStoreFacade} from "../../../bbo-store/store";
import {customZipCodeValidator} from "./validators";
import { Moment } from 'moment';
import * as moment from 'moment';
import { isNil } from 'lodash';

export interface CreditCardFormDialogInput {
    readonly creditCard?: CreditCard | null;
    readonly billingAddress?: BillingAddressGetResponse | null;
    readonly isOneTimeBuying?: boolean;
    readonly showSaveCard?: boolean;
    readonly shouldSaveCreditCard?: boolean;
    readonly submitButtonLabel?: string;
}

export interface CreditCardFormDialogOutput {
    readonly creditCard?: CreditCardEditRequest | CreditCard | undefined;
    readonly billingAddress?: BillingAddressRequest;
    readonly saveCardDetails?: boolean;
    readonly deleteCard?: boolean;
}

// cyber source expiration timeout value (15 minutes)
const SECURE_TOKEN_EXPIRATION_TIMEOUT = 900000;


@Component({
    selector: 'bbo-credit-card-form-dialog',
    templateUrl: './credit-card-form-dialog.component.html',
    styleUrls: ['./credit-card-form-dialog.component.scss'],
})
export class CreditCardFormDialogComponent implements OnInit, OnDestroy {
    xlationCodes = XlationCodes;
    @ViewChild(CreditCardFormComponent) creditCardFormComponent!: CreditCardFormComponent;

    form = this.formBuilder.group({
        billingAddress: new FormGroup<IBillingAddressForm>({
            address: new FormControl<string>("", {validators: [Validators.maxLength(60), Validators.required]}),
            'address-2': new FormControl<string>("", {validators: [Validators.maxLength(60)]}),
            city: new FormControl<string>("", {validators: [Validators.required]}),
            region: new FormControl<string>("", {validators: [Validators.required]}),
            postalCode: new FormControl<string>("", {validators: [Validators.required]}),
            country: new FormControl<CountryDefinition | null>(null, {validators: [Validators.minLength(1), Validators.maxLength(100), Validators.required]}),
            email: new FormControl<string>("", {validators: [Validators.required]})
        }, {validators: customZipCodeValidator}),
        creditCard: new FormGroup<ICreditCardForm>({
            firstName: new FormControl<string>('', [Validators.required]),
            lastName: new FormControl<string>('', [Validators.required]),
            type: new FormControl<string>(CreditCardFormComponent.CARD_PROVIDERS[0]?.id ?? '', [Validators.required]),
            cardNumber: new FormControl<string>(''),   // Validation is handled in the component because inputs are from
            cvv: new FormControl<string>(''),          // a third party (cybersource) and we can't have acces to the inner value
            expirationDate: new FormControl<Moment | null>(null, [Validators.required])
        })
    });
    loading$ = new BehaviorSubject(false);
    savedCreditCardLoading$ = this.generalStore.savedCreditCardLoading$;
    cybersourceFormInstanciateError = false;
    atLeastOneNgSubmit = false;
    globalErrorMessageList: string[] = [];
    formTitleKey = "";
    formSubmitButtonKey = "";
    captureToken = "";
    saveCardDetails = true;

    private tokenExpirationTimeoutId!: number;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: CreditCardFormDialogInput,
        private readonly formBuilder: FormBuilder,
        private readonly dialogRef: MatDialogRef<CreditCardFormDialogComponent, CreditCardFormDialogOutput>,
        private readonly matDialog: MatDialog,
        private readonly generalStore: GeneralStoreFacade
    ) {
        this.initLabels();
    }

    initLabels() {
        if (isNotNil(this.data?.creditCard)) {
            this.formTitleKey = XlationCodes.editCreditCardDetails;
            this.formSubmitButtonKey = XlationCodes.saveAndReturn
        } else {
            this.formTitleKey = XlationCodes.newPaymentCard;
            this.formSubmitButtonKey = XlationCodes.saveAndReturn;
        }
        if (this.data?.isOneTimeBuying) {
            this.formTitleKey = XlationCodes.payWithCreditCard;
            this.formSubmitButtonKey = XlationCodes.payNow;
        }
        if (this.data?.submitButtonLabel) {
            this.formSubmitButtonKey = this.data?.submitButtonLabel
        }
    }

    private patchBillingAddressForm(response: BillingAddressGetResponse): void {
        const country = COUNTRIES.find((value) => value.id === response.countryCode) ?? null;
        let state = response.state;
        if (country?.states?.aspect === "select") {
            state = country.states.values?.find((value) => value.id === response.state)?.id ?? '';
        }
        this.form.controls.billingAddress.setValue(
            {
                address: response.addressLine1,
                "address-2": response.addressLine2,
                city: response.city,
                country,
                postalCode: response.zipCode,
                region: state,
                email: response.email
            }
        );
    }

    private patchCreditCardForm(response: CreditCard): void {
        // moment months are zero indexed, so January is month 0.
        const expirationMonthAsNumber = parseInt(response.expirationMonth, 10) - 1;
        this.form.controls.creditCard.setValue({
            firstName: response.firstName,
            lastName: response.lastName,
            expirationDate: moment().month(expirationMonthAsNumber).year(parseInt(response.expirationYear, 10)),
            type: response.type,
            // Now because it's on edit mode, some values are faked for security measures
            // user will have to delete and create a new card
            cardNumber: `ending with •••• ${response.last4DigitsCardNumber}`,
            cvv: '•••'
        });
    }

    onSubmitForm(): void {
        this.atLeastOneNgSubmit = true;
        if (this.loading$.getValue()) {
            return;
        }
        this.globalErrorMessageList = [];
        this.loading$.next(true);
        this.form.markAllAsTouched(); // mark every controls so that inputs that have touched

        // check if captureToken is not empty if we create a credit card, if we are in edition mode
        // we don't require this captureToken
        if (this.form.invalid || (isNil(this.data?.creditCard) && this.captureToken === '')) {
            this.loading$.next(false);
            return;
        }

        if (isNotNil(this.data?.creditCard)) {
            const creditCard =
                mapperEditCreditCardFormToRequest(this.form.controls.creditCard.controls);
            const billingAddress =
                mapperBillingAddressFormToRequest(this.form.controls.billingAddress.controls);
            this.loading$.next(false);
            this.dialogRef.close({creditCard, billingAddress});
        } else {
            this.creditCardFormComponent.exportSecureToken().pipe(
                catchError((error) => {
                    console.warn("Error when exporting the token from the cybersource secure form", error);
                    this.globalErrorMessageList.push("An error occurred while validating your credit card informations.");
                    // in that case we rethrow because we cannot continue the flow without the secure token
                    this.loading$.next(false);
                    return throwError(() => error);
                })
            ).subscribe(({transientToken, expirationMonth, expirationYear}) => {
                //getting credit card last 4 digits from jwt token
                const tokenResult: any = jwt_decode(transientToken);
                const last4Digits = tokenResult?.content?.paymentInformation?.card?.number?.maskedValue?.replace(/[X]/g, '');
                const creditCard =
                    mapperCreateCreditCardFormToRequest(this.form.controls.creditCard.controls, expirationMonth, expirationYear, last4Digits, this.captureToken, transientToken);
                const billingAddress =
                    mapperBillingAddressFormToRequest(this.form.controls.billingAddress.controls);
                this.loading$.next(false);
                if (this.data?.showSaveCard && this.saveCardDetails) this.handleSaveCardForFuturePurchases(creditCard, billingAddress);
                this.dialogRef.close({creditCard, billingAddress, saveCardDetails: this.saveCardDetails || this.data?.shouldSaveCreditCard});
            })
        }
    }

    handleSaveCardForFuturePurchases(card: CreditCard, billingAddress: BillingAddressRequest) {
        this.generalStore.replaceSavedCreditCard(card);
        this.generalStore.updateSavedBillingAddress(billingAddress);
    }

    onSecureFormCreated(captureToken: string): void {
        this.captureToken = captureToken;
        this.tokenExpirationTimeoutId = window.setTimeout(() => {
            this.onCancelClick();
        }, SECURE_TOKEN_EXPIRATION_TIMEOUT)
    }

    ngOnInit(): void {
        if (this.data?.creditCard) {
            this.patchCreditCardForm(this.data.creditCard);
        }
        if (this.data?.billingAddress) {
            this.patchBillingAddressForm(this.data.billingAddress);
        }
    }

    onCancelClick(): void {
        this.dialogRef.close()
    }

    ngOnDestroy(): void {
        if (this.tokenExpirationTimeoutId) {
            window.clearTimeout(this.tokenExpirationTimeoutId)
        }
    }

    deleteSavedCard() {
        this.matDialog.open<DeleteCreditCardConfirmationModalComponent, undefined, boolean>(
            DeleteCreditCardConfirmationModalComponent,
            {
                backdropClass: 'bbo-backdrop-blurred',
                panelClass: ['bbo-dialog'],
                ariaModal: true,
                autoFocus: false
            }
        )
            // after modal closure check the user response about delete this credit card, if he wants to keep his credit card so return empty
            // in case of the user confirms his credit card deletion then check if an autorefill is present for this user
            // if it is, so delete it at first and then call the credit card deletion.
            // in case of the user doesn't have an autorefill just delete his credit card directly
            .afterClosed()
            .pipe(
                take(1),
                tap((result) => {
                    if (result) {
                        this.generalStore.deleteCreditCard();
                    }
                })
            )
            .subscribe(() => this.dialogRef.close({deleteCard: true}));
    }
}
