import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {EntityStateSlice} from "./generic-object-reducer";
import {get} from "lodash";

/**
 * @description
 *  Type params:
 *  - D for Data - represents the basic DTO entity type
 *  - Ext for Extension - represents possible additional data in store
 */
export const getEntityFeatureSelector = <
    D,
    R,
    Ext extends object = never,
>(
    feature: string
) => createFeatureSelector<EntityStateSlice<D, R, Ext>>(feature);

/**
 * @description
 *  Type params:
 *  - D for Data - represents the basic DTO entity type
 *  - Ext for Extension - represents possible additional data in store
 */
export type EntityStateSelector<
    D,
    R,
    Ext extends object = never,
> = MemoizedSelector<object, EntityStateSlice<D, R, Ext>>;

/**
 * @description
 *  Type params:
 *  - D for Data - represents the basic DTO entity type
 *  - Ext for Extension - represents possible additional data in store
 */
export const genericObjectSelectors = <
    D,
    R,
    Ext extends object = never,
>(
    getState: EntityStateSelector<D, R, Ext>
) => {
    const base = {
        data: createSelector(getState, ({data}) => data),
        result: createSelector(getState, ({result}) => result),
        loading: createSelector(getState, ({loading}) => loading),
        loaded: createSelector(getState, ({loaded}) => loaded),
        extension: createSelector(getState, ({extension}) => extension),
        error: createSelector(getState, ({error}) => error)
    };

    return {
        ...base,
        state: getState,
        pending: createSelector(
            base.loaded,
            base.loading,
            (loaded, loading) => (loading || !loaded)
        )
    };
};
